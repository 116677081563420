import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import axios from "axios";
import React, { useState } from "react";
import { toast, Toaster } from "react-hot-toast";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { CustomPagination } from "../../../components/custom/Custom.DataGrid";
import {
  toastDangerStyle,
  toastSuccessStyle,
  toastWarnigStyle,
} from "../../../components/shared/Custom.Styles";
import { API_URLS } from "../../../utils/api.routes";
import DepartmentActions from "./DepartmentActions";

function Department() {
  const [name, setName] = useState("");

  // For table list view
  const [rowId, setRowId] = useState(null);
  const [pageSize, setPageSize] = useState(5);

  let headersList = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };

  let bodyContent = JSON.stringify({
    name: name,
  });
  const handleSubmit = async (e) => {
    e.preventDefault();
    await fetch(API_URLS.department, {
      method: "POST",
      body: bodyContent,
      headers: headersList,
    })
      .then((res) => {
        if (res.status === 201) {
          setName("");
          refetch();
          toast.success(`Created Successfully`, toastSuccessStyle);
        } else if (res.status === 400) {
          toast.error(`${res.statusText}`, toastDangerStyle);
          setTimeout(() => {
            toast(`Department Already Exists`, toastWarnigStyle);
          }, 1000);
        } else {
          toast.error(`${res.status} ${res.statusText}`, toastDangerStyle);
        }
      })
      .catch((err) => {
        toast.error(`Error Not Found !`, toastDangerStyle);
      });
  };

  async function fetchData() {
    const { data } = await axios.get(API_URLS.department);
    return data;
  }
  const { data, error, isError, isLoading, refetch } = useQuery(
    "departments",
    fetchData
  );
  if (isLoading) {
    return <div className="main-content">Loading...</div>;
  }
  if (isError) {
    return <div className="main-content">Error!! {error.message}</div>;
  }
  const columns = [
    {
      field: "id",
      headerName: "SL",
      headerAlign: "center",
      type: "number",
      hide: true,
      width: "80",
    },
    {
      field: "name",
      headerName: "Department",
      headerAlign: "center",
      editable: true,
      width: 250,
    },
    {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      width: 150,
      renderCell: (params) => (
        <DepartmentActions {...{ params, rowId, setRowId }} />
      ),
    },
  ];

  return (
    <div className="main-content">
      <div className="row">
        <div className="col-12 col-md-12 col-lg-12">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/super-admin-dashboard">Home</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Departments
              </li>
            </ol>
          </nav>
        </div>
        <div className="col-md-12 col-lg-4 col-xl-4">
          <div className="shadow-sm p-3 mb-5 bg-white rounded">
            <h5 className="text-centter text-dark">New Department</h5>
            <form onSubmit={handleSubmit}>
              <div className="card-body">
                <div className="form-group">
                  <label htmlFor="dName">Designation Title</label>
                  <input
                    type="text"
                    id="dName"
                    className="form-control"
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
              </div>
              <div className="text-center">
                <button className="btn btn-primary mr-1" type="submit">
                  Add Department
                </button>
                <Toaster position="top-right" reverseOrder={false} />
              </div>
            </form>
          </div>
        </div>
        <div className="col-md-12 col-lg-6 col-xl-6">
          <div className="shadow-sm p-3 mb-5 bg-white rounded">
            <h4 className="text-center text-dark">Departments List</h4>
            <div style={{ height: 420 }}>
              <DataGrid
                columns={columns}
                components={{
                  Toolbar: GridToolbar,
                  Pagination: CustomPagination,
                }}
                rows={data}
                getRowId={(row) => row.id}
                rowsPerPageOptions={[5, 10, 20]}
                rowHeight={45}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                getRowSpacing={(params) => ({
                  top: params.isFirstVisible ? 0 : 5,
                  bottom: params.isLastVisible ? 0 : 5,
                })}
                onCellEditCommit={(params) => setRowId(params.id)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Department;
