import React from "react";

export default function AccessDeniedPage() {
  return (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{ minHeight: "100vh" }}
    >
      <h2 className="text-danger">Error ... Page Not Found</h2>
    </div>
  );
}
