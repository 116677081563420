import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { API_URLS } from "../../utils/api.routes";
import ListIssueActions from "./ALLissuedbooksActions";

function ExampleComponent() {
  const [issue, setIssue] = useState([]);
  const [rowId, setRowId] = useState(null);
  const [pageSize, setPageSize] = useState(5);

  useEffect(() => {
    fetch(API_URLS.library_issue)
      .then((response) => response.json())
      .then((issue) => {
        console.log(issue.data); // added this line
        setIssue(issue.data);
      });
  }, []);

  const columns = [
    {
      field: "member_id",
      headerName: "Member ID",
      hide: true,
      headerAlign: "center",
      width: 80,
    },
    {
      field: "date",
      headerName: "Isue Date",
      type: "number",
      hide: true,
      headerAlign: "center",
      width: 80,
    },
    {
      field: "days",
      headerName: "ISsue Days",
      headerAlign: "center",
      hide: true,
      editable: true,
      width: 100,
    },
    {
      field: "return_date",
      headerName: "Return Date",
      headerAlign: "center",
      hidable: false,
      editable: true,
      width: 130,
    },
    {
      field: "charges",
      headerName: "Charges",
      headerAlign: "center",
      hidable: false,
      editable: true,
      width: 130,
    },
    {
      field: "token",
      headerName: "Token",
      headerAlign: "center",
      hidable: false,
      editable: true,
      width: 130,
    },
    {
      field: "isbn_no",
      headerName: "ISBN NO",
      headerAlign: "center",
      hidable: false,
      editable: true,
      width: 130,
    },

    {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      renderCell: (params) => (
        <ListIssueActions {...{ params, rowId, setRowId }} />
      ),
    },
  ];

  return (
    <div className="main-content">
      <div className="row justify-content-center">
        <div className="col-12 col-md-12 col-lg-12">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/admin">Home</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Issued Books List
              </li>
              <div className="col-4">
                <Link to="/admin/libary/add-book" className="font-weight-bold">
                  Issue New Book
                </Link>
              </div>
            </ol>
          </nav>
        </div>
        <div className="col-md-12 col-lg-12 col-xl-12">
          <div className="shadow-sm p-3 mb-5 bg-white rounded">
            <div className="text-center">
              <h5 className="text-center text-dark">Class Rooms List</h5>
            </div>
            <div style={{ height: 420 }}>
              <DataGrid
                columns={columns}
                components={{
                  Toolbar: GridToolbar,
                }}
                rows={issue}
                getRowId={(row) => row.id}
                rowsPerPageOptions={[5, 10, 20]}
                rowHeight={45}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                getRowSpacing={(params) => ({
                  top: params.isFirstVisible ? 0 : 5,
                  bottom: params.isLastVisible ? 0 : 5,
                })}
                onCellEditCommit={(params) => setRowId(params.id)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ExampleComponent;
