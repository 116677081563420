import React from "react";
import { API_URLS } from "../../../utils/api.routes";
import { useEffect } from "react";
import { useState } from "react";
import { CustomClassroomTimetable } from "./CustomTimetable";

export default function ClassroomTimetable({ classroom }) {
  const [days, setDays] = useState([]);
  const [timeplan, setTimeplan] = useState([]);
  const [timeTable, setTimeTable] = useState([]);
  const [currentTerm, setCurrentTerm] = useState("");

  useEffect(() => {
    const fetchDays = async () => {
      const res = await fetch(API_URLS.days);
      const data = await res.json();
      setDays(data);
    };
    const fetchTimes = async () => {
      const res = await fetch(API_URLS.timeplan);
      const data = await res.json();
      setTimeplan(data);
    };
    const fetchCurrentAcademicTerm = async () => {
      const res = await fetch(API_URLS.current_academic_term);
      const data = await res.json();
      setCurrentTerm(data.id);
    };
    fetchDays();
    fetchTimes();
    fetchCurrentAcademicTerm();
  }, []);

  useEffect(() => {
    const fetchTimeTable = async () => {
      const res = await fetch(
        `${API_URLS.class_timetable}?grade=${classroom}&term=${currentTerm}`
      );
      const data = await res.json();
      setTimeTable(data);
    };
    fetchTimeTable();
  }, [currentTerm]);
  return (
    <div className="col-12 d-flex flex-wrap">
      <h4 className="col-12 text-info text-center">
        Timetable for {classroom}
      </h4>
      <div className="col-12">
        {timeTable && (
          <CustomClassroomTimetable
            tableData={timeTable}
            days={days}
            timeplan={timeplan}
          />
        )}
      </div>
    </div>
  );
}
