import React from "react";
import { toast, Toaster } from "react-hot-toast";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  toastDangerStyle,
  toastSuccessStyle,
} from "../../../components/shared/Custom.Styles";
import { API_URLS } from "../../../utils/api.routes";

function StudentClassAllocationDetails() {
  const { state } = useLocation();
  const navigate = useNavigate();

  const editAllocation = () => {
    navigate("/admin/student-allocation/edit", {
      state: state,
    });
  };
  const deleteAllocation = async () => {
    const headersList = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    await fetch(API_URLS.class_allocation.concat(state.id, "/"), {
      method: "DELETE",
      headers: headersList,
    })
      .then((res) => {
        if (res.status === 204) {
          toast.success(
            `Deleted Classroom Allocation Successfully`,
            toastSuccessStyle
          );
          setTimeout(() => {
            navigate("/admin/student-allocation/list");
          }, 1000);
        } else {
          toast.error(`Error !!! ${res.statusText}`, toastDangerStyle);
        }
      })
      .catch((err) => {
        toast.error(`Server Error !!! Not Found`, toastDangerStyle);
      });
  };
  return (
    <div className="main-content">
      <div className="row">
        <div className="col-12 col-sm-12 col-md-8 ">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/admin">Home</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/admin/classrooms/list">Classrooms</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Classroom Details
              </li>
            </ol>
          </nav>
        </div>
        <div className="col-12 col-sm-12 col-md-10 justify-content-center">
          <div className="card shadow-sm p-3 mb-5 bg-body rounded">
            <div className="card-header">
              <h4>Class Room Info</h4>
            </div>
            <div className="row card-body">
              <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                <p className="clearfix">
                  <span className="float-left font-weight-bold">
                    Student ID
                  </span>
                  <span className="float-right text-info font-weight-bold">
                    {state.id}
                  </span>
                </p>
                <p className="clearfix">
                  <span className="float-left font-weight-bold">
                    Student Name
                  </span>
                  <span className="float-right text-info font-weight-bold">
                    {`${state.first_name} ${state.last_name}`}
                  </span>
                </p>
                <p className="clearfix">
                  <span className="float-left font-weight-bold">
                    Admission No
                  </span>
                  <span className="float-right text-info font-weight-bold">
                    {state.admn_no}
                  </span>
                </p>
              </div>
              <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                <p className="clearfix">
                  <span className="float-left font-weight-bold">Stream</span>
                  <span className="float-right text-info font-weight-bold">
                    {state.stream}
                  </span>
                </p>
                <p className="clearfix">
                  <span className="float-left font-weight-bold">
                    Room Alocated
                  </span>
                  <span className="float-right text-info font-weight-bold">
                    {state.class_room_name}
                  </span>
                </p>
                <p className="clearfix">
                  <span className="float-left font-weight-bold">
                    Academic Year
                  </span>
                  <span className="float-right text-info font-weight-bold">
                    {state.year_name}
                  </span>
                </p>
              </div>
              <div className="col-12">
                <button
                  onClick={() => {
                    editAllocation();
                  }}
                  className="btn btn-primary float-left"
                >
                  Edit
                </button>
                <button
                  onClick={() => {
                    deleteAllocation();
                  }}
                  className="btn btn-danger float-right"
                >
                  Delete
                </button>
                <Toaster position="top-right" reverseOrder={false} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StudentClassAllocationDetails;
