import React from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";

const EmergencyContact = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  return (
    <>
      <div className="d-flex flex-wrap justify-content-center">
        <div className="col-12">
          <div className="col-12">
            <h6 className="text-dark text-center">Emergency Contact 1</h6>
          </div>
          <div className="col-12 d-flex flex-wrap">
            <div></div>
            <div className="col-6 col-md-4 col-lg-4">
              <Controller
                control={control}
                name="emerg_first_name1"
                rules={{ required: "this field is required." }}
                render={({ field }) => (
                  <TextField
                    id="emerg-first-name1"
                    label="First Name *"
                    variant="outlined"
                    placeholder="Enter Your First Name"
                    fullWidth
                    margin="normal"
                    {...field}
                  />
                )}
                error={Boolean(errors?.emerg_first_name1)}
                helperText={errors.emerg_first_name1?.message}
              />
            </div>
            <div className="col-6 col-md-4 col-lg-4">
              <Controller
                control={control}
                name="emerg_last_name1"
                rules={{ required: "this field is required." }}
                render={({ field }) => (
                  <TextField
                    id="emerg-last-name2"
                    label="Last Name *"
                    variant="outlined"
                    placeholder="Enter Last Name"
                    fullWidth
                    margin="normal"
                    {...field}
                    error={Boolean(errors?.emerg_last_name1)}
                    helperText={errors.emerg_last_name1?.message}
                  />
                )}
              />
            </div>
            <div className="col-6 col-md-4 col-lg-4">
              <Controller
                control={control}
                name="emerg_phone_no1"
                render={({ field }) => (
                  <TextField
                    id="emerg-phone-no1"
                    label="Tel Number *"
                    variant="outlined"
                    placeholder="Enter Personal Tel Number"
                    fullWidth
                    margin="normal"
                    {...field}
                  />
                )}
              />
            </div>
            <div className="col-6 col-md-4 col-lg-4">
              <Controller
                control={control}
                name="emerg_mobile_no1"
                render={({ field }) => (
                  <TextField
                    id="emerg-mobile-no1"
                    label="Mobile Number"
                    variant="outlined"
                    placeholder="Enter Mobile Number"
                    fullWidth
                    margin="normal"
                    {...field}
                  />
                )}
              />
            </div>
            <div className="col-6 col-md-6 col-lg-4">
              <Controller
                control={control}
                name="emerg_gender1"
                rules={{ required: "this field is required." }}
                render={({ field }) => (
                  <FormControl fullWidth>
                    <InputLabel id="gender-status">Gender</InputLabel>
                    <Select
                      labelId="emerg-gender1"
                      id="Gender"
                      label="Gender"
                      {...field}
                    >
                      <MenuItem value="" disabled>
                        Select Gender
                      </MenuItem>
                      <MenuItem value="Male">Male</MenuItem>
                      <MenuItem value="Female">Female</MenuItem>
                      <MenuItem value="Prefer not to Say">
                        Prefer not to Say
                      </MenuItem>
                    </Select>
                  </FormControl>
                )}
              />
            </div>
            <div className="col-6 col-md-6 col-lg-4">
              <Controller
                control={control}
                name="emerg_relation1"
                rules={{ required: "this field is required." }}
                render={({ field }) => (
                  <FormControl fullWidth>
                    <InputLabel id="relation-status">Relation</InputLabel>
                    <Select
                      labelId="emerg-relation1"
                      id="Gender"
                      label="Gender"
                      {...field}
                    >
                      <MenuItem value="F">Father</MenuItem>
                      <MenuItem value="M">Mother</MenuItem>
                      <MenuItem value="GP">Grand Parent</MenuItem>
                      <MenuItem value="G">Gurdian</MenuItem>
                      <MenuItem value="SP">Sponsor</MenuItem>
                    </Select>
                  </FormControl>
                )}
              />
            </div>
          </div>
        </div>
        <div className="col-12">
          <div className="col-12">
            <h6 className="text-dark text-center">Emergency Contact 2</h6>
          </div>
          <div className="col-12 d-flex flex-wrap">
            <div></div>
            <div className="col-6 col-md-4 col-lg-4">
              <Controller
                control={control}
                name="emerg_first_name2"
                rules={{ required: "this field is required." }}
                render={({ field }) => (
                  <TextField
                    id="emerg-first-name2"
                    label="First Name *"
                    variant="outlined"
                    placeholder="Enter First Name"
                    fullWidth
                    margin="normal"
                    {...field}
                    error={Boolean(errors?.emerg_first_name2)}
                    helperText={errors.emerg_first_name2?.message}
                  />
                )}
              />
            </div>
            <div className="col-6 col-md-4 col-lg-4">
              <Controller
                control={control}
                name="emerg_last_name2"
                rules={{ required: "this field is required." }}
                render={({ field }) => (
                  <TextField
                    id="emerg-last-name2"
                    label="Last Name *"
                    variant="outlined"
                    placeholder="Enter Your Last Name"
                    fullWidth
                    margin="normal"
                    {...field}
                    error={Boolean(errors?.emerg_last_name2)}
                    helperText={errors.emerg_last_name2?.message}
                  />
                )}
              />
            </div>
            <div className="col-6 col-md-4 col-lg-4">
              <Controller
                control={control}
                name="emerg_phone_no2"
                render={({ field }) => (
                  <TextField
                    id="emerg-phone-no2"
                    label="Tel Number *"
                    variant="outlined"
                    placeholder="Enter Personal Tel Number"
                    fullWidth
                    margin="normal"
                    {...field}
                  />
                )}
              />
            </div>
            <div className="col-6 col-md-4 col-lg-4">
              <Controller
                control={control}
                name="emerg_mobile_no2"
                render={({ field }) => (
                  <TextField
                    id="emerg-mobile-no2"
                    label="Mobile Number"
                    variant="outlined"
                    placeholder="Enter Mobile Number"
                    fullWidth
                    margin="normal"
                    {...field}
                  />
                )}
              />
            </div>
            <div className="col-6 col-md-6 col-lg-4">
              <Controller
                control={control}
                name="emerg_gender2"
                rules={{ required: "this field is required." }}
                render={({ field }) => (
                  <FormControl fullWidth>
                    <InputLabel id="gender-status">Gender</InputLabel>
                    <Select
                      labelId="emerg-gender2"
                      id="Gender"
                      label="Gender"
                      {...field}
                    >
                      <MenuItem value="" disabled>
                        Select Gender
                      </MenuItem>
                      <MenuItem value="Male">Male</MenuItem>
                      <MenuItem value="Female">Female</MenuItem>
                      <MenuItem value="Prefer not to Say">
                        Prefer not to Say
                      </MenuItem>
                    </Select>
                  </FormControl>
                )}
              />
            </div>
            <div className="col-6 col-md-6 col-lg-4">
              <Controller
                control={control}
                name="emerg_relation2"
                rules={{ required: "this field is required." }}
                render={({ field }) => (
                  <FormControl fullWidth>
                    <InputLabel id="relation-status">Relation</InputLabel>
                    <Select
                      labelId="emerg-relation2"
                      id="Gender"
                      label="Gender"
                      {...field}
                    >
                      <MenuItem value="Father">Father</MenuItem>
                      <MenuItem value="Mother">Mother</MenuItem>
                      <MenuItem value="Grand Parent">Grand Parent</MenuItem>
                      <MenuItem value="Spouse">Spouse</MenuItem>
                      <MenuItem value="Friend">Friend</MenuItem>
                    </Select>
                  </FormControl>
                )}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmergencyContact;
