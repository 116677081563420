import React, { useEffect, useState } from "react";

import "./assets/css/bootstrap.min.css";
import "./assets/css/style.css";

import "./assets/lib/owlcarousel/assets/owl.carousel.min.css";
import "./assets/lib/animate/animate.min.css"; // Import the CSS file
import { Link } from "react-router-dom";
import authService from "../../services/auth.service";
import { API_URLS } from "../../utils/api.routes";

function Homepage() {
  const currentUser = authService.getCurrentUser();
  const [school, setSchool] = useState("");

  useEffect(() => {
    const fetchSchools = async () => {
      const res = await fetch(API_URLS.school.concat(1, "/"));
      const data = await res.json();
      setSchool(data);
    };
    fetchSchools();
  }, []);

  return (
    <div className="content">
      <div class="container-fluid bg-dark px-5 d-none d-lg-block">
        <div class="row gx-0">
          <div class="col-lg-8 text-center text-lg-start mb-2 mb-lg-0">
            <div
              class="d-inline-flex align-items-center"
              style={{ height: "45px" }}
            >
              <small class="me-3 text-light">
                <i class="fa fa-map-marker-alt me-2"></i>Mogotio RD, Westlands,
                Nairobi
              </small>
              <small class="me-3 text-light">
                <i class="fa fa-phone-alt me-2"></i>+254 796 807 933
              </small>
              <small class="text-light">
                <i class="fa fa-envelope-open me-2"></i>info@gictsytems.com
              </small>
            </div>
          </div>
          <div class="col-lg-4 text-center text-lg-end">
            <div
              class="d-inline-flex align-items-center"
              style={{ height: "45px" }}
            >
              <a
                class="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2"
                href="/"
              >
                <i class="fab fa-twitter fw-normal"></i>
              </a>
              <a
                class="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2"
                href="/"
              >
                <i class="fab fa-facebook-f fw-normal"></i>
              </a>
              <a
                class="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2"
                href="/"
              >
                <i class="fab fa-linkedin-in fw-normal"></i>
              </a>
              <a
                class="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2"
                href="/"
              >
                <i class="fab fa-instagram fw-normal"></i>
              </a>
              <a
                class="btn btn-sm btn-outline-light btn-sm-square rounded-circle"
                href="/"
              >
                <i class="fab fa-youtube fw-normal"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="container-fluid position-relative p-0">
        <nav class="navbar navbar-expand-lg navbar-dark px-5 py-3 py-lg-0">
          <Link to="/" class="navbar-brand p-0">
            <h1 class="m-0">
              <i class="fa fa-user-tie me-2"></i>
              {school["code"]}
            </h1>
          </Link>
          <button
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="navbarCollapse"
          >
            <span class="fa fa-bars"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarCollapse">
            <div class="navbar-nav ms-auto py-0">
              <Link to="/" class="nav-item nav-link active">
                Home
              </Link>
              <Link to="/about-us" class="nav-item nav-link">
                About
              </Link>

              <a href="/" class="nav-item nav-link">
                Services
              </a>
              <div class="nav-item dropdown">
                <a
                  href="/"
                  class="nav-link dropdown-toggle"
                  data-bs-toggle="dropdown"
                >
                  Blog
                </a>
                <div class="dropdown-menu m-0">
                  <a href="/" class="dropdown-item">
                    Blog Grid
                  </a>
                  <a href="/" class="dropdown-item">
                    Blog Detail
                  </a>
                </div>
              </div>
              <div class="nav-item dropdown">
                <a
                  href="/"
                  class="nav-link dropdown-toggle"
                  data-bs-toggle="dropdown"
                >
                  Pages
                </a>
                <div class="dropdown-menu m-0">
                  <a href="/" class="dropdown-item">
                    Pricing Plan
                  </a>
                  <a href="/" class="dropdown-item">
                    Our features
                  </a>
                  <a href="/" class="dropdown-item">
                    Team Members
                  </a>
                  <a href="/" class="dropdown-item">
                    Testimonial
                  </a>
                  <a href="/" class="dropdown-item">
                    Free Quote
                  </a>
                </div>
              </div>
              <a href="/" class="nav-item nav-link">
                Contact
              </a>
            </div>
            <button
              type="button"
              class="btn text-primary ms-3"
              data-bs-toggle="modal"
              data-bs-target="searchModal"
            >
              <i class="fa fa-search"></i>
            </button>
            {!currentUser && (
              <Link to="account/login" class="nav-item nav-link">
                Login
              </Link>
            )}
            {currentUser && (
              <a href="/admin" class="nav-item nav-link">
                Dashboard
              </a>
            )}
          </div>
        </nav>

        <div
          id="header-carousel"
          class="carousel slide carousel-fade"
          data-bs-ride="carousel"
        >
          <div class="carousel-inner">
            <div class="carousel-item active">
              <img
                class="w-100"
                src="assets/img/slideshow/3.jpeg"
                alt="shaddy"
              />
              <div class="carousel-caption d-flex flex-column align-items-center justify-content-center">
                <div class="p-3" style={{ maxWidth: "900px" }}>
                  <h5 class="text-white text-uppercase mb-3 animated slideInDown">
                    Creative & Innovative
                  </h5>
                  <h1 class="display-1 text-white mb-md-4 animated zoomIn">
                    Creative & Innovative Digital Solution
                  </h1>
                  <a
                    href="/"
                    class="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                  >
                    Free Quote
                  </a>
                  <a
                    href="/"
                    class="btn btn-outline-light py-md-3 px-md-5 animated slideInRight"
                  >
                    Contact Us
                  </a>
                </div>
              </div>
            </div>
            <div class="carousel-item">
              <img
                class="w-100"
                src="assets/img/slideshow/3.jpeg"
                alt="shaddy2"
              />
              <div class="carousel-caption d-flex flex-column align-items-center justify-content-center">
                <div class="p-3" style={{ maxWidth: "900px" }}>
                  <h5 class="text-white text-uppercase mb-3 animated slideInDown">
                    Creative & Innovative
                  </h5>
                  <h1 class="display-1 text-white mb-md-4 animated zoomIn">
                    Creative & Innovative Digital Solution
                  </h1>
                  <a
                    href="/"
                    class="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                  >
                    Free Quote
                  </a>
                  <a
                    href="/"
                    class="btn btn-outline-light py-md-3 px-md-5 animated slideInRight"
                  >
                    Contact Us
                  </a>
                </div>
              </div>
            </div>
          </div>
          <button
            class="carousel-control-prev"
            type="button"
            data-bs-target="header-carousel"
            data-bs-slide="prev"
          >
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button
            class="carousel-control-next"
            type="button"
            data-bs-target="header-carousel"
            data-bs-slide="next"
          >
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>
      </div>

      <div class="modal fade" id="searchModal" tabindex="-1">
        <div class="modal-dialog modal-fullscreen">
          <div
            class="modal-content"
            style={{ background: "rgba(9, 30, 62, 0.7)" }}
          >
            <div class="modal-header border-0">
              <button
                type="button"
                class="btn bg-white btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body d-flex align-items-center justify-content-center">
              <div class="input-group" style={{ maxWidth: "600px" }}>
                <input
                  type="text"
                  class="form-control bg-transparent border-primary p-3"
                  placeholder="Type search keyword"
                />
                <button class="btn btn-primary px-4">
                  <i class="bi bi-search"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container-fluid facts py-5 pt-lg-0">
        <div class="container py-5 pt-lg-0">
          <div class="row gx-0">
            <div class="col-lg-4 wow zoomIn" data-wow-delay="0.1s">
              <div
                class="bg-primary shadow d-flex align-items-center justify-content-center p-4"
                style={{ height: "150px" }}
              >
                <div
                  class="bg-white d-flex align-items-center justify-content-center rounded mb-2"
                  style={{ width: "60px", height: "60px" }}
                >
                  <i class="fa fa-users text-primary"></i>
                </div>
                <div class="ps-4">
                  <h5 class="text-white mb-0">Happy Clients</h5>
                  <h1 class="text-white mb-0" data-toggle="counter-up">
                    12345
                  </h1>
                </div>
              </div>
            </div>
            <div class="col-lg-4 wow zoomIn" data-wow-delay="0.3s">
              <div
                class="bg-light shadow d-flex align-items-center justify-content-center p-4"
                style={{ height: "150px" }}
              >
                <div
                  class="bg-primary d-flex align-items-center justify-content-center rounded mb-2"
                  style={{ width: "60px", height: "60px" }}
                >
                  <i class="fa fa-check text-white"></i>
                </div>
                <div class="ps-4">
                  <h5 class="text-primary mb-0">Projects Done</h5>
                  <h1 class="mb-0" data-toggle="counter-up">
                    12345
                  </h1>
                </div>
              </div>
            </div>
            <div class="col-lg-4 wow zoomIn" data-wow-delay="0.6s">
              <div
                class="bg-primary shadow d-flex align-items-center justify-content-center p-4"
                style={{ height: "150px" }}
              >
                <div
                  class="bg-white d-flex align-items-center justify-content-center rounded mb-2"
                  style={{ width: "60px", height: "60px" }}
                >
                  <i class="fa fa-award text-primary"></i>
                </div>
                <div class="ps-4">
                  <h5 class="text-white mb-0">Win Awards</h5>
                  <h1 class="text-white mb-0" data-toggle="counter-up">
                    12345
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
        <div class="container py-5">
          <div class="row g-5">
            <div class="col-lg-7">
              <div class="section-title position-relative pb-3 mb-5">
                <h5 class="fw-bold text-primary text-uppercase">About Us</h5>
                <h1 class="mb-0">
                  The Best IT Solution With 10 Years of Experience
                </h1>
              </div>
              <p class="mb-4">
                Tempor erat elitr rebum at clita. Diam dolor diam ipsum et
                tempor sit. Aliqu diam amet diam et eos labore. Clita erat ipsum
                et lorem et sit, sed stet no labore lorem sit. Sanctus clita duo
                justo et tempor eirmod magna dolore erat amet
              </p>
              <div class="row g-0 mb-3">
                <div class="col-sm-6 wow zoomIn" data-wow-delay="0.2s">
                  <h5 class="mb-3">
                    <i class="fa fa-check text-primary me-3"></i>Award Winning
                  </h5>
                  <h5 class="mb-3">
                    <i class="fa fa-check text-primary me-3"></i>Professional
                    Staff
                  </h5>
                </div>
                <div class="col-sm-6 wow zoomIn" data-wow-delay="0.4s">
                  <h5 class="mb-3">
                    <i class="fa fa-check text-primary me-3"></i>24/7 Support
                  </h5>
                  <h5 class="mb-3">
                    <i class="fa fa-check text-primary me-3"></i>Fair Prices
                  </h5>
                </div>
              </div>
              <div
                class="d-flex align-items-center mb-4 wow fadeIn"
                data-wow-delay="0.6s"
              >
                <div
                  class="bg-primary d-flex align-items-center justify-content-center rounded"
                  style={{ width: "60px", height: "60px" }}
                >
                  <i class="fa fa-phone-alt text-white"></i>
                </div>
                <div class="ps-4">
                  <h5 class="mb-2">Call to ask any question</h5>
                  <h4 class="text-primary mb-0">+012 345 6789</h4>
                </div>
              </div>
              <a
                href="/"
                class="btn btn-primary py-3 px-5 mt-3 wow zoomIn"
                data-wow-delay="0.9s"
              >
                Request A Quote
              </a>
            </div>
            <div class="col-lg-5" style={{ minHeight: "500px" }}>
              <div class="position-relative h-100">
                <img
                  class="position-absolute w-100 h-100 rounded wow zoomIn"
                  alt="sdas"
                  data-wow-delay="0.9s"
                  src="assets/img/slideshow/1.jpeg"
                  style={{ objectFit: "cover" }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
        <div class="container py-5">
          <div
            class="section-title text-center position-relative pb-3 mb-5 mx-auto"
            style={{ maxWidth: "600px" }}
          >
            <h5 class="fw-bold text-primary text-uppercase">Why Choose Us</h5>
            <h1 class="mb-0">
              We Are Here to Grow Your Business Exponentially
            </h1>
          </div>
          <div class="row g-5">
            <div class="col-lg-4">
              <div class="row g-5">
                <div class="col-12 wow zoomIn" data-wow-delay="0.2s">
                  <div
                    class="bg-primary rounded d-flex align-items-center justify-content-center mb-3"
                    style={{ width: "60px", height: "60px" }}
                  >
                    <i class="fa fa-cubes text-white"></i>
                  </div>
                  <h4>Best In Industry</h4>
                  <p class="mb-0">
                    Magna sea eos sit dolor, ipsum amet lorem diam dolor eos et
                    diam dolor
                  </p>
                </div>
                <div class="col-12 wow zoomIn" data-wow-delay="0.6s">
                  <div
                    class="bg-primary rounded d-flex align-items-center justify-content-center mb-3"
                    style={{ width: "60px", height: "60px" }}
                  >
                    <i class="fa fa-award text-white"></i>
                  </div>
                  <h4>Award Winning</h4>
                  <p class="mb-0">
                    Magna sea eos sit dolor, ipsum amet lorem diam dolor eos et
                    diam dolor
                  </p>
                </div>
              </div>
            </div>
            <div
              class="col-lg-4 wow zoomIn"
              data-wow-delay="0.9s"
              style={{ minHeight: "350px" }}
            >
              <div class="position-relative h-100">
                <img
                  class="position-absolute w-100 h-100 rounded wow zoomIn"
                  data-wow-delay="0.1s"
                  alt="sdas"
                  src="assets/img/slideshow/2.jpeg"
                  style={{ objectFit: "cover" }}
                />
              </div>
            </div>
            <div class="col-lg-4">
              <div class="row g-5">
                <div class="col-12 wow zoomIn" data-wow-delay="0.4s">
                  <div
                    class="bg-primary rounded d-flex align-items-center justify-content-center mb-3"
                    style={{ width: "60px", height: "60px" }}
                  >
                    <i class="fa fa-users-cog text-white"></i>
                  </div>
                  <h4>Professional Staff</h4>
                  <p class="mb-0">
                    Magna sea eos sit dolor, ipsum amet lorem diam dolor eos et
                    diam dolor
                  </p>
                </div>
                <div class="col-12 wow zoomIn" data-wow-delay="0.8s">
                  <div
                    class="bg-primary rounded d-flex align-items-center justify-content-center mb-3"
                    style={{ width: "60px", height: "60px" }}
                  >
                    <i class="fa fa-phone-alt text-white"></i>
                  </div>
                  <h4>24/7 Support</h4>
                  <p class="mb-0">
                    Magna sea eos sit dolor, ipsum amet lorem diam dolor eos et
                    diam dolor
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
        <div class="container py-5">
          <div
            class="section-title text-center position-relative pb-3 mb-5 mx-auto"
            style={{ maxWidth: "600px" }}
          >
            <h5 class="fw-bold text-primary text-uppercase">Our Services</h5>
            <h1 class="mb-0">
              Custom IT Solutions for Your Successful Business
            </h1>
          </div>
          <div class="row g-5">
            <div class="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.3s">
              <div class="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                <div class="service-icon">
                  <i class="fa fa-shield-alt text-white"></i>
                </div>
                <h4 class="mb-3">Cyber Security</h4>
                <p class="m-0">
                  Amet justo dolor lorem kasd amet magna sea stet eos vero lorem
                  ipsum dolore sed
                </p>
                <a class="btn btn-lg btn-primary rounded" href="/">
                  <i class="bi bi-arrow-right"></i>
                </a>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.6s">
              <div class="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                <div class="service-icon">
                  <i class="fa fa-chart-pie text-white"></i>
                </div>
                <h4 class="mb-3">Data Analytics</h4>
                <p class="m-0">
                  Amet justo dolor lorem kasd amet magna sea stet eos vero lorem
                  ipsum dolore sed
                </p>
                <a class="btn btn-lg btn-primary rounded" href="/">
                  <i class="bi bi-arrow-right"></i>
                </a>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.9s">
              <div class="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                <div class="service-icon">
                  <i class="fa fa-code text-white"></i>
                </div>
                <h4 class="mb-3">Web Development</h4>
                <p class="m-0">
                  Amet justo dolor lorem kasd amet magna sea stet eos vero lorem
                  ipsum dolore sed
                </p>
                <a class="btn btn-lg btn-primary rounded" href="/">
                  <i class="bi bi-arrow-right"></i>
                </a>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.3s">
              <div class="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                <div class="service-icon">
                  <i class="fab fa-android text-white"></i>
                </div>
                <h4 class="mb-3">Apps Development</h4>
                <p class="m-0">
                  Amet justo dolor lorem kasd amet magna sea stet eos vero lorem
                  ipsum dolore sed
                </p>
                <a class="btn btn-lg btn-primary rounded" href="/">
                  <i class="bi bi-arrow-right"></i>
                </a>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.6s">
              <div class="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                <div class="service-icon">
                  <i class="fa fa-search text-white"></i>
                </div>
                <h4 class="mb-3">SEO Optimization</h4>
                <p class="m-0">
                  Amet justo dolor lorem kasd amet magna sea stet eos vero lorem
                  ipsum dolore sed
                </p>
                <a class="btn btn-lg btn-primary rounded" href="/">
                  <i class="bi bi-arrow-right"></i>
                </a>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.9s">
              <div class="position-relative bg-primary rounded h-100 d-flex flex-column align-items-center justify-content-center text-center p-5">
                <h3 class="text-white mb-3">Call Us For Quote</h3>
                <p class="text-white mb-3">
                  Clita ipsum magna kasd rebum at ipsum amet dolor justo dolor
                  est magna stet eirmod
                </p>
                <h2 class="text-white mb-0">+012 345 6789</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div class="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
        <div class="container py-5">
          <div
            class="section-title text-center position-relative pb-3 mb-5 mx-auto"
            style={{ maxWidth: "600px" }}
          >
            <h5 class="fw-bold text-primary text-uppercase">Pricing Plans</h5>
            <h1 class="mb-0">
              We are Offering Competitive Prices for Our Clients
            </h1>
          </div>
          <div class="row g-0">
            <div class="col-lg-4 wow slideInUp" data-wow-delay="0.6s">
              <div class="bg-light rounded">
                <div class="border-bottom py-4 px-5 mb-4">
                  <h4 class="text-primary mb-1">Basic Plan</h4>
                  <small class="text-uppercase">For Small Size Business</small>
                </div>
                <div class="p-5 pt-0">
                  <h1 class="display-5 mb-3">
                    <small
                      class="align-top"
                      style={{ fontSize: "22px", lineHeight: "45px" }}
                    >
                      $
                    </small>
                    49.00
                    <small
                      class="align-bottom"
                      style={{ fontSize: "16px", lineHeight: "40px" }}
                    >
                      / Month
                    </small>
                  </h1>
                  <div class="d-flex justify-content-between mb-3">
                    <span>HTML5 & CSS3</span>
                    <i class="fa fa-check text-primary pt-1"></i>
                  </div>
                  <div class="d-flex justify-content-between mb-3">
                    <span>Bootstrap v5</span>
                    <i class="fa fa-check text-primary pt-1"></i>
                  </div>
                  <div class="d-flex justify-content-between mb-3">
                    <span>Responsive Layout</span>
                    <i class="fa fa-times text-danger pt-1"></i>
                  </div>
                  <div class="d-flex justify-content-between mb-2">
                    <span>Cross-browser Support</span>
                    <i class="fa fa-times text-danger pt-1"></i>
                  </div>
                  <a href="/" class="btn btn-primary py-2 px-4 mt-4">
                    Order Now
                  </a>
                </div>
              </div>
            </div>
            <div class="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
              <div
                class="bg-white rounded shadow position-relative"
                style={{ zIndex: 1 }}
              >
                <div class="border-bottom py-4 px-5 mb-4">
                  <h4 class="text-primary mb-1">Standard Plan</h4>
                  <small class="text-uppercase">For Medium Size Business</small>
                </div>
                <div class="p-5 pt-0">
                  <h1 class="display-5 mb-3">
                    <small
                      class="align-top"
                      style={{ fontSize: "22px", lineHeight: "45px" }}
                    >
                      $
                    </small>
                    99.00
                    <small
                      class="align-bottom"
                      style={{ fontSize: "16px", lineHeight: "40px" }}
                    >
                      / Month
                    </small>
                  </h1>
                  <div class="d-flex justify-content-between mb-3">
                    <span>HTML5 & CSS3</span>
                    <i class="fa fa-check text-primary pt-1"></i>
                  </div>
                  <div class="d-flex justify-content-between mb-3">
                    <span>Bootstrap v5</span>
                    <i class="fa fa-check text-primary pt-1"></i>
                  </div>
                  <div class="d-flex justify-content-between mb-3">
                    <span>Responsive Layout</span>
                    <i class="fa fa-check text-primary pt-1"></i>
                  </div>
                  <div class="d-flex justify-content-between mb-2">
                    <span>Cross-browser Support</span>
                    <i class="fa fa-times text-danger pt-1"></i>
                  </div>
                  <a href="/" class="btn btn-primary py-2 px-4 mt-4">
                    Order Now
                  </a>
                </div>
              </div>
            </div>
            <div class="col-lg-4 wow slideInUp" data-wow-delay="0.9s">
              <div class="bg-light rounded">
                <div class="border-bottom py-4 px-5 mb-4">
                  <h4 class="text-primary mb-1">Advanced Plan</h4>
                  <small class="text-uppercase">For Large Size Business</small>
                </div>
                <div class="p-5 pt-0">
                  <h1 class="display-5 mb-3">
                    <small
                      class="align-top"
                      style={{ fontSize: "22px", lineHeight: "45px" }}
                    ></small>
                    149.00
                    <small
                      class="align-bottom"
                      style={{ fontSize: "16px", lineHeight: "40px" }}
                    ></small>
                  </h1>
                  <div class="d-flex justify-content-between mb-3">
                    <span>HTML5 & CSS3</span>
                    <i class="fa fa-check text-primary pt-1"></i>
                  </div>
                  <div class="d-flex justify-content-between mb-3">
                    <span>Bootstrap v5</span>
                    <i class="fa fa-check text-primary pt-1"></i>
                  </div>
                  <div class="d-flex justify-content-between mb-3">
                    <span>Responsive Layout</span>
                    <i class="fa fa-check text-primary pt-1"></i>
                  </div>
                  <div class="d-flex justify-content-between mb-2">
                    <span>Cross-browser Support</span>
                    <i class="fa fa-check text-primary pt-1"></i>
                  </div>
                  <a href="/" class="btn btn-primary py-2 px-4 mt-4">
                    Order Now
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
        <div class="container py-5">
          <div
            class="section-title text-center position-relative pb-3 mb-5 mx-auto"
            style={{ maxWidth: "600px" }}
          >
            <h5 class="fw-bold text-primary text-uppercase">Pricing Plans</h5>
            <h1 class="mb-0">
              We are Offering Competitive Prices for Our Clients
            </h1>
          </div>
          <div class="row g-0">
            <div class="col-lg-4 wow slideInUp" data-wow-delay="0.6s">
              <div class="bg-light rounded">
                <div class="border-bottom py-4 px-5 mb-4">
                  <h4 class="text-primary mb-1">Basic Plan</h4>
                  <small class="text-uppercase">For Small Size Business</small>
                </div>
                <div class="p-5 pt-0">
                  <h1 class="display-5 mb-3">
                    <small
                      class="align-top"
                      style={{ fontSize: "22px", lineHeight: "45px" }}
                    >
                      $
                    </small>
                    49.00
                    <small
                      class="align-bottom"
                      style={{ fontSize: "16px", lineHeight: "40px" }}
                    >
                      / Month
                    </small>
                  </h1>
                  <div class="d-flex justify-content-between mb-3">
                    <span>HTML5 & CSS3</span>
                    <i class="fa fa-check text-primary pt-1"></i>
                  </div>
                  <div class="d-flex justify-content-between mb-3">
                    <span>Bootstrap v5</span>
                    <i class="fa fa-check text-primary pt-1"></i>
                  </div>
                  <div class="d-flex justify-content-between mb-3">
                    <span>Responsive Layout</span>
                    <i class="fa fa-times text-danger pt-1"></i>
                  </div>
                  <div class="d-flex justify-content-between mb-2">
                    <span>Cross-browser Support</span>
                    <i class="fa fa-times text-danger pt-1"></i>
                  </div>
                  <a href="/" class="btn btn-primary py-2 px-4 mt-4">
                    Order Now
                  </a>
                </div>
              </div>
            </div>
            <div class="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
              <div
                class="bg-white rounded shadow position-relative"
                style={{ zIndex: 1 }}
              >
                <div class="border-bottom py-4 px-5 mb-4">
                  <h4 class="text-primary mb-1">Standard Plan</h4>
                  <small class="text-uppercase">For Medium Size Business</small>
                </div>
                <div class="p-5 pt-0">
                  <h1 class="display-5 mb-3">
                    <small
                      class="align-top"
                      style={{ fontSize: "22px", lineHeight: "45px" }}
                    >
                      $
                    </small>
                    99.00
                    <small
                      class="align-bottom"
                      style={{ fontSize: "16px", lineHeight: "40px" }}
                    >
                      / Month
                    </small>
                  </h1>
                  <div class="d-flex justify-content-between mb-3">
                    <span>HTML5 & CSS3</span>
                    <i class="fa fa-check text-primary pt-1"></i>
                  </div>
                  <div class="d-flex justify-content-between mb-3">
                    <span>Bootstrap v5</span>
                    <i class="fa fa-check text-primary pt-1"></i>
                  </div>
                  <div class="d-flex justify-content-between mb-3">
                    <span>Responsive Layout</span>
                    <i class="fa fa-check text-primary pt-1"></i>
                  </div>
                  <div class="d-flex justify-content-between mb-2">
                    <span>Cross-browser Support</span>
                    <i class="fa fa-times text-danger pt-1"></i>
                  </div>
                  <a href="/" class="btn btn-primary py-2 px-4 mt-4">
                    Order Now
                  </a>
                </div>
              </div>
            </div>
            <div class="col-lg-4 wow slideInUp" data-wow-delay="0.9s">
              <div class="bg-light rounded">
                <div class="border-bottom py-4 px-5 mb-4">
                  <h4 class="text-primary mb-1">Advanced Plan</h4>
                  <small class="text-uppercase">For Large Size Business</small>
                </div>
                <div class="p-5 pt-0">
                  <h1 class="display-5 mb-3">
                    <small
                      class="align-top"
                      style={{ fontSize: "22px", lineHheight: "45px" }}
                    >
                      $
                    </small>
                    149.00
                    <small
                      class="align-bottom"
                      style={{ fontSize: "16px", lineHheight: "40px" }}
                    >
                      / Month
                    </small>
                  </h1>
                  <div class="d-flex justify-content-between mb-3">
                    <span>HTML5 & CSS3</span>
                    <i class="fa fa-check text-primary pt-1"></i>
                  </div>
                  <div class="d-flex justify-content-between mb-3">
                    <span>Bootstrap v5</span>
                    <i class="fa fa-check text-primary pt-1"></i>
                  </div>
                  <div class="d-flex justify-content-between mb-3">
                    <span>Responsive Layout</span>
                    <i class="fa fa-check text-primary pt-1"></i>
                  </div>
                  <div class="d-flex justify-content-between mb-2">
                    <span>Cross-browser Support</span>
                    <i class="fa fa-check text-primary pt-1"></i>
                  </div>
                  <a href="/" class="btn btn-primary py-2 px-4 mt-4">
                    Order Now
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <div class="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
        <div class="container py-5">
          <div class="row g-5">
            <div class="col-lg-7">
              <div class="section-title position-relative pb-3 mb-5">
                <h5 class="fw-bold text-primary text-uppercase">
                  Request A Quote
                </h5>
                <h1 class="mb-0">
                  Need A Free Quote? Please Feel Free to Contact Us
                </h1>
              </div>
              <div class="row gx-3">
                <div class="col-sm-6 wow zoomIn" data-wow-delay="0.2s">
                  <h5 class="mb-4">
                    <i class="fa fa-reply text-primary me-3"></i>Reply within 24
                    hours
                  </h5>
                </div>
                <div class="col-sm-6 wow zoomIn" data-wow-delay="0.4s">
                  <h5 class="mb-4">
                    <i class="fa fa-phone-alt text-primary me-3"></i>24 hrs
                    telephone support
                  </h5>
                </div>
              </div>
              <p class="mb-4">
                If you have any questions, comments, or concerns, please don't
                hesitate to reach out to us. Our team is here to help and we
                value your feedback. Simply fill out the form below and we'll
                get back to you as soon as possible. Thank you for your interest
                in our services!
              </p>
              <div
                class="d-flex align-items-center mt-2 wow zoomIn"
                data-wow-delay="0.6s"
              >
                <div
                  class="bg-primary d-flex align-items-center justify-content-center rounded"
                  style={{ width: "60px", height: "60px" }}
                >
                  <i class="fa fa-phone-alt text-white"></i>
                </div>
                <div class="ps-4">
                  <h5 class="mb-2">Call to ask any question</h5>
                  <h4 class="text-primary mb-0">+254 796 807 933</h4>
                </div>
              </div>
            </div>
            <div class="col-lg-5">
              <div
                class="bg-primary rounded h-100 d-flex align-items-center p-5 wow zoomIn"
                data-wow-delay="0.9s"
              >
                <form>
                  <div class="row g-3">
                    <div class="col-xl-12">
                      <input
                        type="text"
                        class="form-control bg-light border-0"
                        placeholder="Your Name"
                        style={{ height: " 55px" }}
                      />
                    </div>
                    <div class="col-12">
                      <input
                        type="email"
                        class="form-control bg-light border-0"
                        placeholder="Your Email"
                        style={{ height: "55px" }}
                      />
                    </div>
                    <div class="col-12">
                      <select
                        class="form-select bg-light border-0"
                        style={{ height: "55px" }}
                      >
                        <option selected>Select A Service</option>
                        <option value="1">Service 1</option>
                        <option value="2">Service 2</option>
                        <option value="3">Service 3</option>
                      </select>
                    </div>
                    <div class="col-12">
                      <textarea
                        class="form-control bg-light border-0"
                        rows="3"
                        placeholder="Message"
                      ></textarea>
                    </div>
                    <div class="col-12">
                      <button class="btn btn-dark w-100 py-3" type="submit">
                        Request A Quote
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div class="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
        <div class="container py-5">
          <div
            class="section-title text-center position-relative pb-3 mb-4 mx-auto"
            style={{ maxWidth: "600px" }}
          >
            <h5 class="fw-bold text-primary text-uppercase">Testimonial</h5>
            <h1 class="mb-0">
              What Our Clients Say About Our Digital Services
            </h1>
          </div>
          <div
            class="owl-carousel testimonial-carousel wow fadeInUp"
            data-wow-delay="0.6s"
          >
            <div class="testimonial-item bg-light my-4">
              <div class="d-flex align-items-center border-bottom pt-5 pb-4 px-5">
                <img
                  class="img-fluid rounded"
                  alt="sdass"
                  src="/webassets/img/testimonial-1.jpg"
                  style={{ width: "60px", height: "60px" }}
                />
                <div class="ps-4">
                  <h4 class="text-primary mb-1">Client Name</h4>
                  <small class="text-uppercase">Profession</small>
                </div>
              </div>
              <div class="pt-4 pb-5 px-5">
                Dolor et eos labore, stet justo sed est sed. Diam sed sed dolor
                stet amet eirmod eos labore diam
              </div>
            </div>
            <div class="testimonial-item bg-light my-4">
              <div class="d-flex align-items-center border-bottom pt-5 pb-4 px-5">
                <img
                  class="img-fluid rounded"
                  src="/webassets/img/testimonial-2.jpg"
                  alt="sdasz"
                  style={{ width: "60px", height: "60px" }}
                />
                <div class="ps-4">
                  <h4 class="text-primary mb-1">Client Name</h4>
                  <small class="text-uppercase">Profession</small>
                </div>
              </div>
              <div class="pt-4 pb-5 px-5">
                Dolor et eos labore, stet justo sed est sed. Diam sed sed dolor
                stet amet eirmod eos labore diam
              </div>
            </div>
            <div class="testimonial-item bg-light my-4">
              <div class="d-flex align-items-center border-bottom pt-5 pb-4 px-5">
                <img
                  class="img-fluid rounded"
                  src="/webassets/img/testimonial-3.jpg"
                  alt="sdasf"
                  style={{ width: "60px", height: "60px" }}
                />
                <div class="ps-4">
                  <h4 class="text-primary mb-1">Client Name</h4>
                  <small class="text-uppercase">Profession</small>
                </div>
              </div>
              <div class="pt-4 pb-5 px-5">
                Dolor et eos labore, stet justo sed est sed. Diam sed sed dolor
                stet amet eirmod eos labore diam
              </div>
            </div>
            <div class="testimonial-item bg-light my-4">
              <div class="d-flex align-items-center border-bottom pt-5 pb-4 px-5">
                <img
                  class="img-fluid rounded"
                  alt="sdasjj"
                  src="/webassets/img/testimonial-4.jpg"
                  style={{ width: "60px", height: "60px" }}
                />
                <div class="ps-4">
                  <h4 class="text-primary mb-1">Client Name</h4>
                  <small class="text-uppercase">Profession</small>
                </div>
              </div>
              <div class="pt-4 pb-5 px-5">
                Dolor et eos labore, stet justo sed est sed. Diam sed sed dolor
                stet amet eirmod eos labore diam
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
        <div class="container py-5">
          <div
            class="section-title text-center position-relative pb-3 mb-5 mx-auto"
            style={{ maxWidth: "600px" }}
          >
            <h5 class="fw-bold text-primary text-uppercase">Team Members</h5>
            <h1 class="mb-0">
              Professional Stuffs Ready to Help Your Business
            </h1>
          </div>
          <div class="row g-5">
            <div class="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
              <div class="team-item bg-light rounded overflow-hidden">
                <div class="team-img position-relative overflow-hidden">
                  <img
                    class="img-fluid w-100"
                    src="/webassets/img/team-1.jpg"
                    alt="joe"
                  />
                  <div class="team-social">
                    <a
                      class="btn btn-lg btn-primary btn-lg-square rounded"
                      href="/"
                    >
                      <i class="fab fa-twitter fw-normal"></i>
                    </a>
                    <a
                      class="btn btn-lg btn-primary btn-lg-square rounded"
                      href="/"
                    >
                      <i class="fab fa-facebook-f fw-normal"></i>
                    </a>
                    <a
                      class="btn btn-lg btn-primary btn-lg-square rounded"
                      href="/"
                    >
                      <i class="fab fa-instagram fw-normal"></i>
                    </a>
                    <a
                      class="btn btn-lg btn-primary btn-lg-square rounded"
                      href="/"
                    >
                      <i class="fab fa-linkedin-in fw-normal"></i>
                    </a>
                  </div>
                </div>
                <div class="text-center py-4">
                  <h4 class="text-primary">Full Name</h4>
                  <p class="text-uppercase m-0">Designation</p>
                </div>
              </div>
            </div>
            <div class="col-lg-4 wow slideInUp" data-wow-delay="0.6s">
              <div class="team-item bg-light rounded overflow-hidden">
                <div class="team-img position-relative overflow-hidden">
                  <img
                    class="img-fluid w-100"
                    src="/webassets/img/team-2.jpg"
                    alt="joe1"
                  />
                  <div class="team-social">
                    <a
                      class="btn btn-lg btn-primary btn-lg-square rounded"
                      href="/"
                    >
                      <i class="fab fa-twitter fw-normal"></i>
                    </a>
                    <a
                      class="btn btn-lg btn-primary btn-lg-square rounded"
                      href="/"
                    >
                      <i class="fab fa-facebook-f fw-normal"></i>
                    </a>
                    <a
                      class="btn btn-lg btn-primary btn-lg-square rounded"
                      href="/"
                    >
                      <i class="fab fa-instagram fw-normal"></i>
                    </a>
                    <a
                      class="btn btn-lg btn-primary btn-lg-square rounded"
                      href="/"
                    >
                      <i class="fab fa-linkedin-in fw-normal"></i>
                    </a>
                  </div>
                </div>
                <div class="text-center py-4">
                  <h4 class="text-primary">Full Name</h4>
                  <p class="text-uppercase m-0">Designation</p>
                </div>
              </div>
            </div>
            <div class="col-lg-4 wow slideInUp" data-wow-delay="0.9s">
              <div class="team-item bg-light rounded overflow-hidden">
                <div class="team-img position-relative overflow-hidden">
                  <img
                    class="img-fluid w-100"
                    src="/webassets/img/team-3.jpg"
                    alt="joe12"
                  />
                  <div class="team-social">
                    <a
                      class="btn btn-lg btn-primary btn-lg-square rounded"
                      href="/"
                    >
                      <i class="fab fa-twitter fw-normal"></i>
                    </a>
                    <a
                      class="btn btn-lg btn-primary btn-lg-square rounded"
                      href="/"
                    >
                      <i class="fab fa-facebook-f fw-normal"></i>
                    </a>
                    <a
                      class="btn btn-lg btn-primary btn-lg-square rounded"
                      href="/"
                    >
                      <i class="fab fa-instagram fw-normal"></i>
                    </a>
                    <a
                      class="btn btn-lg btn-primary btn-lg-square rounded"
                      href="/"
                    >
                      <i class="fab fa-linkedin-in fw-normal"></i>
                    </a>
                  </div>
                </div>
                <div class="text-center py-4">
                  <h4 class="text-primary">Full Name</h4>
                  <p class="text-uppercase m-0">Designation</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <div class="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
        <div class="container py-5">
          <div
            class="section-title text-center position-relative pb-3 mb-5 mx-auto"
            style={{ maxWidth: "600px" }}
          >
            <h5 class="fw-bold text-primary text-uppercase">Latest Blog</h5>
            <h1 class="mb-0">Read The Latest Articles from Our Blog Post</h1>
          </div>
          <div class="row g-5">
            <div class="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
              <div class="blog-item bg-light rounded overflow-hidden">
                <div class="blog-img position-relative overflow-hidden">
                  <img
                    class="img-fluid"
                    src="/assets/img/slideshow/4.jpeg"
                    alt="joe123"
                  />
                  <a
                    class="position-absolute top-0 start-0 bg-primary text-white rounded-end mt-5 py-2 px-4"
                    href="/"
                  >
                    Web Design
                  </a>
                </div>
                <div class="p-4">
                  <div class="d-flex mb-3">
                    <small class="me-3">
                      <i class="far fa-user text-primary me-2"></i>John Doe
                    </small>
                    <small>
                      <i class="far fa-calendar-alt text-primary me-2"></i>01
                      Jan, 2045
                    </small>
                  </div>
                  <h4 class="mb-3">How to build a website</h4>
                  <p>
                    Dolor et eos labore stet justo sed est sed sed sed dolor
                    stet amet
                  </p>
                  <a class="text-uppercase" href="/">
                    Read More <i class="bi bi-arrow-right"></i>
                  </a>
                </div>
              </div>
            </div>
            <div class="col-lg-4 wow slideInUp" data-wow-delay="0.6s">
              <div class="blog-item bg-light rounded overflow-hidden">
                <div class="blog-img position-relative overflow-hidden">
                  <img
                    class="img-fluid"
                    src="/assets/img/slideshow/5.jpeg"
                    alt="joe1234"
                  />
                  <a
                    class="position-absolute top-0 start-0 bg-primary text-white rounded-end mt-5 py-2 px-4"
                    href="/"
                  >
                    Web Design
                  </a>
                </div>
                <div class="p-4">
                  <div class="d-flex mb-3">
                    <small class="me-3">
                      <i class="far fa-user text-primary me-2"></i>John Doe
                    </small>
                    <small>
                      <i class="far fa-calendar-alt text-primary me-2"></i>01
                      Jan, 2045
                    </small>
                  </div>
                  <h4 class="mb-3">How to build a website</h4>
                  <p>
                    Dolor et eos labore stet justo sed est sed sed sed dolor
                    stet amet
                  </p>
                  <a class="text-uppercase" href="/">
                    Read More <i class="bi bi-arrow-right"></i>
                  </a>
                </div>
              </div>
            </div>
            <div class="col-lg-4 wow slideInUp" data-wow-delay="0.9s">
              <div class="blog-item bg-light rounded overflow-hidden">
                <div class="blog-img position-relative overflow-hidden">
                  <img
                    class="img-fluid"
                    src="/assets/img/slideshow/6.jpeg"
                    alt="joe12345"
                  />
                  <a
                    class="position-absolute top-0 start-0 bg-primary text-white rounded-end mt-5 py-2 px-4"
                    href="/"
                  >
                    Web Design
                  </a>
                </div>
                <div class="p-4">
                  <div class="d-flex mb-3">
                    <small class="me-3">
                      <i class="far fa-user text-primary me-2"></i>John Doe
                    </small>
                    <small>
                      <i class="far fa-calendar-alt text-primary me-2"></i>01
                      Jan, 2045
                    </small>
                  </div>
                  <h4 class="mb-3">How to build a website</h4>
                  <p>
                    Dolor et eos labore stet justo sed est sed sed sed dolor
                    stet amet
                  </p>
                  <a class="text-uppercase" href="/">
                    Read More <i class="bi bi-arrow-right"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
        <div class="container py-5 mb-5">
          <div class="bg-white">
            <div class="owl-carousel vendor-carousel">
              <img src="/webassets/img/vendor-1.jpg" alt="joe123456" />
              <img src="/webassets/img/vendor-2.jpg" alt="joe1234567" />
              <img src="/webassets/img/vendor-3.jpg" alt="joe1234567" />
              <img src="/webassets/img/vendor-4.jpg" alt="joe123456786" />
              <img src="/webassets/img/vendor-5.jpg" alt="joe1234567865" />
              <img src="/webassets/img/vendor-6.jpg" alt="joe12345678654" />
              <img src="/webassets/img/vendor-7.jpg" alt="joe1234567865432" />
              <img src="/webassets/img/vendor-8.jpg" alt="joe12345678654321" />
              <img src="/webassets/img/vendor-9.jpg" alt="joe123456786543211" />
            </div>
          </div>
        </div>
      </div>

      <div
        class="container-fluid bg-dark text-light mt-5 wow fadeInUp"
        data-wow-delay="0.1s"
      >
        <div class="container">
          <div class="row gx-5">
            <div class="col-lg-4 col-md-6 footer-about">
              <div class="d-flex flex-column align-items-center justify-content-center text-center h-100 bg-primary p-4">
                <a href="index.html" class="navbar-brand">
                  <h1 class="m-0 text-white">
                    <i class="fa fa-user-tie me-2"></i>GICT
                  </h1>
                </a>
                <p class="mt-3 mb-4">
                  If you have any questions, comments, or concerns, please don't
                  hesitate to reach out to us. Our team is here to help and we
                  value your feedback. Simply fill out the form below and we'll
                  get back to you as soon as possible. Thank you for your
                  interest in our services!
                </p>
                <form action="">
                  <div class="input-group">
                    <input
                      type="text"
                      class="form-control border-white p-3"
                      placeholder="Your Email"
                    />
                    <button class="btn btn-dark">Sign Up</button>
                  </div>
                </form>
              </div>
            </div>
            <div class="col-lg-8 col-md-6">
              <div class="row gx-5">
                <div class="col-lg-4 col-md-12 pt-5 mb-5">
                  <div class="section-title section-title-sm position-relative pb-3 mb-4">
                    <h3 class="text-light mb-0">Get In Touch</h3>
                  </div>
                  <div class="d-flex mb-2">
                    <i class="bi bi-geo-alt text-primary me-2"></i>
                    <p class="mb-0">Mogotio RD, Westlands, Nairobi</p>
                  </div>
                  <div class="d-flex mb-2">
                    <i class="bi bi-envelope-open text-primary me-2"></i>
                    <p class="mb-0">info@gictsytems.com</p>
                  </div>
                  <div class="d-flex mb-2">
                    <i class="bi bi-telephone text-primary me-2"></i>
                    <p class="mb-0">+254 796 807 933</p>
                  </div>
                  <div class="d-flex mt-4">
                    <a class="btn btn-primary btn-square me-2" href="/">
                      <i class="fab fa-twitter fw-normal"></i>
                    </a>
                    <a class="btn btn-primary btn-square me-2" href="/">
                      <i class="fab fa-facebook-f fw-normal"></i>
                    </a>
                    <a class="btn btn-primary btn-square me-2" href="/">
                      <i class="fab fa-linkedin-in fw-normal"></i>
                    </a>
                    <a class="btn btn-primary btn-square" href="/">
                      <i class="fab fa-instagram fw-normal"></i>
                    </a>
                  </div>
                </div>
                <div class="col-lg-4 col-md-12 pt-0 pt-lg-5 mb-5">
                  <div class="section-title section-title-sm position-relative pb-3 mb-4">
                    <h3 class="text-light mb-0">Quick Links</h3>
                  </div>
                  <div class="link-animated d-flex flex-column justify-content-start">
                    <a class="text-light mb-2" href="/">
                      <i class="bi bi-arrow-right text-primary me-2"></i>Home
                    </a>
                    <a class="text-light mb-2" href="/">
                      <i class="bi bi-arrow-right text-primary me-2"></i>About
                      Us
                    </a>
                    <a class="text-light mb-2" href="/">
                      <i class="bi bi-arrow-right text-primary me-2"></i>Our
                      Services
                    </a>
                    <a class="text-light mb-2" href="/">
                      <i class="bi bi-arrow-right text-primary me-2"></i>Meet
                      The Team
                    </a>
                    <a class="text-light mb-2" href="/">
                      <i class="bi bi-arrow-right text-primary me-2"></i>Latest
                      Blog
                    </a>
                    <a class="text-light" href="/">
                      <i class="bi bi-arrow-right text-primary me-2"></i>Contact
                      Us
                    </a>
                  </div>
                </div>
                <div class="col-lg-4 col-md-12 pt-0 pt-lg-5 mb-5">
                  <div class="section-title section-title-sm position-relative pb-3 mb-4">
                    <h3 class="text-light mb-0">Popular Links</h3>
                  </div>
                  <div class="link-animated d-flex flex-column justify-content-start">
                    <a class="text-light mb-2" href="/">
                      <i class="bi bi-arrow-right text-primary me-2"></i>Home
                    </a>
                    <a class="text-light mb-2" href="/">
                      <i class="bi bi-arrow-right text-primary me-2"></i>About
                      Us
                    </a>
                    <a class="text-light mb-2" href="/">
                      <i class="bi bi-arrow-right text-primary me-2"></i>Our
                      Services
                    </a>
                    <a class="text-light mb-2" href="/">
                      <i class="bi bi-arrow-right text-primary me-2"></i>Meet
                      The Team
                    </a>
                    <a class="text-light mb-2" href="/">
                      <i class="bi bi-arrow-right text-primary me-2"></i>Latest
                      Blog
                    </a>
                    <a class="text-light" href="/">
                      <i class="bi bi-arrow-right text-primary me-2"></i>Contact
                      Us
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container-fluid text-white" style={{ background: "#061429" }}>
        <div class="container text-center">
          <div class="row justify-content-end">
            <div class="col-lg-8 col-md-6">
              <div
                class="d-flex align-items-center justify-content-center"
                style={{ height: "75px" }}
              >
                <p class="mb-0">
                  &copy;
                  <a class="text-white border-bottom" href="/">
                    GICT ERP
                  </a>
                  . All Rights Reserved. Designed by
                  <a
                    class="text-white border-bottom"
                    href="https://gictsystems.com/"
                  >
                    GICT SYSTEMS
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Homepage;
