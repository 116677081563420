import React from "react";
import { AdminPanelSettingsOutlined } from "@mui/icons-material";
import { Link } from "react-router-dom";
import authService from "../../services/auth.service";

export default function Admin() {
  const currentUser = authService.getCurrentUser();
  return (
    <>
      <li className="dropdown">
        <Link to="#" className="menu-toggle nav-link has-dropdown">
          <AdminPanelSettingsOutlined fontSize="medium" className="icon-mr" />
          <span>Admin Section</span>
        </Link>
        <ul className="dropdown-menu">
          {currentUser.roles.includes("VIEW_ADMIN_DASHBOARD") ||
          currentUser.roles.includes("SUPER_ADMIN") ? (
            <li>
              <Link className="nav-link" to="/admin">
                Main Page
              </Link>
            </li>
          ) : (
            <></>
          )}
          {/* 
          
          {/* <li>
            <Link className="nav-link" to="/admin">
              Admission Query
            </Link>
          </li>
          <li>
            <Link className="nav-link" to="portfolio.html">
              Visitor Book
            </Link>
          </li>
          <li>
            <Link className="nav-link" to="blog.html">
              Complaint
            </Link>
          </li>
          <li>
            <Link className="nav-link" to="calendar.html">
              Postal Receive
            </Link>
          </li>
          <li>
            <Link className="nav-link" to="calendar.html">
              Postal Dispatch
            </Link>
          </li>
          <li>
            <Link className="nav-link" to="calendar.html">
              Phone Call Log
            </Link>
          </li>
          <li>
            <Link className="nav-link" to="calendar.html">
              Admin Setup
            </Link>
          </li>
          <li>
            <Link className="nav-link" to="calendar.html">
              Student Certificate
            </Link>
          </li>
          <li>
            <Link className="nav-link" to="calendar.html">
              Generate Certificate
            </Link>
          </li>
          <li>
            <Link className="nav-link" to="calendar.html">
              ID Card
            </Link>
          </li>
          <li>
            <Link className="nav-link" to="calendar.html">
              Generate ID Card
            </Link>
          </li> */}
        </ul>
      </li>
    </>
  );
}
