import React from "react";
import { TextField } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";

const ContactForm = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  return (
    <>
      <div className="d-flex flex-wrap justify-content-center">
        <div className="col-6 col-md-4 col-lg-4">
          <Controller
            control={control}
            name="service_no"
            render={({ field }) => (
              <TextField
                id="service-no"
                label="Service Number"
                variant="outlined"
                placeholder="Enter Service Number"
                fullWidth
                margin="normal"
                {...field}
              />
            )}
          />
        </div>
        <div className="col-6 col-md-4 col-lg-4">
          <Controller
            control={control}
            name="driving_license"
            render={({ field }) => (
              <TextField
                id="first-name"
                label="Driving License"
                variant="outlined"
                placeholder="Enter Driving License No"
                fullWidth
                margin="normal"
                {...field}
              />
            )}
          />
        </div>
        <div className="col-6 col-md-4 col-lg-4">
          <Controller
            control={control}
            name="current_address"
            rules={{ required: "this field is required." }}
            render={({ field }) => (
              <TextField
                id="current-address"
                label="Current Address *"
                variant="outlined"
                placeholder="Enter Current Address"
                fullWidth
                margin="normal"
                {...field}
                error={Boolean(errors?.current_address)}
                helperText={errors.current_address?.message}
              />
            )}
          />
        </div>
        <div className="col-6 col-md-4 col-lg-4">
          <Controller
            control={control}
            name="permanent_address"
            render={({ field }) => (
              <TextField
                id="permanent-address"
                label="Permanent Address *"
                variant="outlined"
                placeholder="Enter Permanant Address"
                fullWidth
                margin="normal"
                {...field}
              />
            )}
          />
        </div>
        <div className="col-6 col-md-4 col-lg-4">
          <Controller
            control={control}
            name="postal_code"
            render={({ field }) => (
              <TextField
                id="national_id"
                label="Postal Code *"
                variant="outlined"
                placeholder="Enter Postal Office Code"
                fullWidth
                margin="normal"
                {...field}
              />
            )}
          />
        </div>
        <div className="col-6 col-md-4 col-lg-4">
          <Controller
            control={control}
            name="zip_code"
            render={({ field }) => (
              <TextField
                id="zip-code"
                label="Zip Code"
                variant="outlined"
                placeholder="Enter Zip Code"
                fullWidth
                margin="normal"
                {...field}
              />
            )}
          />
        </div>
        <div className="col-6 col-md-4 col-lg-4">
          <Controller
            control={control}
            name="postal_town"
            render={({ field }) => (
              <TextField
                id="tel_no"
                label="Postal Town *"
                variant="outlined"
                placeholder="Enter Postal Town"
                fullWidth
                margin="normal"
                {...field}
              />
            )}
          />
        </div>
        <div className="col-6 col-md-4 col-lg-4">
          <Controller
            control={control}
            name="home_county"
            render={({ field }) => (
              <TextField
                id="home_county"
                label="Home County"
                variant="outlined"
                placeholder="Enter Home County"
                fullWidth
                margin="normal"
                {...field}
              />
            )}
          />
        </div>
        <div className="col-6 col-md-4 col-lg-4">
          <Controller
            control={control}
            name="home_sub_county"
            render={({ field }) => (
              <TextField
                id="home-sub-county"
                label="Home Sub-County"
                variant="outlined"
                placeholder="Enter Sub County"
                fullWidth
                margin="normal"
                {...field}
              />
            )}
          />
        </div>
        <div className="col-6 col-md-6 col-lg-4">
          <Controller
            control={control}
            name="home_ward"
            render={({ field }) => (
              <TextField
                id="home-ward"
                label="Home Ward"
                variant="outlined"
                placeholder="Enter Ward"
                fullWidth
                margin="normal"
                {...field}
              />
            )}
          />
        </div>
        <div className="col-6 col-md-4 col-lg-4">
          <Controller
            control={control}
            name="home_location"
            render={({ field }) => (
              <TextField
                id="home_location"
                variant="outlined"
                label="Home Location"
                placeholder="Enter Home Location"
                fullWidth
                margin="normal"
                {...field}
              />
            )}
          />
        </div>
        <div className="col-6 col-md-4 col-lg-4">
          <Controller
            control={control}
            name="home_village"
            render={({ field }) => (
              <TextField
                id="home-vilage"
                variant="outlined"
                label="Home Village"
                placeholder="Enter Home Vilage"
                fullWidth
                margin="normal"
                {...field}
              />
            )}
          />
        </div>
      </div>
    </>
  );
};

export default ContactForm;
