import { MailOutlineOutlined } from "@mui/icons-material";
import React, { useState } from "react";
import { toast, Toaster } from "react-hot-toast";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { API_URLS } from "../../../../utils/api.routes";
import {
  toastDangerStyle,
  toastSuccessStyle,
  toastWarnigStyle,
} from "../../../../components/shared/Custom.Styles";

export default function StudEditDetails() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const data = state.data;

  const initialValues = {
    telNo: data.tel_no === null ? "" : data.tel_no,
    email: data.e_mail === null ? "" : data.e_mail,
    height: data.height === null ? 0 : data.height,
    weight: data.weight === null ? 0 : data.weight,
    notes: data.additional_notes === null ? "" : data.additional_notes,
  };
  const [values, setValues] = useState(initialValues);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };
  const headersList = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };
  const bodyContent = JSON.stringify({
    e_mail: values.email,
    tel_no: values.telNo,
    height: values.height,
    weight: values.weight,
    additional_notes: values.notes,
  });
  const handleSubmit = async (e) => {
    e.preventDefault();
    await fetch(API_URLS.students_info.concat(data.id, "/"), {
      method: "PATCH",
      headers: headersList,
      body: bodyContent,
    })
      .then((res) => {
        if (res.status === 200) {
          toast.success(`Successfully Updated Student`, toastSuccessStyle);
          setTimeout(() => {
            navigate("/admin/user/profile", {
              state: data.id,
            });
          }, 2000);
        } else if (res.status === 400) {
          toast.error(
            `${res.status} Error !!! ${res.statusText}`,
            toastDangerStyle
          );
          setTimeout(() => {
            toast(`Seems Admission Number Already Taken`, toastWarnigStyle);
          }, 1000);
        } else {
          toast.error(
            `${res.status} Error !!! ${res.statusText}`,
            toastDangerStyle
          );
        }
      })
      .catch((err) => {
        toast.error(`Error !!! Not Found}`, toastDangerStyle);
      });
  };
  console.log(state.data.id);
  return (
    <div className="main-content">
      <div className="section-body">
        <div className="col-12 col-md-12 col-lg-12">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/admin">Home</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Edit Personal Information
              </li>
            </ol>
          </nav>
        </div>
        <form className="row" onSubmit={handleSubmit}>
          <div className="col-12 col-md-12 col-lg-12">
            <div className="shadow-sm p-1 mb-3 bg-white rounded">
              <h5 className="text-dark text-center">Students Personal Info</h5>
              <div className="col-12 d-flex flex-wrap">
                <div className="form-group col-6">
                  <label>Phone Number</label>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <div className="input-group-text">
                        <i className="fas fa-phone"></i>
                      </div>
                    </div>
                    <input
                      type="text"
                      className="form-control phone-number"
                      onChange={handleInputChange}
                      value={values.telNo}
                      name="telNo"
                    />
                  </div>
                </div>
                <div className="form-group col-6">
                  <label>Email Address</label>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <div className="input-group-text">
                        <i>
                          <MailOutlineOutlined />
                        </i>
                      </div>
                    </div>
                    <input
                      type="text"
                      className="form-control phone-number"
                      onChange={handleInputChange}
                      value={values.email}
                      name="email"
                    />
                  </div>
                </div>
                <div className="form-group col-6">
                  <label>Height</label>
                  <input
                    type="text"
                    className="form-control"
                    onChange={handleInputChange}
                    value={values.height}
                    name="height"
                  />
                </div>
                <div className="form-group col-6">
                  <label>Weight</label>
                  <input
                    type="text"
                    className="form-control"
                    onChange={handleInputChange}
                    value={values.weight}
                    name="weight"
                  />
                </div>
                <div className="form-group col-12">
                  <label>Other Notes</label>
                  <input
                    type="text"
                    className="form-control"
                    onChange={handleInputChange}
                    value={values.notes}
                    name="notes"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 d-flex justify-content-end">
            <button className="btn btn-primary" type="submit">
              Update
            </button>
            <Toaster position="top-right" reverseOrder={false} />
          </div>
        </form>
      </div>
    </div>
  );
}
