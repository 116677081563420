import React from "react";
import { TextField } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";

const ProfessionForm = () => {
  const { control } = useFormContext();
  return (
    <>
      <div className="d-flex flex-wrap justify-content-center">
        <div className="col-12 ">
          <div className="col-12">
            <h5 className="text-center text-dark">Qualifications</h5>
          </div>
          <div className="col-12 d-flex flex-wrap justify-content-center">
            <div className="col-6 col-md-6 col-lg-6">
              <Controller
                control={control}
                name="prof_qualification_1"
                render={({ field }) => (
                  <TextField
                    id="prof-qualification-1"
                    label="Qualification 1"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    {...field}
                  />
                )}
              />
            </div>
            <div className="col-6 col-md-6 col-lg-6">
              <Controller
                control={control}
                name="prof_qualification_2"
                render={({ field }) => (
                  <TextField
                    id="prof_qualification_2"
                    label="Qualification 2"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    {...field}
                  />
                )}
              />
            </div>
            <div className="col-6 col-md-6 col-lg-6">
              <Controller
                control={control}
                name="prof_qualification_3"
                render={({ field }) => (
                  <TextField
                    id="prof-qualification-3"
                    label="Qualification 3"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    {...field}
                  />
                )}
              />
            </div>
            <div className="col-6 col-md-6 col-lg-6">
              <Controller
                control={control}
                name="prof_qualification_4"
                render={({ field }) => (
                  <TextField
                    id="prof-qualification-4"
                    label="Qualification 4"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    {...field}
                  />
                )}
              />
            </div>
          </div>
        </div>
        <div className="col-12 ">
          <div className="col-12">
            <h5 className="text-center text-dark">Employer</h5>
          </div>
          <div className="col-12 d-flex flex-wrap justify-content-center">
            <div className="col-3">
              <Controller
                control={control}
                name="prev_empl1"
                render={({ field }) => (
                  <TextField
                    id="prev-empl1"
                    label="Employer 1"
                    variant="outlined"
                    placeholder="Enter Employer Name"
                    fullWidth
                    margin="normal"
                    {...field}
                  />
                )}
              />
            </div>
            <div className="col-3">
              <Controller
                control={control}
                name="prev_empl_email1"
                render={({ field }) => (
                  <TextField
                    id="pre-_empl-email1"
                    label="Employer 1 Email"
                    variant="outlined"
                    placeholder="Employer Email"
                    type="email"
                    fullWidth
                    margin="normal"
                    {...field}
                  />
                )}
              />
            </div>
            <div className="col-3">
              <Controller
                control={control}
                name="prev_empl_tel1"
                render={({ field }) => (
                  <TextField
                    id="prev-empl-tel1"
                    label="Employer 1 Tel No"
                    variant="outlined"
                    placeholder="Employer Tel No"
                    fullWidth
                    margin="normal"
                    {...field}
                  />
                )}
              />
            </div>
            <div className="col-3">
              <Controller
                control={control}
                name="prev_empl_address1"
                render={({ field }) => (
                  <TextField
                    id="prev-empl-tel1"
                    label="Employer 1 Address"
                    variant="outlined"
                    placeholder="Employer Address"
                    fullWidth
                    margin="normal"
                    {...field}
                  />
                )}
              />
            </div>
            <div className="col-3">
              <Controller
                control={control}
                name="prev_empl2"
                render={({ field }) => (
                  <TextField
                    id="prev-empl2"
                    label="Employer 2"
                    variant="outlined"
                    placeholder="Enter Employer Name"
                    fullWidth
                    margin="normal"
                    {...field}
                  />
                )}
              />
            </div>
            <div className="col-3">
              <Controller
                control={control}
                name="prev_empl_email2"
                render={({ field }) => (
                  <TextField
                    id="pre-_empl-email2"
                    label="Employer 2 Email"
                    variant="outlined"
                    placeholder="Employer Email"
                    type="email"
                    fullWidth
                    margin="normal"
                    {...field}
                  />
                )}
              />
            </div>
            <div className="col-3">
              <Controller
                control={control}
                name="prev_empl_tel2"
                render={({ field }) => (
                  <TextField
                    id="prev-empl-tel2"
                    label="Employer 2 Tel No"
                    variant="outlined"
                    placeholder="Employer Tel No"
                    fullWidth
                    margin="normal"
                    {...field}
                  />
                )}
              />
            </div>
            <div className="col-3">
              <Controller
                control={control}
                name="prev_empl_address2"
                render={({ field }) => (
                  <TextField
                    id="prev-empl-tel2"
                    label="Employer 2 Address"
                    variant="outlined"
                    placeholder="Employer Address"
                    fullWidth
                    margin="normal"
                    {...field}
                  />
                )}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfessionForm;
