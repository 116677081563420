import { InfoOutlined } from "@mui/icons-material";
import axios from "axios";
import React, { useState } from "react";
import { Toaster, toast } from "react-hot-toast";
import { useQuery } from "react-query";
import { Link, useNavigate } from "react-router-dom";
import { API_URLS } from "../../../utils/api.routes";
import {
  toastDangerStyle,
  toastSuccessStyle,
  toastWarnigStyle,
} from "../../../components/shared/Custom.Styles";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { CustomPagination } from "../../../components/custom/Custom.DataGrid";
import VehicleActions from "./VehiclesActions";

export default function Vehicles() {
  const [model, setModel] = useState("");
  const [number, setNumber] = useState("");
  const [driver, setDriver] = useState("");
  const [license, setLicense] = useState("");
  const [vRoute, setVRoute] = useState("");

  const [rowId, setRowId] = useState(null);
  const [pageSize, setPageSize] = useState(5);

  let headersList = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };

  let bodyContent = JSON.stringify({
    vehicle_model: model,
    vehicle_number: number,
    driver: driver,
    driver_license: license,
    Route: vRoute,
  });

  async function fetchVehicles() {
    const { data } = await axios.get(API_URLS.vehicles);
    return data;
  }
  const { data, error, isError, isLoading, refetch } = useQuery(
    "vehicles",
    fetchVehicles
  );
  if (isLoading) {
    return <div className="main-content">Loading...</div>;
  }
  if (isError) {
    <div className="main-content">
      <div>Error!! {error.message}</div>
    </div>;
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    await fetch(API_URLS.routes, {
      method: "POST",
      body: bodyContent,
      headers: headersList,
    })
      .then((res) => {
        if (res.status === 201) {
          setModel("");
          setNumber("");
          setDriver("");
          setLicense("");
          setVRoute("");
          refetch();
          toast.success("Successfully Created Vehicle", toastSuccessStyle);
          refetch();
        } else if (res.status === 400) {
          toast.error(`Error !!! ${res.statusText}`, toastDangerStyle);
          setTimeout(() => {
            toast(`Seems Route Already Exists`, toastWarnigStyle);
          }, 1000);
        } else {
          toast.error(
            `${res.status} Error !!! ${res.statusText}`,
            toastDangerStyle
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const columns = [
    {
      field: "id",
      headerName: "SL",
      headerAlign: "center",
      type: "number",
      width: "80",
    },
    {
      field: "vehicle_model",
      headerName: "Vehicle Model",
      headerAlign: "center",
      editable: true,
      width: 150,
    },
    {
      field: "vehicle_number",
      headerName: "Number Plate",
      headerAlign: "center",
      editable: true,
      width: 120,
    },
    {
      field: "driver",
      headerName: "Driver",
      headerAlign: "center",
      editable: true,
      width: 120,
    },
    {
      field: "driver_license",
      headerName: "License",
      headerAlign: "center",
      editable: true,
      width: 120,
    },
    {
      field: "Route",
      headerName: "Vehicle Route",
      headerAlign: "center",
      editable: true,
      width: 120,
    },
    {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      width: 150,
      renderCell: (params) => (
        <VehicleActions {...{ params, rowId, setRowId, refetch }} />
      ),
    },
  ];
  return (
    <div className="main-content">
      <div className="row">
        <div className="col-12 col-md-12 col-lg-12">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/admin">Home</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Vehicles
              </li>
            </ol>
          </nav>
        </div>
        <div className="col-md-12 col-lg-12 col-xl-12">
          <div className="shadow-sm p-3 mb-5 bg-white rounded">
            <form onSubmit={handleSubmit}>
              <h5 className="text-center text-dark">Register New Vehicle</h5>
              <div className="card-body d-flex flex-wrap">
                <div className="form-group col-6 col-md-3 col-lg-3 col-6 col-md-3 col-lg-3">
                  <label htmlFor="vmodel">Vehicle Model</label>
                  <input
                    type="text"
                    id="vmodel"
                    className="form-control"
                    onChange={(e) => setModel(e.target.value)}
                  />
                </div>
                <div className="form-group col-6 col-md-2 col-lg-2">
                  <label htmlFor="vno">Vehicle Number</label>
                  <input
                    type="text"
                    id="vno"
                    className="form-control"
                    onChange={(e) => setNumber(e.target.value)}
                  />
                </div>
                <div className="form-group col-6 col-md-3 col-lg-3">
                  <label htmlFor="vdriver">Vehicle Driver</label>
                  <input
                    type="text"
                    id="vdriver"
                    className="form-control"
                    onChange={(e) => setDriver(e.target.value)}
                  />
                </div>
                <div className="form-group col-6 col-md-2 col-lg-2">
                  <label htmlFor="vlicense">Driver License</label>
                  <input
                    type="text"
                    id="vlicense"
                    className="form-control"
                    onChange={(e) => setLicense(e.target.value)}
                  />
                </div>
                <div className="form-group col-6 col-md-2 col-lg-2">
                  <label htmlFor="vRoute">Vehicle Route</label>
                  <input
                    type="text"
                    id="vRoute"
                    className="form-control"
                    onChange={(e) => setVRoute(e.target.value)}
                  />
                </div>
              </div>
              <div className="text-right">
                <button className="btn btn-primary mr-1" type="submit">
                  Register Vehicle
                </button>
                <Toaster position="top-right" reverseOrder={false} />
              </div>
            </form>
          </div>
        </div>
        <div className="col-12 col-md-12 col-lg-12 col-xl-12">
          <div className="shadow p-3 mb-5 bg-white rounded">
            <h5 className="text-center text-dark">Vehicles List</h5>
            <div style={{ display: "flex", height: "100%" }}>
              <div style={{ flexGrow: 1, width: "100%" }}>
                <DataGrid
                  autoHeight
                  columns={columns}
                  rows={data}
                  components={{
                    Toolbar: GridToolbar,
                    Pagination: CustomPagination,
                  }}
                  getRowId={(row) => row.id}
                  pageSize={pageSize}
                  onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                  getRowSpacing={(params) => ({
                    top: params.isFirstVisible ? 0 : 5,
                    bottom: params.isLastVisible ? 0 : 5,
                  })}
                  onCellEditCommit={(params) => setRowId(params.id)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
