import axios from 'axios';
import React from 'react'
import { useQuery } from 'react-query';
import { Link, useLocation } from 'react-router-dom';
import {API_URLS} from '../../utils/api.routes'

export default function Diarydetails() {
  const { state } = useLocation();
  const fetchDiaryDetails = async () => {
    const { data } = await axios.get(API_URLS.diary.concat(state, "/"));
    return data;
  };
  const { data, error, isError, isLoading } = useQuery(
    "diary",
    fetchDiaryDetails
  );
  if (isLoading) {
    return (
      <div className="main-content">
        <div className="row justify-content-center align-items-center">
          <h1>Loading</h1>
        </div>
      </div>
    );
  }
  if (isError) {
    return (
      <div className="main-content">
        <div className="row justify-content-center align-items-center">
          <h4 className="text-danger">Error!!! {error.message}</h4>
        </div>
      </div>
    );
  }
  console.log(data)
  return (
    <div className='main-content'>
      <div className="col-12 col-md-12 col-lg-12">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/admin">Home</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/admin/lesson-plan/diary">Diary</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Diary Details
              </li>
            </ol>
          </nav>
        </div>
      <div className="col-8">
        <div className="card shadow-sm p-3 mb-5 bg-white rounded">
          <h3 className="text-center text-info">Diary Details</h3>
          <div className='text-dark'>ID : {data.id}</div>
          <div className='text-dark'>Student : {data.student}</div>
          <div className='text-dark'>Term: {data.term}</div>
          <div className='text-dark'>Week: {data.week}</div>
          <div className='text-dark'>Date: {data.date}</div>
          <div className='text-dark'>Task: {data.task}</div>
          <div className='text-dark'>Teacher's comments: {data.teacher_comment}</div>
        </div>
      </div>
    </div>
  )
}
