import {
  EditOutlined,
  InfoOutlined,
  VisibilityOutlined,
} from "@mui/icons-material";
import axios from "axios";
import React from "react";
import { useQuery } from "react-query";
import { Link, useNavigate } from "react-router-dom";
import { API_URLS } from "../../../utils/api.routes";

function ParentsList() {
  const navigate = useNavigate();

  const fetchParents = async () => {
    const { data } = await axios.get(API_URLS.stud_parents);
    return data;
  };
  const { data, error, isError, isLoading } = useQuery("parent", fetchParents);
  if (isLoading) {
    return <h6>Loading...</h6>;
  }
  if (isError) {
    return <h6>Error!! {error.message}</h6>;
  }
  const editParent = (id) => {
    navigate("/admin/parent/edit", {
      state: id,
    });
  };
  const detailsPage = (id) => {
    navigate("/admin/parent/details", {
      state: id,
    });
  };
  return (
    <div className="main-content">
      {" "}
      <div className="row">
        <div className="col-12 col-md-12 col-lg-12">
          <div className="card shadow-sm p-3 mb-5 bg-body rounded">
            <div className="card-header">
              <h4>Parents List</h4>
            </div>
            <div className="card-body">
              <div className="table-responsive">
                <table className="table table-bordered table-md">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>First Name</th>
                      <th>Last Name</th>
                      <th>Gender</th>
                      <th>Tel No 1</th>
                      <th>Tel No 2</th>
                      <th>Relation</th>
                      <th>Edit</th>
                      <th>Info</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((parent, index) => {
                      return (
                        <tr key={index}>
                          <td>{parent.id}</td>
                          <td>{parent.first_name}</td>
                          <td>{parent.last_name}</td>
                          <td>{parent.gender}</td>
                          <td>{parent.tel_no1}</td>
                          <td>{parent.tel_no2}</td>
                          <td>{parent.relation}</td>
                          <td>
                            <button
                              onClick={() => {
                                editParent(parent.id);
                              }}
                              className="btn btn-sm"
                            >
                              <EditOutlined color="info" />
                            </button>
                          </td>
                          <td>
                            <button
                              onClick={() => {
                                detailsPage(parent.id);
                              }}
                              className="btn btn-sm"
                            >
                              <InfoOutlined color="success" />
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="card-footer text-right">
              <nav className="d-inline-block">
                <ul className="pagination mb-0">
                  <li className="page-item disabled">
                    <Link className="page-link" to="#" tabIndex="-1">
                      <i className="fas fa-chevron-left"></i>
                    </Link>
                  </li>
                  <li className="page-item active">
                    <Link className="page-link" to="#">
                      1 <span className="sr-only">(current)</span>
                    </Link>
                  </li>
                  <li className="page-item">
                    <Link className="page-link" to="#">
                      2
                    </Link>
                  </li>
                  <li className="page-item">
                    <Link className="page-link" to="#">
                      3
                    </Link>
                  </li>
                  <li className="page-item">
                    <Link className="page-link" to="#">
                      <i className="fas fa-chevron-right"></i>
                    </Link>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ParentsList;
