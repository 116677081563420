import React from "react";
import authService from "../../../services/auth.service";
import { useState } from "react";
import { useEffect } from "react";
import { API_URLS } from "../../../utils/api.routes";
import {
  toastDangerStyle,
  toastSuccessStyle,
  toastWarnigStyle,
} from "../../../components/shared/Custom.Styles";
import { Toaster, toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import AccessDenied from "../../../components/custom/Custom.AccessDenied";

export default function SchoolInfo() {
  const navigate = useNavigate();
  const currentUser = authService.getCurrentUser();
  const [school, setSchool] = useState({});
  const [schName, setSchName] = useState("");
  const [schCode, setSchCode] = useState("");
  const [schLogo, setSchLogo] = useState("");
  const [formData, setFormData] = useState(null);
  const [imagePreviewUrl, setImagePreviewUrl] = useState(null);

  useEffect(() => {
    const fetchSchoolInfo = async () => {
      const res = await fetch(API_URLS.school);
      const data = await res.json();
      setSchool(data[0]);
      setSchName(data[0]["name"]);
      setSchCode(data[0]["code"]);
      setSchLogo(data[0]["logo"]);
    };
    fetchSchoolInfo();
  }, []);

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append("logo", file);
    setFormData(formData);
    setImagePreviewUrl(URL.createObjectURL(file));
    // uploadImage(formData);
  };
  const headersList = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };

  const updateSchoolData = async () => {
    await fetch(API_URLS.school.concat(school["id"], "/"), {
      method: "PUT",
      headers: headersList,
      body: JSON.stringify({ code: schCode, name: schName }),
    })
      .then((res) => {
        if (res.status === 200) {
          toast.success("Updated Successful", toastSuccessStyle);
        } else {
          toast.error("Error Updating", toastDangerStyle);
        }
      })
      .catch((err) => {
        toast.error("An Error Occured", toastDangerStyle);
      });
  };

  const uploadImage = () => {
    fetch(API_URLS.school.concat(school["id"], "/"), {
      method: "PATCH",
      body: formData,
    })
      .then((res) => {
        if (res.status === 200) {
          toast.success("Update Successful", toastSuccessStyle);
          navigate("/admin");
        } else {
          toast.error("Update Error", toastDangerStyle);
          setTimeout(() => {
            toast(`Ensure Required Fields are not empty`, toastWarnigStyle);
          }, 1000);
        }
      })
      .catch((error) => {
        toast.error("Error updating details", toastDangerStyle);
      });
  };
  if (!currentUser.roles.includes("SUPER_ADMIN")) {
    return <AccessDenied />;
  }
  return (
    <div className="main-content">
      <div className="col-8">
        <div className="card shadow-sm p-3 mb-5 bg-white rounded">
          <h3 className="text-center text-info">School Details</h3>
          <div className="form-group d-flex">
            <div className="col-4">
              <label className="text-dark">
                <h6>School Code</h6>
              </label>
            </div>
            <div className="col-8">
              <input
                type="text"
                className="form-control"
                onChange={(e) => {
                  setSchCode(e.target.value);
                }}
                value={schCode}
                name="School Code"
              />
            </div>
          </div>
          <div className="form-group d-flex">
            <div className="col-4">
              <label className="text-dark">
                <h6>School Name</h6>
              </label>
            </div>
            <div className="col-8">
              <input
                type="text"
                className="form-control"
                onChange={(e) => {
                  setSchName(e.target.value);
                }}
                value={schName}
                name="School Name"
              />
            </div>
          </div>
          <div className="form-group d-flex justify-content-end">
            <button className="btn btn-info mr-1" onClick={updateSchoolData}>
              Update Info
            </button>
          </div>
          <div className="form-group d-flex">
            <div className="col-6">
              <img alt="sch-logo" src={schLogo} style={{ width: "80%" }} />
            </div>
            <div className="col-6 d-flex flex-column">
              <input type="file" onChange={handleFileSelect} />
              <img
                src={imagePreviewUrl}
                alt="Preview Logo"
                style={{ width: "60%" }}
              />
              <button
                className="btn btn-info align-self-end"
                style={{ width: "fit-content" }}
                onClick={uploadImage}
              >
                Update New Logo
              </button>
              <Toaster position="top-right" reverseOrder={false} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
