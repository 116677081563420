import React, { useState } from "react";
import FormPersonalDetails from "./steps/FormPersonalDetails";
import FormOtherDetails from "./steps/FormOtherDetails";
import FormParentsDetails from "./steps/FormParentsDetails";
import FormAcademics from "./steps/FormAcademics";
import Confirm from "./steps/Confirm";
import Success from "./steps/Success";
import WelcomeForm from "./steps/WelcomeForm";
import { Link, Navigate } from "react-router-dom";
import authService from "../../../services/auth.service";
import AccessDenied from "../../../components/custom/Custom.AccessDenied";

export default function StudentForm() {
  const currentUser = authService.getCurrentUser();

  const [state, setState] = useState({
    step: 1,
    admn_no: "",
    first_name: "",
    last_name: "",
    gender: "",
    dob: "",
    blood_group: "",
    religion: "",
    e_mail: "",
    tel_no: "",
    category: "",
    start_grade: "",
    current_grade: "",
    height: null,
    weight: null,
    current_address: "",
    permanent_address: "",
    route_list: "",
    vehicle_no: "",
    dorm_name: "",
    room_no: "",
    national_id: "",
    birth_cert_no: "",
    bank_acc_no: "",
    prev_school: "",
    special_med: "",
    person_disability: "",
    additional_notes: "",
    other_name: "",
    country: "",
    county: "",
    sub_county: "",
    ward: "",
    location: "",
    postal_code: "",
    zip_code: "",
    postal_location: "",
    parent_full_name: "",
    parent_relation: "",
    parent_tel_no: "",
    parent_email: "",
    parent1_full_name: "",
    parent1_relation: "",
    parent1_tel_no: "",
    parent1_email: "",
    parent2_full_name: "",
    parent2_relation: "",
    parent2_tel_no: "",
    parent2_email: "",
    class_room_allocated: "",
  });
  // Proceed to next step
  const nextStep = () => {
    const { step } = state;
    setState({
      ...state,
      step: step + 1,
    });
  };
  // Proceed to prev step
  const prevStep = () => {
    const { step } = state;
    setState({
      ...state,
      step: step - 1,
    });
  };
  const handleChange = (input) => (e) => {
    setState({
      ...state,
      [input]: e.target.value,
    });
  };
  const { step } = state;

  const {
    admn_no,
    first_name,
    last_name,
    gender,
    dob,
    blood_group,
    religion,
    e_mail,
    tel_no,
    category,
    start_grade,
    current_grade,
    height,
    weight,
    current_address,
    permanent_address,
    route_list,
    vehicle_no,
    dorm_name,
    room_no,
    national_id,
    birth_cert_no,
    bank_acc_no,
    prev_school,
    special_med,
    person_disability,
    additional_notes,
    other_name,
    country,
    county,
    sub_county,
    ward,
    location,
    postal_code,
    zip_code,
    postal_location,
    parent_full_name,
    parent_relation,
    parent_tel_no,
    parent_email,
    parent1_full_name,
    parent1_relation,
    parent1_tel_no,
    parent1_email,
    parent2_full_name,
    parent2_relation,
    parent2_tel_no,
    parent2_email,
    class_room_allocated,
  } = state;

  const values = {
    admn_no,
    first_name,
    last_name,
    gender,
    dob,
    blood_group,
    religion,
    e_mail,
    tel_no,
    category,
    start_grade,
    current_grade,
    height,
    weight,
    current_address,
    permanent_address,
    route_list,
    vehicle_no,
    dorm_name,
    room_no,
    national_id,
    birth_cert_no,
    bank_acc_no,
    prev_school,
    special_med,
    person_disability,
    additional_notes,
    other_name,
    country,
    county,
    sub_county,
    ward,
    location,
    postal_code,
    zip_code,
    postal_location,
    parent_full_name,
    parent_relation,
    parent_tel_no,
    parent_email,
    parent1_full_name,
    parent1_relation,
    parent1_tel_no,
    parent1_email,
    parent2_full_name,
    parent2_relation,
    parent2_tel_no,
    parent2_email,
    class_room_allocated,
  };

  if (
    !currentUser.roles.includes("ADD_STUDENT") &&
    !currentUser.roles.includes("SUPER_ADMIN")
  ) {
    return <AccessDenied />;
  }

  return (
    <div className="main-content">
      <div className="row">
        <div className="col-12 col-md-12 col-lg-12">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link className="font-weight-bold" to="/admin">
                  Home
                </Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                <span className="font-weight-bold">Register Student</span>
              </li>
            </ol>
          </nav>
        </div>
        <div className="col-md-12 col-lg-12 col-xl-12">
          <div className="shadow p-3 mb-5 bg-white rounded " style={{}}>
            {(() => {
              switch (step) {
                case 1:
                  return (
                    <WelcomeForm
                      nextStep={nextStep}
                      handleChange={handleChange}
                      values={values}
                    />
                  );
                case 2:
                  return (
                    <FormPersonalDetails
                      nextStep={nextStep}
                      handleChange={handleChange}
                      values={values}
                    />
                  );
                case 3:
                  return (
                    <FormOtherDetails
                      nextStep={nextStep}
                      prevStep={prevStep}
                      handleChange={handleChange}
                      values={values}
                    />
                  );
                case 4:
                  return (
                    <FormAcademics
                      nextStep={nextStep}
                      prevStep={prevStep}
                      handleChange={handleChange}
                      values={values}
                    />
                  );
                case 5:
                  return (
                    <FormParentsDetails
                      nextStep={nextStep}
                      prevStep={prevStep}
                      handleChange={handleChange}
                      values={values}
                    />
                  );
                case 6:
                  return (
                    <Confirm
                      nextStep={nextStep}
                      prevStep={prevStep}
                      values={values}
                    />
                  );
                case 7:
                  return <Success prevStep={prevStep} values={values} />;
              }
            })()}
          </div>
        </div>
      </div>
    </div>
  );
}
