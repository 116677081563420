import { MailOutlineOutlined } from "@mui/icons-material";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { API_URLS } from "../../../utils/api.routes";

const initialValues = {
  admnNo: "",
  firstName: "",
  lastName: "",
  telNo: "",
  email: "",
  startGrade: "",
  currentGrade: "",
  dob: "",
  birthCertNo: "",
  passport: "",
  // Other Details
  currAddress: "",
  permAddress: "",
  nationalID: "",
  height: "",
  weight: "",
  prevSchool: "",
  dorm: "",
  roomNo: "",
  notes: "",
};

function RegisterNewStudent() {
  const [values, setValues] = useState(initialValues);
  const [gender, setGender] = useState("");
  const [studCategory, setStudCategory] = useState("");
  const [religion, setReligion] = useState("");
  const [bloodGroup, setBloodGroup] = useState("");
  const [message, setMessage] = useState("");

  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };
  // // Start Fetching Students Category
  // const fetchCategories = async () => {
  //   const { data } = await axios.get(
  //     "http://127.0.0.1:8000/api/v1/students/category/"
  //   );
  //   return data;
  // };
  // const { data } = useQuery("category", fetchCategories);
  // // End Fetching Categories

  // Posting Contraints
  const headersList = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };
  const bodyContent = JSON.stringify({
    admn_no: values.admnNo,
    first_name: values.firstName,
    last_name: values.lastName,
    gender: gender,
    dob: values.dob,
    category: studCategory,
    blood_group: bloodGroup,
    religion: religion,
    e_mail: values.email,
    tel_no: values.telNo,
    start_grade: values.startGrade,
    current_grade: values.currentGrade,
    height: values.height,
    weight: values.weight,
    current_address: values.currAddress,
    permanent_address: values.permAddress,
    dorm_name: values.dorm,
    room_no: values.roomNo,
    birth_cert_no: values.birthCertNo,
    prev_school: values.prevSchool,
    additional_notes: values.notes,
  });

  // Posting Data Handler
  const handleSubmit = async (e) => {
    e.preventDefault();
    await fetch(API_URLS.students_info, {
      method: "POST",
      headers: headersList,
      body: bodyContent,
    })
      .then((res) => {
        setMessage("Student Registered Successfully");
        console.log(message);
        navigate("/admin/students/list");
      })
      .catch((err) => {
        console.log(err);
        setMessage("Some Error Occured");
      });
  };

  return (
    <div className="main-content">
      <div className="section-body">
        <div className="col-12 col-md-12 col-lg-12">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/admin">Home</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Register New Student
              </li>
            </ol>
          </nav>
        </div>
        <h1>
          UseEffect to fetch data to be mapped on the student Category field
        </h1>
        <form className="row" onSubmit={handleSubmit}>
          <div className="col-12 col-md-6 col-lg-6">
            <div className="card shadow-sm p-3 mb-5 bg-body rounded">
              <div className="card-header">
                <h4>Students Personal Info</h4>
              </div>
              <div className="card-body">
                <div className="form-group">
                  <label>Admission Number </label>
                  <input
                    type="text"
                    className="form-control"
                    onChange={handleInputChange}
                    value={values.admnNo}
                    name="admnNo"
                  />
                </div>
                <div className="form-group">
                  <label>First Name</label>
                  <input
                    type="text"
                    className="form-control"
                    onChange={handleInputChange}
                    value={values.firstName}
                    name="firstName"
                  />
                </div>
                <div className="form-group">
                  <label>Last Name</label>
                  <input
                    type="text"
                    className="form-control"
                    onChange={handleInputChange}
                    value={values.lastName}
                    name="lastName"
                  />
                </div>
                <div className="form-group">
                  <label>Select Gender</label>
                  <select
                    className="form-control"
                    onChange={(e) => setGender(e.target.value)}
                    value={gender}
                  >
                    <option value="" disabled>
                      Select Gender
                    </option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                    <option value="Other">Other</option>
                  </select>
                </div>
                <div className="form-group">
                  <label>Phone Number *</label>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <div className="input-group-text">
                        <i className="fas fa-phone"></i>
                      </div>
                    </div>
                    <input
                      type="text"
                      className="form-control phone-number"
                      onChange={handleInputChange}
                      value={values.telNo}
                      name="telNo"
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label>Email Address</label>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <div className="input-group-text">
                        <i>
                          <MailOutlineOutlined />
                        </i>
                      </div>
                    </div>
                    <input
                      type="text"
                      className="form-control phone-number"
                      onChange={handleInputChange}
                      value={values.email}
                      name="email"
                    />
                  </div>
                </div>
                {/* NEED ACTION */}
                <div className="form-group">
                  <label>Student Category</label>
                  <select
                    className="form-control"
                    onChange={(e) => setStudCategory(e.target.value)}
                    value={studCategory}
                  >
                    <option value="" disabled>
                      Choose Student Category
                    </option>
                    <option value="KD">Kindergarten</option>
                    <option value="JR">Junior Primary</option>
                    <option value="SR">Senior Primary</option>
                    {/* TO IMPLEMENT WHEN CREATED THE TABLE CATEGORY
                   {data.map((category, index) => {
                    return (
                      <option key={index} value={category.id}>
                        {category.name}
                      </option>
                    );
                  })} */}
                  </select>
                </div>
                <div className="form-group">
                  <label>Start Grade</label>
                  <input
                    type="text"
                    className="form-control"
                    onChange={handleInputChange}
                    value={values.startGrade}
                    name="startGrade"
                  />
                </div>
                <div className="form-group">
                  <label>Current Grade</label>
                  <input
                    type="text"
                    className="form-control"
                    onChange={handleInputChange}
                    value={values.currentGrade}
                    name="currentGrade"
                  />
                </div>
                <div className="form-group">
                  <label>Date Of Birth</label>
                  <input
                    type="text"
                    className="form-control datepicker"
                    onChange={handleInputChange}
                    value={values.dob}
                    name="dob"
                  />
                </div>
                <div className="form-group">
                  <label>Birth Certificate NO:</label>
                  <input
                    type="text"
                    className="form-control"
                    onChange={handleInputChange}
                    value={values.birthCertNo}
                    name="birthCertNo"
                  />
                </div>
                <div className="form-group">
                  <label>Passport:</label>
                  <input
                    type="text"
                    className="form-control"
                    onChange={handleInputChange}
                    value={values.passport}
                    name="passport"
                  />
                </div>
              </div>
            </div>
          </div>
          {/* Other Details Section */}
          <div className="col-12 col-md-6 col-lg-6">
            <div className="card shadow-sm p-3 mb-5 bg-body rounded">
              <div className="card-header">
                <h4>Student Other Details</h4>
              </div>
              <div className="card-body">
                <div className="form-group">
                  <label>Current Address</label>
                  <input
                    type="text"
                    className="form-control"
                    onChange={handleInputChange}
                    value={values.currAddress}
                    name="currAddress"
                  />
                </div>
                <div className="form-group">
                  <label>Parmanent Address</label>
                  <input
                    type="text"
                    className="form-control"
                    onChange={handleInputChange}
                    value={values.permAddress}
                    name="permAddress"
                  />
                </div>
                <div className="form-group">
                  <label>National ID</label>
                  <input
                    type="text"
                    className="form-control"
                    onChange={handleInputChange}
                    value={values.nationalID}
                    name="nationalID"
                  />
                </div>
                <div className="form-group">
                  <label>Religion</label>
                  <select
                    className="form-control"
                    onChange={(e) => setReligion(e.target.value)}
                    value={religion}
                  >
                    <option value="" disabled>
                      Select Religion
                    </option>
                    <option value="christian">Christian</option>
                    <option value="muslim">Muslim</option>
                    <option value="hindu">Hindu</option>
                    <option value="prefer not to say">Prefer not to say</option>
                  </select>
                </div>
                <div className="form-group">
                  <label>Height</label>
                  <input
                    type="text"
                    className="form-control"
                    onChange={handleInputChange}
                    value={values.height}
                    name="height"
                  />
                </div>
                <div className="form-group">
                  <label>Weight</label>
                  <input
                    type="text"
                    className="form-control"
                    onChange={handleInputChange}
                    value={values.weight}
                    name="weight"
                  />
                </div>
              </div>
              <div className="card-header">
                <h4>Other Information</h4>
              </div>
              <div className="card-body">
                <div className="form-group">
                  <label>Blood Group</label>
                  <select
                    className="form-control"
                    onChange={(e) => setBloodGroup(e.target.value)}
                    value={bloodGroup}
                  >
                    <option value="" disabled>
                      Choose Blood Group
                    </option>
                    <option value="A+">A+</option>
                    <option value="A-">A-</option>
                    <option value="AB">AB</option>
                    <option value="B+">B+</option>
                    <option value="B-">B-</option>
                    <option value="O+">O+</option>
                    <option value="O-">O-</option>
                  </select>
                </div>
                <div className="form-group">
                  <label>Previous School</label>
                  <input
                    type="text"
                    className="form-control"
                    onChange={handleInputChange}
                    value={values.prevSchool}
                    name="prevSchool"
                  />
                </div>
                <div className="form-group">
                  <label>Dormitory Allocation</label>
                  <input
                    type="text"
                    className="form-control"
                    onChange={handleInputChange}
                    value={values.dorm}
                    name="dorm"
                  />
                </div>
                <div className="form-group">
                  <label>Room Number</label>
                  <input
                    type="text"
                    className="form-control"
                    onChange={handleInputChange}
                    value={values.roomNo}
                    name="roomNo"
                  />
                </div>
                <div className="form-group">
                  <label>Other Notes</label>
                  <input
                    type="text"
                    className="form-control"
                    onChange={handleInputChange}
                    value={values.notes}
                    name="notes"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 d-flex justify-content-center">
            <div className="card shadow-sm p-3 mb-5 bg-body rounded">
              <button className="btn btn-primary" type="submit">
                Register Student
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default RegisterNewStudent;
