import React from "react";
import { Outlet } from "react-router-dom";
import PublicNavbar from "../../components/layouts/PublicNavbar";

export default function HomeDashboard() {
  return (
    <div>
      {/* <PublicNavbar /> */}
      <Outlet />
    </div>
  );
}
