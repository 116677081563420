import React from "react";

export default function AccessDenied() {
  return (
    <div className="main-content">
      <h6 className="text-danger">Error ... 403 Access Denied</h6>
      <p>You do not have permission to access this resource.</p>
    </div>
  );
}
