import React, { useState } from "react";
import axios from "axios";
import { MailOutlineOutlined } from "@mui/icons-material";

import { Link, useNavigate } from "react-router-dom";
import { API_URLS } from "../../utils/api.routes";
import { toast, Toaster } from "react-hot-toast";
import {
  toastDangerStyle,
  toastSuccessStyle,
  toastWarnigStyle,
} from "../../components/shared/Custom.Styles";

const AddBookForm = () => {
  const [Booktittle, setBooktittle] = useState("");
  const [Bookcategory, setBookCategory] = useState("");
  const [BookNo, setBookNo] = useState("");
  const [ISBNNo, setISBNNo] = useState("");
  const [PublisherName, setPublisherName] = useState("");

  const [AuthorName, setAuthorName] = useState("");
  const [BookPrice, setBookPrice] = useState("");
  const [ShelfNo, setShelfNo] = useState("");
  const [RackNo, setRackNo] = useState("");
  const [Quantity, setQuantity] = useState("");

  const [Description, setDescription] = useState("");
  const [Hireprice, setHireprice] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    const bodyContent = JSON.stringify({
      Booktittle: Booktittle,
      Bookcategory: Bookcategory,
      BookNo: BookNo,
      ISBNNo: ISBNNo,
      PublisherName: PublisherName,
      AuthorName: AuthorName,
      ShelfNo: parseInt(ShelfNo),
      RackNo: parseInt(RackNo),
      Quantity: parseInt(Quantity),
      BookPrice: parseInt(BookPrice),
      Description: Description,
      Hireprice: parseInt(Hireprice),
    });
    await fetch("http://34.201.161.63:8090/books", {
      method: "POST",
      body: bodyContent,
      // headers: headersList,
    })
      .then((res) => {
        if (res.status === 200) {
          // refetch();
          // setCode("");
          // setName("");
          toast.success(`Created Category Successfully`, toastSuccessStyle);
        } else if (res.status === 400) {
          toast.error(`${res.statusText}`, toastDangerStyle);
          setTimeout(() => {
            toast(`Check Category Already Exists`, toastWarnigStyle);
          }, 1000);
        } else {
          toast.error(`${res.status} ${res.statusText}`, toastDangerStyle);
        }
      })
      .catch((err) => {
        toast.error(`Error Not Found !`, toastDangerStyle);
      });
  };
  return (
    <div className="main-content">
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="title"> Book Title:</label>
          <input
            type="text"
            id="Booktittle"
            value={Booktittle}
            onChange={(event) => setBooktittle(event.target.value)}
          />
        </div>
        <div>
          <label htmlFor="author">Bookcategory:</label>
          <input
            type="text"
            id="Bookcategory"
            value={Bookcategory}
            onChange={(event) => setBookCategory(event.target.value)}
          />
        </div>
        <div>
          <label htmlFor="publisher">BookNo:</label>
          <input
            type="number"
            id="BookNo"
            value={BookNo}
            onChange={(event) => setBookNo(event.target.value)}
          />
        </div>
        <div>
          <label htmlFor="publishedDate">ISBNNo Date:</label>
          <input
            type="number"
            id="ISBNNo"
            value={ISBNNo}
            onChange={(event) => setISBNNo(event.target.value)}
          />
        </div>

        <div>
          <label htmlFor="publishedDate">PublisherName:</label>
          <input
            type="text"
            id="ISBNPublisherNameNo"
            value={PublisherName}
            onChange={(event) => setPublisherName(event.target.value)}
          />
        </div>

        <div>
          <label htmlFor="publishedDate">AuthorName:</label>
          <input
            type="text"
            id="AuthorName"
            value={AuthorName}
            onChange={(event) => setAuthorName(event.target.value)}
          />
        </div>
        <div>
          <label htmlFor="publishedDate">ShelfNo:</label>
          <input
            type="number"
            id="ShelfNo"
            value={ShelfNo}
            onChange={(event) => setShelfNo(event.target.value)}
          />
        </div>
        <div>
          <label htmlFor="publishedDate">RackNo:</label>
          <input
            type="number"
            id="RackNo"
            value={RackNo}
            onChange={(event) => setRackNo(event.target.value)}
          />
        </div>
        <div>
          <label htmlFor="publishedDate">BookPrice:</label>
          <input
            type="number"
            id="BookPrice"
            value={BookPrice}
            onChange={(event) => setBookPrice(event.target.value)}
          />
        </div>
        <div>
          <label htmlFor="publishedDate">Hireprice:</label>
          <input
            type="number"
            id="Hireprice"
            value={Hireprice}
            onChange={(event) => setHireprice(event.target.value)}
          />
        </div>

        <div>
          <label htmlFor="publishedDate">Quantity</label>
          <input
            type="number"
            id="Quantity"
            value={Quantity}
            onChange={(event) => setQuantity(event.target.value)}
          />
        </div>
        <div>
          <label htmlFor="description">Description:</label>
          <textarea
            id="description"
            value={Description}
            onChange={(event) => setDescription(event.target.value)}
          />
        </div>
        <button type="submit">Add Book</button>
        <Toaster position="top-right" reverseOrder={false} />
      </form>
    </div>
  );
};

export default AddBookForm;
