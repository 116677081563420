import { InfoOutlined } from "@mui/icons-material";
import axios from "axios";
import React, { useState } from "react";
import { toast, Toaster } from "react-hot-toast";
import { useQuery } from "react-query";
import { Link, useNavigate } from "react-router-dom";
import {
  toastDangerStyle,
  toastSuccessStyle,
  toastWarnigStyle,
} from "../../../components/shared/Custom.Styles";
import { API_URLS } from "../../../utils/api.routes";
import {
  SubjectSearchSelectInput,
  TeacherSearchSelectInput,
} from "../../../components/custom/Custom.SearchInput";
import authHeader from "../../../services/auth-header";
import {
  CustomIsError,
  CustomIsLoading,
} from "../../../components/custom/Custom.UseQuery";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { CustomPagination } from "../../../components/custom/Custom.DataGrid";
import authService from "../../../services/auth.service";

const AssignSubject = ({ refetch }) => {
  const [subject, setSubject] = useState("");
  const [teacher, setTeacher] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    await fetch(API_URLS.subject_teacher, {
      method: "POST",
      body: JSON.stringify({
        subject: subject.id,
        teacher: teacher.x_staff_id,
      }),
      headers: authHeader(),
    })
      .then((res) => {
        if (res.status === 201) {
          refetch();
          toast.success(`Subject Assigned Successful`, toastSuccessStyle);
        } else if (res.status === 400) {
          toast.error(`Error !!! ${res.statusText}`, toastDangerStyle);
          setTimeout(() => {
            toast(`Seems Subject Already Assigned`, toastWarnigStyle);
          }, 1000);
        } else {
          toast.error(
            `${res.status} Error !!! ${res.statusText}`,
            toastDangerStyle
          );
        }
      })
      .catch((err) => {
        toast.error(`Error !!! Not Found}`, toastDangerStyle);
      });
  };
  return (
    <div className="col-md-6 col-lg-12 col-xl-4">
      <form onSubmit={handleSubmit}>
        <div className="shadow-sm p-3 mb-5 bg-white rounded">
          <h6 className="text-center text-dark">Assign Subject To Teacher</h6>
          <div className="card-body">
            <div className="form-group">
              <label htmlFor="stud">Subject</label>
              <SubjectSearchSelectInput
                onSelectedItemChange={(sub) => {
                  setSubject(sub);
                }}
                searchFields={["subject_name", "subject_code"]}
              />
              {subject && (
                <p className="font-weight-bold text-info">
                  Subject: {subject.subject_name}
                </p>
              )}
            </div>
            <div className="form-group">
              {/* <Autocomplete
                freeSolo
                disableClearable
                options={teachers.map(
                  (t) => `${t.first_name} ${t.last_name} (ID:${t.staff_id})`
                )}
                onChange={(e, value) => {
                  for (var i = 0; i < teachers.length; i++) {
                    let optn = `${teachers[i]["first_name"]} ${teachers[i]["last_name"]} (ID:${teachers[i]["staff_id"]})`;
                    if (value === optn) {
                      setTeacher(teachers[i]["id"]);
                      break;
                    }
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Teacher"
                    variant="outlined"
                    InputProps={{
                      ...params.InputProps,
                      type: "search",
                    }}
                  />
                )}
              /> */}
              <label htmlFor="stud">Teacher</label>
              <TeacherSearchSelectInput
                onSelectedItemChange={(teacher) => {
                  setTeacher(teacher);
                }}
                searchFields={["name", "x_staff_id"]}
              />
              {teacher && (
                <p className="font-weight-bold text-info">
                  Teacher: {teacher.x_staff_id}
                </p>
              )}
            </div>
            <div className="col-12 d-flex justify-content-center">
              <button
                className="align-self-center btn btn-primary mr-1"
                type="submit"
              >
                Assign Subject
              </button>
            </div>
          </div>

          <Toaster position="top-right" reverseOrder={false} />
        </div>
      </form>
    </div>
  );
};

function SubjectTeacher() {
  const currentUser = authService.getCurrentUser();

  const navigate = useNavigate();
  const [rowId, setRowId] = useState(null);
  const [pageSize, setPageSize] = useState(5);

  async function fetchSubjectTeachers() {
    const { data } = await axios.get(API_URLS.subject_teacher);
    return data;
  }
  const { data, error, isError, isLoading, refetch } = useQuery(
    "category",
    fetchSubjectTeachers
  );
  if (isLoading) {
    return <CustomIsLoading />;
  }
  if (isError) {
    return <CustomIsError message={error.message} />;
  }
  const editableColumns = [
    {
      field: "id",
      headerName: "SL",
      headerAlign: "center",
      type: "number",
      width: "80",
    },
    {
      field: "teacher",
      headerName: "Teacher",
      headerAlign: "center",
      editable: true,
      width: 120,
    },
    {
      field: "subject_name",
      headerName: "Subject",
      headerAlign: "center",
      editable: true,
      width: 120,
    },
    // renderCell: (params) =>
    // {
    //   field: "actions",
    //   headerName: "Actions",
    //   type: "actions",
    //   width: 150,
    //   renderCell: (params) => (
    //     <ClassStreamActions {...{ params, rowId, setRowId, refetch }} />
    //   ),
    // },
  ];

  const columns = [
    {
      field: "id",
      headerName: "SL",
      headerAlign: "center",
      type: "number",
      width: "80",
    },
    {
      field: "teacher",
      headerName: "Teacher",
      headerAlign: "center",
      width: 120,
    },
    {
      field: "subject_name",
      headerName: "Subject",
      headerAlign: "center",
      width: 120,
    },
  ];
  return (
    <div className="main-content">
      <div className="row">
        <div className="col-12 col-md-12 col-lg-12">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/admin">Home</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Teacher Subjects
              </li>
            </ol>
          </nav>
        </div>
        <AssignSubject refetch={refetch} />
        <div className="col-md-6 col-lg-12 col-xl-8">
          <div className="shadow-sm p-3 mb-5 bg-white rounded">
            <h6 className="text-center text-dark">
              Teachers Allocated Subjects
            </h6>

            <div style={{ width: "100%" }}>
              <DataGrid
                autoHeight
                columns={
                  currentUser.roles.includes("SUPER_ADMIN")
                    ? editableColumns
                    : columns
                }
                components={{
                  Toolbar: GridToolbar,
                  Pagination: CustomPagination,
                }}
                rows={data}
                getRowId={(row) => row.id}
                rowsPerPageOptions={[5, 10, 20]}
                rowHeight={45}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                getRowSpacing={(params) => ({
                  top: params.isFirstVisible ? 0 : 5,
                  bottom: params.isLastVisible ? 0 : 5,
                })}
                onCellEditCommit={(params) => setRowId(params.id)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SubjectTeacher;
