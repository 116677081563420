export const roles = [
  {
    title: "General",
    items: [
      { id: 3, label: "Accountant", value: "3", isChecked: false },
      { id: 4, label: "Teacher", value: "4", isChecked: false },
      { id: 5, label: "Parent", value: "5", isChecked: false },
      { id: 6, label: "Student", value: "6", isChecked: false },
      { id: 7, label: "Change School", value: "7", isChecked: false },
    ],
  },
  {
    title: "School",
    items: [
      { id: 8, label: "Add", value: "8", isChecked: false },
      { id: 9, label: "Update", value: "9", isChecked: false },
      { id: 10, label: "View", value: "10", isChecked: false },
      { id: 11, label: "Delete", value: "11", isChecked: false },
      { id: 12, label: "Upload", value: "12", isChecked: false },
    ],
  },
  {
    title: "Student Category",
    items: [
      { id: 13, label: "Add", value: "13", isChecked: false },
      { id: 14, label: "Update", value: "14", isChecked: false },
      { id: 15, label: "View", value: "15", isChecked: false },
      { id: 16, label: "Delete", value: "16", isChecked: false },
      { id: 17, label: "Upload", value: "17", isChecked: false },
    ],
  },
  {
    title: "Student",
    items: [
      { id: 18, label: "Add", value: "18", isChecked: false },
      { id: 19, label: "Update", value: "19", isChecked: false },
      { id: 20, label: "View", value: "20", isChecked: false },
      { id: 21, label: "Delete", value: "21", isChecked: false },
      { id: 22, label: "Upload", value: "22", isChecked: false },
    ],
  },
  {
    title: "Grade",
    items: [
      { id: 23, label: "Add", value: "23", isChecked: false },
      { id: 24, label: "Update", value: "24", isChecked: false },
      { id: 25, label: "View", value: "25", isChecked: false },
      { id: 26, label: "Delete", value: "26", isChecked: false },
      { id: 27, label: "Upload", value: "27", isChecked: false },
    ],
  },
  {
    title: "Stream",
    items: [
      { id: 28, label: "Add", value: "28", isChecked: false },
      { id: 29, label: "Update", value: "29", isChecked: false },
      { id: 30, label: "View", value: "30", isChecked: false },
      { id: 31, label: "Delete", value: "31", isChecked: false },
      { id: 32, label: "Upload", value: "32", isChecked: false },
    ],
  },
  {
    title: "Class Room",
    items: [
      { id: 33, label: "Add", value: "33", isChecked: false },
      { id: 34, label: "Update", value: "34", isChecked: false },
      { id: 35, label: "View", value: "35", isChecked: false },
      { id: 36, label: "Delete", value: "36", isChecked: false },
      { id: 37, label: "Upload", value: "37", isChecked: false },
    ],
  },
  {
    title: "Academic Year",
    items: [
      { id: 38, label: "Add", value: "38", isChecked: false },
      { id: 39, label: "Update", value: "39", isChecked: false },
      { id: 40, label: "View", value: "40", isChecked: false },
      { id: 41, label: "Delete", value: "41", isChecked: false },
      { id: 42, label: "Upload", value: "42", isChecked: false },
    ],
  },
  {
    title: "Academic Term",
    items: [
      { id: 43, label: "Add", value: "43", isChecked: false },
      { id: 44, label: "Update", value: "44", isChecked: false },
      { id: 45, label: "View", value: "45", isChecked: false },
      { id: 46, label: "Delete", value: "46", isChecked: false },
      { id: 47, label: "Upload", value: "47", isChecked: false },
    ],
  },
  {
    title: "Subject",
    items: [
      { id: 48, label: "Add", value: "48", isChecked: false },
      { id: 49, label: "Update", value: "49", isChecked: false },
      { id: 50, label: "View", value: "50", isChecked: false },
      { id: 51, label: "Delete", value: "51", isChecked: false },
      { id: 52, label: "Upload", value: "52", isChecked: false },
    ],
  },
  {
    title: "Assign Subject",
    items: [
      { id: 53, label: "Add", value: "53", isChecked: false },
      { id: 54, label: "Update", value: "54", isChecked: false },
      { id: 55, label: "View", value: "55", isChecked: false },
      { id: 56, label: "Delete", value: "56", isChecked: false },
      { id: 57, label: "Upload", value: "57", isChecked: false },
    ],
  },
  {
    title: "Assign Subject Teacher",
    items: [
      {
        id: 58,
        label: "Add",
        value: "58",
        isChecked: false,
      },
      {
        id: 59,
        label: "Update",
        value: "59",
        isChecked: false,
      },
      {
        id: 60,
        label: "View",
        value: "60",
        isChecked: false,
      },
      {
        id: 61,
        label: "Delete",
        value: "61",
        isChecked: false,
      },
      {
        id: 62,
        label: "Upload",
        value: "62",
        isChecked: false,
      },
    ],
  },
  {
    title: "Student Class Allocation",
    items: [
      {
        id: 63,
        label: "Add",
        value: "63",
        isChecked: false,
      },
      {
        id: 64,
        label: "Update",
        value: "64",
        isChecked: false,
      },
      {
        id: 65,
        label: "View",
        value: "65",
        isChecked: false,
      },
      {
        id: 66,
        label: "Delete",
        value: "66",
        isChecked: false,
      },
      {
        id: 67,
        label: "Upload",
        value: "67",
        isChecked: false,
      },
    ],
  },
  {
    title: "Roles&Permissions",
    items: [
      { id: 68, label: "Assign", value: "68", isChecked: false },
      { id: 69, label: "Unassign", value: "69", isChecked: false },
    ],
  },
  {
    title: "Topic",
    items: [
      { id: 70, label: "Add", value: "70", isChecked: false },
      { id: 71, label: "Update", value: "71", isChecked: false },
      { id: 72, label: "View", value: "72", isChecked: false },
      { id: 73, label: "Delete", value: "73", isChecked: false },
      { id: 74, label: "Upload", value: "74", isChecked: false },
    ],
  },
  {
    title: "Sub Topic",
    items: [
      { id: 75, label: "Add", value: "75", isChecked: false },
      { id: 76, label: "Update", value: "76", isChecked: false },
      { id: 77, label: "View", value: "77", isChecked: false },
      { id: 78, label: "Delete", value: "78", isChecked: false },
      { id: 79, label: "Upload", value: "79", isChecked: false },
    ],
  },
  {
    title: "Schemes of Work",
    items: [
      { id: 80, label: "Add", value: "80", isChecked: false },
      { id: 81, label: "Update", value: "81", isChecked: false },
      { id: 82, label: "View", value: "82", isChecked: false },
      { id: 83, label: "Delete", value: "83", isChecked: false },
      { id: 84, label: "Upload", value: "84", isChecked: false },
    ],
  },
  {
    title: "Timetable",
    items: [
      { id: 85, label: "Add", value: "85", isChecked: false },
      { id: 86, label: "Update", value: "86", isChecked: false },
      { id: 87, label: "View", value: "87", isChecked: false },
      { id: 88, label: "Delete", value: "88", isChecked: false },
      { id: 89, label: "Upload", value: "89", isChecked: false },
    ],
  },
  {
    title: "Assessment Rubrics",
    items: [
      { id: 90, label: "Add", value: "90", isChecked: false },
      { id: 91, label: "Update", value: "91", isChecked: false },
      { id: 92, label: "View", value: "92", isChecked: false },
      { id: 93, label: "Delete", value: "93", isChecked: false },
      { id: 94, label: "Upload", value: "94", isChecked: false },
    ],
  },
  {
    title: "Diary",
    items: [
      { id: 95, label: "Add", value: "95", isChecked: false },
      { id: 96, label: "Update", value: "96", isChecked: false },
      { id: 97, label: "View", value: "97", isChecked: false },
      { id: 98, label: "Delete", value: "98", isChecked: false },
      { id: 99, label: "Upload", value: "99", isChecked: false },
    ],
  },
  {
    title: "Lesson",
    items: [
      { id: 100, label: "Add", value: "100", isChecked: false },
      { id: 101, label: "Update", value: "101", isChecked: false },
      { id: 102, label: "View", value: "102", isChecked: false },
      { id: 103, label: "Delete", value: "103", isChecked: false },
      { id: 104, label: "Upload", value: "104", isChecked: false },
    ],
  },
  {
    title: "Dormitory",
    items: [
      { id: 105, label: "Add", value: "105", isChecked: false },
      { id: 106, label: "Update", value: "106", isChecked: false },
      { id: 107, label: "View", value: "107", isChecked: false },
      { id: 108, label: "Delete", value: "108", isChecked: false },
      { id: 109, label: "Upload", value: "109", isChecked: false },
    ],
  },
  {
    title: "Dormitory Rooms",
    items: [
      { id: 110, label: "Add", value: "110", isChecked: false },
      { id: 111, label: "Update", value: "111", isChecked: false },
      { id: 112, label: "View", value: "112", isChecked: false },
      { id: 113, label: "Delete", value: "113", isChecked: false },
      { id: 114, label: "Upload", value: "114", isChecked: false },
    ],
  },
  {
    title: "Vehicle Route",
    items: [
      { id: 115, label: "Add", value: "115", isChecked: false },
      { id: 116, label: "Update", value: "116", isChecked: false },
      { id: 117, label: "View", value: "117", isChecked: false },
      { id: 118, label: "Delete", value: "118", isChecked: false },
      { id: 119, label: "Upload", value: "119", isChecked: false },
    ],
  },
  {
    title: "Vehicles",
    items: [
      { id: 120, label: "Add", value: "120", isChecked: false },
      { id: 121, label: "Update", value: "121", isChecked: false },
      { id: 122, label: "View", value: "122", isChecked: false },
      { id: 123, label: "Delete", value: "123", isChecked: false },
      { id: 124, label: "Upload", value: "124", isChecked: false },
    ],
  },
  {
    title: "Exam Type",
    items: [
      { id: 125, label: "Add", value: "125", isChecked: false },
      { id: 126, label: "Update", value: "126", isChecked: false },
      { id: 127, label: "View", value: "127", isChecked: false },
      { id: 128, label: "Delete", value: "128", isChecked: false },
      { id: 129, label: "Upload", value: "129", isChecked: false },
    ],
  },
  {
    title: "Exam Setup",
    items: [
      { id: 130, label: "Add", value: "130", isChecked: false },
      { id: 131, label: "Update", value: "131", isChecked: false },
      { id: 132, label: "View", value: "132", isChecked: false },
      { id: 133, label: "Delete", value: "133", isChecked: false },
      { id: 134, label: "Upload", value: "134", isChecked: false },
    ],
  },
  {
    title: "Exam Schedule",
    items: [
      { id: 135, label: "Add", value: "135", isChecked: false },
      { id: 136, label: "Update", value: "136", isChecked: false },
      { id: 137, label: "View", value: "137", isChecked: false },
      { id: 138, label: "Delete", value: "138", isChecked: false },
      { id: 139, label: "Upload", value: "139", isChecked: false },
    ],
  },
  {
    title: "Exam Attendance",
    items: [
      { id: 140, label: "Add", value: "140", isChecked: false },
      { id: 141, label: "Update", value: "141", isChecked: false },
      { id: 142, label: "View", value: "142", isChecked: false },
      { id: 143, label: "Delete", value: "143", isChecked: false },
      { id: 144, label: "Upload", value: "144", isChecked: false },
    ],
  },
  {
    title: "Exam Marks Grade",
    items: [
      { id: 145, label: "Add", value: "145", isChecked: false },
      { id: 146, label: "Update", value: "146", isChecked: false },
      { id: 147, label: "View", value: "147", isChecked: false },
      { id: 148, label: "Delete", value: "148", isChecked: false },
      { id: 149, label: "Upload", value: "149", isChecked: false },
    ],
  },
  {
    title: "Exam Marks Register",
    items: [
      { id: 150, label: "Add", value: "150", isChecked: false },
      { id: 151, label: "Update", value: "151", isChecked: false },
      { id: 152, label: "View", value: "152", isChecked: false },
      { id: 153, label: "Delete", value: "153", isChecked: false },
      { id: 154, label: "Upload", value: "154", isChecked: false },
    ],
  },
  {
    title: "Sidebar",
    items: [
      { id: 155, label: "Admin Section", value: "155", isChecked: false },
      { id: 156, label: "Student Info", value: "156", isChecked: false },
      { id: 157, label: "Academics", value: "157", isChecked: false },
      { id: 158, label: "Lesson Plan", value: "158", isChecked: false },
      { id: 159, label: "Examination", value: "159", isChecked: false },
      { id: 160, label: "Fees", value: "160", isChecked: false },
      { id: 161, label: "Accounts", value: "161", isChecked: false },
      { id: 162, label: "Transport", value: "162", isChecked: false },
      { id: 163, label: "Dormitory", value: "163", isChecked: false },
      { id: 164, label: "HR", value: "164", isChecked: false },
      { id: 165, label: "Assignment", value: "165", isChecked: false },
      { id: 166, label: "Quizzes", value: "166", isChecked: false },
      { id: 167, label: "Study Materials", value: "167", isChecked: false },
      { id: 168, label: "Library", value: "168", isChecked: false },
      { id: 169, label: "Roles", value: "169", isChecked: false },
      { id: 170, label: "Front Settings", value: "170", isChecked: false },
      { id: 171, label: "System Settings", value: "171", isChecked: false },
      { id: 172, label: "User Dashboard", value: "172", isChecked: false },
    ],
  },
  {
    title: "Parent",
    items: [
      { id: 173, label: "Comment Diary", value: "173", isChecked: false },
      { id: 174, label: "Comment Assessment", value: "174", isChecked: false },
      { id: 175, label: "Exam Performance", value: "175", isChecked: false },
    ],
  },
  // { title: "", items: [] },
  // { title: "", items: [] },
];
