import { Autocomplete, TextField } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast, Toaster } from "react-hot-toast";
import { useQuery } from "react-query";
import { CustomPagination } from "../../../../components/custom/Custom.DataGrid";
import {
  toastSuccessStyle,
  toastDangerStyle,
  toastWarnigStyle,
} from "../../../../components/shared/Custom.Styles";
import { API_URLS } from "../../../../utils/api.routes";
import authService from "../../../../services/auth.service";
import { Link } from "react-router-dom";
import SubjectPerGradeActions from "./SubjectPerGradeActions";
import authHeader from "../../../../services/auth-header";
import AccessDenied from "../../../../components/custom/Custom.AccessDenied";

export default function SubjectPerGrade() {
  const currentUser = authService.getCurrentUser();
  // Used When mapping on the post request
  const [subject, setSubject] = useState("");
  const [grade, setGrade] = useState("");

  const [subjects, setSubjects] = useState([]);
  const [grades, setGrades] = useState([]);

  // For table list view
  const [rowId, setRowId] = useState(null);
  const [pageSize, setPageSize] = useState(10);

  useEffect(() => {
    const fetchSubjects = async () => {
      const res = await fetch(API_URLS.subjects);
      const data = await res.json();
      setSubjects(data);
    };
    const fetchClassrooms = async () => {
      const res = await fetch(API_URLS.grade);
      const data = await res.json();
      setGrades(data);
    };

    fetchSubjects();
    fetchClassrooms();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    await fetch(API_URLS.per_grade_subjects, {
      method: "POST",
      body: JSON.stringify({
        subject: subject,
        grade: grade,
      }),
      headers: authHeader(),
    })
      .then((res) => {
        if (res.status === 201) {
          refetch();
          toast.success(`Subject Assigned Successful`, toastSuccessStyle);
        } else if (res.status === 400) {
          toast.error(`Error !!! ${res.statusText}`, toastDangerStyle);
          setTimeout(() => {
            toast(`Seems Subject Already Assigned`, toastWarnigStyle);
          }, 1000);
        } else {
          toast.error(
            `${res.status} Error !!! ${res.statusText}`,
            toastDangerStyle
          );
        }
      })
      .catch((err) => {
        toast.error(`Error !!! Not Found}`, toastDangerStyle);
      });
  };

  async function fetchGradeSubjects() {
    const { data } = await axios.get(API_URLS.per_grade_subjects);
    return data;
  }
  const { data, error, isError, isLoading, refetch } = useQuery(
    "grade_subjects",
    fetchGradeSubjects
  );
  if (isLoading) {
    return <div className="main-content">Loading...</div>;
  }
  if (isError) {
    return <div className="main-content">Error!! {error.message}</div>;
  }

  const editableColumns = [
    {
      field: "id",
      headerName: "SL",
      headerAlign: "center",
      type: "number",
      hide: true,
      width: "80",
    },
    {
      field: "subject_name",
      headerName: "Subject",
      headerAlign: "center",
      editable: true,
      width: 150,
    },
    {
      field: "grade_name",
      headerName: "Grade Name",
      editable: true,
      valueOptions: ({ row }) => [
        "Grade 1",
        "Grade 2",
        "Grade 3",
        "Grade 4",
        "Grade 5",
        "Grade 6",
      ],
      width: 200,
    },
    {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      width: 150,
      renderCell: (params) => (
        <SubjectPerGradeActions {...{ params, rowId, setRowId }} />
      ),
    },
  ];
  const columns = [
    {
      field: "id",
      headerName: "SL",
      headerAlign: "center",
      type: "number",
      hide: true,
      width: "80",
    },
    {
      field: "grade_name",
      headerName: "Subject",
      headerAlign: "center",
      width: 150,
    },
    {
      field: "grade_name",
      headerName: "Grade Name",
      headerAlign: "center",
      width: 200,
    },
  ];

  if (
    !currentUser.roles.includes("VIEW_SUBJECT") &&
    !currentUser.roles.includes("SUPER_ADMIN")
  ) {
    return <AccessDenied />;
  }
  return (
    <div className="main-content">
      <div className="row">
        <div className="col-12 col-md-12 col-lg-12">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/admin">Home</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Grade Subjects
              </li>
            </ol>
          </nav>
        </div>
        {currentUser.roles.includes("ADD_SUBJECT_PER_GRADE") ||
        currentUser.roles.includes("SUPER_ADMIN") ? (
          <div className="col-md-6 col-lg-12 col-xl-4">
            <form onSubmit={handleSubmit}>
              <div className="shadow-sm p-3 mb-5 bg-white rounded">
                <h5 className="text-center text-dark">
                  Assign Subject To Grade
                </h5>
                <div className="card-body">
                  <div className="form-group">
                    <Autocomplete
                      freeSolo
                      disableClearable
                      options={subjects.map((s) => `${s.subject_name}`)}
                      onChange={(e, value) => {
                        for (var i = 0; i < subjects.length; i++) {
                          let optn = `${subjects[i]["subject_name"]}`;
                          if (value === optn) {
                            setSubject(subjects[i]["id"]);
                            break;
                          }
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Subject"
                          variant="outlined"
                          InputProps={{
                            ...params.InputProps,
                            type: "search",
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className="form-group">
                    <Autocomplete
                      freeSolo
                      disableClearable
                      options={grades.map((cl) => `${cl.name}`)}
                      onChange={(e, value) => {
                        for (var i = 0; i < grades.length; i++) {
                          let optn = `${grades[i]["name"]}`;
                          if (value === optn) {
                            setGrade(grades[i]["id"]);
                            break;
                          }
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Grade"
                          variant="outlined"
                          InputProps={{
                            ...params.InputProps,
                            type: "search",
                          }}
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="text-center">
                  <button className="btn btn-primary mr-1" type="submit">
                    Save Subject
                  </button>
                  <Toaster position="top-right" reverseOrder={false} />
                </div>
              </div>
            </form>
          </div>
        ) : (
          <></>
        )}
        <div className="col-md-6 col-lg-12 col-xl-8">
          <div className="shadow-sm p-3 mb-5 bg-white rounded">
            <h5 className="text-center text-dark">Subjects Taught Per Grade</h5>
            <div style={{ width: "100%" }}>
              <DataGrid
                autoHeight
                columns={
                  currentUser.roles.includes("UPDATE_ACADEMIC_TERM") ||
                  currentUser.roles.includes("SUPER_ADMIN")
                    ? editableColumns
                    : columns
                }
                components={{
                  Toolbar: GridToolbar,
                  Pagination: CustomPagination,
                }}
                rows={data}
                getRowId={(row) => row.id}
                rowsPerPageOptions={[5, 10, 20]}
                rowHeight={45}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                getRowSpacing={(params) => ({
                  top: params.isFirstVisible ? 0 : 5,
                  bottom: params.isLastVisible ? 0 : 5,
                })}
                onCellEditCommit={(params) => setRowId(params.id)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
