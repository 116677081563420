import React from "react";
import {
  LineChart,
  Line,
  BarChart,
  Bar,
  ReferenceLine,
  ResponsiveContainer,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";

const data = [
  {
    name: "January",
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: "February",
    uv: -3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: "March",
    uv: -2000,
    pv: -9800,
    amt: 2290,
  },
  {
    name: "April",
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: "May",
    uv: -1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: "June",
    uv: 2390,
    pv: -3800,
    amt: 2500,
  },
  {
    name: "July",
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
];

function Profitloss() {
  return (
    <div className="main-content">
      {" "}
      <BarChart
        width={500}
        height={300}
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <ReferenceLine y={0} stroke="#000" />
        <Bar dataKey="pv" fill="#8884d8" />
        <Bar dataKey="uv" fill="#82ca9d" />
      </BarChart>
      <div class="row">
        <div class="col-12 col-sm-12 col-lg-4">
          <div class="card">
            <div class="card-header">
              <h4>Chart</h4>
            </div>
            <div class="card-body">
              <div id="chart4" class="chartsh"></div>
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-12 col-lg-4">
          <div class="card">
            <div class="card-header">
              <h4>Chart</h4>
            </div>
            <div class="card-body">
              <div class="summary">
                <div
                  class="summary-chart active"
                  data-tab-group="summary-tab"
                  id="summary-chart"
                >
                  <div id="chart3" class="chartsh"></div>
                </div>
                <div data-tab-group="summary-tab" id="summary-text"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-12 col-lg-4">
          <div class="card">
            <div class="card-header">
              <h4>Chart</h4>
            </div>
            <div class="card-body"></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Profitloss;
