import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import authService from "../../services/auth.service";
import { API_URLS } from "../../utils/api.routes";
const data = [
  { name: "Jan", uv: 4000, pv: 2400, amt: 2400 },
  { name: "Feb", uv: 3000, pv: 1398, amt: 2210 },
  { name: "Mar", uv: 2000, pv: 9800, amt: 2290 },
  { name: "Apr", uv: 2780, pv: 3908, amt: 2000 },
  { name: "May", uv: 1890, pv: 4800, amt: 2181 },
  { name: "Jun", uv: 2390, pv: 3800, amt: 2500 },
  { name: "Jul", uv: 3490, pv: 4300, amt: 2100 },
];

function Expenses() {
  // const [currentUser, setCurrentUser] = useState(null);
  const currentUser = authService.getCurrentUser();

  const [invoice, setInvoice] = useState([]);
  const [rowId, setRowId] = useState(null);
  const [pageSize, setPageSize] = useState(5);

  // useEffect(() => {
  //   const fetchCurrentUser = async () => {
  //     const user = await authService.getCurrentUser();
  //     setCurrentUser(user);
  //   };
  //   fetchCurrentUser();
  // }, []);

  useEffect(() => {
    const fetchInvoices = async () => {
      if (currentUser.roles.includes("SUPER_ADMIN")) {
        const res = await fetch("http://34.201.161.63:8009/expenses");
        console.log("Admin");
        const data = await res.json();
        setInvoice(data);
      } else if (currentUser.roles.includes("STUDENT")) {
        const res = await fetch(`http://34.201.161.63:8009/expenses`);
        console.log("Student");
        const data = await res.json();
        setInvoice(data);
      }
    };
    fetchInvoices();
  }, []);

  // const handleRowClick = (params) => {
  //   setSelectedRow(params.row);
  // };

  // const handleCloseModal = () => {
  //   setSelectedRow(null);
  // };

  console.log(currentUser?.category);
  console.log(invoice);

  const columns = [
    {
      field: "date",
      headerName: "date",
      hide: false,
      headerAlign: "center",
      width: 80,
    },
    {
      field: "employee_id",
      headerName: "Employee",

      hide: false,
      headerAlign: "center",
      width: 80,
    },
    {
      field: "id",
      headerName: "Expense id",
      headerAlign: "center",
      hide: false,
      editable: true,
      width: 100,
    },
    {
      field: "name",
      headerName: "Expense name",
      headerAlign: "center",
      hidable: false,
      editable: true,
      width: 130,
    },
    {
      field: "total_amount",
      headerName: "Amount",
      headerAlign: "center",
      hidable: false,
      editable: true,
      width: 130,
    },
  ];

  return (
    <div className="main-content">
      <div className="row justify-content-center">
        <h1 className="text-muted">{currentUser.username}</h1>
        <div className="col-12 col-md-12 col-lg-12">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/admin">Home</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                All Expenses
              </li>
              {/*
              <div className="col-4">
                <Link to="/admin/libary/add-book" className="font-weight-bold">
                  Ceate invoice
                </Link>
              </div>
              */}
            </ol>
          </nav>
        </div>

        <LineChart width={600} height={400} data={data}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line
            type="monotone"
            dataKey="uv"
            stroke="#8884d8"
            activeDot={{ r: 8 }}
          />
          <Line type="monotone" dataKey="pv" stroke="#82ca9d" />
        </LineChart>
        <div className="col-md-12 col-lg-12 col-xl-12">
          <div className="shadow-sm p-3 mb-5 bg-white rounded">
            <div className="text-center">
              <h5 className="text-center text-dark">Expenses list</h5>
            </div>
            <div style={{ height: 420 }}>
              {invoice && (
                <DataGrid
                  columns={columns}
                  components={{
                    Toolbar: GridToolbar,
                  }}
                  rows={invoice}
                  getRowId={(row) => row.id}
                  rowsPerPageOptions={[5, 10, 20]}
                  rowHeight={45}
                  pageSize={pageSize}
                  onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                  getRowSpacing={(params) => ({
                    top: params.isFirstVisible ? 0 : 5,
                    bottom: params.isLastVisible ? 0 : 5,
                  })}
                  onCellEditCommit={(params) => setRowId(params.id)}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Expenses;
