import { Button } from "@mui/material";
import React from "react";
// import LoremIpsum from "react-lorem-ipsum";
export default function WelcomeForm(props) {
  const Continue = (e) => {
    e.preventDefault();
    props.nextStep();
  };

  const { values, handleChange } = props;
  return (
    <div style={{ height: "70vh" }}>
      <h4 className="text-dark text-center">New Student Registration Form</h4>
      <div
        className="d-flex flex-wrap justify-content-around"
        style={{ height: "80%" }}
      >
        <div
          className="col-4"
          style={{
            backgroundImage:
              'url("https://img.freepik.com/premium-vector/school-building-vector-icon-illustration-building-landmark-icon-concept-white-isolated_138676-441.jpg?w=2000")',
            width: "100%",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        ></div>
        <div className="col-7 align-self-end">
          <div className="d-flex flex-column">
            <div className="text-muted">
              {/* <LoremIpsum p={1} /> */}
              Welcome to our student registration page! Here, you can register
              for our exciting courses and programs and take the first step
              towards your academic and professional goals. To get started,
              click on the link below to access our registration form. The form
              will guide you through the registration process and ask for your
              basic information, including your name, contact details, and
              academic background. Please note that some fields in the
              registration form are required, while others are optional. Be sure
              to read the instructions carefully and fill out all required
              fields to ensure that your registration is processed successfully.{" "}
              <br />
              If you have any questions or need assistance with the registration
              process, please contact our friendly support team at
              support@school.edu or call us at +1-555-1234. We look forward to
              welcoming you to our community of learners and helping you achieve
              your academic and career goals!
            </div>
            <div className="align-self-end text-right mt-3">
              <Button color="primary" variant="contained" onClick={Continue}>
                Start Registration
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
