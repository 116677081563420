import axios from "axios";
import React from "react";
import { useQuery } from "react-query";
import { Link, useLocation } from "react-router-dom";
import { API_URLS } from "../../utils/api.routes";
import {
  CustomIsError,
  CustomIsLoading,
} from "../../components/custom/Custom.UseQuery";
import AccessDenied from "../../components/custom/Custom.AccessDenied";
import authService from "../../services/auth.service";

export default function Roomdetails() {
  const currentUser = authService.getCurrentUser();

  const { state } = useLocation();
  const fetchRoomDetails = async () => {
    const { data } = await axios.get(API_URLS.dorm_room.concat(state, "/"));
    return data;
  };
  const { data, error, isError, isLoading } = useQuery(
    "dorm_room",
    fetchRoomDetails
  );
  if (isLoading) {
    return <CustomIsLoading />;
  }
  if (isError) {
    return <CustomIsError message={error.message} />;
  }
  if (
    !currentUser.roles.includes("VIEW_DORMITORY_ROOM") &&
    !currentUser.roles.includes("SUPER_ADMIN")
  ) {
    return <AccessDenied />;
  }
  return (
    <div className="main-content">
      <div className="col-12 col-md-12 col-lg-12">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/admin">Home</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/admin/dormitory/rooms">Rooms</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Room Details
            </li>
          </ol>
        </nav>
      </div>
      <div className="col-8">
        <div className="card shadow-sm p-3 mb-5 bg-white rounded">
          <h3 className="text-center text-info">Room Details</h3>
          <div className="text-dark">ID: {data.id}</div>
          <div className="text-dark">Name: {data.name}</div>
          <div className="text-dark">Dormitory: {data.dormitory}</div>
          <div className="text-dark">Capacity: {data.capacity}</div>
          <div className="text-dark">Description: {data.description}</div>
        </div>
      </div>
    </div>
  );
}
