import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { API_URLS } from "../../utils/api.routes";
import axios from "axios";
import { useQuery } from "react-query";
import Tour from "reactour";
import { IconButton } from "@mui/material";
import { InfoOutlined } from "@mui/icons-material";
import { Cell, Pie, PieChart } from "recharts";
import { Link } from "react-router-dom";

const pieData = [
  { name: "Present", value: 20 },
  { name: "Absent", value: 1 },
];

const COLORS = ["#32CD32", "#DC143C"];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="black"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}% (${pieData[index].name})`}
    </text>
  );
};

export default function AdminOutline() {
  const [schools, setSchools] = useState([]);
  const [studentsCount, setStudentsCount] = useState(null);
  const [staff, setStaff] = useState([]);
  const [streams, setStreamCount] = useState(null);
  const [classrooms, setClassRoomsCount] = useState(null);

  const [showIntro, setShowIntro] = useState(false);

  useEffect(() => {
    const fetchSchools = async () => {
      const res = await fetch(API_URLS.school);
      const data = await res.json();
      setSchools(data);
    };
    async function fetchStudents() {
      const res = await fetch(API_URLS.students_count);
      const data = await res.json();
      setStudentsCount(data.student_count);
    }
    async function fetchStaff() {
      const res = await fetch(API_URLS.hr_staff);
      const data = await res.json();
      setStaff(data);
    }
    async function fetchStreamCount() {
      const res = await fetch(API_URLS.stream_count);
      const data = await res.json();
      setStreamCount(data.stream_count);
    }
    async function fetchClassRoomCount() {
      const res = await fetch(API_URLS.classroom_count);
      const data = await res.json();
      setClassRoomsCount(data.classroom_count);
    }
    fetchSchools();
    fetchStudents();
    fetchStaff();
    fetchStreamCount();
    fetchClassRoomCount();
  }, []);

  const steps = [
    {
      selector: "#students",
      content: "Number of Students registered",
    },
    {
      selector: "#staff",
      content: "This is the number of staff",
    },
    {
      selector: "#streams",
      content: "This is the number of streams registered",
    },
    {
      selector: "#classrooms",
      content: "This is a list of classes registered",
    },
  ];

  const fetchData = async () => {
    const { data } = await axios.get(API_URLS.school);
    return data;
  };
  const { data, error, isError, isLoading } = useQuery("schools", fetchData);
  if (isLoading) {
    return <div className="main-content">Loading...</div>;
  }
  if (isError) {
    return <div className="main-content">Error!! {error.message}</div>;
  }
  return (
    <div className="main-content">
      <div className="row">
        <div className="col-12">
          <h4 className="text-center text-dark">
            Welcome to {data[0]["name"]}-{data[0]["code"]}
            <IconButton
              onClick={() => setShowIntro(true)}
              color="primary"
              aria-label="add to shopping cart"
            >
              <InfoOutlined size="small" />
            </IconButton>
          </h4>
        </div>
      </div>
      <Tour
        steps={steps}
        isOpen={showIntro}
        onRequestClose={() => setShowIntro(false)}
      />

      <div className="row">
        <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-xs-12">
          <div
            id="students"
            className="card shadow-sm p-3 mb-5 bg-white rounded"
          >
            <div className="card-statistic-4">
              <div className="align-items-center justify-content-between">
                <div className="row ">
                  <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 pr-0 pt-3">
                    <div id="studentslist" className="card-content">
                      <Link to="/admin/students/list">
                        <h5 className="font-15 text-dark">Students</h5>
                      </Link>
                      <h2 className="mb-3 font-18">{studentsCount}</h2>
                      <p className="mb-0">
                        {/* <span className="col-green">10%</span> Increase */}
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 pl-0">
                    <div className="banner-img">
                      <img src="assets/img/banner/students.png" alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-xs-12">
          <div id="staff" className="card shadow-sm p-3 mb-5 bg-white rounded">
            <div className="card-statistic-4">
              <div className="align-items-center justify-content-between">
                <div className="row ">
                  <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 pr-0 pt-3">
                    <div className="card-content">
                      <Link to="/admin">
                        {" "}
                        <h5 className="font-15 text-dark">Staff</h5>
                      </Link>
                      <h2 className="mb-3 font-18">{staff.length}</h2>
                      <p className="mb-0">
                        {/* <span className="col-orange">09%</span> Decrease */}
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 pl-0">
                    <div className="banner-img">
                      <img src="assets/img/banner/2.png" alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-xs-12">
          <div
            id="streams"
            className="card shadow-sm p-3 mb-5 bg-white rounded"
          >
            <div className="card-statistic-4">
              <div className="align-items-center justify-content-between">
                <div className="row ">
                  <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 pr-0 pt-3">
                    <div className="card-content">
                      <Link to="/admin/stream/list">
                        <h5 className="font-15 text-dark">Streams</h5>
                      </Link>
                      <h2 className="mb-3 font-18">{streams}</h2>
                      <p className="mb-0">
                        {/* <span className="col-green">18%</span>
                        Increase */}
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 pl-0">
                    <div className="banner-img">
                      <img src="assets/img/banner/3.png" alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-xs-12">
          <div
            id="classrooms"
            className="card shadow-sm p-3 mb-5 bg-white rounded"
          >
            <div className="card-statistic-4">
              <div className="align-items-center justify-content-between">
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 pr-0 pt-3">
                    <div className="card-content">
                      <Link to="/admin/classroom/list">
                        <h5 className="font-15 text-dark">Class Rooms</h5>
                      </Link>
                      <h2 className="mb-3 font-18">{classrooms}</h2>
                      <p className="mb-0">
                        {/* <span className="col-green">42%</span> Increase */}
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 pl-0">
                    <div className="banner-img">
                      <img src="assets/img/banner/4.png" alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
