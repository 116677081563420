import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";

const data = [
  { name: "Jan", uv: 4000, pv: 2400, amt: 2400 },
  { name: "Feb", uv: 3000, pv: 1398, amt: 2210 },
  { name: "Mar", uv: 2000, pv: 9800, amt: 2290 },
  { name: "Apr", uv: 2780, pv: 3908, amt: 2000 },
  { name: "May", uv: 1890, pv: 4800, amt: 2181 },
  { name: "Jun", uv: 2390, pv: 3800, amt: 2500 },
  { name: "Jul", uv: 3490, pv: 4300, amt: 2100 },
];

function Income() {
  return (
    <div class="row">
      <div class="col-12 col-sm-12 col-lg-12">
        <div class="card ">
          <div class="card-header">
            <h4>Revenue chart</h4>
            <div class="card-header-action">
              <div class="dropdown">
                <a
                  href="#"
                  data-toggle="dropdown"
                  class="btn btn-warning dropdown-toggle"
                >
                  Options
                </a>
                <div class="dropdown-menu">
                  <a href="#" class="dropdown-item has-icon">
                    <i class="fas fa-eye"></i> View
                  </a>
                  <a href="#" class="dropdown-item has-icon">
                    <i class="far fa-edit"></i> Edit
                  </a>
                  <div class="dropdown-divider"></div>
                  <a href="#" class="dropdown-item has-icon text-danger">
                    <i class="far fa-trash-alt"></i>
                    Delete
                  </a>
                </div>
              </div>
              <a href="#" class="btn btn-primary">
                View All
              </a>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-lg-9">
                <div id="chart1"></div>
                <div class="row mb-0">
                  <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                    <div class="list-inline text-center">
                      <div class="list-inline-item p-r-30">
                        <i data-feather="arrow-up-circle" class="col-green"></i>
                        <h5 class="m-b-0">Ksh675</h5>
                        <p class="text-muted font-14 m-b-0">Weekly Earnings</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                    <div class="list-inline text-center">
                      <div class="list-inline-item p-r-30">
                        <i
                          data-feather="arrow-down-circle"
                          class="col-orange"
                        ></i>
                        <h5 class="m-b-0">Ksh1,587</h5>
                        <p class="text-muted font-14 m-b-0">Monthly Earnings</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                    <div class="list-inline text-center">
                      <div class="list-inline-item p-r-30">
                        <i data-feather="arrow-up-circle" class="col-green"></i>
                        <h5 class="mb-0 m-b-0">Ksh45,965</h5>
                        <p class="text-muted font-14 m-b-0">Yearly Earnings</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-3">
                <div class="row mt-5">
                  <div class="col-7 col-xl-7 mb-3">Total customers</div>
                  <div class="col-5 col-xl-5 mb-3">
                    <span class="text-big">8,257</span>
                    <sup class="col-green">+09%</sup>
                  </div>
                  <div class="col-7 col-xl-7 mb-3">Total Income</div>
                  <div class="col-5 col-xl-5 mb-3">
                    <span class="text-big">Ksh9,857</span>
                    <sup class="text-danger">-18%</sup>
                  </div>
                  <div class="col-7 col-xl-7 mb-3">Project completed</div>
                  <div class="col-5 col-xl-5 mb-3">
                    <span class="text-big">28</span>
                    <sup class="col-green">+16%</sup>
                  </div>
                  <div class="col-7 col-xl-7 mb-3">Total expense</div>
                  <div class="col-5 col-xl-5 mb-3">
                    <span class="text-big">Ksh6,287</span>
                    <sup class="col-green">+09%</sup>
                  </div>
                  <div class="col-7 col-xl-7 mb-3">New Customers</div>
                  <div class="col-5 col-xl-5 mb-3">
                    <span class="text-big">684</span>
                    <sup class="col-green">+22%</sup>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Income;
