import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

function SubjectTaughtEdit() {
  const { state } = useLocation();
  const navigate = useNavigate();

  const [code, setCode] = useState(state.sub_code);
  const [name, setName] = useState(state.sub_name);
  const [message, setMessage] = useState("");

  const headersList = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };

  const bodyContent = JSON.stringify({
    id: state.sub_id,
    subject_code: code,
    subject_name: name,
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    const apiEnd = "http://127.0.0.1:8000/api/v1/grades/subjects/";
    await fetch(apiEnd.concat(state.sub_id, "/"), {
      method: "PUT",
      body: bodyContent,
      headers: headersList,
    })
      .then((res) => {
        setMessage("Successfully Updated Academic Year");
        console.log(message);
        navigate("/subjects");
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        console.log("PUT Request Was Called");
      });
  };
  return (
    <div className="main-content">
      <div className="row">
        <div className="col-md-6 col-lg-12 col-xl-4">
          <div className="card shadow-sm p-3 mb-5 bg-body rounded">
            <div className="card-header">
              <h4>Edit Subject</h4>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="card-body">
                <div className="form-group">
                  <label htmlFor="accYear">Subject Code</label>
                  <input
                    type="text"
                    id="accYear"
                    className="form-control"
                    value={code}
                    onChange={(e) => setCode(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label>Subject Name</label>
                  <input
                    type="text"
                    className="form-control"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
              </div>
              <div className="card-footer text-center">
                <button className="btn btn-primary mr-1" type="submit">
                  Update Subject
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SubjectTaughtEdit;
