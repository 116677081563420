import React from "react";
import { PeopleOutline } from "@mui/icons-material";
import { Link } from "react-router-dom";

export default function HumanResource() {
  return (
    <>
      <li className="dropdown">
        <Link to="#" className="menu-toggle nav-link has-dropdown">
          <PeopleOutline fontSize="medium" className="icon-mr" />
          <span>Human Resource</span>
        </Link>
        <ul className="dropdown-menu">
          {/*  <li>
            <Link className="nav-link" to="/admin/hr/designation">
              Designation
            </Link>
          </li>
          <li>
            <Link className="nav-link" to="/admin/hr/department">
              Department
            </Link>
          </li>
        <li>
            <Link className="nav-link" to="/admin/hr-category">
              Staff Category
            </Link>
          </li> */}
          {/* <li>
            <Link className="nav-link" to="/admin/hr/add-staff">
              Add Staff
            </Link>
          </li> 
          <li>
            <Link className="nav-link" to="/admin/hr/add-staff-1">
              Add Staff
            </Link>
          </li>*/}
          <li>
            <Link className="nav-link" to="/admin/hr/staff-list">
              Staff List
            </Link>
          </li>
          {/* <li>
            <Link className="nav-link" to="/hr-staff-deirectory">
              Staff Directory
            </Link>
          </li>
          <li>
            <Link className="nav-link" to="/hr-staff-attendance">
              Staff Attendance
            </Link>
          </li>
          <li>
            <Link className="nav-link" to="/hr-staff-attendance-report">
              Staff Attendance Report
            </Link>
          </li>
          <li>
            <Link className="nav-link" to="/hr-payroll">
              Payroll
            </Link>
          </li>
          <li>
            <Link className="nav-link" to="/hr-payroll-report">
              Payroll Report
            </Link>
          </li> */}
        </ul>
      </li>
    </>
  );
}
