import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import axios from "axios";
import React, { useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { Link } from "react-router-dom";
import { useQuery } from "react-query";
import { Button } from "@mui/material";
import { Send } from "@mui/icons-material";
import {
  SetupSearchSelectInput,
  StudentSearchSelectInput,
  SubjectSearchSelectInput,
} from "../../../components/custom/Custom.SearchInput";
import { API_URLS } from "../../../utils/api.routes";
import MarksRegisterActions from "./MarksRegisterActions";
import {
  toastDangerStyle,
  toastSuccessStyle,
  toastWarnigStyle,
} from "../../../components/shared/Custom.Styles";
import { CustomPagination } from "../../../components/custom/Custom.DataGrid";
import { useEffect } from "react";
import {
  CustomIsError,
  CustomIsLoading,
} from "../../../components/custom/Custom.UseQuery";
import AccessDenied from "../../../components/custom/Custom.AccessDenied";
import authService from "../../../services/auth.service";

export default function MarksRegister() {
  const currentUser = authService.getCurrentUser();

  // input fields change to respective lesson plan fields
  const [admn_no, setAdm] = useState("");
  const [setup, setSetup] = useState("");
  const [subject, setSubject] = useState("");
  const [cat_marks, setCat] = useState("");
  const [assignment_marks, setAssign] = useState("");
  const [exam_marks, setExam] = useState("");

  //import file hook
  const [file, setFile] = useState();

  // for pagination
  const [rowId, setRowId] = useState(null);
  const [pageSize, setPageSize] = useState(5);

  const [regStudents, setRegStudents] = useState([]);

  useEffect(() => {
    const fetchStudents = async () => {
      const res = await fetch(API_URLS.students_info);
      const data = await res.json();
      setRegStudents(data);
    };

    fetchStudents();
  }, []);

  let headersList = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };

  // change to respective lesson plan body
  let bodyContent = JSON.stringify({
    admn_no: admn_no,
    setup: setup,
    subject: subject,
    cat_marks: cat_marks,
    assignment_marks: assignment_marks,
    exam_marks: exam_marks,
  });
  const handleSubmit = async (e) => {
    e.preventDefault();
    await fetch(API_URLS.exam_markregister, {
      method: "POST",
      headers: headersList,
      body: bodyContent,
    })
      .then((res) => {
        if (res.status === 201) {
          setAdm("");
          setSetup("");
          setSubject("");
          setCat("");
          setAssign("");
          setExam("");

          refetch();
          toast.success(
            `Successfully Created Marks Register`,
            toastSuccessStyle
          );
        } else if (res.status === 400) {
          toast.error(`Error !!! ${res.statusText}`, toastDangerStyle);
          setTimeout(() => {
            toast(`Seems Marks Register Already Exists`, toastWarnigStyle);
          }, 1000);
        } else {
          toast.error(
            `${res.status} Error !!! ${res.statusText}`,
            toastDangerStyle
          );
        }
      })
      .catch((err) => {
        toast.error(`Error, Not Found !!!`, toastDangerStyle);
      });
  };

  //import
  const handleFileSelection = (e) => {
    const selectedFile = e.target.files[0];
    if (
      selectedFile.type === "text/csv" ||
      selectedFile.type === "application/json" ||
      selectedFile.type === "application/vnd.ms-excel" ||
      selectedFile.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      setFile(selectedFile);
    } else {
      toast("Please select a CSV, JSON or Excel file.", toastWarnigStyle);
    }
  };

  const UploadFile = () => {
    const formData = new FormData();
    formData.append("file", file);

    fetch(API_URLS.upload_marksregister, {
      method: "POST",
      body: formData,
    })
      .then((res) => {
        if (res.status === 200) {
          refetch();
          setFile(null);
          toast.success("Upload Successful", toastSuccessStyle);
        }
      })
      .catch((error) => {
        toast.error(`Upload Error ${error}`, toastDangerStyle);
      });
  };

  //

  async function fetchMarksRegister() {
    const { data } = await axios.get(API_URLS.exam_markregister);
    return data;
  }
  const { data, error, isError, isLoading, refetch } = useQuery(
    "exam_markregister",
    fetchMarksRegister
  );

  const editableColumns = [
    {
      field: "id",
      headerName: "SL",
      headerAlign: "center",
      type: "number",
      width: "80",
    },
    {
      field: "admn_no",
      headerName: "Admn",
      headerAlign: "center",
      editable: true,
      width: 120,
    },
    {
      field: "setup",
      headerName: "Setup",
      headerAlign: "center",
      editable: true,
      width: 120,
    },
    {
      field: "subject",
      headerName: "Subject",
      headerAlign: "center",
      editable: true,
      width: 120,
    },
    {
      field: "cat_marks",
      headerName: "Catmarks",
      headerAlign: "center",
      editable: true,
      width: 120,
    },
    {
      field: "assignment_marks",
      headerName: "Assignmentmarks",
      headerAlign: "center",
      editable: true,
      width: 120,
    },
    {
      field: "exam_marks",
      headerName: "Exammarks",
      headerAlign: "center",
      editable: true,
      width: 120,
    },

    {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      renderCell: (params) => (
        <MarksRegisterActions {...{ params, rowId, setRowId }} />
      ),
    },
  ];
  const columns = [
    {
      field: "id",
      headerName: "SL",
      headerAlign: "center",
      type: "number",
      width: "80",
    },
    {
      field: "admn_no",
      headerName: "Admn",
      headerAlign: "center",
      width: 120,
    },
    {
      field: "setup",
      headerName: "Setup",
      headerAlign: "center",
      width: 120,
    },
    {
      field: "subject",
      headerName: "Subject",
      headerAlign: "center",
      width: 120,
    },
    {
      field: "cat_marks",
      headerName: "Catmarks",
      headerAlign: "center",
      width: 120,
    },
    {
      field: "assignment_marks",
      headerName: "Assignmentmarks",
      headerAlign: "center",
      width: 120,
    },
    {
      field: "exam_marks",
      headerName: "Exammarks",
      headerAlign: "center",
      width: 120,
    },
  ];
  if (isLoading) {
    return <CustomIsLoading />;
  }
  if (isError) {
    return <CustomIsError message={error.message} />;
  }
  if (
    !currentUser.roles.includes("VIEW_EXAM_MARKS_GRADE") &&
    !currentUser.roles.includes("SUPER_ADMIN")
  ) {
    return <AccessDenied />;
  }

  return (
    <div className="main-content">
      <div className="row">
        <div className="col-12 col-md-12 col-lg-12">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/admin">Home</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Marks Register
              </li>
            </ol>
          </nav>
        </div>
        {currentUser.roles.includes("ADD_GRADE") ||
        currentUser.roles.includes("SUPER_ADMIN") ? (
          <div className="col-md-6 col-lg-12 col-xl-4">
            <div className="shadow-sm p-3 mb-5 bg-white rounded">
              <div className="text-center text-dark">
                <h5>Add Marks Register</h5>
              </div>
              <form onSubmit={handleSubmit}>
                <div className="card-body">
                  <div className="form-group">
                    <label htmlFor="sStudent">Student</label>
                    <StudentSearchSelectInput
                      onSelectedItemChange={(item) => {
                        setAdm(item.admn_no);
                      }}
                      searchFields={["admn_no"]}
                    />
                    <p className="text-dark">Selected Student: {admn_no}</p>
                  </div>
                  <div className="form-group">
                    <label htmlFor="sTopic">Exam Setup</label>
                    <SetupSearchSelectInput
                      onSelectedItemChange={(item) => {
                        setSetup(item.id);
                      }}
                      searchFields={["grade"]}
                    />
                    <p className="text-dark">Selected Exam Setup: {setup}</p>
                  </div>
                  <div className="form-group">
                    <label htmlFor="sTopic">Subject</label>
                    <SubjectSearchSelectInput
                      onSelectedItemChange={(item) => {
                        setSubject(item.id);
                      }}
                      searchFields={["subject_name"]}
                    />
                    <p className="text-dark">Subject: {subject}</p>
                  </div>

                  <div className="form-group">
                    <label htmlFor="sCat">Cat Marks</label>
                    <input
                      type="text"
                      id="sCat"
                      className="form-control"
                      value={cat_marks}
                      onChange={(e) => setCat(e.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="sAssign">Assignment Marks</label>
                    <input
                      type="text"
                      id="sAssign"
                      className="form-control"
                      value={assignment_marks}
                      onChange={(e) => setAssign(e.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="sExam">Exam Marks</label>
                    <input
                      type="text"
                      id="sExam"
                      className="form-control"
                      value={exam_marks}
                      onChange={(e) => setExam(e.target.value)}
                    />
                  </div>
                </div>
                <div className="text-center">
                  <button className="btn btn-primary mr-1" type="submit">
                    Add New Marks Register
                  </button>
                  <Toaster position="top-right" reverseOrder={false} />
                </div>
              </form>
            </div>
          </div>
        ) : (
          <></>
        )}
        <div className="col-md-6 col-lg-12 col-xl-8">
          <div className="shadow-sm p-3 mb-5 bg-white rounded">
            <h5 className="text-center text-dark">Marks Register List</h5>
            {/* import */}
            {currentUser.roles.includes("UPLOAD_EXAM_MARKS_REGISTER") ||
            currentUser.roles.includes("SUPER_ADMIN") ? (
              <div className="d-flex justify-content-end">
                <div className="d-flex m-1">
                  <Button variant="text" component="label">
                    Import
                    <input
                      hidden
                      type={"file"}
                      id={"csvFileInput"}
                      accept=".csv,.json,.xlsx,application/vnd.ms-excel"
                      onChange={handleFileSelection}
                    />
                  </Button>
                  {file && (
                    <div className="text-info align-self-center">
                      File: {file.name}
                    </div>
                  )}
                </div>
                <Button
                  size="small"
                  variant="contained"
                  endIcon={<Send />}
                  onClick={UploadFile}
                >
                  Submit
                </Button>
              </div>
            ) : (
              <></>
            )}

            <div style={{ height: 420 }}>
              <DataGrid
                columns={
                  currentUser.roles.includes("UPDATE_EXAM_MARKS_REGISTER") ||
                  currentUser.roles.includes("SUPER_ADMIN")
                    ? editableColumns
                    : columns
                }
                components={{
                  Toolbar: GridToolbar,
                  Pagination: CustomPagination,
                }}
                rows={data}
                getRowId={(row) => row.id}
                rowsPerPageOptions={[5, 10, 20]}
                rowHeight={45}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                getRowSpacing={(params) => ({
                  top: params.isFirstVisible ? 0 : 5,
                  bottom: params.isLastVisible ? 0 : 5,
                })}
                onCellEditCommit={(params) => setRowId(params.id)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
