// const authModule = "http://34.207.212.127:8080/api/v1/"; // aws
const authModule = "http://34.203.201.215:8080/api/v1/"; // aws current
// const authModule = "http://localhost:8080/api/v1/"; // When running on localhost
// const authModule = "http://localhost:6868/api/v1/"; // when running from docker

const module1 = "http://34.232.52.162:8000/api/v1/"; // aws
// const module1 = "http://localhost:8000/api/v1/"; // local

// const module2 = "http://127.0.0.1:8001/api/v1/"; // local
const module2 = "http://54.165.208.126:8000/api/v1/";

const module2_img = "http://127.0.0.1:8001";

const library = "http://127.0.0.1:8000/api/v1/";

const module3 = "http://34.201.161.63:8090/";

const hr_teachers = "http://34.201.161.63:8009";

const module4 = "http://localhost:9000/";

const module5 = "http://34.201.161.63:8009/";

export const API_URLS = {
  // Authentication
  signin: authModule + "auth/signin",
  register: authModule + "auth/signup",
  users: authModule + "auth/users/",
  fetchRoles: authModule + "auth/roles",
  refreshtoken: authModule + "auth/refreshtoken",
  stud_to_auth: authModule + "students/",
  // Permissions and Roles
  user_roles: authModule + "roles/",
  user_perm: authModule + "roles/",

  // Students
  students_count: module1 + "stud/student_count/",
  students_info: module1 + "stud/students/",
  custom_students_list: module1 + "stud/custom_students_list/",
  custom_student_admn: module1 + "stud/student/",
  full_student_admn: module1 + "stud/full_student/",
  student_category: module1 + "stud/category/",
  upload_student_category: module1 + "stud/upload_student_category/",
  stud_parents: module1 + "stud/parents/",
  stud_daily_attendance: module1 + "stud/students-daily-attendance/",
  attendance_instance: module1 + "stud/attendance-instance/",
  check_attendance_taken: module1 + "stud/check-attendance-instance/",
  check_attendance_report: module1 + "stud/check-attendance-report/",
  stud_attendance_report: module1 + "stud/stud-attendance-report",
  // Grades
  days: module1 + "grades/days/",
  get_school: module1 + "grades/get_school/",
  school: module1 + "grades/school/",
  grade: module1 + "grades/grade/",
  upload_grade: module1 + "grades/grade/upload-file/",
  academic_year: module1 + "grades/academics_years/",
  upload_academic_year: module1 + "grades/year/upload-file/",
  academic_term: module1 + "grades/academics_terms/",
  current_academic_term: module1 + "grades/current-term/",
  upload_academic_term: module1 + "grades/term/upload-file/",
  stream_count: module1 + "grades/stream_count/",
  class_stream: module1 + "grades/streams/",
  upload_stream: module1 + "grades/stream/upload-file/",
  classroom_count: module1 + "grades/classroom_count",
  getgrades: module1 + "grades/getgrades",
  class_room: module1 + "grades/class_streams/",
  class_allocation: module1 + "grades/class_allocations/",
  stud_class_allocation: module1 + "grades/student-allocation/",
  per_classroom_students: module1 + "grades/custom-per-class-students/",
  per_grade_subjects_admn: module1 + "grades/per-grade-subjects/",
  subject_count: module1 + "grades/subject_count",
  subjects: module1 + "grades/subjects/",
  upload_subject: module1 + "grades/upload_subject/",
  per_grade_subjects: module1 + "grades/per_grade_subjects/",
  subject_teacher: module1 + "grades/per_subject_teachers/",
  teacher_classrooms: module1 + "grades/teacher-classrooms/",
  // Admin
  teaching_staff: module1 + "admin/teaching-staff/",
  // Human Resource
  designation: module2 + "hr/designation/",
  department: module2 + "hr/department/",
  hr_staff: module2 + "hr/staff/",
  hr_attendance: module2 + "hr/attendance/",
  hr_teachers_odoo: hr_teachers,
  // examination
  exam_type: module2 + "examination/type/",
  upload_examtype: module2 + "lessonplan/upload_examtype/",
  exam_setup: module2 + "examination/setup/",
  upload_examsetup: module2 + "lessonplan/upload_examsetup/",
  exam_schedule: module2 + "examination/schedule/",
  upload_schedule: module2 + "lessonplan/upload_schedule/",
  exam_markregister: module2 + "examination/marksregister/",
  upload_marksregister: module2 + "lessonplan/upload_marksregister/",
  exam_marksgrade: module2 + "examination/marksgrade/",
  upload_marksgrade: module2 + "lessonplan/upload_marksgrade/",
  exam_sendviasms: module2 + "examination/sendviasms/",
  exam_attendance: module2 + "examination/examattendance/",
  check_exam_attendance_taken:
    module2 + "examination/check-attendance-instance/",
  check_exam_attendance_report:
    module2 + "examination/check-attendance-report/",
  upload_examattendance: module2 + "lessonplan/upload_examattendance/",
  exam_rule: module2 + "examination/rule/",
  exam_settings: module2 + "examination/settings/",
  // Dormitory
  dormitory: module2 + "dormitory/dorm/",
  dorm_room: module2 + "dormitory/room/",
  bed: module2 + "dormitory/bed/",

  // Transport
  vehicles: module2 + "transport/vehicles/",
  routes: module2 + "transport/routes/",

  //Library
  library_books: module3 + "books",
  library_issue: module3 + "issue",
  library_members: module3 + "members",
  library_categories: module3 + "categories",
  library_return: module3 + "return",

  // lesson plan
  topic: module2 + "lessonplan/topic/",
  upload_topic: module2 + "lessonplan/upload_topic/",
  subtopic: module2 + "lessonplan/subtopic/",
  upload_subtopic: module2 + "lessonplan/upload_subtopic/",
  lesson: module2 + "lessonplan/lesson/",
  content: module2 + "lessonplan/content/",
  upload_lesson: module2 + "lessonplan/upload_lesson/",
  schemes_of_work: module2 + "lessonplan/schemesofwork/",
  upload_schemes: module2 + "lessonplan/upload_schemes/",
  timetable: module2 + "lessonplan/timetable/",
  class_timetable: module2 + "lessonplan/classroom-timetable/",
  teacher_timetable: module2 + "lessonplan/teacher-timetable/",
  teacher_tt_records: module2 + "lessonplan/teacher-tt-records/",
  upload_timetable: module2 + "lessonplan/upload_timetable/",
  timeplan: module2 + "lessonplan/timeplan/",
  upload_timeplan: module2 + "lessonplan/upload_timeplan/",
  upload_details: module2 + "lessonplan/upload_details/",
  assessmentrubrics: module2 + "lessonplan/assessmentrubrics/",
  assessmentdetails: module2 + "lessonplan/assessmentdetails/",
  upload_rubrics: module2 + "lessonplan/upload_rubrics/",
  diary: module2 + "lessonplan/diary/",
  upload_diary: module2 + "lessonplan/upload_diary/",
  lessoncontent: module2 + "lessonplan/lessoncontent/",

  // Frontend Settings
  school_messages: module1 + "frontconfig/messages/",
  school_logos: module1 + "frontconfig/logo/",

  //assignments
  assignments: module2 + "assignments/assignment/",
  upload_assignments: module2 + "lessonplan/upload_assignments/",

  //quizzes
  quizzes: module2 + "quizzes/quiz/",
  upload_quizzes: module2 + "lessonplan/upload_quizzes/",

  //parents comments
  parents_comments: module2 + "parentscomments/assessmentcomments/",
  parents_comments: module2 + "parentscomments/diarycomments/",
  //inventory
  inventory_get: module4 + "products",
  inventory_post: module4 + "products",
  invoice_post: module4 + "invoices",

  //feees
  allinvoices: module5 + "customers",
  module2_img: module2_img,

  // images serialized module2
  module2_img: module2_img,
};
