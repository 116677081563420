import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  ClassSearchSelectInput,
  SetupSearchSelectInput,
} from "../../../components/custom/Custom.SearchInput";
import { API_URLS } from "../../../utils/api.routes";
import ExamStudentAttendance from "./ExamStudentAttendance";

export default function MarkExamAttendance() {
  const [classRoom, setClassRoom] = useState("");
  const [examSetup, setExamSetup] = useState("");
  const [students, setStudents] = useState(null);
  const [examSetupList, setExamSetupList] = useState(null);

  useEffect(() => {
    const fetchExamSetup = async () => {
      const res = await fetch(API_URLS.exam_setup);
      const data = await res.json();
      setExamSetupList(data);
    };
    fetchExamSetup();
  }, []);
  useEffect(() => {
    const fetchStudentsPerClass = async () => {
      const res = await fetch(
        API_URLS.per_classroom_students.concat(classRoom.id, "/")
      );
      const data = await res.json();
      setStudents(data);
    };
    fetchStudentsPerClass();
  }, [classRoom]);
  return (
    <div className="main-content">
      <div className="row ">
        <div className="col-12 col-sm-12 col-md-8 ">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/admin/administration/dashboard">Home</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Mark Exam Attendance
              </li>
            </ol>
          </nav>
        </div>
        <div className="col-12 col-sm-12 col-md-12 justify-content-center">
          <div className="shadow-sm p-3 mb-5 bg-white rounded">
            <h5 className="text-center text-dark">Mark Exam Attendance</h5>
            <div className="row card-body">
              <div className="col-12 d-flex flex-wrap">
                <div className="col-12 col-md-3 col-lg-3 form-group">
                  <label htmlFor="stud">Classroom Code</label>
                  <ClassSearchSelectInput
                    onSelectedItemChange={(cls) => {
                      setClassRoom(cls);
                    }}
                    searchFields={["class_room_code", "class_room_code"]}
                  />
                  {classRoom && (
                    <p className="font-weight-bold text-info">
                      Classroom: {classRoom.class_room_code}
                    </p>
                  )}
                </div>
                <div className="col-12 col-md-3 col-lg-3 form-group">
                  <label htmlFor="stud">Exam Setup</label>
                  <SetupSearchSelectInput
                    onSelectedItemChange={(setup) => {
                      setExamSetup(setup);
                    }}
                    searchFields={["grade", "subject"]}
                  />
                  {examSetup !== "" && (
                    <p className="font-weight-bold text-info">
                      ExamSetup: {examSetup.grade} {examSetup.subject}
                    </p>
                  )}
                </div>
                <div className="col-12 col-sm-12 col-md-12 justify-content-center">
                  {examSetup && (
                    <ExamStudentAttendance
                      students={students}
                      class_code={classRoom.class_room_code}
                      exam_setup_id={examSetup.id}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="pb-5">
          <span className="text-info">@GICT Systems</span>
        </div>
      </div>
    </div>
  );
}
