import { Button } from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";
import { API_URLS } from "../../../../utils/api.routes";
import { toast, Toaster } from "react-hot-toast";
import {
  toastDangerStyle,
  toastSuccessStyle,
  toastWarnigStyle,
} from "../../../../components/shared/Custom.Styles";
import authHeader from "../../../../services/auth-header";

export default function Confirm(props) {
  const [admnNo, setAdmnNo] = useState("");
  const [studName, setStudName] = useState("");
  const [studPhone, setStudPhone] = useState("");
  const [studEmail, setStudEmail] = useState("");
  const [newStudent, setNewStudent] = useState([]);
  const [registered, setregistered] = useState(false);

  const registerStudent = async (e) => {
    e.preventDefault();
    await fetch(API_URLS.students_info, {
      method: "POST",
      headers: authHeader(),
      body: JSON.stringify(values),
    })
      .then((res) => {
        const status = res.status;
        if (res.status === 201) {
          toast.success(`Student Registered Successfully`, toastSuccessStyle);
          res.json().then((data) => {
            setAdmnNo(data.admn_no);
            setStudName(data.first_name + " " + data.last_name);
            setStudPhone(data.tel_no);
            setStudEmail(data.e_mail);
            setNewStudent(data);
            setregistered(true);
            fetch(API_URLS.stud_to_auth, {
              method: "POST",
              headers: authHeader(),
              body: JSON.stringify({ admn_no: data.admn_no }),
            });
            fetch(API_URLS.register, {
              method: "POST",
              headers: authHeader(),
              body: JSON.stringify({
                username: data.admn_no,
                category: "student",
                email: data.e_mail,
                password: "root",
              }),
            }).then((res) => {
              // if(res.status === 200){
              //   toast
              // }
            });
          });
        } else if (res.status === 400) {
          toast.error(`${res.statusText}`, toastDangerStyle);
          setTimeout(() => {
            toast(`Check And Compulsory Fields are added`, toastWarnigStyle);
          }, 1000);
        } else {
          toast.error(`${res.status} ${res.statusText}`, toastDangerStyle);
        }
      })
      .catch((err) => {
        toast.error(`Error Not Found !`, toastDangerStyle);
      });
  };
  // ! New Student Registration
  const registerStudentWithAccounts = async (newStudent) => {
    const url = API_URLS.allinvoices;
    const headers = {
      "Content-Type": "application/json",
      // include any authentication headers if required
    };
    const body = JSON.stringify({
      Name: studName,
      Email: studEmail,
      Phone: studPhone,
      Street: admnNo,
    });

    try {
      const response = await fetch(url, {
        method: "POST",
        headers,
        body,
      });

      if (!response.ok) {
        throw new Error(`${response.status} ${response.statusText}`);
      }

      // Success toast notification
      toast.success(`Created Member Successfully`, toastSuccessStyle);

      const data = await response.json();
      return data;
    } catch (error) {
      // Error toast notification
      toast.error(error.message, toastDangerStyle);
      console.error(error);
    }
  };

  const Continue = (e) => {
    e.preventDefault();

    props.nextStep();
  };

  const Back = (e) => {
    e.preventDefault();
    props.prevStep();
  };
  const { values, handleChange } = props;
  useEffect(() => {
    console.log(values);
  }, []);
  return (
    <div style={{ height: "" }}>
      <h5 className="text-center text-dark mb-2">Confirm Details</h5>
      <div
        className="d-flex flex-column align-items-end"
        style={{ height: "80%" }}
      >
        <div className="col-12 d-flex flex-wrap">
          <div className="col-12 col-md-6 col-lg-6 mt-3">
            <h6 className="text-info">Student Information</h6>
            <div className="text-dark mb-1 d-flex justify-content-between border-bottom">
              <b>First Name</b>{" "}
              <span className="text-right mr-2">{values.first_name}</span>
            </div>
            <div className="text-dark mb-1 d-flex justify-content-between border-bottom">
              <b>Last Name</b>{" "}
              <span className="text-right mr-2">{values.last_name}</span>
            </div>
            <div className="text-dark mb-1 d-flex justify-content-between border-bottom">
              <b>Other Name</b>{" "}
              <span className="text-right mr-2">{values.other_name}</span>
            </div>
            <div className="text-dark mb-1 d-flex justify-content-between border-bottom">
              <b>Phone Number</b>{" "}
              <span className="text-right mr-2">{values.tel_no}</span>
            </div>
            <div className="text-dark mb-1 d-flex justify-content-between border-bottom">
              <b>Email Address</b>{" "}
              <span className="text-right mr-2">{values.e_mail}</span>
            </div>
            <div className="text-dark mb-1 d-flex justify-content-between border-bottom">
              <b>Gender</b>{" "}
              <span className="text-right mr-2">{values.gender}</span>
            </div>
            <div className="text-dark mb-1 d-flex justify-content-between border-bottom">
              <b>Date of Birth</b>{" "}
              <span className="text-right mr-2">{values.dob}</span>
            </div>
            <div className="text-dark mb-1 d-flex justify-content-between border-bottom">
              <b>Blood Group</b>{" "}
              <span className="text-right mr-2">{values.blood_group}</span>
            </div>
            <div className="text-dark mb-1 d-flex justify-content-between border-bottom">
              <b>Person With Disability</b>{" "}
              <span className="text-right mr-2">
                {values.person_disability === true ? <>YES</> : <>NO</>}
              </span>
            </div>
            <div className="text-dark mb-1 d-flex justify-content-between border-bottom">
              <b>Birth Certificate Number</b>{" "}
              <span className="text-right mr-2">{values.birth_cert_no}</span>
            </div>
            <div className="text-dark mb-1 d-flex justify-content-between border-bottom">
              <b>Religion</b>
              <span className="text-right mr-2">{values.religion}</span>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-6 mt-3">
            <h6 className="text-info">Other Information</h6>
            <div className="text-dark mb-1 d-flex justify-content-between border-bottom">
              <b>Current Address</b>{" "}
              <span className="text-right mr-2">{values.current_address}</span>
            </div>
            <div className="text-dark mb-1 d-flex justify-content-between border-bottom">
              <b>Permanent Address</b>{" "}
              <span className="text-right mr-2">
                {values.permanent_address}
              </span>
            </div>
            <div className="text-dark mb-1 d-flex justify-content-between border-bottom">
              <b>Height</b>{" "}
              <span className="text-right mr-2">{values.height}</span>
            </div>
            <div className="text-dark mb-1 d-flex justify-content-between border-bottom">
              <b>Weight</b>{" "}
              <span className="text-right mr-2">{values.weight}</span>
            </div>
            <div className="text-dark mb-1 d-flex justify-content-between border-bottom">
              <b>Country</b>{" "}
              <span className="text-right mr-2">{values.country}</span>
            </div>
            <div className="text-dark mb-1 d-flex justify-content-between border-bottom">
              <b>County</b>{" "}
              <span className="text-right mr-2">{values.county}</span>
            </div>
            <div className="text-dark mb-1 d-flex justify-content-between border-bottom">
              <b>Sub County</b>{" "}
              <span className="text-right mr-2">{values.sub_county}</span>
            </div>
            <div className="text-dark mb-1 d-flex justify-content-between border-bottom">
              <b>Ward </b>{" "}
              <span className="text-right mr-2">{values.ward}</span>
            </div>
            <div className="text-dark mb-1 d-flex justify-content-between border-bottom">
              <b>Location</b>{" "}
              <span className="text-right mr-2">{values.location}</span>
            </div>
            <div className="text-dark mb-1 d-flex justify-content-between border-bottom">
              <b>Poastal Code</b>{" "}
              <span className="text-right mr-2">{values.postal_code}</span>
            </div>
            <div className="text-dark mb-1 d-flex justify-content-between border-bottom">
              <b>Zip Code</b>{" "}
              <span className="text-right mr-2">{values.zip_code}</span>
            </div>
            <div className="text-dark mb-1 d-flex justify-content-between border-bottom">
              <b>Town</b>{" "}
              <span className="text-right mr-2">{values.postal_location}</span>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-6 mt-3">
            <h6 className="text-info">Academic Information</h6>
            <div className="text-dark mb-1 d-flex justify-content-between border-bottom">
              <b>Student Category</b>{" "}
              <span className="text-right mr-2">{values.category}</span>
            </div>
            <div className="text-dark mb-1 d-flex justify-content-between border-bottom">
              <b>Start Grade</b>{" "}
              <span className="text-right mr-2">{values.start_grade}</span>
            </div>
            <div className="text-dark mb-1 d-flex justify-content-between border-bottom">
              <b>Current Grade</b>{" "}
              <span className="text-right mr-2">{values.current_grade}</span>
            </div>
            <div className="text-dark mb-1 d-flex justify-content-between border-bottom">
              <b>Dormitory</b>{" "}
              <span className="text-right mr-2">{values.dorm_name}</span>
            </div>
            <div className="text-dark mb-1 d-flex justify-content-between border-bottom">
              <b>Room</b>{" "}
              <span className="text-right mr-2">{values.room_no}</span>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-6 mt-3">
            <h6 className="text-info">Emergency Contacts</h6>
            <div className="text-dark mb-1 d-flex justify-content-between border-bottom">
              <b>Parent Name</b>{" "}
              <span className="text-right mr-2">{values.parent_full_name}</span>
            </div>
            <div className="text-dark mb-1 d-flex justify-content-between border-bottom">
              <b>Parent Relation</b>{" "}
              <span className="text-right mr-2">{values.parent_relation}</span>
            </div>{" "}
            <div className="text-dark mb-1 d-flex justify-content-between border-bottom">
              <b>Parent Tel NO</b>{" "}
              <span className="text-right mr-2">{values.parent_tel_no}</span>
            </div>{" "}
            <div className="text-dark mb-1 d-flex justify-content-between border-bottom">
              <b>Parent Email</b>{" "}
              <span className="text-right mr-2">{values.parent_email}</span>
            </div>{" "}
            <div className="text-dark mb-1 d-flex justify-content-between border-bottom">
              <b>Parent Name</b>{" "}
              <span className="text-right mr-2">
                {values.parent1_full_name}
              </span>
            </div>{" "}
            <div className="text-dark mb-1 d-flex justify-content-between border-bottom">
              <b>Parent Relation</b>{" "}
              <span className="text-right mr-2">{values.parent1_relation}</span>
            </div>{" "}
            <div className="text-dark mb-1 d-flex justify-content-between border-bottom">
              <b>Parent Phone NO</b>{" "}
              <span className="text-right mr-2">{values.parent1_tel_no}</span>
            </div>
            <div className="text-dark mb-1 d-flex justify-content-between border-bottom">
              <b>Parent Email</b>{" "}
              <span className="text-right mr-2">{values.parent1_email}</span>
            </div>
          </div>

          {admnNo && (
            <div className="text-dark m-2">
              <h3 className="text-center">
                Your Admission Number is :{" "}
                <span className="text-info font-weight-bold">{admnNo}</span>
              </h3>
            </div>
          )}
        </div>
        <div className="col-12">
          <div className="d-flex justify-content-between">
            <Button color="primary" variant="contained" onClick={Back}>
              Back
            </Button>
            {registered && (
              <Button
                color="primary"
                variant="contained"
                onClick={registerStudentWithAccounts}
              >
                Register With Accounts
              </Button>
            )}

            {!registered ? (
              <Button
                color="primary"
                variant="contained"
                onClick={registerStudent}
              >
                Register Student
              </Button>
            ) : (
              <>
                <Button color="primary" variant="contained" onClick={Continue}>
                  Finish
                </Button>
              </>
            )}

            <Toaster position="top-right" reverseOrder={false} />
          </div>
        </div>
      </div>
    </div>
  );
}
