import React from "react";
import { PersonPinOutlined } from "@mui/icons-material";
import { Link } from "react-router-dom";
import authService from "../../services/auth.service";

export default function Student() {
  const currentUser = authService.getCurrentUser();
  return (
    <>
      <li className="dropdown">
        <Link to="#" className="menu-toggle nav-link has-dropdown">
          <PersonPinOutlined fontSize="medium" className="icon-mr" />
          <span>Student Info</span>
        </Link>
        <ul className="dropdown-menu">
          {currentUser.roles.includes("VIEW_STUDENT_CATEGORY") ||
          currentUser.roles.includes("SUPER_ADMIN") ? (
            <li>
              <Link className="nav-link" to="/admin/student-category/list">
                Student Category
              </Link>
            </li>
          ) : (
            <></>
          )}
          {/* <li>
            <Link className="nav-link" to="/admin/students/new-student">
              Add Student
            </Link>
          </li> */}
          {currentUser.roles.includes("STUDENT") ? (
            <li>
              <Link className="nav-link" to="/admin/user/profile">
                My profile
              </Link>
            </li>
          ) : (
            <></>
          )}
          {currentUser.roles.includes("ADD_STUDENT") ||
          currentUser.roles.includes("SUPER_ADMIN") ? (
            <li>
              <Link className="nav-link" to="/admin/students/new-student-1">
                Add Student
              </Link>
            </li>
          ) : (
            <></>
          )}
          {currentUser.roles.includes("VIEW_STUDENT") ||
          currentUser.roles.includes("SUPER_ADMIN") ? (
            <li>
              <Link className="nav-link" to="/admin/students/list">
                Student List
              </Link>
            </li>
          ) : (
            <></>
          )}
          {currentUser.roles.includes("UPDATE_STUDENT") ||
          currentUser.roles.includes("SUPER_ADMIN") ? (
            <li>
              <Link className="nav-link" to="/admin/students/promote">
                Promote Student
              </Link>
            </li>
          ) : (
            <></>
          )}
          {/*<li>
            <Link className="nav-link" to="/admin/parents/new-parent">
              Add Parent
            </Link>
          </li>
          <li>
            <Link className="nav-link" to="/admin/parents/list">
              Parent List
            </Link>
          </li>
           <li>
            <Link className="nav-link" to="/admin/parents/per-student">
              Student Parents
            </Link>
          </li>
          <li>
            <Link className="nav-link" to="#">
              Student Attendance
            </Link>
          </li>
          <li>
            <Link className="nav-link" to="#">
              Student Attendance Report
            </Link>
          </li> */}
          {/* To Change From Here*/}
          {/* <li>
            <Link className="nav-link" to="calendar.html">
              Subject Wise Attendance
            </Link>
          </li>
          <li>
            <Link className="nav-link" to="calendar.html">
              Subject Attendance Report
            </Link>
          </li> */}
        </ul>
      </li>
    </>
  );
}
