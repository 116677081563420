import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import axios from "axios";
import React, { useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { Link } from "react-router-dom";
import { useQuery } from "react-query";
import { API_URLS } from "../../utils/api.routes";
import {
  toastDangerStyle,
  toastSuccessStyle,
  toastWarnigStyle,
} from "../../components/shared/Custom.Styles";
import TopicActions from "./TopicActions";
import { CustomPagination } from "../../components/custom/Custom.DataGrid";
import { Button } from "@mui/material";
import { Send } from "@mui/icons-material";
import {
 
  SubjectSearchSelectInput,
} from "../../components/custom/Custom.SearchInput";
import authService from "../../services/auth.service";

export default function Topic() {

  const currentUser = authService.getCurrentUser();

  // input fields change to respective lesson plan fields
  const [subject, setSubject] = useState("");
  const [name, setName] = useState("");

  //import file hook
  const [file, setFile] = useState();

  // for pagination
  const [rowId, setRowId] = useState(null);
  const [pageSize, setPageSize] = useState(5);

  let headersList = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };

  // change to respective lesson plan body
  let bodyContent = JSON.stringify({
    subject: subject,
    name: name,
  });
  const handleSubmit = async (e) => {
    e.preventDefault();
    await fetch(API_URLS.topic, {
      method: "POST",
      headers: headersList,
      body: bodyContent,
    })
      .then((res) => {
        if (res.status === 201) {
          setSubject("");
          setName("");

          refetch();
          toast.success(`Successfully Created Topic`, toastSuccessStyle);
        } else if (res.status === 400) {
          toast.error(`Error !!! ${res.statusText}`, toastDangerStyle);
          setTimeout(() => {
            toast(`Seems Topic Already Exists`, toastWarnigStyle);
          }, 1000);
        } else {
          toast.error(
            `${res.status} Error !!! ${res.statusText}`,
            toastDangerStyle
          );
        }
      })
      .catch((err) => {
        toast.error(`Error, Not Found !!!`, toastDangerStyle);
      });
  };

  //import
  const handleFileSelection = (e) => {
    const selectedFile = e.target.files[0];
    if (
      selectedFile.type === "text/csv" ||
      selectedFile.type === "application/json" ||
      selectedFile.type === "application/vnd.ms-excel" ||
      selectedFile.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      setFile(selectedFile);
    } else {
      toast("Please select a CSV, JSON or Excel file.", toastWarnigStyle);
    }
  };

  const UploadFile = () => {
    const formData = new FormData();
    formData.append("file", file);

    fetch(API_URLS.upload_topic, {
      method: "POST",
      body: formData,
    })
      .then((res) => {
        if (res.status === 200) {
          refetch();
          setFile(null);
          toast.success("Upload Successfull", toastSuccessStyle);
        }
      })
      .catch((error) => {
        toast.error(`Upload Error ${error}`, toastDangerStyle);
      });
  };

  //

  async function fetchTopic() {
    const { data } = await axios.get(API_URLS.topic);
    return data;
  }
  const { data, error, isError, isLoading, refetch } = useQuery(
    "topic",
    fetchTopic
  );
  if (isLoading) {
    return <div className="main-content">Loading...</div>;
  }
  if (isError) {
    return (
      <div className="main-content">
        <div>Error!! {error.message}</div>
      </div>
    );
  }
  console.log(data);

  const handleSelectedSubjChange = (item) => {
    setSubject(item.subject_name);
  };

  const editableColumns = [
    {
      field: "id",
      headerName: "SL",
      headerAlign: "center",
      type: "number",
      width: "80",
    },
    {
      field: "subject",
      headerName: "Subject",
      headerAlign: "center",
      editable: true,
      width: 120,
    },
    {
      field: "name",
      headerName: "Name",
      headerAlign: "center",
      editable: true,
      width: 120,
    },

    {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      renderCell: (params) => <TopicActions {...{ params, rowId, setRowId }} />,
    },
  ];
  const columns = [
    {
      field: "id",
      headerName: "SL",
      headerAlign: "center",
      type: "number",
      width: "80",
    },
    {
      field: "subject",
      headerName: "Subject",
      headerAlign: "center",
      width: 120,
    },
    {
      field: "name",
      headerName: "Name",
      headerAlign: "center",
      width: 120,
    },

    {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      renderCell: (params) => <TopicActions {...{ params, rowId, setRowId }} />,
    },
  ];
  return (
    <div className="main-content">
      <div className="row">
        <div className="col-12 col-md-12 col-lg-12">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/admin">Home</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Topic
              </li>
            </ol>
          </nav>
        </div>
        {currentUser.roles.includes("ADD_TOPIC") ||
        currentUser.roles.includes("SUPER_ADMIN") ? (
        <div className="col-md-6 col-lg-12 col-xl-4">
          <div className="shadow-sm p-3 mb-5 bg-white rounded">
            <div className="text-center text-dark">
              <h5>Add Topic</h5>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="card-body">
                <div className="form-group">
                  <label htmlFor="sSubject">Subject</label>
                  <SubjectSearchSelectInput
                    onSelectedItemChange={handleSelectedSubjChange}
                    searchFields={["subject_name", "subject_code"]}
                  />
                  <p className="text-dark">Subject: {subject}</p>
                </div>
                <div className="form-group">
                  <label htmlFor="sName">Name</label>
                  <input
                    type="text"
                    id="sName"
                    className="form-control"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
              </div>
              <div className="text-center">
                <button className="btn btn-primary mr-1" type="submit">
                  Add New Topic
                </button>
                <Toaster position="top-right" reverseOrder={false} />
              </div>
            </form>
          </div>
        </div>

        ):<></>}

        <div className="col-md-6 col-lg-12 col-xl-8">
          <div className="shadow-sm p-3 mb-5 bg-white rounded">
            <div className="text-center">
              <h5 className="text-center text-dark">Topics List</h5>
             
             
             
              {/* import */}

              {currentUser.roles.includes("UPLOAD_TOPIC") ||
            currentUser.roles.includes("SUPER_ADMIN") ? (

              <div className="d-flex justify-content-end">
                <div className="d-flex m-1">
                  <Button variant="text" component="label">
                  Download Template
                    <input
                      hidden
                      type={"file"}
                      id={"csvFileInput"}
                      accept=".csv,.json,.xlsx,application/vnd.ms-excel"
                      onChange={handleFileSelection}
                    />
                  </Button>
                  {file && (
                    <div className="text-info align-self-center">
                      File: {file.name}
                    </div>
                  )}
                </div>
                <div className="d-flex m-1">
                  <Button variant="text" component="label">
                  Import
                    <input
                      hidden
                      type={"file"}
                      id={"csvFileInput"}
                      accept=".csv,.json,.xlsx,application/vnd.ms-excel"
                      onChange={handleFileSelection}
                    />
                  </Button>
                  {file && (
                    <div className="text-info align-self-center">
                      File: {file.name}
                    </div>
                  )}
                </div>
                
                <Button
                  size="small"
                  variant="contained"
                  endIcon={<Send />}
                  onClick={UploadFile}
                >
                  Submit
                </Button>
              </div>
            ):<></>}

            </div>
            {/*  */}

            <div style={{ height: 420 }}>
              <DataGrid
                columns={currentUser.roles.includes("UPDATE_TOPIC") ||
                currentUser.roles.includes("SUPER_ADMIN")
                  ? editableColumns
                  : columns}
                components={{
                  Toolbar: GridToolbar,
                  Pagination: CustomPagination,
                }}
                rows={data}
                getRowId={(row) => row.id}
                rowsPerPageOptions={[5, 10, 20]}
                rowHeight={45}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                getRowSpacing={(params) => ({
                  top: params.isFirstVisible ? 0 : 5,
                  bottom: params.isLastVisible ? 0 : 5,
                })}
                onCellEditCommit={(params) => setRowId(params.id)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
