import React from "react";
import { Quiz } from "@mui/icons-material";
import { Link } from "react-router-dom";
import authService from "../../services/auth.service";

export default function Examination() {
  const currentUser = authService.getCurrentUser();
  return (
    <>
      <li className="dropdown">
        <Link to="#" className="menu-toggle nav-link has-dropdown">
          <Quiz fontSize="medium" className="icon-mr" />
          <span>Examination</span>
        </Link>
        <ul className="dropdown-menu">
        {currentUser.roles.includes("VIEW_EXAM_TYPE") ||
          currentUser.roles.includes("SUPER_ADMIN") ? (
              <li>
                <Link className="nav-link" to="/admin/exam-type/list">
                  Exam Type
                </Link>
              </li>
                ) : (
                  <></>
                )}
                {currentUser.roles.includes("VIEW_EXAM_SETUP") ||
                currentUser.roles.includes("SUPER_ADMIN") ? (
              <li>
                <Link className="nav-link" to="/admin/exam-setup/list">
                  Exam Setup
                </Link>
              </li>
                ) : (
                  <></>
                )}
                {currentUser.roles.includes("VIEW_EXAM_SCHEDULE") ||
                currentUser.roles.includes("SUPER_ADMIN") ? (
              <li>
                <Link className="nav-link" to="/admin/exam-schedule/list">
                  Exam Schedule
                </Link>
              </li>
                ) : (
                  <></>
                )}
                {currentUser.roles.includes("VIEW_EXAM_ATTENDANCE") ||
                currentUser.roles.includes("SUPER_ADMIN") ? (
              <li>
                <Link className="nav-link" to="/admin/exam-attendance/list">
                  Exam Attendance
                </Link>
              </li>
                ) : (
                  <></>
                )}
                {currentUser.roles.includes("VIEW_MARK_ATTENDANCE") ||
                currentUser.roles.includes("SUPER_ADMIN") ? (
              <li>
                <Link className="nav-link" to="/admin/exam-attendance/register">
                  Mark Attendance
                </Link>
              </li>
                ) : (
                  <></>
                )}
                {currentUser.roles.includes("VIEW_MARKS_GRADE") ||
                currentUser.roles.includes("SUPER_ADMIN") ? (
              <li>
                <Link className="nav-link" to="/admin/exam-marks-grade/list">
                  Marks Grade
                </Link>
              </li>
                ) : (
                  <></>
                )}
                {currentUser.roles.includes("VIEW_MARKS_REGISTER") ||
                currentUser.roles.includes("SUPER_ADMIN") ? (
              <li>
                <Link className="nav-link" to="/admin/exam-marks-register/list">
                  Marks Register
                </Link>
              </li>
             
         
            
                       
                       ) : 
                       <></>
                     }
        </ul>
      </li>
    </>
  );
}
