import React, { useEffect, useState } from "react";
import axios from "axios";
import { MailOutlineOutlined } from "@mui/icons-material";

import { Link, useNavigate } from "react-router-dom";
import { API_URLS } from "../../utils/api.routes";
import { toast, Toaster } from "react-hot-toast";
import {
  toastDangerStyle,
  toastSuccessStyle,
  toastWarnigStyle,
} from "../../components/shared/Custom.Styles";
import { Autocomplete, TextField } from "@mui/material";

const AddMemberForm = () => {
  const [MemberID, setMemberID] = useState("");
  const [Name, setName] = useState("");
  const [Type, setType] = useState("");
  const [Mail, setMail] = useState("");
  const [Mobile, setMobile] = useState("");

  const [students, setStudents] = useState([]);

  useEffect(() => {
    const fetchStudents = async () => {
      const response = await fetch(API_URLS.students_info);
      const data = await response.json();
      setStudents(data);
    };
    fetchStudents();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const bodyContent = JSON.stringify({
      MemberID: MemberID,
      Name: Name,
      Type: Type,
      Mail: Mail,
      Mobile: Mobile,
    });
    await fetch("http://34.201.161.63:8090/members", {
      method: "POST",
      body: bodyContent,
      // headers: headersList,
    })
      .then((res) => {
        if (res.status === 200) {
          // refetch();
          // setCode("");
          // setName("");
          toast.success(`Created Member Successfully`, toastSuccessStyle);
        } else if (res.status === 400) {
          toast.error(`${res.statusText}`, toastDangerStyle);
          setTimeout(() => {
            toast(`Check Member Already Exists`, toastWarnigStyle);
          }, 1000);
        } else {
          toast.error(`${res.status} ${res.statusText}`, toastDangerStyle);
        }
      })
      .catch((err) => {
        toast.error(`Error Not Found !`, toastDangerStyle);
      });
  };
  return (
    <div className="main-content">
      <form onSubmit={handleSubmit}>
        <div>
          <Autocomplete
            freeSolo
            disableClearable
            options={students.map(
              (stud) => `${stud.admn_no} ${stud.first_name}`
            )}
            value={MemberID}
            onChange={(e, value) => {
              setMemberID(value);
              for (var i = 0; i < students.length; i++) {
                if (value === students[i]["admn_no"]) {
                  setMemberID(students[i]["admn_no"]);
                  break;
                }
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Member ID"
                variant="outlined"
                InputProps={{
                  ...params.InputProps,
                  type: "search",
                }}
              />
            )}
          />
        </div>
        <div>
          <label htmlFor="author">Name:</label>
          <input
            type="text"
            id="Name"
            value={Name}
            onChange={(event) => setName(event.target.value)}
          />
        </div>
        <div>
          <label htmlFor="publisher">Type:</label>
          <input
            type="text"
            id="Type"
            value={Type}
            onChange={(event) => setType(event.target.value)}
          />
        </div>
        <div>
          <label htmlFor="publishedDate">Mail:</label>
          <input
            type="text"
            id="Mail"
            value={Mail}
            onChange={(event) => setMail(event.target.value)}
          />
        </div>

        <div>
          <label htmlFor="publishedDate">Mobile:</label>
          <input
            type="text"
            id="Mobile"
            value={Mobile}
            onChange={(event) => setMobile(event.target.value)}
          />
        </div>

        <button type="submit">Add Book</button>
        <Toaster position="top-right" reverseOrder={false} />
      </form>
    </div>
  );
};

export default AddMemberForm;
