import React from "react";
import toast, { Toaster } from "react-hot-toast";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  toastDangerStyle,
  toastSuccessStyle,
} from "../../../components/shared/Custom.Styles";
import { API_URLS } from "../../../utils/api.routes";

function ViewStreams() {
  const { state } = useLocation();
  const navigate = useNavigate();

  const editStream = (id, code, name) => {
    navigate("/admin/stream/edit", {
      state: {
        stream_id: id,
        stream_code: code,
        stream_name: name,
      },
    });
  };
  const deleteStream = async (stream_id) => {
    const headersList = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    await fetch(API_URLS.class_stream.concat(stream_id, "/"), {
      method: "DELETE",
      headers: headersList,
    })
      .then((res) => {
        if (res.status === 204) {
          toast.success(`Deleted Stream Successfully`, toastSuccessStyle);
          setTimeout(() => {
            navigate("/streams");
          }, 1000);
        } else {
          toast.error(`Error ! ${res.statusText}`, toastDangerStyle);
        }
      })
      .catch((err) => {
        toast.error(`Error !!! Not Found`, toastSuccessStyle);
      });
  };
  return (
    <div className="main-content">
      <div className="row justify-content-center">
        <div className="col-12 col-md-12 col-lg-12">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/admin/administration/dashboard">Home</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/admin/stream/list">Streams</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Stream Details
              </li>
            </ol>
          </nav>
        </div>
        <div className="col-12 col-md-6 col-sm-12">
          <div className="card shadow-sm p-3 mb-5 bg-body rounded">
            <div className="card-header">
              <h4>Stream Info</h4>
            </div>
            <div className="card-body">
              <div className="py-4">
                <p className="clearfix">
                  <span className="float-left font-weight-bold">Year ID</span>
                  <span className="float-right text-muted font-weight-bold">
                    {state.stream_id}
                  </span>
                </p>
                <p className="clearfix">
                  <span className="float-left font-weight-bold">
                    Stream Code
                  </span>
                  <span className="float-right text-muted font-weight-bold">
                    {state.stream_code}
                  </span>
                </p>
                <p className="clearfix">
                  <span className="float-left font-weight-bold">
                    Stream Name
                  </span>
                  <span className="float-right text-muted font-weight-bold">
                    {state.stream_name}
                  </span>
                </p>
                <div>
                  <button
                    onClick={() => {
                      editStream(
                        state.stream_id,
                        state.stream_code,
                        state.stream_name
                      );
                    }}
                    className="btn btn-primary float-left"
                  >
                    Edit
                  </button>
                  <button
                    onClick={() => {
                      deleteStream(state.stream_id);
                    }}
                    className="btn btn-danger float-right"
                  >
                    Delete
                  </button>
                  <Toaster position="top-right" reverseOrder={false} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ViewStreams;
