import React, { useState } from "react";
import axios from "axios";
import { MailOutlineOutlined } from "@mui/icons-material";

import { Link, useNavigate } from "react-router-dom";
import { API_URLS } from "../../utils/api.routes";
import { toast, Toaster } from "react-hot-toast";
import {
  toastDangerStyle,
  toastSuccessStyle,
  toastWarnigStyle,
} from "../../components/shared/Custom.Styles";

export const IssueForm = () => {
  const [MemberID, setMemberID] = useState("");
  const [ISBNNo, setISBNNo] = useState("");
  const [IssueDate, setIssueDate] = useState("");
  const [IssueDays, setIssueDays] = useState("");
  const [ReturnDate, setReturnDate] = useState("");
  const [Charge, setCharge] = useState("");
  const [Token, setToken] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    const bodyContent = JSON.stringify({
      MemberID: MemberID,
      ISBNNo: ISBNNo,
      IssueDate: IssueDate,
      IssueDays: IssueDays,
      ReturnDate: ReturnDate,
      Charge: Charge,
      Token: Token,
    });
    await fetch("http://54.146.65.27:8090/issue", {
      method: "POST",
      body: bodyContent,
      // headers: headersList,
    })
      .then((res) => {
        if (res.status === 200) {
          // refetch();
          // setCode("");
          // setName("");
          toast.success(`Created Issue record Successfully`, toastSuccessStyle);
        } else if (res.status === 400) {
          toast.error(`${res.statusText}`, toastDangerStyle);
          setTimeout(() => {
            toast(`Check Issue record Already Exists`, toastWarnigStyle);
          }, 1000);
        } else {
          toast.error(`${res.status} ${res.statusText}`, toastDangerStyle);
        }
      })
      .catch((err) => {
        toast.error(`Error Not Found !`, toastDangerStyle);
      });
  };
  return (
    <form onSubmit={handleSubmit}>
      <div>
        <label htmlFor="title"> MemberID:</label>
        <input
          type="text"
          id="MemberID"
          value={MemberID}
          onChange={(event) => setMemberID(event.target.value)}
        />
      </div>
      <div>
        <label htmlFor="author">ISBNNo:</label>
        <input
          type="text"
          id="ISBNNo"
          value={ISBNNo}
          onChange={(event) => setISBNNo(event.target.value)}
        />
      </div>
      <div>
        <label htmlFor="publisher">IssueDate:</label>
        <input
          type="text"
          id="IssueDate"
          value={IssueDate}
          onChange={(event) => setIssueDate(event.target.value)}
        />
      </div>
      <div>
        <label htmlFor="publishedDate">IssueDays:</label>
        <input
          type="text"
          id="IssueDays"
          value={IssueDays}
          onChange={(event) => setIssueDays(event.target.value)}
        />
      </div>

      <div>
        <label htmlFor="publishedDate">ReturnDate:</label>
        <input
          type="text"
          id="ReturnDate"
          value={ReturnDate}
          onChange={(event) => setReturnDate(event.target.value)}
        />
      </div>
      <div>
        <label htmlFor="publishedDate">Charge:</label>
        <input
          type="text"
          id="Charge"
          value={Charge}
          onChange={(event) => setCharge(event.target.value)}
        />
      </div>
      <div>
        <label htmlFor="publishedDate">Token:</label>
        <input
          type="text"
          id="Token"
          value={Token}
          onChange={(event) => setToken(event.target.value)}
        />
      </div>

      <button type="submit">Register an Issue</button>
      <Toaster position="top-right" reverseOrder={false} />
    </form>
  );
};

const ReturnForm = () => {
  const [MemberID, setMemberID] = useState("");
  const [ISBNNo, setISBNNo] = useState("");
  const [IssueDate, setIssueDate] = useState("");
  const [IssueDays, setIssueDays] = useState("");
  const [ReturnDate, setReturnDate] = useState("");
  const [Charge, setCharge] = useState("");
  const [Token, setToken] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    const bodyContent = JSON.stringify({
      MemberID: MemberID,
      ISBNNo: ISBNNo,
      IssueDate: IssueDate,
      IssueDays: IssueDays,
      ReturnDate: ReturnDate,
      Charge: Charge,
      Token: Token,
    });
    await fetch("http://34.201.161.63:8090/return", {
      method: "POST",
      body: bodyContent,
      // headers: headersList,
    })
      .then((res) => {
        if (res.status === 200) {
          // refetch();
          // setCode("");
          // setName("");
          toast.success(
            `Created Return record Successfully`,
            toastSuccessStyle
          );
        } else if (res.status === 400) {
          toast.error(`${res.statusText}`, toastDangerStyle);
          setTimeout(() => {
            toast(`Check Return record Already Exists`, toastWarnigStyle);
          }, 1000);
        } else {
          toast.error(`${res.status} ${res.statusText}`, toastDangerStyle);
        }
      })
      .catch((err) => {
        toast.error(`Error Not Found !`, toastDangerStyle);
      });
  };
  return (
    <div className="main-content">
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="title"> MemberID:</label>
          <input
            type="text"
            id="MemberID"
            value={MemberID}
            onChange={(event) => setMemberID(event.target.value)}
          />
        </div>
        <div>
          <label htmlFor="author">ISBNNo:</label>
          <input
            type="text"
            id="ISBNNo"
            value={ISBNNo}
            onChange={(event) => setISBNNo(event.target.value)}
          />
        </div>
        <div>
          <label htmlFor="publisher">IssueDate:</label>
          <input
            type="text"
            id="IssueDate"
            value={IssueDate}
            onChange={(event) => setIssueDate(event.target.value)}
          />
        </div>
        <div>
          <label htmlFor="publishedDate">IssueDays:</label>
          <input
            type="text"
            id="IssueDays"
            value={IssueDays}
            onChange={(event) => setIssueDays(event.target.value)}
          />
        </div>

        <div>
          <label htmlFor="publishedDate">ReturnDate:</label>
          <input
            type="text"
            id="ReturnDate"
            value={ReturnDate}
            onChange={(event) => setReturnDate(event.target.value)}
          />
        </div>
        <div>
          <label htmlFor="publishedDate">Charge:</label>
          <input
            type="text"
            id="Charge"
            value={Charge}
            onChange={(event) => setCharge(event.target.value)}
          />
        </div>
        <div>
          <label htmlFor="publishedDate">Token:</label>
          <input
            type="text"
            id="Token"
            value={Token}
            onChange={(event) => setToken(event.target.value)}
          />
        </div>

        <button type="submit">Register a Return</button>
        <Toaster position="top-right" reverseOrder={false} />
      </form>
      <IssueForm />
    </div>
  );
};

export default ReturnForm;
