import React, { useState } from "react";
import { Link } from "react-router-dom";
import authService from "../../../services/auth.service";
import {
  GradeSearchSelectInput,
  StudentSearchSelectInput,
} from "../../../components/custom/Custom.SearchInput";
import { Toaster, toast } from "react-hot-toast";
import { API_URLS } from "../../../utils/api.routes";
import {
  toastDangerStyle,
  toastSuccessStyle,
  toastWarnigStyle,
} from "../../../components/shared/Custom.Styles";
import authHeader from "../../../services/auth-header";

export default function StudentPromotion() {
  const currentUser = authService.getCurrentUser();
  const [student, setStudent] = useState(null);
  const [grade, setGrade] = useState(null);
  const handleSubmit = async (e) => {
    e.preventDefault();
    await fetch(`${API_URLS.students_info}${student.id}/`, {
      method: "PATCH",
      body: JSON.stringify({
        current_grade: grade.id,
      }),
      headers: authHeader(),
    })
      .then((res) => {
        if (res.status === 201) {
          toast.success(`Subject Assigned Successful`, toastSuccessStyle);
        } else if (res.status === 400) {
          toast.error(`Error !!! ${res.statusText}`, toastDangerStyle);
          setTimeout(() => {
            toast(`Seems Subject Already Assigned`, toastWarnigStyle);
          }, 1000);
        } else {
          toast.error(
            `${res.status} Error !!! ${res.statusText}`,
            toastDangerStyle
          );
        }
      })
      .catch((err) => {
        toast.error(`Error !!! Not Found}`, toastDangerStyle);
      });
  };
  return (
    <div className="main-content">
      <div className="row ">
        <div className="col-12 col-sm-12 col-md-8 ">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/admin">Home</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Student Promotion
              </li>
            </ol>
          </nav>
        </div>
        {currentUser.roles.includes("SUPER_ADMIN") ? (
          <div className="col-md-12 col-lg-12 col-xl-12">
            <div className="shadow-sm p-3 mb-5 bg-white rounded">
              <form onSubmit={handleSubmit}>
                <div className="card-body d-flex flex-wrap">
                  <div className="form-group col-12 col-md-4 col-lg-3">
                    <label htmlFor="sCode">Student</label>
                    <StudentSearchSelectInput
                      onSelectedItemChange={(stud) => {
                        setStudent(stud);
                      }}
                      searchFields={["first_name", "last_name", "admn_no"]}
                    />
                    {student && (
                      <p className="font-weight-bold text-info">
                        Student: {student.admn_no}
                      </p>
                    )}
                  </div>
                  <div className="form-group col-12 col-md-4 col-lg-3">
                    <label htmlFor="sName">Grade Name</label>
                    <GradeSearchSelectInput
                      onSelectedItemChange={(grade) => {
                        setGrade(grade);
                      }}
                      searchFields={["name", "code"]}
                    />
                    {grade && (
                      <p className="font-weight-bold text-info">
                        Grade: {grade.name}
                      </p>
                    )}
                  </div>
                  <div className="col-12 col-md-4 col-lg-3 d-flex flex-column justify-content-center">
                    <button className="btn btn-primary mr-1" type="submit">
                      Promote Student
                    </button>
                    <Toaster position="top-right" reverseOrder={false} />
                  </div>
                </div>
              </form>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
