import React from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { useEffect } from "react";
import { API_URLS } from "../../../../utils/api.routes";
import { useState } from "react";

const ConfirmationForm = () => {
  const [designations, setDesignations] = useState([]);
  const [departments, setDepartments] = useState([]);
  const { control } = useFormContext();

  useEffect(() => {
    fetch(API_URLS.designation)
      .then((response) => response.json())
      .then((data) => setDesignations(data));
    fetch(API_URLS.department)
      .then((res) => res.json())
      .then((data) => setDepartments(data));
  }, []);

  return (
    <>
      <div className="d-flex flex-wrap justify-content-center">
        <div className="col-12">
          <h6 className="text-dark text-center">Assign</h6>
        </div>
        <div className="col-6 col-md-6 col-lg-4">
          <Controller
            control={control}
            name="designation"
            rules={{ required: "this field is required." }}
            render={({ field }) => (
              <FormControl fullWidth>
                <InputLabel id="designation-id">Designation</InputLabel>
                <Select
                  labelId="designation"
                  id="designation"
                  label="designation"
                  {...field}
                >
                  {designations.map((des, index) => {
                    return (
                      <MenuItem key={index} value={des.id}>
                        {des.title}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            )}
          />
        </div>
        <div className="col-6 col-md-6 col-lg-4">
          <Controller
            control={control}
            name="department"
            rules={{ required: "this field is required." }}
            render={({ field }) => (
              <FormControl fullWidth>
                <InputLabel id="department-id">Department</InputLabel>
                <Select
                  labelId="emerg-relation1"
                  id="department"
                  label="department"
                  {...field}
                >
                  {departments.map((dep, index) => {
                    return (
                      <MenuItem key={index} value={dep.id}>
                        {dep.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            )}
          />
        </div>
      </div>
    </>
  );
};

export default ConfirmationForm;
