import { Delete } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import React from "react";

export function EditButton() {
  return <button className="btn btn-primary float-left" />;
}

export function CustomDeleteIcon() {
  return (
    <IconButton size="large">
      <Delete color="error" fontSize="inherit" />
    </IconButton>
  );
}
