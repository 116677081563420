import axios from "axios";
import React, { useState } from "react";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { API_URLS } from "../../utils/api.routes";
import {
  toastDangerStyle,
  toastWarnigStyle,
} from "../../components/shared/Custom.Styles";
import { toast, Toaster } from "react-hot-toast";
import BedActions from "./BedActions";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { CustomPagination } from "../../components/custom/Custom.DataGrid";
import {
  DormitorySearchSelectInput,
  RoomSearchSelectInput,
} from "../../components/custom/Custom.SearchInput";
import {
  CustomIsError,
  CustomIsLoading,
} from "../../components/custom/Custom.UseQuery";
import authHeader from "../../services/auth-header";
import authService from "../../services/auth.service";
import AccessDenied from "../../components/custom/Custom.AccessDenied";

export default function Bed() {
  const currentUser = authService.getCurrentUser();

  const [dormitory, setDorm] = useState("");
  const [room, setRoom] = useState("");
  const [number, setNumber] = useState("");
  const [occupied, setOccupied] = useState("");

  const [rowId, setRowId] = useState(null);
  const [pageSize, setPageSize] = useState(5);

  const handleSubmit = async (e) => {
    e.preventDefault();
    await fetch(API_URLS.bed, {
      method: "POST",
      body: JSON.stringify({
        dormitory: dormitory,
        room: room,
        number: number,
        occupied: occupied,
      }),
      headers: authHeader(),
    })
      .then((res) => {
        if (res.status === 201) {
          setDorm("");
          setRoom("");
          setNumber("");
          setOccupied("");
          toast.success("Successfully Added Bed");
          refetch();
        } else if (res.status === 400) {
          toast.error(`Error !!! ${res.statusText}`, toastDangerStyle);
          setTimeout(() => {
            toast(`Seems Bed Already Exists`, toastWarnigStyle);
          }, 1000);
        } else {
          toast.error(
            `${res.status} Error !!! ${res.statusText}`,
            toastDangerStyle
          );
        }
      })
      .catch((err) => {
        toast.error(`Error !!!}`, toastDangerStyle);
      });
  };
  async function fetchBed() {
    const { data } = await axios.get(API_URLS.bed);
    return data;
  }
  const { data, error, isError, isLoading, refetch } = useQuery(
    "bed",
    fetchBed
  );

  const editableColumns = [
    {
      field: "id",
      headerName: "SL",
      headerAlign: "center",
      type: "number",
      hide: true,
      width: "80",
    },
    {
      field: "dormitory",
      headerName: "Dormitory",
      headerAlign: "center",
      editable: true,
      width: 150,
    },
    {
      field: "room",
      headerName: "Room",
      headerAlign: "center",
      editable: true,
      width: 120,
    },

    {
      field: "number",
      headerName: "Number",
      headerAlign: "center",
      editable: true,
      width: 120,
    },
    {
      field: "occupied",
      headerName: "Occupied",
      headerAlign: "center",
      editable: true,
      width: 120,
    },

    {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      width: 150,
      renderCell: (params) => (
        <BedActions {...{ params, rowId, setRowId, refetch }} />
      ),
    },
  ];

  const columns = [
    {
      field: "id",
      headerName: "SL",
      headerAlign: "center",
      type: "number",
      width: "80",
    },
    {
      field: "dormitory",
      headerName: "Dormitory",
      headerAlign: "center",
      editable: true,
      width: 150,
    },
    {
      field: "room",
      headerName: "Room",
      headerAlign: "center",
      width: 120,
    },

    {
      field: "number",
      headerName: "Number",
      headerAlign: "center",
      width: 120,
    },
    {
      field: "occupied",
      headerName: "Occupied",
      headerAlign: "center",
      width: 120,
    },
  ];
  if (isLoading) {
    return <CustomIsLoading />;
  }
  if (isError) {
    return <CustomIsError message={error.message} />;
  }
  if (
    !currentUser.roles.includes("VIEW_DORMITORY_BED") &&
    !currentUser.roles.includes("SUPER_ADMIN")
  ) {
    return <AccessDenied />;
  }
  return (
    <div className="main-content">
      <div className="row">
        <div className="col-12 col-md-12 col-lg-12">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/admin">Home</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Beds
              </li>
            </ol>
          </nav>
        </div>
        {currentUser.roles.includes("ADD_DORMITORY_BED") ||
        currentUser.roles.includes("SUPER_ADMIN") ? (
          <div className="col-md-6 col-lg-12 col-xl-4">
            <div className="shadow-sm p-3 mb-5 bg-white rounded">
              <h5 className="text-center text-dark">Create New Bed </h5>
              <form onSubmit={handleSubmit}>
                <div className="card-body">
                  <div className="form-group">
                    <label htmlFor="sDormitory">Dormitory</label>
                    <DormitorySearchSelectInput
                      onSelectedItemChange={(item) => {
                        setDorm(item.id);
                      }}
                      searchFields={["name"]}
                    />
                    <p className="text-dark">Selected Dormitory: {dormitory}</p>
                  </div>
                  <div className="form-group">
                    <label htmlFor="sRoom">Room</label>
                    <RoomSearchSelectInput
                      onSelectedItemChange={(item) => {
                        setRoom(item.id);
                      }}
                      searchFields={["name"]}
                    />
                    <p className="text-dark">Selected Room: {room}</p>
                  </div>

                  <div className="form-group">
                    <label htmlFor="rNumber">Number</label>
                    <input
                      type="text"
                      id="rNumber"
                      className="form-control"
                      onChange={(e) => setNumber(e.target.value)}
                      value={number}
                    />
                  </div>
                  <div className="form-group">
                    <label>Occupied?</label>
                    <select
                      type="text"
                      id="sOccupied"
                      className="form-control"
                      onChange={(e) => setOccupied(e.target.value)}
                      value={occupied}
                    >
                      <option value="" disabled>
                        Select
                      </option>
                      <option value="1">Yes</option>
                      <option value="0">No</option>
                    </select>
                  </div>
                </div>
                <div className="text-center">
                  <button className="btn btn-primary mr-1" type="submit">
                    Add New Bed
                  </button>
                  <Toaster position="top-right" reverseOrder={false} />
                </div>
              </form>
            </div>
          </div>
        ) : (
          <></>
        )}
        <div className="col-md-6 col-lg-12 col-xl-8">
          <div className="shadow-sm p-3 mb-5 bg-white rounded">
            <h5 className="text-center text-dark">Beds List</h5>
            <div style={{ height: 420 }}>
              <DataGrid
                columns={
                  currentUser.roles.includes("UPDATE_DORMITORY_BED") ||
                  currentUser.roles.includes("SUPER_ADMIN")
                    ? editableColumns
                    : columns
                }
                components={{
                  Toolbar: GridToolbar,
                  Pagination: CustomPagination,
                }}
                rows={data}
                getRowId={(row) => row.id}
                rowsPerPageOptions={[5, 10, 20]}
                rowHeight={45}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                getRowSpacing={(params) => ({
                  top: params.isFirstVisible ? 0 : 5,
                  bottom: params.isLastVisible ? 0 : 5,
                })}
                onCellEditCommit={(params) => setRowId(params.id)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
