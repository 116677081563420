import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import axios from "axios";
import React, { useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import {
  toastDangerStyle,
  toastSuccessStyle,
  toastWarnigStyle,
} from "../../components/shared/Custom.Styles";
import { API_URLS } from "../../utils/api.routes";
import StudentCategoryAction from "../../pages/students/category/StudentCategoryAction";

import { CustomPagination } from "../../components/custom/Custom.DataGrid";
import authService from "../../services/auth.service";
import { Button } from "@mui/material";
import { Send } from "@mui/icons-material";
import authHeader from "../../services/auth-header";
import {
  CustomIsError,
  CustomIsLoading,
} from "../../components/custom/Custom.UseQuery";
import AccessDenied from "../../components/custom/Custom.AccessDenied";
function Groups() {
  const currentUser = authService.getCurrentUser();
  const [code, setCode] = useState("");
  const [name, setName] = useState("");

  const [file, setFile] = useState();

  const [rowId, setRowId] = useState(null);
  const [pageSize, setPageSize] = useState(5);

  const fetchCategory = async () => {
    const { data } = await axios.get(API_URLS.student_category);
    return data;
  };
  const { data, error, isError, isLoading, refetch } = useQuery(
    "category",
    fetchCategory
  );

  if (isLoading) {
    return <CustomIsLoading />;
  }
  if (isError) {
    return <CustomIsError mesaage={error.message} />;
  }

  const handleFileUpload = (e) => {
    setFile(e.target.files[0]);
  };

  const UploadFile = () => {
    const formData = new FormData();
    formData.append("csv_file", file);

    fetch(API_URLS.upload_student_category, {
      method: "POST",
      body: formData,
    })
      .then((res) => {
        if (res.status === 200) {
          refetch();
          setFile(null);
          toast.success("Upload Successfull", toastSuccessStyle);
        }
      })
      .catch((error) => {
        toast.error(`Upload Error ${error}`, toastDangerStyle);
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await fetch(API_URLS.student_category, {
      method: "POST",
      body: JSON.stringify({
        code: code.toUpperCase(),
        name: name,
      }),
      headers: authHeader(),
    })
      .then((res) => {
        if (res.status === 201) {
          refetch();
          setCode("");
          setName("");
          toast.success(`Created Category Successfully`, toastSuccessStyle);
        } else if (res.status === 400) {
          toast.error(`${res.statusText}`, toastDangerStyle);
          setTimeout(() => {
            toast(`Check Record Already Exists`, toastWarnigStyle);
          }, 1000);
        } else {
          toast.error(`${res.status} ${res.statusText}`, toastDangerStyle);
        }
      })
      .catch((err) => {
        toast.error(`Error Not Found ! ${err}`, toastDangerStyle);
      });
  };

  const editablecolumns = [
    {
      field: "id",
      headerName: "SL",
      headerAlign: "center",
      print: true,
      type: "number",
      width: 100,
    },
    {
      field: "code",
      headerName: "Code",
      headerAlign: "center",
      print: true,
      width: 100,
      editable: true,
    },
    {
      field: "name",
      headerName: "Name",
      headerAlign: "center",
      print: true,
      width: 200,
      editable: true,
    },
    {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      print: false,
      width: 150,
      renderCell: (params) => (
        <StudentCategoryAction {...{ params, rowId, setRowId, refetch }} />
      ),
    },
  ];
  const columns = [
    {
      field: "code",
      headerName: "Code",
      headerAlign: "center",
      width: 100,
    },
    {
      field: "name",
      headerName: "Name",
      headerAlign: "center",
      width: 200,
    },
  ];
  if (
    !currentUser.roles.includes("VIEW_STUDENT_CATEGORY") &&
    !currentUser.roles.includes("SUPER_ADMIN")
  ) {
    return <AccessDenied />;
  }

  return (
    <div className="main-content">
      <div className="row">
        <div className="col-12 col-md-12 col-lg-12">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link className="font-weight-bold" to="/admin">
                  Home
                </Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                <span className="font-weight-bold">Fees Group</span>
              </li>
            </ol>
          </nav>
        </div>
        {currentUser.roles.includes("ADD_STUDENT_CATEGORY") ||
        currentUser.roles.includes("SUPER_ADMIN") ? (
          <div className="col-md-6 col-lg-12 col-xl-4">
            <div className="shadow p-3 mb-5 bg-white rounded">
              <form onSubmit={handleSubmit}>
                <h5 className="text-center text-dark">Add Fee group</h5>
                <div className="card-body">
                  <div className="form-group">
                    <label htmlFor="code">Category Code</label>
                    <input
                      type="text"
                      id="code"
                      className="form-control"
                      value={code}
                      onChange={(e) => setCode(e.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="studCategory">Category Name</label>
                    <input
                      type="text"
                      id="studCategory"
                      className="form-control"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                </div>
                <div className="text-center">
                  <button className="btn btn-primary mr-1" type="submit">
                    Add New Category
                  </button>
                </div>
                <Toaster position="top-right" reverseOrder={false} />
              </form>
            </div>
          </div>
        ) : (
          <></>
        )}

        <div className="col-md-6 col-lg-12 col-xl-8">
          <div className="shadow p-3 mb-5 bg-white rounded">
            <h5 className="text-center text-dark">Fee group List</h5>
            {currentUser.roles.includes("UPLOAD_STUDENT_CATEGORY") ||
            currentUser.roles.includes("SUPER_ADMIN") ? (
              <div className="d-flex justify-content-end">
                <div className="d-flex m-1">
                  <Button variant="text" component="label">
                    Upload
                    <input
                      hidden
                      type={"file"}
                      id={"csvFileInput"}
                      accept={".csv"}
                      onChange={handleFileUpload}
                    />
                  </Button>
                  {file && (
                    <div className="text-info align-self-center">
                      File: {file.name}
                    </div>
                  )}
                </div>
                <Button
                  size="small"
                  variant="contained"
                  endIcon={<Send />}
                  onClick={UploadFile}
                >
                  Submit
                </Button>
              </div>
            ) : (
              <></>
            )}

            <div style={{ width: "" }}>
              <DataGrid
                autoHeight
                columns={
                  currentUser.roles.includes("ADD_STUDENT_CATEGORY") ||
                  currentUser.roles.includes("SUPER_ADMIN")
                    ? editablecolumns
                    : columns
                }
                rows={data}
                components={{
                  Toolbar: GridToolbar,
                  Pagination: CustomPagination,
                }}
                getRowId={(row) => row.id}
                rowsPerPageOptions={[5, 10, 20]}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                getRowSpacing={(params) => ({
                  top: params.isFirstVisible ? 0 : 5,
                  bottom: params.isLastVisible ? 0 : 5,
                })}
                onCellEditCommit={(params) => setRowId(params.id)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Groups;
