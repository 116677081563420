import { MailOutlineOutlined } from "@mui/icons-material";
import axios from "axios";
import React, { useState } from "react";
import { toast, Toaster } from "react-hot-toast";
import { Link } from "react-router-dom";
import {
  toastDangerStyle,
  toastSuccessStyle,
  toastWarnigStyle,
} from "../../../components/shared/Custom.Styles";
import { API_URLS } from "../../../utils/api.routes";

const initialValues = {
  studId: "",
  firstName: "",
  lastName: "",
  telNo1: "",
  telNo2: "",
  email: "",
  currAddress: "",
  permAddress: "",
};

function RegisterStudentParent() {
  const [values, setValues] = useState(initialValues);
  const [gender, setGender] = useState("");
  const [relation, setRelation] = useState("");
  const [message, setMessage] = useState("");

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };
  const headersList = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };
  // Parents Info to post
  const bodyContent = JSON.stringify({
    student: values.studId,
    first_name: values.firstName,
    last_name: values.lastName,
    gender: gender,
    tel_no1: values.telNo1,
    tel_no2: values.telNo2,
    e_mail: values.email,
    relation: relation,
    current_address: values.currAddress,
    permanent_address: values.permAddress,
  });
  const handleSubmit = async (e) => {
    e.preventDefault();
    await axios
      .request({
        url: API_URLS,
        method: "POST",
        headers: headersList,
        data: bodyContent,
      })
      .then((res) => {
        if (res.status === 201) {
          toast.success("Successfully Added Parent", toastSuccessStyle);
        } else if (res.status === 400) {
          toast.error(`Error !!! ${res.statusText}`, toastDangerStyle);
          setTimeout(() => {
            toast(`Seems Parent Already Exists`, toastWarnigStyle);
          }, 1000);
        } else {
          toast.error(
            `${res.status} Error !!! ${res.statusText}`,
            toastDangerStyle
          );
        }
      })
      .catch((err) => {
        toast.error(`Error, Not Found !!!`, toastDangerStyle);
      });
  };
  return (
    <div className="main-content">
      <div className="section-body">
        <div class="col-12 col-md-12 col-lg-12">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <Link to="/super-admin-dashboard">Home</Link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                Register Student Parent
              </li>
            </ol>
          </nav>
        </div>
        <form className="row" onSubmit={handleSubmit}>
          <div className="col-12 col-md-6 col-lg-6">
            <div className="card">
              <div className="card-header">
                <h4>Parent Personal Info</h4>
              </div>
              <div className="card-body">
                <div className="form-group">
                  <label>Student</label>
                  <input
                    type="text"
                    className="form-control"
                    onChange={handleInputChange}
                    value={values.id}
                    name="studId"
                  />
                </div>
                <div className="form-group">
                  <label>First Name</label>
                  <input
                    type="text"
                    className="form-control"
                    onChange={handleInputChange}
                    value={values.firstName}
                    name="firstName"
                  />
                </div>
                <div className="form-group">
                  <label>Last Name</label>
                  <input
                    type="text"
                    className="form-control"
                    onChange={handleInputChange}
                    value={values.lastName}
                    name="lastName"
                  />
                </div>
                <div className="form-group">
                  <label>Select Gender</label>
                  <select
                    className="form-control"
                    onChange={(e) => setGender(e.target.value)}
                    value={gender}
                  >
                    <option value="" disabled>
                      Select Gender
                    </option>
                    <option value="M">Male</option>
                    <option value="F">Female</option>
                    <option value="O">Other</option>
                  </select>
                </div>
                <div
                  className="form-group"
                  onChange={(e) => setRelation(e.target.value)}
                  value={relation}
                >
                  <label>Relation</label>
                  <select className="form-control">
                    <option value="" disabled>
                      Select Relationship
                    </option>
                    <option value="F">Father</option>
                    <option value="M">Mother</option>
                    <option value="GP">Grand Parent</option>
                    <option value="G">Gurdian</option>
                    <option value="SP">Sponsor</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          {/* Other Details Section */}
          <div className="col-12 col-md-6 col-lg-6">
            <div className="card">
              <div className="card-header">
                <h4>Other Details</h4>
              </div>
              <div className="card-body">
                <div className="form-group">
                  <label>Phone Number 1</label>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <div className="input-group-text">
                        <i className="fas fa-phone"></i>
                      </div>
                    </div>
                    <input
                      type="text"
                      className="form-control phone-number"
                      onChange={handleInputChange}
                      value={values.telNo1}
                      name="telNo1"
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label>Phone Number 2</label>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <div className="input-group-text">
                        <i className="fas fa-phone"></i>
                      </div>
                    </div>
                    <input
                      type="text"
                      className="form-control phone-number"
                      onChange={handleInputChange}
                      value={values.telNo2}
                      name="telNo2"
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label>Email Address</label>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <div className="input-group-text">
                        <i>
                          <MailOutlineOutlined />
                        </i>
                      </div>
                    </div>
                    <input
                      type="email"
                      className="form-control phone-number"
                      onChange={handleInputChange}
                      value={values.email}
                      name="email"
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label>Current Address</label>
                  <input
                    type="text"
                    className="form-control"
                    onChange={handleInputChange}
                    value={values.currAddress}
                    name="currAddress"
                  />
                </div>
                <div className="form-group">
                  <label>Parmanent Address</label>
                  <input
                    type="text"
                    className="form-control"
                    onChange={handleInputChange}
                    value={values.permAddress}
                    name="permAddress"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 d-flex justify-content-end">
            <div className="card">
              <button className="btn btn-primary" type="submit">
                Add Parent
              </button>
            </div>
            <Toaster position="top-right" reverseOrder={false} />
          </div>
        </form>
      </div>
    </div>
  );
}

export default RegisterStudentParent;
