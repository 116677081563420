import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import axios from "axios";
import React, { useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { Link } from "react-router-dom";
import { useQuery } from "react-query";
import { Button } from "@mui/material";
import { Send } from "@mui/icons-material";
import {
  SetupSearchSelectInput,
  TermSearchSelectInput,
} from "../../../components/custom/Custom.SearchInput";
import { API_URLS } from "../../../utils/api.routes";
import ExamScheduleActions from "./ExamScheduleActions";
import {
  toastDangerStyle,
  toastSuccessStyle,
  toastWarnigStyle,
} from "../../../components/shared/Custom.Styles";
import { CustomPagination } from "../../../components/custom/Custom.DataGrid";
import authService from "../../../services/auth.service";
import authHeader from "../../../services/auth-header";
import {
  CustomIsError,
  CustomIsLoading,
} from "../../../components/custom/Custom.UseQuery";

export default function ExamSchedule() {
  const currentUser = authService.getCurrentUser();
  // input fields change to respective lesson plan fields
  const [setup, setSetup] = useState("");
  const [room, setRoom] = useState("");
  const [term, setTerm] = useState("");
  const [date, setDate] = useState("");
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");
  const [teacher, setTeacher] = useState("");

  //import file hook
  const [file, setFile] = useState();

  // for pagination
  const [rowId, setRowId] = useState(null);
  const [pageSize, setPageSize] = useState(5);

  // change to respective lesson plan body
  let bodyContent = JSON.stringify({
    setup: setup,
    room: room,
    term: term,
    date: date,
    start: start,
    end: end,
    teacher: teacher,
  });
  const handleSubmit = async (e) => {
    e.preventDefault();
    await fetch(API_URLS.exam_schedule, {
      method: "POST",
      headers: authHeader(),
      body: bodyContent,
    })
      .then((res) => {
        if (res.status === 201) {
          setSetup("");
          setRoom("");
          setTerm("");
          setDate("");
          setStart("");
          setEnd("");
          setTeacher("");

          refetch();
          toast.success(
            `Successfully Created Exam Schedule`,
            toastSuccessStyle
          );
        } else if (res.status === 400) {
          toast.error(`Error !!! ${res.statusText}`, toastDangerStyle);
          setTimeout(() => {
            toast(`Seems Exam Schedule Already Exists`, toastWarnigStyle);
          }, 1000);
        } else {
          toast.error(
            `${res.status} Error !!! ${res.statusText}`,
            toastDangerStyle
          );
        }
      })
      .catch((err) => {
        toast.error(`Error, Not Found !!!`, toastDangerStyle);
      });
  };

  //import
  const handleFileSelection = (e) => {
    const selectedFile = e.target.files[0];
    if (
      selectedFile.type === "text/csv" ||
      selectedFile.type === "application/json" ||
      selectedFile.type === "application/vnd.ms-excel" ||
      selectedFile.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      setFile(selectedFile);
    } else {
      toast("Please select a CSV, JSON or Excel file.", toastWarnigStyle);
    }
  };

  const UploadFile = () => {
    const formData = new FormData();
    formData.append("file", file);

    fetch(API_URLS.upload_examschedule, {
      method: "POST",
      body: formData,
    })
      .then((res) => {
        if (res.status === 200) {
          refetch();
          setFile(null);
          toast.success("Upload Successful", toastSuccessStyle);
        }
      })
      .catch((error) => {
        toast.error(`Upload Error ${error}`, toastDangerStyle);
      });
  };

  //

  async function fetchExamschedule() {
    const { data } = await axios.get(API_URLS.exam_schedule);
    return data;
  }
  const { data, error, isError, isLoading, refetch } = useQuery(
    "exam_schedule",
    fetchExamschedule
  );
  if (isLoading) {
    return <CustomIsLoading />;
  }
  if (isError) {
    return <CustomIsError message={error.message} />;
  }

  const editableColumns = [
    {
      field: "id",
      headerName: "SL",
      headerAlign: "center",
      type: "number",
      width: "80",
    },
    {
      field: "setup",
      headerName: "Setup",
      headerAlign: "center",
      editable: true,
      width: 120,
    },
    {
      field: "room",
      headerName: "Room",
      headerAlign: "center",
      editable: true,
      width: 120,
    },
    {
      field: "term",
      headerName: "Term",
      headerAlign: "center",
      editable: true,
      width: 120,
    },
    {
      field: "date",
      headerName: "Date",
      headerAlign: "center",
      editable: true,
      width: 120,
    },
    {
      field: "start",
      headerName: "Start",
      headerAlign: "center",
      editable: true,
      width: 120,
    },
    {
      field: "end",
      headerName: "End",
      headerAlign: "center",
      editable: true,
      width: 120,
    },
    {
      field: "teacher",
      headerName: "Teacher",
      headerAlign: "center",
      editable: true,
      width: 120,
    },

    {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      width: 150,
      renderCell: (params) => (
        <ExamScheduleActions {...{ params, rowId, setRowId }} />
      ),
    },
  ];
  const columns = [
    {
      field: "id",
      headerName: "SL",
      headerAlign: "center",
      type: "number",
      width: "80",
    },
    {
      field: "setup",
      headerName: "Setup",
      headerAlign: "center",
      width: 120,
    },
    {
      field: "room",
      headerName: "Room",
      headerAlign: "center",
      width: 120,
    },
    {
      field: "term",
      headerName: "Term",
      headerAlign: "center",
      width: 120,
    },
    {
      field: "date",
      headerName: "Date",
      headerAlign: "center",
      width: 120,
    },
    {
      field: "start",
      headerName: "Start",
      headerAlign: "center",
      width: 120,
    },
    {
      field: "end",
      headerName: "End",
      headerAlign: "center",
      width: 120,
    },
    {
      field: "teacher",
      headerName: "Teacher",
      headerAlign: "center",
      width: 120,
    },
  ];
  return (
    <div className="main-content">
      <div className="row">
        <div className="col-12 col-md-12 col-lg-12">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/admin">Home</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Exam Schedule
              </li>
            </ol>
          </nav>
        </div>
        
        {currentUser.roles.includes("ADD_EXAM_SCHEDULE") ||
        currentUser.roles.includes("SUPER_ADMIN") ? (

          <div className="col-md-6 col-lg-12 col-xl-4">
            <div className="shadow-sm p-3 mb-5 bg-white rounded">
            <form onSubmit={handleSubmit}>
              <div className="text-center text-dark">
                <h5>Add Exam Schedule</h5>
              
             
                <div className="card-body">
                <div className="d-flex flex-wrap">
                  <div className="form-group">
                    <label htmlFor="sTopic">Exam Setup</label>
                    <SetupSearchSelectInput
                      onSelectedItemChange={(item) => {
                        setSetup(item.id);
                      }}
                      searchFields={["grade"]}
                    />
                    <p className="text-dark">Selected Exam Setup: {setup}</p>
                  </div>
                  <div className="form-group">
                    <label htmlFor="sRoom">Room</label>
                    <input
                      type="text"
                      id="sRoom"
                      className="form-control"
                      value={room}
                      onChange={(e) => setRoom(e.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="sTerm">Term</label>
                    <TermSearchSelectInput
                      onSelectedItemChange={(item) => {
                        setTerm(item.name);
                      }}
                      searchFields={["name"]}
                    />
                    <p className="text-dark">Selected Term: {setup}</p>
                  </div>
                  <div className="form-group">
                    <label htmlFor="sDate">Date</label>
                    <input
                      type="date"
                      id="sDate"
                      className="form-control"
                      value={date}
                      onChange={(e) => setDate(e.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="sStart">Start Time</label>
                    <input
                      type="time"
                      id="sStart"
                      className="form-control"
                      value={start}
                      onChange={(e) => setStart(e.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="sEnd">End Time</label>
                    <input
                      type="time"
                      id="sEnd"
                      className="form-control"
                      value={end}
                      onChange={(e) => setEnd(e.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="sTeacher">Teacher</label>
                    <input
                      type="text"
                      id="sTeacher"
                      className="form-control"
                      value={teacher}
                      onChange={(e) => setTeacher(e.target.value)}
                    />
                  </div>
                </div>
                <div className="text-center">
                  <button className="btn btn-primary mr-1" type="submit">
                    Add New Exam Schedule
                  </button>
                  <Toaster position="top-right" reverseOrder={false} />
                </div>
              
            </div>
           
               
                
              </div>
              <div className="text-center">
                <button className="btn btn-primary mr-1" type="submit">
                  Add New Exam Schedule
                </button>
                <Toaster position="top-right" reverseOrder={false} />
              </div>
            </form>
          </div>
        </div>

        ):<></>}
        
        <div className="col-md-6 col-lg-12 col-xl-8">
          <div className="shadow-sm p-3 mb-5 bg-white rounded">
            
              <h5 className="text-center text-dark">Exam Schedule List</h5>

                {currentUser.roles.includes("UPLOAD_EXAM_SCHEDULE") ||
              currentUser.roles.includes("SUPER_ADMIN") ? ( 
               
               
              <div className="d-flex justify-content-end">
                <div className="d-flex m-1">
                  <Button variant="text" component="label">
                    Import
                    <input
                      hidden
                      type={"file"}
                      id={"csvFileInput"}
                      accept=".csv,.json,.xlsx,application/vnd.ms-excel"
                      onChange={handleFileSelection}
                    />
                  </Button>
                  {file && (
                    <div className="text-info align-self-center">
                      File: {file.name}
                    </div>
                  )}
                </div>
                <Button
                  size="small"
                  variant="contained"
                  endIcon={<Send />}
                  onClick={UploadFile}
                >
                  Submit
                </Button>
              </div>
            ) : (
              <></>
            )}
            {/*  */}

            <div style={{ height: 420 }}>
              <DataGrid
                columns={
                  currentUser.roles.includes("UPDATE_EXAM_SCHEDULE") ||
                  currentUser.roles.includes("SUPER_ADMIN")
                    ? editableColumns
                    : columns
                }
                components={{
                  Toolbar: GridToolbar,
                  Pagination: CustomPagination,
                }}
                rows={data}
                getRowId={(row) => row.id}
                rowsPerPageOptions={[5, 10, 20]}
                rowHeight={45}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                getRowSpacing={(params) => ({
                  top: params.isFirstVisible ? 0 : 5,
                  bottom: params.isLastVisible ? 0 : 5,
                })}
                onCellEditCommit={(params) => setRowId(params.id)}
              />
            </div>
          </div>
        </div>
      </div>
    
  ); </div>
)}
