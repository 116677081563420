import React from "react";
import { Link, Outlet } from "react-router-dom";

export default function StudentProfile() {
  return (
    <div className="main-content">
      <div className="row">
        <div className="col-12 col-md-12 col-lg-12">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/admin/dashboard">Home</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Student Profile
              </li>
            </ol>
          </nav>
        </div>
        <div className="col-12">
          <Link to="/admin/user/profile">
            <span className="font-weight-bold p-2 m-2">Profile</span>
          </Link>
          <Link to="/admin/user/profile/exams">
            <span className="font-weight-bold p-2 m-2">Exams</span>
          </Link>
          <Link to="/admin/user/profile/assignments">
            <span className="font-weight-bold p-2 m-2">Assignment</span>
          </Link>
        </div>
        <Outlet />
        {/* <Profile /> */}
        {/* <Exam /> */}
      </div>
    </div>
  );
}
