import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import React, { useState } from "react";

export default function CustomAccordion(props) {
  const [expanded, setexpanded] = useState(false);

  const toggleAccordion = () => {
    setexpanded(!expanded);
  };
  return (
    <Accordion
      expanded={expanded}
      onChange={toggleAccordion}
      style={{ width: "100%" }}
    >
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography>{props.headerText}</Typography>
      </AccordionSummary>
      <AccordionDetails className="d-flex flex-wrap">
        {props.children}
      </AccordionDetails>
    </Accordion>

    // <div className="accordion">
    //   <button className="accordion-header" onClick={toggleAccordion}>
    //     {props.headerText}
    //   </button>
    //   {isOpen && <div className="accordion-body">{props.children}</div>}
    // </div>
  );
}
