import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import axios from "axios";
import React, { useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { useQuery } from "react-query";
import { Link, Navigate } from "react-router-dom";
import { CustomPagination } from "../../../components/custom/Custom.DataGrid";
import { API_URLS } from "../../../utils/api.routes";
import AcademicTermActions from "./AcademicTermActions";
import { useEffect } from "react";
import { Button } from "@mui/material";
import {
  toastDangerStyle,
  toastSuccessStyle,
  toastWarnigStyle,
} from "../../../components/shared/Custom.Styles";
import { Send } from "@mui/icons-material";
import authHeader from "../../../services/auth-header";
import authService from "../../../services/auth.service";
import AccessDenied from "../../../components/custom/Custom.AccessDenied";

// Main Function
export default function AcademicTerm() {
  const currentUser = authService.getCurrentUser();

  const [academicYears, setAcademicYears] = useState([]);

  const [name, setName] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [year, setYear] = useState("");
  const [isSession, setIsSession] = useState(false);

  const [file, setFile] = useState();

  const [rowId, setRowId] = useState(null);
  const [pageSize, setPageSize] = useState(5);

  const handleFileUpload = (e) => {
    const selectedFile = e.target.files[0];
    if (
      selectedFile.type === "text/csv" ||
      selectedFile.type === "application/json" ||
      selectedFile.type === "application/vnd.ms-excel" ||
      selectedFile.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      setFile(selectedFile);
    } else {
      toast("Please select a CSV, JSON or Excel file.", toastWarnigStyle);
    }
  };

  const UploadFile = () => {
    const formData = new FormData();
    formData.append("file", file);

    fetch(API_URLS.upload_academic_term, {
      method: "POST",
      body: formData,
    })
      .then((res) => {
        if (res.status === 200) {
          refetch();
          setFile(null);
          toast.success("Upload Successfull", toastSuccessStyle);
        }
      })
      .catch((error) => {
        toast.error(`Upload Error ${error}`, toastDangerStyle);
      });
  };

  useEffect(() => {
    async function fetchAcademicYears() {
      const response = await fetch(API_URLS.academic_year);
      const data = await response.json();
      setAcademicYears(data);
    }
    fetchAcademicYears();
  }, []);

  const bodyContent = JSON.stringify({
    name: name,
    start: startDate,
    end: endDate,
    year: year,
    current_session: isSession,
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    await fetch(API_URLS.academic_term, {
      method: "POST",
      body: bodyContent,
      headers: authHeader(),
    })
      .then((res) => {
        if (res.status === 201) {
          setName("");
          setStartDate("");
          setEndDate("");
          toast.success("Created Academic Term");
          refetch();
        } else {
          toast.error(res.statusText);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  async function fetchYearData() {
    const { data } = await axios.get(API_URLS.academic_term);
    return data;
  }
  const { data, error, isError, isLoading, refetch } = useQuery(
    "accYear",
    fetchYearData
  );
  if (isLoading) {
    return <div className="main-content">Loading...</div>;
  }
  if (isError) {
    return (
      <div className="main-content">
        <span className="text-danger">Error! {error.message}</span>
      </div>
    );
  }

  const editableColumns = [
    {
      field: "id",
      headerName: "ID",
      headerAlign: "center",
      type: "number",
      hide: true,
      width: "80",
    },
    {
      field: "name",
      headerName: "Term Name",
      hideable: false,
      headerAlign: "center",
      editable: true,
      width: 120,
    },
    {
      field: "year",
      headerName: "Year ID",
      headerAlign: "center",
      hide: true,
      width: 120,
    },
    {
      field: "year_name",
      headerName: "Academic Year",
      hideable: false,
      headerAlign: "center",
      editable: true,
      width: 120,
    },
    {
      field: "start",
      headerName: "Start Date",
      headerAlign: "center",
      type: "date",
      editable: true,
      width: 120,
    },
    {
      field: "end",
      headerName: "End Date",
      headerAlign: "center",
      type: "date",
      editable: true,
      width: 120,
    },
    {
      field: "current_session",
      headerName: "Current",
      type: "boolean",
      width: 100,
      editable: true,
    },
    {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      width: 150,
      renderCell: (params) => (
        <AcademicTermActions {...{ params, rowId, setRowId, refetch }} />
      ),
    },
  ];

  const columns = [
    {
      field: "id",
      headerName: "ID",
      headerAlign: "center",
      type: "number",
      hide: true,
      width: "80",
    },
    {
      field: "name",
      headerName: "Term Name",
      hideable: false,
      headerAlign: "center",
      editable: true,
      width: 120,
    },
    {
      field: "year_name",
      headerName: "Academic Year",
      hideable: false,
      headerAlign: "center",
    },
    {
      field: "start",
      headerName: "Start Date",
      headerAlign: "center",
      type: "date",
      width: 120,
    },
    {
      field: "end",
      headerName: "End Date",
      headerAlign: "center",
      type: "date",
      width: 120,
    },
    {
      field: "current_session",
      headerName: "Current",
      type: "boolean",
      width: 100,
    },
  ];

  const handleChange = (event) => {
    setIsSession(event.target.checked);
  };

  if (
    !currentUser.roles.includes("VIEW_ACADEMIC_TERM") &&
    !currentUser.roles.includes("SUPER_ADMIN")
  ) {
    return <AccessDenied />;
  }
  return (
    <div className="main-content">
      <div className="row">
        <div className="col-12 col-md-12 col-lg-12">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/admin">Home</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Academic Year
              </li>
            </ol>
          </nav>
        </div>
        {currentUser.roles.includes("ADD_ACADEMIC_TERM") ||
        currentUser.roles.includes("SUPER_ADMIN") ? (
          <div className="col-md-12 col-lg-12 col-xl-12">
            <div className="shadow-sm p-3 mb-5 bg-white bg-gradient rounded">
              <h5 className="text-center text-dark">New Academic Term</h5>
              <form onSubmit={handleSubmit}>
                <div className="d-flex flex-wrap">
                  <div className="form-group col-6 col-md-3 col-lg-3 col-xl-3">
                    <label htmlFor="accYear">Term Name</label>
                    <input
                      type="text"
                      id="accYear"
                      className="form-control"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                  <div className="form-group  col-6 col-md-3 col-lg-3 col-xl-3">
                    <label>Start Date</label>
                    <input
                      type="date"
                      className="form-control"
                      value={startDate}
                      onChange={(e) => setStartDate(e.target.value)}
                    />
                  </div>
                  <div className="form-group col-6 col-md-3 col-lg-3 col-xl-3">
                    <label>End Date</label>
                    <input
                      type="date"
                      className="form-control datepicker"
                      value={endDate}
                      onChange={(e) => setEndDate(e.target.value)}
                    />
                  </div>
                  <div className="form-group col-6 col-md-3 col-lg-3 col-xl-3">
                    <label>Academic Year</label>
                    <select
                      className="form-control"
                      onChange={(e) => setYear(e.target.value)}
                      value={year}
                    >
                      <option value="" disabled>
                        Select Academic Year
                      </option>
                      {academicYears.map((yr, index) => {
                        return (
                          <option key={index} value={yr.id}>
                            {yr.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div>
                  <div class="form-control col-6 col-md-3 col-lg-3 col-xl-3">
                    <input
                      type="checkbox"
                      value={isSession}
                      checked={isSession}
                      onChange={handleChange}
                    />
                    Is Current Session
                  </div>
                  <div className="text-right">
                    <button
                      className="btn btn-primary mr-1 text-right"
                      type="submit"
                    >
                      Create Academic Term
                    </button>
                    <Toaster position="top-right" reverseOrder={false} />
                  </div>
                </div>
              </form>
            </div>
          </div>
        ) : (
          <></>
        )}

        <div className="col-md-12 col-lg-12 col-xl-12">
          <div className="shadow-sm p-3 mb-5 bg-white rounded">
            <h5 className="text-center text-dark">Academic Term List</h5>
            {currentUser.roles.includes("UPLOAD_ACADEMIC_TERM") ||
            currentUser.roles.includes("SUPER_ADMIN") ? (
              <div className="d-flex justify-content-end">
                <div className="d-flex m-1">
                  <Button variant="text" component="label">
                    Upload
                    <input
                      hidden
                      type={"file"}
                      id={"csvFileInput"}
                      accept=".csv,.json,.xlsx,application/vnd.ms-excel"
                      onChange={handleFileUpload}
                    />
                  </Button>
                  {file && (
                    <div className="text-info align-self-center">
                      File: {file.name}
                    </div>
                  )}
                </div>
                <Button
                  size="small"
                  variant="contained"
                  endIcon={<Send />}
                  onClick={UploadFile}
                >
                  Submit
                </Button>
              </div>
            ) : (
              <></>
            )}
            <div style={{ width: "100%" }}>
              <DataGrid
                autoHeight
                columns={
                  currentUser.roles.includes("UPDATE_ACADEMIC_TERM") ||
                  currentUser.roles.includes("SUPER_ADMIN")
                    ? editableColumns
                    : columns
                }
                components={{
                  Toolbar: GridToolbar,
                  Pagination: CustomPagination,
                }}
                rows={data}
                getRowId={(row) => row.id}
                rowsPerPageOptions={[5, 10, 20]}
                rowHeight={45}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                getRowSpacing={(params) => ({
                  top: params.isFirstVisible ? 0 : 5,
                  bottom: params.isLastVisible ? 0 : 5,
                })}
                onCellEditCommit={(params) => setRowId(params.id)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
