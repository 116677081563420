import axios from "axios";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useQuery } from "react-query";
import { Link, useLocation } from "react-router-dom";
import { API_URLS } from "../../utils/api.routes";

export default function Lessondetails() {
  const [content, setContent] = useState([]);

  useEffect(() => {
    const fetchContent = async () => {
      const res = await fetch(API_URLS.lessoncontent + `?lesson=${state}`);
      const data = await res.json();
      setContent(data);
    };

    fetchContent();
  }, []);

  console.log(content);

  const { state } = useLocation();
  const fetchLessonDetails = async () => {
    const { data } = await axios.get(API_URLS.lesson.concat(state, "/"));
    return data;
  };
  const { data, error, isError, isLoading } = useQuery(
    "lesson",
    fetchLessonDetails
  );
  if (isLoading) {
    return (
      <div className="main-content">
        <div className="row justify-content-center align-items-center">
          <h1>Loading</h1>
        </div>
      </div>
    );
  }
  if (isError) {
    return (
      <div className="main-content">
        <div className="row justify-content-center align-items-center">
          <h4 className="text-danger">Error!!! {error.message}</h4>
        </div>
      </div>
    );
  }
  console.log(data);
  return (
    <div className="main-content">
      <div className="col-12 col-md-12 col-lg-12">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/admin">Home</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/admin/lesson-plan/lesson">Lessons</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Lesson Details
            </li>
          </ol>
        </nav>
      </div>
      <div className="col-8">
        <div className="card shadow-sm p-3 mb-5 bg-white rounded">
          <h3 className="text-center text-info">Lesson Details</h3>
          <div className="text-dark">ID: {data.id}</div>
          <div className="text-dark">Class: {data.lesson_class}</div>
          <div className="text-dark">Subject: {data.subject}</div>
          <div className="text-dark">Name: {data.name}</div>
          <div className="text-dark">Topic: {data.topic}</div>
          <div className="text-dark">Subtopic: {data.subtopic}</div>

          <div className="text-dark">Teacher: {data.teacher}</div>
          <div className="text-dark">Details: {data.details}</div>
          <div className="text-dark">
            Content:{" "}
            {content.map((c) => (
              <img
                key={c.id}
                src={API_URLS.module2_img + c["logo"]}
                alt="content"
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
