import React from "react";
import { CollectionsBookmarkOutlined } from "@mui/icons-material";
import { Link } from "react-router-dom";

export default function SystemSettings() {
  return (
    <>
      <li className="dropdown" style={{ marginBottom: "50px" }}>
        <Link to="#" className="menu-toggle nav-link has-dropdown">
          <CollectionsBookmarkOutlined fontSize="medium" className="icon-mr" />
          <span>System Settings</span>
        </Link>
        <ul className="dropdown-menu">
          <li>
            <Link className="nav-link" to="/admin/page/settings">
              Page Settings
            </Link>
          </li>
          <li>
            <Link className="nav-link" to="/admin/page/school-info">
              School Info
            </Link>
          </li>
          {/* 
          <li>
            <Link className="nav-link" to="email-read.html">
              Syllabus
            </Link>
          </li>
          <li>
            <Link className="nav-link" to="calendar.html">
              Other Downloads
            </Link>
          </li> */}
        </ul>
      </li>
    </>
  );
}
