import React, { useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  toastDangerStyle,
  toastSuccessStyle,
  toastWarnigStyle,
} from "../../../components/shared/Custom.Styles";
import { API_URLS } from "../../../utils/api.routes";

function AcademicYearEdit() {
  const { state } = useLocation();
  const navigate = useNavigate();

  const [name, setName] = useState(state.name);
  const [startDate, setStartDate] = useState(state.start);
  const [endDate, setEndDate] = useState(state.end);

  const headersList = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };

  const bodyContent = JSON.stringify({
    id: state.id,
    name: name,
    start: startDate,
    end: endDate,
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    await fetch(API_URLS.academic_year.concat(state.id, "/"), {
      method: "PUT",
      body: bodyContent,
      headers: headersList,
    })
      .then((res) => {
        if (res.status === 200) {
          toast.success(
            `Successfully Updated Academic Year`,
            toastSuccessStyle
          );
          setTimeout(() => {
            navigate("/admin/academic-year/list");
          }, 2000);
        } else if (res.status === 400) {
          toast.error(
            `${res.status} Error !!! ${res.statusText}`,
            toastDangerStyle
          );
          setTimeout(() => {
            toast(`Seems Category Name Already Exists`, toastWarnigStyle);
          }, 1000);
        } else {
          toast.error(
            `${res.status} Error !!! ${res.statusText}`,
            toastDangerStyle
          );
        }
      })
      .catch((err) => {
        toast.error(`Error !!! Not Found}`, toastDangerStyle);
      });
  };
  return (
    <div className="main-content">
      <div className="col-12 col-md-12 col-lg-12">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/admin">Home</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/admin/academic-year/list">Academic Year</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Edit Year
            </li>
          </ol>
        </nav>
      </div>
      <div className="col-md-4 col-lg-4 col-xl-4">
        <div className="card">
          <div className="card-header">
            <h4>Academic Year Details</h4>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="card-body">
              <div className="form-group">
                <label htmlFor="accYear">Academic Year Name</label>
                <input
                  type="text"
                  id="accYear"
                  className="form-control"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label>Start Date</label>
                <input
                  type="date"
                  className="form-control datepicker"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label>End Date</label>
                <input
                  type="date"
                  className="form-control datepicker"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                />
              </div>
            </div>
            <div className="card-footer text-center">
              <button className="btn btn-primary mr-1" type="submit">
                Update Academic Year
              </button>
              <Toaster position="top-right" reverseOrder={false} />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default AcademicYearEdit;
