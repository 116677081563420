import React, { useState } from "react";
import { Toaster, toast } from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import SchoolActions from "./SchoolActions";
import { API_URLS } from "../../../utils/api.routes";
import axios from "axios";
import { useQuery } from "react-query";
import {
  toastDangerStyle,
  toastSuccessStyle,
  toastWarnigStyle,
} from "../../../components/shared/Custom.Styles";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { CustomPagination } from "../../../components/custom/Custom.DataGrid";
import authService from "../../../services/auth.service";
import authHeader from "../../../services/auth-header";
import AccessDenied from "../../../components/custom/Custom.AccessDenied";

export default function School() {
  const navigate = useNavigate();
  const currentUser = authService.getCurrentUser();
  const [code, setCode] = useState("");
  const [name, setName] = useState("");

  const [rowId, setRowId] = useState(null);
  const [pageSize, setPageSize] = useState(5);

  const handleSubmit = async (e) => {
    e.preventDefault();
    await fetch(API_URLS.school, {
      method: "POST",
      headers: authHeader(),
      body: JSON.stringify({
        code: code,
        name: name,
      }),
    })
      .then((res) => {
        if (res.status === 201) {
          setCode("");
          setName("");
          refetch();
          toast.success(`Successfully Created School`, toastSuccessStyle);
        } else if (res.status === 400) {
          toast.error(`Error !!! ${res.statusText}`, toastDangerStyle);
          setTimeout(() => {
            toast(`Seems School Already Exists`, toastWarnigStyle);
          }, 1000);
        } else {
          toast.error(
            `${res.status} Error !!! ${res.statusText}`,
            toastDangerStyle
          );
        }
      })
      .catch((err) => {
        toast.error(`Error, Not Found !!!`, toastDangerStyle);
      });
  };

  async function fetchSchools() {
    const { data } = await axios.get(API_URLS.school);
    return data;
  }
  const { data, error, isError, isLoading, refetch } = useQuery(
    "schools",
    fetchSchools
  );
  if (isLoading) {
    return <div className="main-content">Loading...</div>;
  }
  if (isError) {
    return (
      <div className="main-content">
        <div>Error!! {error.message}</div>
      </div>
    );
  }

  const editableColumns = [
    {
      field: "id",
      headerName: "SL",
      headerAlign: "center",
      type: "number",
      width: "80",
    },
    {
      field: "code",
      headerName: "School Code",
      headerAlign: "center",
      editable: true,
      width: 100,
    },
    {
      field: "name",
      headerName: "School Name",
      headerAlign: "center",
      editable: true,
      width: 200,
    },
    {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      width: 150,
      renderCell: (params) => (
        <SchoolActions {...{ params, rowId, setRowId, refetch }} />
      ),
    },
  ];
  const columns = [
    {
      field: "code",
      headerName: "School Code",
      headerAlign: "center",
      width: 100,
    },
    {
      field: "name",
      headerName: "School Name",
      headerAlign: "center",
      width: 200,
    },
  ];

  if (
    !currentUser.roles.includes("SUPER_ADMIN") &&
    !currentUser.roles.includes("ADMIN") &&
    !currentUser.roles.includes("VIEW_SCHOOL")
  ) {
    return <AccessDenied />;
  }
  return (
    <div className="main-content">
      <div className="row">
        <div className="col-12 col-md-12 col-lg-12">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/admin">Home</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                School
              </li>
            </ol>
          </nav>
        </div>
        {currentUser.roles.includes("ROLE_ADD_SCHOOL") ||
        currentUser.roles.includes("SUPER_ADMIN") ? (
          <div className="col-md-6 col-lg-12 col-xl-4">
            <div className="shadow-sm p-3 mb-5 bg-white rounded">
              <div className="text-center text-dark">
                <h5>Register School</h5>
              </div>
              <form onSubmit={handleSubmit}>
                <div className="card-body">
                  <div className="form-group">
                    <label htmlFor="sCode">School Code</label>
                    <input
                      type="text"
                      id="sCode"
                      className="form-control"
                      value={code}
                      onChange={(e) => setCode(e.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="sName">School Name</label>
                    <input
                      type="text"
                      id="sName"
                      className="form-control"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                </div>
                <div className="text-center">
                  <button className="btn btn-primary mr-1" type="submit">
                    Register School
                  </button>
                  <Toaster position="top-right" reverseOrder={false} />
                </div>
              </form>
            </div>
          </div>
        ) : (
          <></>
        )}

        <div className="col-md-6 col-lg-12 col-xl-8">
          <div className="shadow-sm p-3 mb-5 bg-white rounded">
            <div className="text-center">
              <h5 className="text-center text-dark">School List</h5>
            </div>
            <div style={{ width: "100%" }}>
              <DataGrid
                autoHeight
                columns={
                  currentUser.roles.includes("ROLE_SUPER_ADMIN") ||
                  currentUser.roles.includes("SUPER_ADMIN")
                    ? editableColumns
                    : columns
                }
                components={{
                  Toolbar: GridToolbar,
                  Pagination: CustomPagination,
                }}
                rows={data}
                getRowId={(row) => row.id}
                rowsPerPageOptions={[5, 10, 20]}
                rowHeight={45}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                getRowSpacing={(params) => ({
                  top: params.isFirstVisible ? 0 : 5,
                  bottom: params.isLastVisible ? 0 : 5,
                })}
                onCellEditCommit={(params) => setRowId(params.id)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
