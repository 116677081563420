import React from "react";
import "./assets/css/bootstrap.min.css";
import "./assets/css/style.css";

import "./assets/lib/owlcarousel/assets/owl.carousel.min.css";
import "./assets/lib/animate/animate.min.css"; // Import the CSS file
import { Link } from "react-router-dom";

export default function AboutUs() {
  return (
    <div className="content">
      <div class="container-fluid bg-dark px-5 d-none d-lg-block">
        <div class="row gx-0">
          <div class="col-lg-8 text-center text-lg-start mb-2 mb-lg-0">
            <div
              class="d-inline-flex align-items-center"
              style={{ height: "45px" }}
            >
              <small class="me-3 text-light">
                <i class="fa fa-map-marker-alt me-2"></i>123 Street, New York,
                USA
              </small>
              <small class="me-3 text-light">
                <i class="fa fa-phone-alt me-2"></i>+012 345 6789
              </small>
              <small class="text-light">
                <i class="fa fa-envelope-open me-2"></i>info@example.com
              </small>
            </div>
          </div>
          <div class="col-lg-4 text-center text-lg-end">
            <div
              class="d-inline-flex align-items-center"
              style={{ height: "45px" }}
            >
              <a
                class="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2"
                href="home"
              >
                <i class="fab fa-twitter fw-normal"></i>
              </a>
              <a
                class="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2"
                href="home"
              >
                <i class="fab fa-facebook-f fw-normal"></i>
              </a>
              <a
                class="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2"
                href="home"
              >
                <i class="fab fa-linkedin-in fw-normal"></i>
              </a>
              <a
                class="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2"
                href="home"
              >
                <i class="fab fa-instagram fw-normal"></i>
              </a>
              <a
                class="btn btn-sm btn-outline-light btn-sm-square rounded-circle"
                href="home"
              >
                <i class="fab fa-youtube fw-normal"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="container-fluid position-relative p-0">
        <nav class="navbar navbar-expand-lg navbar-dark px-5 py-3 py-lg-0">
          <a href="home" class="navbar-brand p-0">
            <h1 class="m-0">
              <i class="fa fa-user-tie me-2"></i>Startup
            </h1>
          </a>
          <button
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="navbarCollapse"
          >
            <span class="fa fa-bars"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarCollapse">
            <div class="navbar-nav ms-auto py-0">
              <Link to="/" class="nav-item nav-link active">
                Home
              </Link>
              <Link to="/about-us" class="nav-item nav-link">
                About
              </Link>
              <a href="service.html" class="nav-item nav-link">
                Services
              </a>
              <div class="nav-item dropdown">
                <a
                  href="home"
                  class="nav-link dropdown-toggle"
                  data-bs-toggle="dropdown"
                >
                  Blog
                </a>
                <div class="dropdown-menu m-0">
                  <a href="blog.html" class="dropdown-item">
                    Blog Grid
                  </a>
                  <a href="detail.html" class="dropdown-item">
                    Blog Detail
                  </a>
                </div>
              </div>
              <div class="nav-item dropdown">
                <a
                  href="home"
                  class="nav-link dropdown-toggle"
                  data-bs-toggle="dropdown"
                >
                  Pages
                </a>
                <div class="dropdown-menu m-0">
                  <a href="price.html" class="dropdown-item">
                    Pricing Plan
                  </a>
                  <a href="feature.html" class="dropdown-item">
                    Our features
                  </a>
                  <a href="team.html" class="dropdown-item">
                    Team Members
                  </a>
                  <a href="testimonial.html" class="dropdown-item">
                    Testimonial
                  </a>
                  <a href="quote.html" class="dropdown-item">
                    Free Quote
                  </a>
                </div>
              </div>
              <a href="contact.html" class="nav-item nav-link">
                Contact
              </a>
            </div>
            <button
              type="button"
              class="btn text-primary ms-3"
              data-bs-toggle="modal"
              data-bs-target="searchModal"
            >
              <i class="fa fa-search"></i>
            </button>
            <Link to="account/login" class="nav-item nav-link">
              Login
            </Link>
          </div>
        </nav>

        <div
          id="header-carousel"
          class="carousel slide carousel-fade"
          data-bs-ride="carousel"
        >
          <div class="carousel-inner">
            <div class="carousel-item active">
              <img
                class="w-100"
                src="assets/img/slideshow/1.jpeg"
                alt="shaddy"
              />
              <div class="carousel-caption d-flex flex-column align-items-center justify-content-center">
                <div class="p-3" style={{ maxWidth: "900px" }}>
                  <h5 class="text-white text-uppercase mb-3 animated slideInDown">
                    Creative & Innovative
                  </h5>
                  <h1 class="display-1 text-white mb-md-4 animated zoomIn">
                    Creative & Innovative Digital Solution
                  </h1>
                  <a
                    href="quote.html"
                    class="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                  >
                    Free Quote
                  </a>
                  <a
                    href="home"
                    class="btn btn-outline-light py-md-3 px-md-5 animated slideInRight"
                  >
                    Contact Us
                  </a>
                </div>
              </div>
            </div>
            <div class="carousel-item">
              <img
                class="w-100"
                src="assets/img/slideshow/2.jpeg"
                alt="shaddy2"
              />
              <div class="carousel-caption d-flex flex-column align-items-center justify-content-center">
                <div class="p-3" style={{ maxWidth: "900px" }}>
                  <h5 class="text-white text-uppercase mb-3 animated slideInDown">
                    Creative & Innovative
                  </h5>
                  <h1 class="display-1 text-white mb-md-4 animated zoomIn">
                    Creative & Innovative Digital Solution
                  </h1>
                  <a
                    href="quote.html"
                    class="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                  >
                    Free Quote
                  </a>
                  <a
                    href="home"
                    class="btn btn-outline-light py-md-3 px-md-5 animated slideInRight"
                  >
                    Contact Us
                  </a>
                </div>
              </div>
            </div>
          </div>
          <button
            class="carousel-control-prev"
            type="button"
            data-bs-target="header-carousel"
            data-bs-slide="prev"
          >
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button
            class="carousel-control-next"
            type="button"
            data-bs-target="header-carousel"
            data-bs-slide="next"
          >
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>
      </div>
    </div>
  );
}
