import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import axios from "axios";
import React, { useState } from "react";
import { toast, Toaster } from "react-hot-toast";
import { useQuery } from "react-query";
import { Link, Navigate } from "react-router-dom";
import { CustomPagination } from "../../../../components/custom/Custom.DataGrid.jsx";
import {
  toastDangerStyle,
  toastSuccessStyle,
  toastWarnigStyle,
} from "../../../../components/shared/Custom.Styles.js";
import { API_URLS } from "../../../../utils/api.routes";
import SubjectTaughtActions from "./SubjectTaughtActions";
import { Button } from "@mui/material";
import { Send } from "@mui/icons-material";
import authHeader from "../../../../services/auth-header.js";
import authService from "../../../../services/auth.service.js";
import AccessDenied from "../../../../components/custom/Custom.AccessDenied.jsx";

export default function SubjectTaught() {
  const currentUser = authService.getCurrentUser();

  const [code, setCode] = useState("");
  const [name, setName] = useState("");

  const [file, setFile] = useState();

  const [rowId, setRowId] = useState(null);
  const [pageSize, setPageSize] = useState(5);

  async function fetchSubjects() {
    const { data } = await axios.get(API_URLS.subjects);
    return data;
  }
  const { data, error, isError, isLoading, refetch } = useQuery(
    "subjects",
    fetchSubjects,
    {
      refetchOnWindowFocus: true,
    }
  );
  if (isLoading) {
    return (
      <div className="main-content">
        <div className="row">
          <h6 className="text-info">Loading...</h6>
        </div>
      </div>
    );
  }
  if (isError) {
    return (
      <div className="main-content">
        <div className="row">
          <h6 className="text-danger">Error!! {error.message}</h6>
        </div>
      </div>
    );
  }

  const handleFileUpload = (e) => {
    const selectedFile = e.target.files[0];
    if (
      selectedFile.type === "text/csv" ||
      selectedFile.type === "application/json" ||
      selectedFile.type === "application/vnd.ms-excel" ||
      selectedFile.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      setFile(selectedFile);
    } else {
      toast("Please select a CSV, JSON or Excel file.", toastWarnigStyle);
    }
  };

  const UploadFile = () => {
    const formData = new FormData();
    formData.append("file", file);

    fetch(API_URLS.upload_subject, {
      method: "POST",
      body: formData,
    })
      .then((res) => {
        if (res.status === 200) {
          toast.success("Upload Successfull", toastSuccessStyle);
        }
      })
      .catch((error) => {
        toast.error(`Upload Error ${error}`, toastDangerStyle);
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await fetch(API_URLS.subjects, {
      method: "POST",
      body: JSON.stringify({
        subject_code: code,
        subject_name: name,
      }),
      headers: authHeader(),
    })
      .then((res) => {
        if (res.status === 201) {
          setCode("");
          setName("");
          refetch();
          toast.success(`Successfully Created Subject`, toastSuccessStyle);
        } else if (res.status === 400) {
          toast.error(`Error !!! ${res.statusText}`, toastDangerStyle);
          setTimeout(() => {
            toast(`Seems Subject Already Exists`, toastWarnigStyle);
          }, 1000);
        } else {
          toast.error(
            `${res.status} Error !!! ${res.statusText}`,
            toastDangerStyle
          );
        }
      })
      .catch((err) => {
        toast.error(`Error, Not Found !`, toastDangerStyle);
      });
  };

  const editableColumns = [
    {
      field: "id",
      headerName: "SL",
      headerAlign: "center",
      type: "number",
      hide: true,
      width: "80",
    },
    {
      field: "subject_code",
      headerName: "Subject  Code",
      headerAlign: "center",
      editable: true,
      width: 150,
    },
    {
      field: "subject_name",
      headerName: "Subject Name",
      editable: true,
      width: 200,
      editable: true,
    },
    {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      width: 150,
      renderCell: (params) => (
        <SubjectTaughtActions {...{ params, rowId, setRowId, refetch }} />
      ),
    },
  ];
  const columns = [
    {
      field: "id",
      headerName: "SL",
      headerAlign: "center",
      type: "number",
      hide: true,
      width: "80",
    },
    {
      field: "subject_code",
      headerName: "Subject  Code",
      headerAlign: "center",
      width: 150,
    },
    {
      field: "subject_name",
      headerName: "Subject Name",
      width: 200,
      editable: true,
    },
  ];

  if (
    !currentUser.roles.includes("VIEW_GRADE") &&
    !currentUser.roles.includes("SUPER_ADMIN")
  ) {
    return <AccessDenied />;
  }
  return (
    <div className="main-content">
      <div className="row">
        <div className="col-12 col-md-12 col-lg-12">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/admin/administration/dashboard">Home</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Subjects Taught
              </li>
            </ol>
          </nav>
        </div>
        {currentUser.roles.includes("ADD_SUBJECT") ||
        currentUser.roles.includes("SUPER_ADMIN") ? (
          <div className="col-md-6 col-lg-12 col-xl-4">
            <div className="shadow-sm p-3 mb-5 bg-white rounded">
              <h5 className="text-center text-dark">Create Subject</h5>
              <form onSubmit={handleSubmit}>
                <div className="card-body">
                  <div className="form-group">
                    <label htmlFor="accYear">Subject Code</label>
                    <input
                      type="text"
                      id="accYear"
                      className="form-control"
                      value={code}
                      onChange={(e) => setCode(e.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <label>Subject Name</label>
                    <input
                      type="text"
                      className="form-control"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                </div>
                <div className="text-center">
                  <button className="btn btn-primary mr-1" type="submit">
                    Create Subject
                  </button>
                  <Toaster position="top-right" reverseOrder={false} />
                </div>
              </form>
            </div>
          </div>
        ) : (
          <></>
        )}
        <div className="col-md-6 col-lg-12 col-xl-8">
          <div className="shadow-sm p-3 mb-5 bg-white rounded">
            <h5 className="col-8 text-center text-dark">
              Subjects Taught List
            </h5>
            {currentUser.roles.includes("UPLOAD_SUBJECT") ||
            currentUser.roles.includes("SUPER_ADMIN") ? (
              <div className="d-flex justify-content-end">
                <div className="d-flex m-1">
                  <Button variant="text" component="label">
                    Upload
                    <input
                      hidden
                      type={"file"}
                      id={"csvFileInput"}
                      accept=".csv,.json,.xlsx,application/vnd.ms-excel"
                      onChange={handleFileUpload}
                    />
                  </Button>
                  {file && (
                    <div className="text-info align-self-center">
                      File: {file.name}
                    </div>
                  )}
                </div>
                <Button
                  size="small"
                  variant="contained"
                  endIcon={<Send />}
                  onClick={UploadFile}
                >
                  Submit
                </Button>
              </div>
            ) : (
              <></>
            )}
            <div style={{ width: "100%" }}>
              <DataGrid
                autoHeight
                columns={
                  currentUser.roles.includes("UPDATE_ACADEMIC_TERM") ||
                  currentUser.roles.includes("SUPER_ADMIN")
                    ? editableColumns
                    : columns
                }
                components={{
                  Toolbar: GridToolbar,
                  Pagination: CustomPagination,
                }}
                rows={data}
                getRowId={(row) => row.id}
                rowsPerPageOptions={[5, 10, 20]}
                rowHeight={45}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                getRowSpacing={(params) => ({
                  top: params.isFirstVisible ? 0 : 5,
                  bottom: params.isLastVisible ? 0 : 5,
                })}
                onCellEditCommit={(params) => setRowId(params.id)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
