import {
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";

export default function UserRoles({ data, onSelectionChange }) {
  const [selectedRoles, setSelectedRoles] = useState([]);

  const handleToggle = (id) => {
    if (selectedRoles.includes(id)) {
      setSelectedRoles(selectedRoles.filter((role) => role !== id));
    } else {
      setSelectedRoles([...selectedRoles, id]);
    }
  };

  useEffect(() => {
    onSelectionChange(selectedRoles);
  }, [selectedRoles, onSelectionChange]);

  return (
    <TableContainer component={Paper}>
      <Table aria-label="customized table">
        <TableHead>
          <TableRow>
            <TableCell>Select</TableCell>
            <TableCell>Role Name</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((role, index) => (
            <TableRow key={index}>
              <TableCell className="m-3">
                <Checkbox
                  type="checkbox"
                  checked={selectedRoles.includes(role.id)}
                  onChange={() => handleToggle(role.id)}
                />
              </TableCell>
              <TableCell>{role.name}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
