import { Check, InfoOutlined, Save } from "@mui/icons-material";
import { Box, CircularProgress, Fab, IconButton, Tooltip } from "@mui/material";
import { green } from "@mui/material/colors";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { API_URLS } from "../../../utils/api.routes";

const headersList = {
  Accept: "application/json",
  "Content-Type": "application/json",
};

export default function StaffListActions({ params, rowId, setRowId }) {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async () => {
    setLoading(true);
    const { code, name, id } = params.row;
    await fetch(API_URLS.hr_staff.concat(id, "/"), {
      method: "PUT",
      body: JSON.stringify({ id: id, code: code, name: name }),
      headers: headersList,
    })
      .then((res) => {
        setSuccess(true);
        setRowId(null);
        setLoading(false);
      })
      .catch((err) => {
        console.log("Promise Error: " + err);
      });
  };

  useEffect(() => {
    if (rowId === params.id && success) setSuccess(false);
  }, [rowId]);

  const detailsPage = () => {
    const { id } = params.row;
    navigate("/admin/hr/staff-details", { state: id });
  };
  return (
    <Box
      sx={{
        m: 1,
        position: "relative",
      }}
    >
      {success ? (
        <Fab
          color="primary"
          sx={{
            width: 40,
            height: 40,
            bgcolor: green[500],
            "&:hover": { bgcolor: green[700] },
          }}
        >
          <Check />
        </Fab>
      ) : (
        <Fab
          color="primary"
          sx={{
            width: 40,
            height: 40,
          }}
          disabled={params.id !== rowId || loading}
          onClick={handleSubmit}
        >
          <Save />
        </Fab>
      )}
      {loading && (
        <CircularProgress
          size={52}
          sx={{
            color: green[500],
            position: "absolute",
            top: -6,
            left: -6,
            zIndex: 1,
          }}
        />
      )}
      <Tooltip title="To Details Page">
        <IconButton onClick={() => detailsPage()}>
          <InfoOutlined color="secondary" />
        </IconButton>
      </Tooltip>
    </Box>
  );
}
