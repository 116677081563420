import React from "react";
import { toast, Toaster } from "react-hot-toast";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  toastDangerStyle,
  toastSuccessStyle,
} from "../../../components/shared/Custom.Styles";
import { API_URLS } from "../../../utils/api.routes";

function AcademicYearDetails() {
  const { state } = useLocation();
  const navigate = useNavigate();

  const editAcademicYear = () => {
    navigate("/admin/academic-year/edit", {
      state: state,
    });
  };
  const deleteAcademicYear = async () => {
    const headersList = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    await fetch(API_URLS.concat(state.id, "/"), {
      method: "DELETE",
      headers: headersList,
    })
      .then((res) => {
        if (res.status === 204) {
          toast.success(
            `Deleted Academic Year Successfully`,
            toastSuccessStyle
          );
          setTimeout(() => {
            navigate("/admin/academic-year/list");
          }, 1000);
        } else {
          toast.error(`Error !!! ${res.statusText}`, toastDangerStyle);
        }
      })
      .catch((err) => {
        toast.error(`Error !!! Not Found`, toastDangerStyle);
      });
  };
  return (
    <div className="main-content">
      <div className="main-content">
        <div className="row justify-content-center">
          <div className="col-12 col-md-12 col-lg-12">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/admin">Home</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/academic-year">Academic Year</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Edit Year
                </li>
              </ol>
            </nav>
          </div>
          <div className="col-12 col-md-6 col-sm-12">
            <div className="card shadow-sm p-3 mb-5 bg-body rounded">
              <div className="card-header">
                <h4>Academic Year Info</h4>
              </div>
              <div className="card-body">
                <div className="py-4">
                  <p className="clearfix">
                    <span className="float-left font-weight-bold">Year ID</span>
                    <span className="float-right text-info font-weight-bold">
                      {state.id}
                    </span>
                  </p>
                  <p className="clearfix">
                    <span className="float-left font-weight-bold">
                      Year Name
                    </span>
                    <span className="float-right text-info font-weight-bold">
                      {state.name}
                    </span>
                  </p>
                  <p className="clearfix">
                    <span className="float-left font-weight-bold">
                      Start Date
                    </span>
                    <span className="float-right text-info font-weight-bold">
                      {state.start}
                    </span>
                  </p>
                  <p className="clearfix">
                    <span className="float-left font-weight-bold">
                      End Date
                    </span>
                    <span className="float-right text-info font-weight-bold">
                      {state.end}
                    </span>
                  </p>
                  <div>
                    <button
                      className="btn btn-primary float-left"
                      onClick={() => {
                        editAcademicYear(
                          state.id,
                          state.name,
                          state.start,
                          state.end
                        );
                      }}
                    >
                      Edit
                    </button>
                    <button
                      className="btn btn-danger float-right"
                      onClick={() => {
                        deleteAcademicYear();
                      }}
                    >
                      Delete
                    </button>
                    <Toaster position="top-right" reverseOrder={false} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AcademicYearDetails;
