import React, { useState } from "react";
import { toast, Toaster } from "react-hot-toast";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  toastDangerStyle,
  toastSuccessStyle,
  toastWarnigStyle,
} from "../../../components/shared/Custom.Styles";
import { API_URLS } from "../../../utils/api.routes";

function ClassRoomEdit() {
  const { state } = useLocation();
  const navigate = useNavigate();

  const [rCode, setRCode] = useState(state.class_room_code);
  const [rName, setRName] = useState(state.class_room_name);
  const [capacity, setCapacity] = useState(state.capacity);
  const [sCode, setSCode] = useState(state.stream_code);
  const [room, setRoom] = useState(state.room_allocated);
  const [accYear, setAccYear] = useState(state.academic_year);
  const [teacher, setTeacher] = useState(state.class_teacher);

  const headersList = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };

  const bodyContent = JSON.stringify({
    id: state.id,
    class_room_code: rCode,
    class_room_name: rName,
    capacity: capacity,
    room_allocated: room,
    stream_code: sCode,
    academic_year: accYear,
    class_teacher: teacher,
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    await fetch(API_URLS.class_room.concat(state.id, "/"), {
      method: "PUT",
      body: bodyContent,
      headers: headersList,
    })
      .then((res) => {
        if (res.status === 200) {
          toast.success(`Designation Update Successful`, toastSuccessStyle);
          setTimeout(() => {
            navigate("/admin/classroom/list");
          }, 2000);
        } else if (res.status === 400) {
          toast.error(`Error !!! ${res.statusText}`, toastDangerStyle);
          setTimeout(() => {
            toast(`Seems Classroom Already Exists`, toastWarnigStyle);
          }, 1000);
        } else {
          toast.error(
            `${res.status} Error !!! ${res.statusText}`,
            toastDangerStyle
          );
        }
      })
      .catch((err) => {
        toast.error(`Error !!! Not Found}`, toastDangerStyle);
      });
  };
  return (
    <div className="row">
      <div className="col-12 col-md-12 col-lg-12">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/admin/administration/dashboard">Home</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/classrooms">Classrooms</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              New Classroom
            </li>
          </ol>
        </nav>
      </div>
      <div className="col-12 col-md-12 col-lg-12">
        <form
          className="d-flex justify-content-center align-items-center"
          onSubmit={handleSubmit}
        >
          <div className="col-md-4 col-lg-4 col-xl-4">
            <div className="card">
              <div className="card-header">
                <h4>className Room </h4>
              </div>
              <div className="card-body">
                <div className="form-group">
                  <label htmlFor="cStream">Stream Code</label>
                  <input
                    type="text"
                    id="cStream"
                    className="form-control"
                    value={sCode}
                    onChange={(e) => setSCode(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="cRoomCode">className Room Code </label>
                  <input
                    type="text"
                    id="cRoomCode"
                    className="form-control"
                    value={rCode}
                    onChange={(e) => setRCode(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="cStream">className Room Name </label>
                  <input
                    type="text"
                    id="cStream"
                    className="form-control"
                    value={rName}
                    onChange={(e) => setRName(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="cStream">Academic Year</label>
                  <input
                    type="text"
                    id="cStream"
                    className="form-control"
                    value={accYear}
                    onChange={(e) => setAccYear(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-lg-4 col-xl-4">
            <div className="card">
              <div className="card-header">
                <h4>Other Details</h4>
              </div>
              <div className="card-body">
                <div className="form-group">
                  <label htmlFor="cStream">className Capacity</label>
                  <input
                    type="text"
                    id="cStream"
                    className="form-control"
                    value={capacity}
                    onChange={(e) => setCapacity(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="acdYear">Room Allocated</label>
                  <input
                    type="text"
                    id="acdYear"
                    className="form-control"
                    value={room}
                    onChange={(e) => setRoom(e.target.value)}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="cStream">className Teacher</label>
                  <input
                    type="text"
                    id="cStream"
                    className="form-control"
                    value={teacher}
                    onChange={(e) => setTeacher(e.target.value)}
                  />
                </div>
              </div>
              <div className="card-footer text-center">
                <button className="btn btn-primary mr-1" type="submit">
                  Update Create Classroom
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
      <Toaster position="top-right" reverseOrder={false} />
    </div>
  );
}

export default ClassRoomEdit;
