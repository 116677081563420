import React, { useState } from "react";
import { Link } from "react-router-dom";
import WelcomeForm from "./steps/WelcomeForm";
import PersonalInfoForm from "./steps/PersonalInfoForm";
import OtherDetailsForm from "./steps/OtherDetailsForm";
import ProfessionForm from "./steps/ProfessionForm";
import AccountForm from "./steps/AccountForm";
import EmergencyContactForm from "./steps/EmergencyContactForm";
import Confirm from "./steps/Confirm";
import Success from "./steps/Success";

export default function StaffForm() {
  const [state, setState] = useState({
    step: 1,
    staff_number: "",
    role: "",
    first_name: "",
    last_name: "",
    other_name: "",
    email: "",
    gender: "",
    dob: "",
    age: "",
    mobile: "",
    marital_status: "",
    emergency_mobile: "",
    driving_license: "",
    special_med: "",
    birth_cert_no: "",
    blood_group: "",
    religion: "",
    height: "",
    weight: "",
    national_id: "",
    current_address: "",
    permanent_address: "",
    experience: "",
    service_no: "",
    basic_salary: "",
    contract_type: "",
    contract_length: "",
    location: "",
    bankaccount_name: "",
    account_number: "",
    bank_name: "",
    branch_name: "",
    facebook_url: "",
    twitter_url: "",
    linkedin_url: "",
    instagram_url: "",
    with_disability: "",
    tel_no: "",
    postal_code: "",
    zip_code: "",
    postal_location: "",
    home_county: "",
    home_sub_county: "",
    home_ward: "",
    home_location: "",
    home_village: "",
    prev_empl1: "",
    prev_empl_email1: "",
    prev_empl_tel1: "",
    prev_empl_address1: "",
    prev_empl2: "",
    prev_empl_email2: "",
    prev_empl_tel2: "",
    prev_empl_address2: "",
    prof_qualification_1: "",
    prof_qualification_2: "",
    prof_qualification_3: "",
    prof_qualification_4: "",
    emerg_first_name1: "",
    emerg_last_name1: "",
    emerg_tel_no1: "",
    emerg_mobile_no1: "",
    emerg_gender1: "",
    emerg_relation1: "",
    emerg_first_name2: "",
    emerg_last_name2: "",
    emerg_tel_no2: "",
    emerg_mobile_no2: "",
    emerg_gender2: "",
    emerg_relation2: "",
    designation: "",
    department: "",
    category: "",
  });

  // Proceed to next step
  const nextStep = () => {
    const { step } = state;
    setState({
      ...state,
      step: step + 1,
    });
  };
  // Proceed to prev step
  const prevStep = () => {
    const { step } = state;
    setState({
      ...state,
      step: step - 1,
    });
  };
  const handleChange = (input) => (e) => {
    setState({
      ...state,
      [input]: e.target.value,
    });
  };
  const { step } = state;

  const {
    staff_number,
    role,
    first_name,
    last_name,
    other_name,
    email,
    gender,
    dob,
    age,
    mobile,
    marital_status,
    emergency_mobile,
    driving_license,
    special_med,
    birth_cert_no,
    blood_group,
    religion,
    height,
    weight,
    national_id,
    current_address,
    permanent_address,
    experience,
    service_no,
    basic_salary,
    contract_type,
    contract_length,
    location,
    bankaccount_name,
    account_number,
    bank_name,
    branch_name,
    facebook_url,
    twitter_url,
    linkedin_url,
    instagram_url,
    with_disability,
    tel_no,
    postal_code,
    zip_code,
    home_county,
    home_sub_county,
    home_ward,
    home_location,
    home_village,
    prev_empl1,
    prev_empl_email1,
    prev_empl_tel1,
    prev_empl_address1,
    prev_empl2,
    prev_empl_email2,
    prev_empl_tel2,
    prev_empl_address2,
    prof_qualification_1,
    prof_qualification_2,
    prof_qualification_3,
    prof_qualification_4,
    emerg_first_name1,
    emerg_last_name1,
    emerg_tel_no1,
    emerg_mobile_no1,
    emerg_gender1,
    emerg_relation1,
    emerg_first_name2,
    emerg_last_name2,
    emerg_tel_no2,
    emerg_mobile_no2,
    emerg_gender2,
    emerg_relation2,
    designation,
    department,
    category,
  } = state;

  const values = {
    staff_number,
    role,
    first_name,
    last_name,
    other_name,
    email,
    gender,
    dob,
    age,
    mobile,
    marital_status,
    emergency_mobile,
    driving_license,
    special_med,
    birth_cert_no,
    blood_group,
    religion,
    height,
    weight,
    national_id,
    current_address,
    permanent_address,
    experience,
    service_no,
    basic_salary,
    contract_type,
    contract_length,
    location,
    bankaccount_name,
    account_number,
    bank_name,
    branch_name,
    facebook_url,
    twitter_url,
    linkedin_url,
    instagram_url,
    with_disability,
    tel_no,
    postal_code,
    zip_code,
    home_county,
    home_sub_county,
    home_ward,
    home_location,
    home_village,
    prev_empl1,
    prev_empl_email1,
    prev_empl_tel1,
    prev_empl_address1,
    prev_empl2,
    prev_empl_email2,
    prev_empl_tel2,
    prev_empl_address2,
    prof_qualification_1,
    prof_qualification_2,
    prof_qualification_3,
    prof_qualification_4,
    emerg_first_name1,
    emerg_last_name1,
    emerg_tel_no1,
    emerg_mobile_no1,
    emerg_gender1,
    emerg_relation1,
    emerg_first_name2,
    emerg_last_name2,
    emerg_tel_no2,
    emerg_mobile_no2,
    emerg_gender2,
    emerg_relation2,
    designation,
    department,
    category,
  };
  return (
    <div className="main-content">
      <div className="row">
        <div className="col-12 col-md-12 col-lg-12">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link className="font-weight-bold" to="/admin">
                  Home
                </Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                <span className="font-weight-bold">Register Student</span>
              </li>
            </ol>
          </nav>
        </div>
        <div className="col-md-12 col-lg-12 col-xl-12">
          <div className="shadow p-3 mb-5 bg-white rounded " style={{}}>
            {(() => {
              switch (step) {
                case 1:
                  return (
                    <WelcomeForm
                      nextStep={nextStep}
                      handleChange={handleChange}
                      values={values}
                    />
                  );
                case 2:
                  return (
                    <PersonalInfoForm
                      nextStep={nextStep}
                      handleChange={handleChange}
                      values={values}
                    />
                  );
                case 3:
                  return (
                    <OtherDetailsForm
                      nextStep={nextStep}
                      prevStep={prevStep}
                      handleChange={handleChange}
                      values={values}
                    />
                  );
                case 4:
                  return (
                    <ProfessionForm
                      nextStep={nextStep}
                      prevStep={prevStep}
                      handleChange={handleChange}
                      values={values}
                    />
                  );
                case 5:
                  return (
                    <AccountForm
                      nextStep={nextStep}
                      prevStep={prevStep}
                      handleChange={handleChange}
                      values={values}
                    />
                  );
                case 6:
                  return (
                    <EmergencyContactForm
                      nextStep={nextStep}
                      prevStep={prevStep}
                      handleChange={handleChange}
                      values={values}
                    />
                  );
                case 7:
                  return (
                    <Confirm
                      nextStep={nextStep}
                      prevStep={prevStep}
                      handleChange={handleChange}
                      values={values}
                    />
                  );
                case 8:
                  return <Success prevStep={prevStep} values={values} />;
              }
            })()}
          </div>
        </div>
      </div>
    </div>
  );
}
