import React, { Component } from "react";

import UserService from "../../services/user.service";
import { API_URLS } from "../../utils/api.routes";
import { Link } from "react-router-dom";
import PublicNavbar from "../../components/layouts/PublicNavbar";
import authService from "../../services/auth.service";

export default class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      content: "",
      school: {},
      logos: {},
      currentUser: {},
    };
  }

  componentDidMount() {
    UserService.getPublicContent().then(
      (response) => {
        this.setState({
          content: response.data,
        });
      },
      (error) => {
        this.setState({
          content:
            (error.response && error.response.data) ||
            error.message ||
            error.toString(),
        });
      }
    );
    // authService.getCurrentUser().then((res) => {
    //   this.setState({ currentUser: res.data });
    // });
    const fetchSchools = async () => {
      const res = await fetch(API_URLS.school.concat(1, "/"));
      const data = await res.json();
      this.setState({ school: data });
    };
    const fetchLogos = async () => {
      const res = await fetch(API_URLS.school_logos.concat(1, "/"));
      const data = await res.json();
      this.setState({ logos: data });
    };
    fetchSchools();
    fetchLogos();
  }

  render() {
    // console.log(this.state.school);
    return (
      <div className="container">
        <PublicNavbar />
        <header style={{ marginTop: 70 }}>
          <div style={{ height: 200 }} className="d-flex align-items-center">
            <div className="bounce">
              <h1>{this.state.school["name"]}</h1>
            </div>
          </div>
          <h5>
            Welcome to{" "}
            <span style={{ fontSize: 30, color: "green" }}>
              {this.state.school["name"]}
            </span>{" "}
            webpage, where we showcase the exciting activities and events
            happening in our school! Here's a glimpse of what our students have
            been up to recently.
          </h5>
        </header>
        <div class="row">
          <div class="col-md-4">
            <div class="card">
              <img
                class="card-img-top"
                src="https://nhmlac.org/sites/default/files/styles/asp_switcher_4_3_med/public/2020-02/200131_p4a3974_marketing_night_of_ideas_cholick.jpg"
                alt="Activity 1"
              />

              <div class="card-body">
                <h4 class="card-title">Field Trip to the Science Museum</h4>
                <p class="card-text">
                  Our students had a blast exploring the exhibits and learning
                  about science and technology at the Science Museum. Our
                  students had a blast exploring the exhibits and learning about
                  science and technology at the Science Museum. Our students had
                  a blast exploring the exhibits and learning about science and
                  technology at the Science Museum.
                </p>
                <p class="card-text">
                  <small class="text-muted">Date: January 15, 2023</small>
                </p>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="card">
              <img
                class="card-img-top"
                src="https://www.dorsetmuseum.org/wp-content/uploads/2021/05/Dorset-Museum-Learn-6.jpg"
                alt="Activity 1"
              />

              <div class="card-body">
                <h4 class="card-title">Annual Science Fair</h4>
                <p class="card-text">
                  Our students are also passionate about exploring the wonders
                  of science. This was evident at our school's annual science
                  fair, where students presented their innovative experiments
                  and research projects. It was amazing to see the creativity
                  and scientific curiosity of our students on display.
                </p>
                <p class="card-text">
                  <small class="text-muted">Date: February 15, 2023</small>
                </p>
              </div>
            </div>
          </div>
          <hr />
          <div class="col-md-4">
            <div class="card">
              <img
                class="card-img-top"
                src="https://3.files.edl.io/c382/22/09/16/154620-39f779a3-fea5-463a-af72-e19a2dffd21b.jpg"
                alt="Activity 1"
              />

              <div class="card-body">
                <h4 class="card-title">Student Council</h4>
                <p class="card-text">
                  Our school's student council has been hard at work organizing
                  various initiatives to improve our school community. One of
                  their recent projects was a school-wide cleanup day, where
                  students and staff worked together to make our school a
                  cleaner and more pleasant environment for everyone. They also
                  organized a charity drive to collect donations for a local
                  food bank.
                </p>
                <p class="card-text">
                  <small class="text-muted">Date: February 15, 2023</small>
                </p>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="card">
              <img
                class="card-img-top"
                src="https://news.ubc.ca/wp-content/uploads/2019/06/shutterstock_198925616.jpg"
                alt="Activity 1"
              />

              <div class="card-body">
                <h4 class="card-title">Music Class</h4>
                <p class="card-text">
                  In addition to sports, community service, and science, our
                  school also has a vibrant music program. Our students have
                  been learning to play a variety of instruments and have been
                  performing in school concerts and events. The music program
                  has helped our students develop their creativity and
                  self-expression while also fostering teamwork and
                  collaboration.
                </p>
                <p class="card-text">
                  <small class="text-muted">Date: February 15, 2023</small>
                </p>
              </div>
            </div>
          </div>
        </div>

        {this.state.content && (
          <h6 className="text-muted" style={{ marginBottom: 10 }}>
            {this.state.content}
          </h6>
        )}
      </div>
    );
  }
}
