import { Check, Delete, Save } from "@mui/icons-material";
import { Box, CircularProgress, Fab, IconButton, Tooltip } from "@mui/material";
import { green } from "@mui/material/colors";
import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { API_URLS } from "../../../utils/api.routes";
import {
  toastDangerStyle,
  toastSuccessStyle,
} from "../../../components/shared/Custom.Styles";

const headersList = {
  Accept: "application/json",
  "Content-Type": "application/json",
};

export default function VehicleRoutesActions({
  params,
  rowId,
  setRowId,
  refetch,
}) {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleSubmit = async () => {
    setLoading(true);
    const { id, title, fare } = params.row;
    await fetch(API_URLS.routes.concat(id, "/"), {
      method: "PUT",
      body: JSON.stringify({
        id: id,
        title: title,
        fare: fare,
      }),
      headers: headersList,
    })
      .then((res) => {
        if (res.status === 200) {
          setSuccess(true);
          setRowId(null);
          setLoading(false);
          refetch();
          toast.success("Update Successful", toastSuccessStyle);
        } else {
          toast.error("Update Error", toastDangerStyle);
          setLoading(false);
        }
      })
      .catch((err) => {
        toast.error("An Error Occured", toastDangerStyle);
      });
  };

  useEffect(() => {
    if (rowId === params.id && success) setSuccess(false);
  }, [rowId]);

  const deleteAcademicYear = async () => {
    const { id } = params.row;
    await fetch(API_URLS.routes.concat(id, "/"), {
      method: "DELETE",
      headers: headersList,
    })
      .then((res) => {
        if (res.status === 204) {
          toast.success("Deleted Successful", toastSuccessStyle);
          refetch();
        } else {
          toast.error("Deleting Error", toastDangerStyle);
        }
      })
      .catch((err) => {
        toast.error("An Error Occured", toastDangerStyle);
      });
  };
  return (
    <Box
      sx={{
        m: 1,
        position: "relative",
      }}
    >
      {success ? (
        <Fab
          color="primary"
          sx={{
            width: 40,
            height: 40,
            bgcolor: green[500],
            "&:hover": { bgcolor: green[700] },
          }}
        >
          <Check />
        </Fab>
      ) : (
        <Fab
          color="primary"
          sx={{
            width: 40,
            height: 40,
          }}
          disabled={params.id !== rowId || loading}
          onClick={handleSubmit}
        >
          <Save />
        </Fab>
      )}
      {loading && (
        <CircularProgress
          size={52}
          sx={{
            color: green[500],
            position: "absolute",
            top: -6,
            left: -6,
            zIndex: 1,
          }}
        />
      )}
      <Tooltip title="Delete Academic Year">
        <IconButton onClick={() => deleteAcademicYear(params.row)}>
          <Delete color="secondary" />
        </IconButton>
      </Tooltip>
    </Box>
  );
}
