import axios from "axios";
import React from "react";
import { useQuery } from "react-query";
import { Link, useLocation } from "react-router-dom";
import { API_URLS } from "../../../utils/api.routes";
import AccessDenied from "../../../components/custom/Custom.AccessDenied";
import authService from "../../../services/auth.service";
import {
  CustomIsError,
  CustomIsLoading,
} from "../../../components/custom/Custom.UseQuery";

export default function Marksregisterdetails() {
  const currentUser = authService.getCurrentUser();
  const { state } = useLocation();
  const fetchMarksregisterDetails = async () => {
    const { data } = await axios.get(
      API_URLS.exam_markregister.concat(state, "/")
    );
    return data;
  };
  const { data, error, isError, isLoading } = useQuery(
    "exam_markregister",
    fetchMarksregisterDetails
  );
  if (isLoading) {
    return <CustomIsLoading />;
  }
  if (isError) {
    return <CustomIsError message={error.message} />;
  }
  if (
    !currentUser.roles.includes("VIEW_EXAM_MARKS_REGISTER") &&
    !currentUser.roles.includes("SUPER_ADMIN")
  ) {
    return <AccessDenied />;
  }
  return (
    <div className="main-content">
      <div className="col-12 col-md-12 col-lg-12">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/admin">Home</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/admin/exam-marks-register/list">Marks Register</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Marks Register Details
            </li>
          </ol>
        </nav>
      </div>
      <div className="col-8">
        <div className="card shadow-sm p-3 mb-5 bg-white rounded">
          <h3 className="text-center text-info">Marks Register Details</h3>
          <div className="text-dark">ID: {data.id}</div>
          <div className="text-dark">Admission Number: {data.admn_no}</div>
          <div className="text-dark">Setup: {data.setup}</div>
          <div className="text-dark">Subject: {data.subject}</div>
          <div className="text-dark">Cat Marks: {data.cat_marks}</div>
          <div className="text-dark">
            Assignment Marks: {data.assignment_marks}
          </div>
          <div className="text-dark"> Exam Marks: {data.exam_marks}</div>
        </div>
      </div>
    </div>
  );
}
