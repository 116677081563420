import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import axios from "axios";
import React, { useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { Link, Navigate } from "react-router-dom";
import { useQuery } from "react-query";
import { API_URLS } from "../../utils/api.routes";
import {
  toastDangerStyle,
  toastSuccessStyle,
  toastWarnigStyle,
} from "../../components/shared/Custom.Styles";
import LessonActions from "./LessonActions";
import { CustomPagination } from "../../components/custom/Custom.DataGrid";
import { Button } from "@mui/material";
import {
  ClassSearchSelectInput,
  SubjectSearchSelectInput,
  TopicSearchSelectInput,
  SubtopicSearchSelectInput,
} from "../../components/custom/Custom.SearchInput";
import { Send } from "@mui/icons-material";
import authService from "../../services/auth.service";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./reactquill.css";

const modules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link", "image"],
    ["clean"],
  ],
};

const formats = [
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
];

export default function Lesson() {
  //copy
  const currentUser = authService.getCurrentUser();

  // input fields change to respective lesson plan fields

  const [lessonClass, setLessonClass] = useState("");
  const [subject, setSubject] = useState("");
  const [name, setName] = useState("");
  const [topic, setTopic] = useState("");
  const [subtopic, setSubtopic] = useState("");
  const [teacher, setTeacher] = useState("");
  const [details, setDetails] = useState("");
  const [formData, setFormData] = useState(null);
  const [imagePreviewUrl, setImagePreviewUrl] = useState(null);

  //import file hook
  const [file, setFile] = useState();

  // for pegination
  const [rowId, setRowId] = useState(null);
  const [pageSize, setPageSize] = useState(5);

  const [content, setContent] = useState("");

  let headersList = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };

  // change to respective lesson plan body
  let bodyContent = JSON.stringify({
    lesson_class: lessonClass,
    subject: subject,
    name: name,
    topic: topic,
    subtopic: subtopic,
    teacher: teacher,
    details: details,
  });

  //upload details

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append("logo", file);
    setFormData(formData);
    setImagePreviewUrl(URL.createObjectURL(file));
    // uploadImage(formData);
  };

  const uploadImage = () => {
    fetch(API_URLS.content, {
      method: "PATCH",
      body: formData,
    })
      .then((res) => {
        if (res.status === 200) {
          toast.success("Update Successful", toastSuccessStyle);
          Navigate("/admin");
        } else {
          toast.error("Update Error", toastDangerStyle);
          setTimeout(() => {
            toast(`Ensure Required Fields are not empty`, toastWarnigStyle);
          }, 1000);
        }
      })
      .catch((error) => {
        toast.error("Error updating details", toastDangerStyle);
      });
  };
  //

  //  This is a post request function depending on the submission of the form
  const handleSubmit = async (e) => {
    e.preventDefault();
    await fetch(API_URLS.lesson, {
      method: "POST",
      headers: headersList,
      body: bodyContent,
    })
      .then((res) => {
        if (res.status === 201) {
          setLessonClass("");
          setSubject("");
          setName("");
          setTopic("");
          setSubtopic("");
          setTeacher("");
          setDetails("");

          refetch();
          toast.success(`Successfully Created Lesson`, toastSuccessStyle);
        } else if (res.status === 400) {
          toast.error(`Error !!! ${res.statusText}`, toastDangerStyle);
          setTimeout(() => {
            toast(`Seems Lesson Already Exists`, toastWarnigStyle);
          }, 1000);
        } else {
          toast.error(
            `${res.status} Error !!! ${res.statusText}`,
            toastDangerStyle
          );
        }
      })
      .catch((err) => {
        toast.error(`Error, Not Found !!!`, toastDangerStyle);
      });
  };

  //import
  const handleFileSelection = (e) => {
    const selectedFile = e.target.files[0];
    if (
      selectedFile.type === "text/csv" ||
      selectedFile.type === "application/json" ||
      selectedFile.type === "application/vnd.ms-excel" ||
      selectedFile.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      setFile(selectedFile);
    } else {
      toast("Please select a CSV, JSON or Excel file.", toastWarnigStyle);
    }
  };

  const UploadFile = () => {
    const formData = new FormData();
    formData.append("file", file);

    fetch(API_URLS.upload_lesson, {
      method: "POST",
      body: formData,
    })
      .then((res) => {
        if (res.status === 200) {
          refetch();
          setFile(null);
          toast.success("Upload Successfull", toastSuccessStyle);
        }
      })
      .catch((error) => {
        toast.error(`Upload Error ${error}`, toastDangerStyle);
      });
  };

  //

  async function fetchLesson() {
    const { data } = await axios.get(API_URLS.lesson);
    return data;
  }
  const { data, error, isError, isLoading, refetch } = useQuery(
    "lesson",
    fetchLesson
  );
  if (isLoading) {
    return <div className="main-content">Loading...</div>;
  }
  if (isError) {
    return (
      <div className="main-content">
        <div>Error!! {error.message}</div>
      </div>
    );
  }
  const handleSelectedSubjChange = (item) => {
    setSubject(item.subject_name);
  };

  //editable
  const editableColumns = [
    {
      field: "id",
      headerName: "SL",
      headerAlign: "center",
      type: "number",
      width: "80",
    },
    {
      field: "lesson_class",
      headerName: "Lesson Class",
      headerAlign: "center",
      editable: true,
      width: 120,
    },
    {
      field: "subject",
      headerName: "Subject",
      headerAlign: "center",
      editable: true,
      width: 120,
    },

    {
      field: "name",
      headerName: "Name",
      headerAlign: "center",
      editable: true,
      width: 120,
    },
    {
      field: "topic",
      headerName: "Topic",
      headerAlign: "center",
      editable: true,
      width: 120,
    },
    {
      field: "subtopic",
      headerName: "Subtopic",
      headerAlign: "center",
      editable: true,
      width: 120,
    },
    {
      field: "teacher",
      headerName: "Teacher",
      headerAlign: "center",
      editable: true,
      width: 120,
    },
    {
      field: "details",
      headerName: "Details",
      headerAlign: "center",
      editable: true,
      width: 120,
    },

    {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      hide: true,
      renderCell: (params) => (
        <LessonActions {...{ params, rowId, setRowId }} />
      ),
    },
  ];

  const columns = [
    {
      field: "id",
      headerName: "SL",
      headerAlign: "center",
      type: "number",
      width: "80",
    },
    {
      field: "lesson_class",
      headerName: "Lesson Class",
      headerAlign: "center",
      width: 120,
    },
    {
      field: "subject",
      headerName: "Subject",
      headerAlign: "center",
      width: 120,
    },

    {
      field: "name",
      headerName: "Name",
      headerAlign: "center",
      width: 120,
    },
    {
      field: "topic",
      headerName: "Topic",
      headerAlign: "center",
      width: 120,
    },
    {
      field: "subtopic",
      headerName: "Subtopic",
      headerAlign: "center",
    },
    {
      field: "teacher",
      headerName: "Teacher",
      headerAlign: "center",
      width: 120,
    },
    {
      field: "details",
      headerName: "Details",
      headerAlign: "center",
      width: 120,
    },

    {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      hide: true,
      renderCell: (params) => (
        <LessonActions {...{ params, rowId, setRowId }} />
      ),
    },
  ];

  return (
    <div className="main-content">
      <div className="row">
        <div className="col-12 col-md-12 col-lg-12">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/admin">Home</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Lesson
              </li>
            </ol>
          </nav>
        </div>
        {/* copy */}
        {currentUser.roles.includes("ADD_LESSON") ||
        currentUser.roles.includes("SUPER_ADMIN") ? (
          <div className="col-md-6 col-lg-12 col-xl-4">
            <div className="shadow-sm p-3 mb-5 bg-white rounded">
              <div className="text-center text-dark">
                <h5>Add Lesson</h5>
              </div>
              <form onSubmit={handleSubmit}>
                <div className="card-body">
                  <div className="form-group">
                    <label htmlFor="sLessonClass">Lesson Class</label>
                    <ClassSearchSelectInput
                      onSelectedItemChange={(item) => {
                        setLessonClass(item.class_room_code);
                      }}
                      searchFields={["class_room_code", "class_room_name"]}
                    />
                    <p className="text-dark">Selected Class: {lessonClass}</p>
                  </div>
                  <div className="form-group">
                    <label htmlFor="sSubject">Subject</label>
                    <SubjectSearchSelectInput
                      onSelectedItemChange={handleSelectedSubjChange}
                      searchFields={["subject_name", "subject_code"]}
                    />
                    <p className="text-dark">Subject: {subject}</p>
                  </div>
                  <div className="form-group">
                    <label htmlFor="sName">Name</label>
                    <input
                      type="text"
                      id="sName"
                      className="form-control"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="sTopic">Topic</label>
                    <TopicSearchSelectInput
                      onSelectedItemChange={(item) => {
                        setTopic(item.id);
                      }}
                      searchFields={["name"]}
                    />
                    <p className="text-dark">Selected Topic: {topic}</p>
                  </div>
                  <div className="form-group">
                    <label htmlFor="sSubtopic">Subtopic</label>
                    <SubtopicSearchSelectInput
                      onSelectedItemChange={(item) => {
                        setSubtopic(item.id);
                      }}
                      searchFields={["name"]}
                    />
                    <p className="text-dark">Selected Subtopic: {subtopic}</p>
                  </div>
                  <div className="form-group">
                    <label htmlFor="sTeacher">Teacher</label>
                    <input
                      type="text"
                      id="sTeacher"
                      className="form-control"
                      value={teacher}
                      onChange={(e) => setTeacher(e.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="sDetails">Details</label>
                    <input
                      type="text"
                      id="sDetails"
                      className="form-control"
                      value={details}
                      onChange={(e) => setDetails(e.target.value)}
                    />
                  </div>
                  <div className="form-group d-flex">
                    <div className="col-6 d-flex flex-column">
                      <input type="file" onChange={handleFileSelect} />
                      <img
                        src={imagePreviewUrl}
                        alt="Preview Logo"
                        style={{ width: "60%" }}
                      />
                      <button
                        className="btn btn-info align-self-end"
                        style={{ width: "fit-content" }}
                        onClick={uploadImage}
                      >
                        Update New Logo
                      </button>
                      <Toaster position="top-right" reverseOrder={false} />
                    </div>
                  </div>
                </div>
                <div className="text-center">
                  <button className="btn btn-primary mr-1" type="submit">
                    Add New Lesson
                  </button>
                  <Toaster position="top-right" reverseOrder={false} />
                </div>
              </form>
            </div>
          </div>
        ) : (
          <></>
        )}

        <div className="col-md-6 col-lg-12 col-xl-8">
          <div className="shadow-sm p-3 mb-5 bg-white rounded">
            <div className="text-center">
              <h5 className="text-center text-dark">Lessons List</h5>

              {/* COPY */}
              {/* IMPORT */}
              {currentUser.roles.includes("UPLOAD_LESSON") ||
              currentUser.roles.includes("SUPER_ADMIN") ? (
                <div className="d-flex justify-content-end">
                  <div className="d-flex m-1">
                    <Button variant="text" component="label">
                      Import
                      <input
                        hidden
                        type={"file"}
                        id={"csvFileInput"}
                        accept=".csv,.json,.xlsx,application/vnd.ms-excel"
                        onChange={handleFileSelection}
                      />
                    </Button>
                    {file && (
                      <div className="text-info align-self-center">
                        File: {file.name}
                      </div>
                    )}
                  </div>
                  <Button
                    size="small"
                    variant="contained"
                    endIcon={<Send />}
                    onClick={UploadFile}
                  >
                    Submit
                  </Button>
                </div>
              ) : (
                <></>
              )}
            </div>

            <div style={{ width: "100%" }}>
              <DataGrid
                autoHeight
                columns={
                  currentUser.roles.includes("UPDATE_LESSON") ||
                  currentUser.roles.includes("SUPER_ADMIN")
                    ? editableColumns
                    : columns
                }
                components={{
                  Toolbar: GridToolbar,
                  Pagination: CustomPagination,
                }}
                rows={data}
                getRowId={(row) => row.id}
                rowsPerPageOptions={[5, 10, 20]}
                rowHeight={45}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                getRowSpacing={(params) => ({
                  top: params.isFirstVisible ? 0 : 5,
                  bottom: params.isLastVisible ? 0 : 5,
                })}
                onCellEditCommit={(params) => setRowId(params.id)}
              />
            </div>
          </div>
        </div>

        <div className="col-12">
          <div className="shadow-sm p-3 mb-5 bg-white rounded">
            <ReactQuill theme="snow" value={content} onChange={setContent} />
          </div>
        </div>
      </div>
    </div>
  );
}
