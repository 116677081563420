import React from "react";
import { MailOutlineOutlined } from "@mui/icons-material";
import { Button } from "@mui/material";

export default function OtherDetailsForm(props) {
  const Continue = (e) => {
    e.preventDefault();
    props.nextStep();
  };
  const Back = (e) => {
    e.preventDefault();
    props.prevStep();
  };
  const { values, handleChange } = props;

  return (
    <>
      <div>
        <h5 className="text-center text-dark">Other Information</h5>
      </div>
      <div className="col-12 col-md-12 col-lg-12">
        <div className="d-flex flex-wrap" style={{ minHeight: 350 }}>
          <div className="form-group col-4">
            <label>
              Service Number<span className="text-danger"> *</span>
            </label>
            <input
              type="text"
              className="form-control"
              onChange={handleChange("service_no")}
              value={values.service_no}
            />
          </div>
          <div className="form-group col-4">
            <label>Religion</label>
            <select
              className="form-control"
              onChange={handleChange("religion")}
              value={values.religion}
            >
              <option value="" disabled>
                Select Religion
              </option>
              <option value="christian">Christian</option>
              <option value="muslim">Muslim</option>
              <option value="hindu">Hindu</option>
              <option value="prefer not to say">Prefer not to say</option>
            </select>
          </div>
          <div className="form-group col-4">
            <label>Marital Status</label>
            <select
              className="form-control"
              onChange={handleChange("marital_status")}
              value={values.marital_status}
            >
              <option value="" disabled>
                Select Marital Status
              </option>
              <option value="Single">Single</option>
              <option value="Married">Married</option>
              <option value="prefer not to say">Prefer not to say</option>
            </select>
          </div>
          <div className="form-group col-4">
            <label>Driving License No</label>
            <input
              type="text"
              className="form-control"
              onChange={handleChange("driving_license")}
              value={values.driving_license}
            />
          </div>
          <div className="form-group col-4">
            <label>Current Address</label>
            <input
              type="text"
              className="form-control"
              onChange={handleChange("current_address")}
              value={values.current_address}
            />
          </div>
          <div className="form-group col-4">
            <label>Permanent Address</label>
            <input
              type="text"
              className="form-control "
              onChange={handleChange("permanent_address")}
              value={values.permanent_address}
            />
          </div>
          <div className="form-group col-4">
            <label>Height</label>
            <input
              type="number"
              className="form-control"
              onChange={handleChange("height")}
              value={values.height}
            />
          </div>
          <div className="form-group col-4">
            <label>Weight</label>
            <input
              type="number"
              className="form-control"
              onChange={handleChange("weight")}
              value={values.weight}
            />
          </div>

          <div className="form-group col-4">
            <label>County</label>
            <input
              type="text"
              className="form-control"
              onChange={handleChange("home_county")}
              value={values.home_county}
            />
          </div>
          <div className="form-group col-4">
            <label>Sub County</label>
            <input
              type="text"
              className="form-control"
              onChange={handleChange("home_sub_county")}
              value={values.home_sub_county}
            />
          </div>
          <div className="form-group col-4">
            <label>Ward</label>
            <input
              type="text"
              className="form-control"
              onChange={handleChange("home_ward")}
              value={values.home_ward}
            />
          </div>
          <div className="form-group col-4">
            <label>Location</label>
            <input
              type="text"
              className="form-control"
              onChange={handleChange("home_location")}
              value={values.home_location}
            />
          </div>
          <div className="form-group col-4">
            <label>Postal Code</label>
            <input
              type="text"
              className="form-control"
              onChange={handleChange("postal_code")}
              value={values.postal_code}
            />
          </div>
          <div className="form-group col-4">
            <label>Zip Code</label>
            <input
              type="text"
              className="form-control"
              onChange={handleChange("zip_code")}
              value={values.zip_code}
            />
          </div>
          <div className="form-group col-4">
            <label>Town</label>
            <input
              type="text"
              className="form-control"
              onChange={handleChange("postal_location")}
              value={values.postal_location}
            />
          </div>
        </div>
        <div className="col-12 d-flex justify-content-between">
          <Button color="primary" variant="contained" onClick={Back}>
            Back
          </Button>
          <Button color="primary" variant="contained" onClick={Continue}>
            Next
          </Button>
        </div>
      </div>
    </>
  );
}
