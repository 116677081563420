import { Send } from "@mui/icons-material";
import { Button } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import axios from "axios";
import React, { useState } from "react";
import { toast, Toaster } from "react-hot-toast";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import {
  toastDangerStyle,
  toastSuccessStyle,
  toastWarnigStyle,
} from "../../../components/shared/Custom.Styles";
import ExamSetupActions from "./ExamSetupActions";
import { CustomPagination } from "../../../components/custom/Custom.DataGrid";
import { API_URLS } from "../../../utils/api.routes";
import {
  ExamtypeSearchSelectInput,
  GradeSearchSelectInput,
  SubjectSearchSelectInput,
  TermSearchSelectInput,
} from "../../../components/custom/Custom.SearchInput";
import AccessDenied from "../../../components/custom/Custom.AccessDenied";
import authService from "../../../services/auth.service";
import authHeader from "../../../services/auth-header";

export default function ExamSetup() {
  const currentUser = authService.getCurrentUser();
  const [type, setType] = useState("");
  const [grade, setGrade] = useState("");
  const [term, setTerm] = useState("");
  const [subject, setSubject] = useState("");
  const [marks, setMarks] = useState("");

  const [rowId, setRowId] = useState(null);
  const [pageSize, setPageSize] = useState(10);

  //import file hook
  const [file, setFile] = useState();

  let bodyContent = JSON.stringify({
    type: type,
    grade: grade,
    term: term,
    subject: subject,
    marks: marks,
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    await fetch(API_URLS.exam_setup, {
      method: "POST",
      headers: authHeader(),
      body: bodyContent,
    })
      .then((res) => {
        if (res.status === 201) {
          setType("");
          setGrade("");
          setTerm("");
          setSubject("");
          setMarks("");

          refetch();
          toast.success(
            `Successfully Created Exam Schedule`,
            toastSuccessStyle
          );
        } else if (res.status === 400) {
          toast.error(`Error !!! ${res.statusText}`, toastDangerStyle);
          setTimeout(() => {
            toast(`Seems Exam Schedule Already Exists`, toastWarnigStyle);
          }, 1000);
        } else {
          toast.error(
            `${res.status} Error !!! ${res.statusText}`,
            toastDangerStyle
          );
        }
      })
      .catch((err) => {
        toast.error(`Error, Not Found !!!`, toastDangerStyle);
      });
  };

  //import
  const handleFileSelection = (e) => {
    const selectedFile = e.target.files[0];
    if (
      selectedFile.type === "text/csv" ||
      selectedFile.type === "application/json" ||
      selectedFile.type === "application/vnd.ms-excel" ||
      selectedFile.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      setFile(selectedFile);
    } else {
      toast("Please select a CSV, JSON or Excel file.", toastWarnigStyle);
    }
  };

  const UploadFile = () => {
    const formData = new FormData();
    formData.append("file", file);

    fetch(API_URLS.upload_examschedule, {
      method: "POST",
      body: formData,
    })
      .then((res) => {
        if (res.status === 200) {
          refetch();
          setFile(null);
          toast.success("Upload Successful", toastSuccessStyle);
        }
      })
      .catch((error) => {
        toast.error(`Upload Error ${error}`, toastDangerStyle);
      });
  };

  //

  const fetchExamSetup = async () => {
    const { data } = await axios.get(API_URLS.exam_setup);
    return data;
  };
  const { data, error, isError, isLoading, refetch } = useQuery(
    "exam_type",
    fetchExamSetup
  );

  if (isLoading) {
    return <div>Loading...</div>;
  }
  if (isError) {
    return <div>Error!! {error.message}</div>;
  }

  const editableColumns = [
    {
      field: "id",
      headerName: "SL",
      headerAlign: "center",
      type: "number",
      width: "80",
    },

    {
      field: "type_name",
      headerName: "Type",
      headerAlign: "center",
      width: 150,
    },
    {
      field: "grade",
      headerName: "Grade",
      headerAlign: "center",
      width: 150,
    },

    {
      field: "term",
      headerName: "Term",
      headerAlign: "center",
      width: 150,
    },

    {
      field: "subject",
      headerName: "Subject",
      headerAlign: "center",
    },

    {
      field: "marks",
      headerName: "Marks",
      headerAlign: "center",
      width: 80,
    },

    {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      width: 150,
      renderCell: (params) => (
        <ExamSetupActions {...{ params, rowId, setRowId, refetch }} />
      ),
    },
  ];
  const columns = [
    {
      field: "id",
      headerName: "SL",
      headerAlign: "center",
      type: "number",
      width: "80",
    },

    {
      field: "type_name",
      headerName: "Type",
      headerAlign: "center",
      width: 150,
    },
    {
      field: "grade",
      headerName: "Grade",
      headerAlign: "center",
      width: 150,
    },

    {
      field: "term",
      headerName: "Term",
      headerAlign: "center",
      width: 150,
    },

    {
      field: "subject",
      headerName: "Subject",
      headerAlign: "center",
    },

    {
      field: "marks",
      headerName: "Marks",
      headerAlign: "center",
      width: 80,
    },

    {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      width: 150,
      renderCell: (params) => (
        <ExamSetupActions {...{ params, rowId, setRowId, refetch }} />
      ),
    },
  ];
  if (
    !currentUser.roles.includes("VIEW_EXAM_SETUP") &&
    !currentUser.roles.includes("SUPER_ADMIN")
  ) {
    return <AccessDenied />;
  }
  return (
    <div className="main-content">
      <div className="row">
        <div className="col-12 col-md-12 col-lg-12">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link className="font-weight-bold" to="/admin">
                  Home
                </Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                <span className="font-weight-bold">Exam Setup</span>
              </li>
            </ol>
          </nav>
        </div>
        {currentUser.roles.includes("ADD_EXAM_SETUP") ||
        currentUser.roles.includes("SUPER_ADMIN") ? (
          <div className="col-md-12 col-lg-12 col-xl-12">
            <div className="shadow-sm p-3 mb-5 bg-white rounded">
              <form onSubmit={handleSubmit}>
                <h5 className="text-center text-dark">Add Exam Setup</h5>
                <div className="card-body">
                  <div className="d-flex flex-wrap">
                    <div className="form-group mr-2">
                      <label htmlFor="sExamtype">Exam Type</label>
                      <ExamtypeSearchSelectInput
                        onSelectedItemChange={(item) => {
                          setType(item.id);
                        }}
                        searchFields={["name"]}
                      />
                      zz
                      <p className="text-dark">Selected Exam Type: {type}</p>
                    </div>
                    <div className="form-group mr-2">
                      <label htmlFor="sGrade">Grade</label>
                      <GradeSearchSelectInput
                        onSelectedItemChange={(item) => {
                          setGrade(item.code);
                        }}
                        searchFields={["code", "name"]}
                      />
                      <p className="text-dark">Selected Grade: {grade}</p>
                    </div>
                    <div className="form-group mr-2">
                      <label htmlFor="sTerm">Term</label>
                      <TermSearchSelectInput
                        onSelectedItemChange={(item) => {
                          setTerm(item.name);
                        }}
                        searchFields={["name"]}
                      />
                      <p className="text-dark">Selected Term: {term}</p>
                    </div>
                    <div className="form-group mr-2">
                      <label htmlFor="sSubject">Subject</label>
                      <SubjectSearchSelectInput
                        onSelectedItemChange={(item) => {
                          setSubject(item.subject_name);
                        }}
                        searchFields={["subject_name", "subject_code"]}
                      />
                      <p className="text-dark">Subject: {subject}</p>
                    </div>
                    <div className="form-group mr-2">
                      <label htmlFor="sMarks">Marks</label>
                      <input
                        type="text"
                        id="sMarks"
                        className="form-control"
                        value={marks}
                        onChange={(e) => setMarks(e.target.value)}
                      />
                    </div>
                    <div className="form-group col-3">
                      <button className="btn btn-primary mr-1" type="submit">
                        Add Exam
                      </button>
                    </div>
                  </div>
                </div>
                <div className="form-group d-flex justify-content-around">
                  <Toaster position="top-right" reverseOrder={false} />
                </div>
              </form>
            </div>
          </div>
        ) : (
          <></>
        )}

        <div className="col-md-12 col-lg-12 col-xl-12">
          <div className="shadow-sm p-3 mb-5 bg-white rounded">
            <h5 className="text-center text-dark">Exam List</h5>
            {currentUser.roles.includes("UPLOAD_EXAM_SETUP") ||
            currentUser.roles.includes("SUPER_ADMIN") ? (
              <div className="d-flex justify-content-end">
                <div className="d-flex m-1">
                  <Button variant="text" component="label">
                    Import
                    <input
                      hidden
                      type={"file"}
                      id={"csvFileInput"}
                      accept=".csv,.json,.xlsx,application/vnd.ms-excel"
                      onChange={handleFileSelection}
                    />
                  </Button>
                  {file && (
                    <div className="text-info align-self-center">
                      File: {file.name}
                    </div>
                  )}
                </div>
                <Button
                  size="small"
                  variant="contained"
                  endIcon={<Send />}
                  onClick={UploadFile}
                >
                  Submit
                </Button>
              </div>
            ) : (
              <></>
            )}
            <div style={{ width: "100%" }}>
              <DataGrid
                autoHeight
                columns={
                  currentUser.roles.includes("UPDATE_EXAM_SETUP") ||
                  currentUser.roles.includes("SUPER_ADMIN")
                    ? editableColumns
                    : columns
                }
                rows={data}
                components={{
                  Toolbar: GridToolbar,
                  Pagination: CustomPagination,
                }}
                getRowId={(row) => row.id}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                getRowSpacing={(params) => ({
                  top: params.isFirstVisible ? 0 : 5,
                  bottom: params.isLastVisible ? 0 : 5,
                })}
                onCellEditCommit={(params) => setRowId(params.id)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
