import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import axios from "axios";
import React, { useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { Link } from "react-router-dom";
import { useQuery } from "react-query";
import { API_URLS } from "../../utils/api.routes";
import {
  toastDangerStyle,
  toastSuccessStyle,
  toastWarnigStyle,
} from "../../components/shared/Custom.Styles";
import TimetableActions from "./TimetableActions";
import { CustomPagination } from "../../components/custom/Custom.DataGrid";
import { Button } from "@mui/material";
import { Send } from "@mui/icons-material";
import {
  ClassSearchSelectInput,
  SubjectSearchSelectInput,
  TermSearchSelectInput,
  TimeplanSearchSelectInput,
} from "../../components/custom/Custom.SearchInput";
import { useEffect } from "react";


export default function Timetable() {
  // input fields change to respective lesson plan fields

  const [time, setTime] = useState("");
  const [grade, setGrade] = useState("");
  const [term, setTerm] = useState("");
  const [teacher, setTeacher] = useState("");
  const [subject, setSubject] = useState("");
  const [venue, setVenue] = useState("");

  const [timeplan, setTimeplan] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [classes, setClasses] = useState([]);

  useEffect(() => {
    const fetchTimeplan = async () => {
      const res = await fetch(API_URLS.timeplan);
      const data = await res.json();
      setTimeplan(data);
    };
    const fetchSubjects = async () => {
      const res = await fetch(API_URLS.subjects);
      const data = await res.json();
      setSubjects(data);
    };
    const fetchClasses = async () => {
      const res = await fetch(API_URLS.class_room);
      const data = await res.json();
      setClasses(data);
    };
    fetchTimeplan();
    fetchSubjects();
    fetchClasses();
  }, []);

  //import file hook
  const [file, setFile] = useState();

  // for pegination
  const [rowId, setRowId] = useState(null);
  const [pageSize, setPageSize] = useState(5);

  const [selectedData, setSelectedData] = useState(null);

  const handleSelectChange = (e) => {
    const selectedRow = e.target.value;
    setSelectedData(selectedRow);
    // You can also call an API here to post the selected data
    // using the selectedRow variable
  };

  let headersList = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };

  // change to respective lesson plan body
  let bodyContent = JSON.stringify({
    time: time,
    grade: grade,
    term: term,
    teacher: teacher,
    subject: subject,
    venue: venue,
  });
  const handleSubmit = async (e) => {
    e.preventDefault();
    await fetch(API_URLS.timetable, {
      method: "POST",
      headers: headersList,
      body: bodyContent,
    })
      .then((res) => {
        if (res.status === 201) {
          setTime("");
          setGrade("");
          setTerm("");
          setTeacher("");
          setSubject("");
          setVenue("");
          refetch();
          toast.success(`Successfully Created `, toastSuccessStyle);
        } else if (res.status === 400) {
          toast.error(`Error !!! ${res.statusText}`, toastDangerStyle);
          setTimeout(() => {
            toast(`Seems Timetable Already Exists`, toastWarnigStyle);
          }, 1000);
        } else {
          toast.error(
            `${res.status} Error !!! ${res.statusText}`,
            toastDangerStyle
          );
        }
      })
      .catch((err) => {
        toast.error(`Error, Not Found !!!`, toastDangerStyle);
      });
  };

  //import
  const handleFileSelection = (e) => {
    const selectedFile = e.target.files[0];
    if (
      selectedFile.type === "text/csv" ||
      selectedFile.type === "application/json" ||
      selectedFile.type === "application/vnd.ms-excel" ||
      selectedFile.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      setFile(selectedFile);
    } else {
      toast("Please select a CSV, JSON or Excel file.", toastWarnigStyle);
    }
  };

  const UploadFile = () => {
    const formData = new FormData();
    formData.append("file", file);

    fetch(API_URLS.upload_lesson, {
      method: "POST",
      body: formData,
    })
      .then((res) => {
        if (res.status === 200) {
          refetch();
          setFile(null);
          toast.success("Upload Successfull", toastSuccessStyle);
        }
      })
      .catch((error) => {
        toast.error(`Upload Error ${error}`, toastDangerStyle);
      });
  };

  //

  async function fetchTimetable() {
    const { data } = await axios.get(API_URLS.timetable);
    return data;
  }
  const { data, error, isError, isLoading, refetch } = useQuery(
    "timetable",
    fetchTimetable
  );
  if (isLoading) {
    return <div className="main-content">Loading...</div>;
  }
  if (isError) {
    return (
      <div className="main-content">
        <div>Error!! {error.message}</div>
      </div>
    );
  }
  console.log(data);

  const columns = [
    {
      field: "id",
      headerName: "SL",
      headerAlign: "center",
      type: "number",
      width: "80",
    },

    {
      field: "time",
      headerName: "Time",
      headerAlign: "center",
      editable: true,
      width: 120,
    },
    {
      field: "grade",
      headerName: "Grade",
      headerAlign: "center",
      editable: true,
      width: 120,
    },
    {
      field: "term",
      headerName: "Term",
      headerAlign: "center",
      editable: true,
      width: 120,
    },
    {
      field: "teacher",
      headerName: "Teacher",
      headerAlign: "center",
      editable: true,
      width: 120,
    },
    {
      field: "subject",
      headerName: "Subject",
      headerAlign: "center",
      editable: true,
      width: 120,
    },
    {
      field: "venue",
      headerName: "Venue",
      headerAlign: "center",
      editable: true,
      width: 120,
    },
    {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      renderCell: (params) => (
        <TimetableActions {...{ params, rowId, setRowId }} />
      ),
    },
  ];

  return (
    <div className="main-content">
      <div className="row">
        <div className="col-12 col-md-12 col-lg-12">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/admin">Home</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Timetable
              </li>
            </ol>
          </nav>
        </div>
        {/* <div className="col-12">
          <table>
            <thead>
              <tr>
                {timeplan.map((t, index) => {
                  return <th>{t.name}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              <tr>
                {timeplan.map((tp, index) => {
                  return (
                    <td key={index}>
                      <select
                        value={selectedData}
                        onChange={handleSelectChange}
                      >
                        <option value="">Subject</option>
                        {subjects.map((sub, index) => {
                          return (
                            <option value={sub.subject_name}>
                              {sub.subject_name}
                            </option>
                          );
                        })}
                      </select>
                      <select
                        value={selectedData}
                        onChange={handleSelectChange}
                      >
                        <option value="">Class</option>
                        {classes.map((cl, index) => {
                          return (
                            <option value={cl.class_room_name}>
                              {cl.class_room_name}
                            </option>
                          );
                        })}
                      </select>
                    </td>
                  );
                })}
              </tr>
            </tbody>
          </table>
        </div> */}
        <div className="col-md-6 col-lg-12 col-xl-4">
          <div className="shadow-sm p-3 mb-5 bg-white rounded">
            <div className="text-center text-dark">
              <h5>Add Timetable</h5>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="card-body">
                <div className="form-group">
                  <label htmlFor="sTimeplan">Time Plan</label>
                  <TimeplanSearchSelectInput
                    onSelectedItemChange={(item) => {
                      setTime(item.id);
                    }}
                    searchFields={["name"]}
                  />
                  <p className="text-dark">Selected Time: {grade}</p>
                </div>
                <div className="form-group">
                  <label htmlFor="sLessonClass">Class</label>
                  <ClassSearchSelectInput
                    onSelectedItemChange={(item) => {
                      setGrade(item.class_room_name);
                    }}
                    searchFields={["class_room_code", "class_room_name"]}
                  />
                  <p className="text-dark">Selected Class: {grade}</p>
                </div>
                <div className="form-group">
                  <label htmlFor="sTerm">Term</label>
                  <TermSearchSelectInput
                    onSelectedItemChange={(item) => {
                      setTerm(item.id);
                    }}
                    searchFields={["name"]}
                  />
                  <p className="text-dark">Term: {term}</p>
                </div>

                <div className="form-group">
                  <label htmlFor="sTeacher">Teacher</label>
                  <input
                    type="text"
                    id="sTeacher"
                    className="form-control"
                    value={teacher}
                    onChange={(e) => setTeacher(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="sSubject">Subject</label>
                  <SubjectSearchSelectInput
                    onSelectedItemChange={(item) => {
                      setSubject(item.id);
                    }}
                    searchFields={["subject_name", "subject_code"]}
                  />
                  <p className="text-dark">Subject: {subject}</p>
                </div>

                <div className="form-group">
                  <label htmlFor="sVenue">Venue</label>
                  <input
                    type="text"
                    id="sVenue"
                    className="form-control"
                    value={venue}
                    onChange={(e) => setVenue(e.target.value)}
                  />
                </div>
              </div>
              <div className="text-center">
                <button className="btn btn-primary mr-1" type="submit">
                  Add New Timetable
                </button>
                <Toaster position="top-right" reverseOrder={false} />
              </div>
            </form>
          </div>
        </div>

        <div className="col-md-6 col-lg-12 col-xl-8">
          <div className="shadow-sm p-3 mb-5 bg-white rounded">
            <div className="text-center">
              <h5 className="text-center text-dark">Timetables List</h5>

              <div className="d-flex justify-content-end">
                <div className="d-flex m-1">
                  <Button variant="text" component="label">
                    Import
                    <input
                      hidden
                      type={"file"}
                      id={"csvFileInput"}
                      accept=".csv,.json,.xlsx,application/vnd.ms-excel"
                      onChange={handleFileSelection}
                    />
                  </Button>
                  {file && (
                    <div className="text-info align-self-center">
                      File: {file.name}
                    </div>
                  )}
                </div>
                <Button
                  size="small"
                  variant="contained"
                  endIcon={<Send />}
                  onClick={UploadFile}
                >
                  Submit
                </Button>
              </div>
            </div>
            <div style={{ height: 420 }}>
              <DataGrid
                columns={columns}
                components={{
                  Toolbar: GridToolbar,
                  Pagination: CustomPagination,
                }}
                rows={data}
                getRowId={(row) => row.id}
                rowsPerPageOptions={[5, 10, 20]}
                rowHeight={45}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                getRowSpacing={(params) => ({
                  top: params.isFirstVisible ? 0 : 5,
                  bottom: params.isLastVisible ? 0 : 5,
                })}
                onCellEditCommit={(params) => setRowId(params.id)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    
  );
  
}
