import React from "react";
import { PointOfSaleOutlined } from "@mui/icons-material";
import { Link } from "react-router-dom";

export default function Fee() {
  return (
    <>
      <li className="dropdown">
        <Link to="#" className="menu-toggle nav-link has-dropdown">
          <PointOfSaleOutlined fontSize="medium" className="icon-mr" />
          <span>Fees</span>
        </Link>
        <ul className="dropdown-menu">
          <li>
            <Link className="nav-link" to="/admin/fees/Feesgroup">
              Fees Group
            </Link>
          </li>
          <li>
            <Link className="nav-link" to="/admin/fees/Feesitems">
              Fees Type
            </Link>
          </li>
          <li>
            <Link className="nav-link" to="/admin/fees/invoices">
              Fees Invoice
            </Link>
          </li>
          <li>
            <Link className="nav-link" to="/admin/fees/payments">
              Fees Payments
            </Link>
          </li>

          <li>
            <Link className="nav-link" to="/admin/fees/Unpaidinvoices">
              Unpaid invoices
            </Link>
          </li>
        </ul>
      </li>
    </>
  );
}
