import axios from "axios";
import React from "react";
import { useQuery } from "react-query";
import { Link, useLocation } from "react-router-dom";
import { API_URLS } from "../../../utils/api.routes";
import authService from "../../../services/auth.service";
import {
  CustomIsError,
  CustomIsLoading,
} from "../../../components/custom/Custom.UseQuery";
import AccessDenied from "../../../components/custom/Custom.AccessDenied";

export default function Marksgradedetails() {
  const currentUser = authService.getCurrentUser();
  const { state } = useLocation();
  const fetchMarksgradeDetails = async () => {
    const { data } = await axios.get(
      API_URLS.exam_marksgrade.concat(state, "/")
    );
    return data;
  };
  const { data, error, isError, isLoading } = useQuery(
    "exam_marksgrade",
    fetchMarksgradeDetails
  );
  if (isLoading) {
    return <CustomIsLoading />;
  }
  if (isError) {
    return <CustomIsError message={error.message} />;
  }
  if (
    !currentUser.roles.includes("VIEW_EXAM_MARKS_GRADE") &&
    !currentUser.roles.includes("SUPER_ADMIN")
  ) {
    return <AccessDenied />;
  }
  return (
    <div className="main-content">
      <div className="col-12 col-md-12 col-lg-12">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/admin">Home</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/admin/exam-marks-grade/list">Marks Grade</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Marks Grade Details
            </li>
          </ol>
        </nav>
      </div>
      <div className="col-8">
        <div className="card shadow-sm p-3 mb-5 bg-white rounded">
          <h3 className="text-center text-info">Marks Grade Details</h3>
          <div className="text-dark">ID: {data.id}</div>
          <div className="text-dark">Grade: {data.grade}</div>
          <div className="text-dark">GPA: {data.GPA}</div>
          <div className="text-dark">Percent From: {data.percent_from}</div>
          <div className="text-dark">Percent To: {data.percent_to}</div>
          <div className="text-dark">GPA From: {data.GPA_from}</div>
          <div className="text-dark">GPA To: {data.GPA_to}</div>
          <div className="text-dark">Description: {data.description}</div>
        </div>
      </div>
    </div>
  );
}
