import StudentCategoryEdit from "../pages/students/category/StudentCategoryEdit";
import StudentCategoryDetails from "../pages/students/category/StudentCategoryDetails";
import RegisterNewStudent from "../pages/students/stud/RegisterNewStudent";
import EditStudent from "../pages/students/stud/EditStudent";
import StudentDetails from "../pages/students/stud/StudentDetails";
import StudentCategory from "../pages/students/category/StudentCategory";
import StudentsList from "../pages/students/stud/StudentsList";
import ParentsList from "../pages/students/parent/ParentsList";
import RegisterStudentParent from "../pages/students/parent/RegisterStudentParent";
import ParentEdit from "../pages/students/parent/ParentEdit";
import ParentDetails from "../pages/students/parent/ParentDetails";
import ClassStream from "../pages/academics/stream/ClassStream";
import StreamDetails from "../pages/academics/stream/StreamDetails";
import StreamEdit from "../pages/academics/stream/StreamEdit";
import ClassRoom from "../pages/academics/classroom/ClassRoom";
import ClassRoomAdd from "../pages/academics/classroom/ClassRoomAdd";
import ClassRoomEdit from "../pages/academics/classroom/ClassRoomEdit";
import ClassRoomDetails from "../pages/academics/classroom/ClassRoomDetails";
import SubjectTaught from "../pages/academics/subjects/taught/SubjectTaught";
import SubjectTaughtEdit from "../pages/academics/subjects/SubjectTaughtEdit";
import SubjectTaughtDetails from "../pages/academics/subjects/SubjectTaughtDetails";
import SubjectTeacher from "../pages/academics/subjects/SubjectTeacher";
import SubjectPerGrade from "../pages/academics/subjects/perclass/SubjectPerGrade";
import StudentClassAllocation from "../pages/academics/studAllocation/StudentClassAllocation";
import StudentClassAllocationEdit from "../pages/academics/studAllocation/StudentClassAllocationEdit";
import StudentClassAllocationDetails from "../pages/academics/studAllocation/StudentClassAllocationDetails";
import AcademicYear from "../pages/academics/year/AcademicYear";
import AcademicYearEdit from "../pages/academics/year/AcademicYearEdit";
import AcademicYearDetails from "../pages/academics/year/AcademicYearDetails";
import ExamType from "../pages/exams/type/ExamType";
import ExamSetup from "../pages/exams/setup/ExamSetup";
import ExamSetupEdit from "../pages/exams/setup/ExamSetupEdit";
import ExamSchedule from "../pages/exams/schedule/ExamSchedule";
import ExamScheduleEdit from "../pages/exams/schedule/ExamScheduleEdit";
import ExamAttendance from "../pages/exams/attendance/ExamAttendance";
import MarksGrade from "../pages/exams/grade/MarksGrade";
import MarksRegister from "../pages/exams/register/MarksRegister";
import InitialFilters from "../pages/academics/InitialFilters";
import FullFeaturedCrudGrid from "../pages/academics/FullFeaturedCrudGrid";
import Department from "../pages/hr/department/Department";
import Designation from "../pages/hr/designation/Designation";
import AddStaff from "../pages/hr/staff/AddStaff";
import ParentRegistration from "../pages/students/parent/ParentRegistration";
//Library
import Lostbooks from "../pages/library/Lostbooks";
import Addbook from "../pages/library/Addbook";
import Addmember from "../pages/library/Addmember";
import Allissuedbooks from "../pages/library/Allissuedbooks";
import Bookcategory from "../pages/library/Bookcategory";
import Booklist from "../pages/library/Booklist";
import Booksubjects from "../pages/library/Booksubjects";
import Issuereturnbooks from "../pages/library/Issuereturnbooks";
import Payments from "../pages/fees/payments";
import Groups from "../pages/fees/Feesgroup";
import Items from "../pages/fees/Feesitems";
import Bank from "../pages/account/Bank";
import Expense from "../pages/account/Expense";
import Income from "../pages/account/Income";
import Profitloss from "../pages/account/profitloss";
import Transfers from "../pages/account/Transfers";
import Unpaidinvoices from "../pages/fees/Unpaidinvoices";
import StudentForm from "../pages/students/stud/StudentForm";
import Grade from "../pages/academics/grade/Grade";
import StaffForm from "../pages/hr/staff/StaffForm";
import StaffList from "../pages/hr/staff/StaffList";
import StaffDetails from "../pages/hr/staff/StaffDetails";
import EditStaffDetails from "../pages/hr/staff/EditStaffDetails";
import VehicleRoutes from "../pages/transport/vehicleroutes/VehicleRoutes";
import Vehicles from "../pages/transport/vehicles/Vehicles";
import Dormitory from "../pages/dormitory/Dormitory";
import Bed from "../pages/dormitory/Bed";
import DormitoryRooms from "../pages/dormitory/DormitoryRooms";
import Invoices from "../pages/fees/invoices";
import AcademicTerm from "../pages/academics/term/AcademicTerm";
import DatagridWithEdit from "../pages/academics/DataGridWithEdit";
import Topic from "../pages/lessonplan/Topic";
import Subtopic from "../pages/lessonplan/Subtopic";
import Lesson from "../pages/lessonplan/Lesson";
import Schemesofwork from "../pages/lessonplan/Schemesofwork";
import Timetable from "../pages/lessonplan/Timetable";
import Timeplan from "../pages/lessonplan/Timeplan";
import Assessmentrubrics from "../pages/lessonplan/Assessmentrubrics";
import Diary from "../pages/lessonplan/Diary";
import PageSettings from "../pages/system/PageSettings";
import School from "../pages/academics/school/School";
import Assignments from "../pages/assignments/Assignments";
import Quizzes from "../pages/quizzes/Quizzes";
import Issueretunbooks from "../pages/library/Issuereturnbooks";
import Ebooks from "../pages/library/Ebooks";
import SchoolInfo from "../pages/system/school/SchoolInfo";
import AssignPermissions from "../pages/roles/AssignPermissions";
import Assignment from "../pages/studymaterials/Assignment";
import UploadContent from "../pages/studymaterials/UploadContent";
import Syllabus from "../pages/studymaterials/Syllabus";
import OtherDownloads from "../pages/studymaterials/OtherDownloads";
import StudentProfile from "../pages/students/stud/profile/StudentProfile";
import StudentTransportReport from "../pages/transport/StudentTransportReport";
import ExamReport from "../pages/exams/student/ExamReport";
import UserDetailsPage from "../pages/roles/UserDetailsPage";
import Assessmentcomments from "../pages/lessonplan/Assessmentcomments";
import Diarycomments from "../pages/lessonplan/Diarycomments";
import Topicdetails from "../pages/lessonplan/Topicdetails";
import Lessondetails from "../pages/lessonplan/Lessondetails";
import SOWdetails from "../pages/lessonplan/SOWdetails";
import Rubricsdetails from "../pages/lessonplan/Rubricsdetails";
import Diarydetails from "../pages/lessonplan/Diarydetails";
import Dormitorydetails from "../pages/dormitory/Dormitorydetails";
import Roomdetails from "../pages/dormitory/Roomdetails";
import Assignmentdetails from "../pages/assignments/Assignmentdetails";
import Quizdetails from "../pages/quizzes/Quizdetails";
import Examsetupdetails from "../pages/exams/setup/Examsetupdetails";
import Examscheduledetails from "../pages/exams/schedule/Examscheduledetails";
import Examattendancedetails from "../pages/exams/attendance/Examattendancedetails";
import Marksgradedetails from "../pages/exams/grade/Marksgradedetails";
import Marksregisterdetails from "../pages/exams/register/Marksregisterdetails";
import NewTimeTable from "../pages/lessonplan/timetable/NewTimeTable";
import StudEditDetails from "../pages/students/stud/profile/StudEditDetails";
import ExamStudentAttendance from "../pages/exams/attendance/ExamStudentAttendance";
import MarkExamAttendance from "../pages/exams/attendance/MarkExamAttendance";

//inventory
import AddinventoryForm from "../pages/inventory/Addinventory";

import Viewinventory from "../pages/inventory/Viewinventory";
import CreateInvoiceForm from "../pages/inventory/CreateInvoice";
import AttendanceInstance from "../pages/academics/attendanceInstance/AttendanceInstance";
import StudentPromotion from "../pages/academics/promote/StudentPromotion";
import AssessmentDetails from "../pages/lessonplan/AssessmentDetails";

export const PRIVATE_ROUTES = [
  { element: InitialFilters, layout: "datatable", path: "filters" },
  { element: FullFeaturedCrudGrid, layout: "datatable", path: "full" },
  { element: ParentRegistration, layout: "stepper", path: "form" },

  // Administration

  // Academics
  { element: School, layout: "school", path: "list" },
  { element: AcademicYear, layout: "academic-year", path: "list" },
  { element: AcademicTerm, layout: "academic-term", path: "list" },
  { element: Grade, layout: "grade", path: "list" },
  { element: AcademicYearEdit, layout: "academic-year", path: "edit" },
  { element: AcademicYearDetails, layout: "academic-year", path: "details" },
  { element: ClassStream, layout: "stream", path: "list" },
  { element: StreamEdit, layout: "stream", path: "edit" },
  { element: StreamDetails, layout: "stream", path: "details" },
  { element: ClassRoom, layout: "classroom", path: "list" },
  { element: ClassRoomAdd, layout: "classroom", path: "add" },
  { element: ClassRoomEdit, layout: "classroom", path: "edit" },
  { element: ClassRoomDetails, layout: "classroom", path: "details/:id" },
  { element: StudentPromotion, layout: "students", path: "promote" },
  {
    element: StudentClassAllocation,
    layout: "classroom",
    path: "student-allocation",
  },
  {
    element: StudentClassAllocationEdit,
    layout: "classroom",
    path: "student-allocation-edit",
  },
  {
    element: StudentClassAllocationDetails,
    layout: "classroom",
    path: "student-allocation-details",
  },
  { element: SubjectTaught, layout: "subjects", path: "list" },
  { element: SubjectTaughtEdit, layout: "subject", path: "edit" },
  { element: SubjectTaughtDetails, layout: "subject", path: "detail" },
  { element: SubjectTeacher, layout: "subject", path: "teacher" },
  { element: SubjectPerGrade, layout: "subject", path: "per-grade" },
  {
    element: StudentClassAllocation,
    layout: "student-allocation",
    path: "list",
  },
  {
    element: StudentClassAllocationEdit,
    layout: "student-allocation",
    path: "edit",
  },
  {
    element: StudentClassAllocationDetails,
    layout: "subject",
    path: "details",
  },

  // Student
  {
    element: StudentCategory,
    layout: "student-category",
    path: "list",
  },
  {
    element: StudentCategoryEdit,
    layout: "student-category",
    path: "edit",
  },
  {
    element: StudentCategoryDetails,
    layout: "student-category",
    path: "details",
  },
  {
    element: RegisterNewStudent,
    layout: "students",
    path: "new-student",
  },
  {
    element: AttendanceInstance,
    layout: "attendance",
    path: "instance",
  },
  // ! Dummy Field
  {
    element: StudentForm,
    layout: "students",
    path: "new-student-1",
  },
  {
    element: EditStudent,
    layout: "student",
    path: "edit",
  },
  {
    element: StudentsList,
    layout: "students",
    path: "list",
  },
  {
    element: StudentDetails,
    layout: "student",
    path: "details",
  },
  {
    element: StudEditDetails,
    layout: "student",
    path: "edit-self",
  },
  {
    element: StudentProfile,
    layout: "student",
    path: "profile",
  },
  {
    element: ParentsList,
    layout: "parents",
    path: "list",
  },
  {
    element: ParentEdit,
    layout: "parent",
    path: "edit",
  },
  {
    element: ParentDetails,
    layout: "parent",
    path: "details",
  },
  {
    element: RegisterStudentParent,
    layout: "parents",
    path: "new-parent",
  },

  // Examination module
  // Examination module
  { element: ExamType, layout: "exam-type", path: "list" },
  { element: ExamSetup, layout: "exam-setup", path: "list" },
  { element: ExamSetupEdit, layout: "exam-setup", path: "edit" },
  { element: ExamSchedule, layout: "exam-schedule", path: "list" },
  { element: ExamScheduleEdit, layout: "exam-schedule", path: "edit" },
  { element: ExamAttendance, layout: "exam-attendance", path: "list" },
  {
    element: MarkExamAttendance,
    layout: "exam-attendance",
    path: "register",
  },
  { element: MarksGrade, layout: "exam-marks-grade", path: "list" },
  { element: MarksRegister, layout: "exam-marks-register", path: "list" },
  { element: Examsetupdetails, layout: "exam-setup", path: "examsetupdetails" },
  {
    element: Examscheduledetails,
    layout: "exam-schedule",
    path: "examscheduledetails",
  },
  {
    element: Examattendancedetails,
    layout: "exam-attendance",
    path: "examattendancedetails",
  },
  {
    element: Marksgradedetails,
    layout: "exam-marks-grade",
    path: "marksgradedetails",
  },
  {
    element: Marksregisterdetails,
    layout: "exam-marks-register",
    path: "marksregisterdetails",
  },
  { element: ExamReport, layout: "student-exams", path: "report" },

  // Human Resource Model
  { element: Department, layout: "hr", path: "department" },
  { element: Designation, layout: "hr", path: "designation" },
  { element: AddStaff, layout: "hr", path: "add-staff" },
  { element: StaffList, layout: "hr", path: "staff-list" },
  { element: EditStaffDetails, layout: "hr", path: "edit-staff" },
  { element: StaffDetails, layout: "hr", path: "staff-details" },
  { element: StaffForm, layout: "hr", path: "add-staff-1" },
  // Transport
  { element: VehicleRoutes, layout: "transport", path: "vehicle-routes" },
  { element: Vehicles, layout: "transport", path: "vehicles" },
  // dormitory
  { element: Dormitory, layout: "dormitory", path: "dorms" },
  { element: DormitoryRooms, layout: "dormitory", path: "rooms" },
  { element: Dormitorydetails, layout: "dormitory", path: "dormitorydetails" },
  { element: Roomdetails, layout: "dormitory", path: "roomdetails" },
  { element: Bed, layout: "dormitory", path: "bed" },
  // Library
  { element: Lostbooks, layout: "library", path: "lost-books" },
  { element: Addbook, layout: "library", path: "add-book" },
  { element: Addmember, layout: "library", path: "add-member" },
  { element: Allissuedbooks, layout: "library", path: "all-issued-book" },
  { element: Bookcategory, layout: "library", path: "book-category" },
  { element: Booklist, layout: "library", path: "book-list" },
  { element: Booksubjects, layout: "library", path: "book-subject" },
  { element: Ebooks, layout: "library", path: "e-books" },
  { element: Issueretunbooks, layout: "library", path: "issue-return-book" },

  //inventory
  { element: AddinventoryForm, layout: "inventory", path: "Addinventory" },
  { element: CreateInvoiceForm, layout: "inventory", path: "CreateInvoice" },
  { element: Viewinventory, layout: "inventory", path: "Viewinventory" },

  // Fees
  { element: Payments, layout: "fees", path: "payments" },
  { element: Invoices, layout: "fees", path: "invoices" },
  { element: Groups, layout: "fees", path: "Feesgroup" },
  { element: Items, layout: "fees", path: "Feesitems" },
  { element: Unpaidinvoices, layout: "fees", path: "Unpaidinvoices" },

  // Account
  { element: Bank, layout: "account", path: "Bank" },
  { element: Expense, layout: "account", path: "Expense" },
  { element: Income, layout: "account", path: "Income" },
  { element: Profitloss, layout: "account", path: "profitloss" },
  { element: Transfers, layout: "account", path: "Transfers" },
  // Lesson Plan
  { element: Topic, layout: "lesson-plan", path: "topic" },
  { element: Subtopic, layout: "lesson-plan", path: "subtopic" },
  { element: Lesson, layout: "lesson-plan", path: "lesson" },
  { element: Schemesofwork, layout: "lesson-plan", path: "schemesofwork" },
  { element: Timetable, layout: "lesson-plan", path: "timetable" },
  { element: NewTimeTable, layout: "lesson-plan", path: "new-timetable" },
  { element: Timeplan, layout: "lesson-plan", path: "timeplan" },
  { element: Topicdetails, layout: "lesson-plan", path: "topicdetails" },
  { element: Lessondetails, layout: "lesson-plan", path: "lessondetails" },
  { element: SOWdetails, layout: "lesson-plan", path: "sowdetails" },
  { element: Rubricsdetails, layout: "lesson-plan", path: "rubricsdetails" },
  { element: Diarydetails, layout: "lesson-plan", path: "diarydetails" },
  {
    element: Assessmentrubrics,
    layout: "lesson-plan",
    path: "assessmentrubrics",
  },
  {
    element: AssessmentDetails,
    layout: "lesson-plan",
    path: "assessmentdetails",
  },
  { element: Diary, layout: "lesson-plan", path: "diary" },

  // System Settings
  { element: PageSettings, layout: "page", path: "settings" },
  { element: SchoolInfo, layout: "page", path: "school-info" },

  // Roles and Permissions
  { element: AssignPermissions, layout: "roles", path: "assign-roles" },
  { element: UserDetailsPage, layout: "roles", path: "user-details" },

  // Study materials
  { element: Assignment, layout: "study-materials", path: "assignment" },
  { element: UploadContent, layout: "study-materials", path: "upload-content" },
  { element: Syllabus, layout: "study-materials", path: "syllabus" },
  {
    element: OtherDownloads,
    layout: "study-materials",
    path: "other-downloads",
  },

  //assignments
  { element: Assignments, layout: "assignments", path: "addassignment" },
  {
    element: Assignmentdetails,
    layout: "assignments",
    path: "assignmentdetails",
  },

  //quizzes
  { element: Quizzes, layout: "quizzes", path: "addquiz" },
  { element: Quizdetails, layout: "quizzes", path: "quizdetails" },

  //parents comments
  {
    element: Assessmentcomments,
    layout: "parentscomments",
    path: "assessmentcomments",
  },
  {
    element: Diarycomments,
    layout: "parentscomments",
    path: "diarycomments",
  },
];
