import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import StaffListActions from "./StaffListActions";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { API_URLS } from "../../../utils/api.routes";
import { CustomPagination } from "../../../components/custom/Custom.DataGrid";

export default function StaffList() {
  const [staffs, setStaff] = useState([]);

  const [rowId, setRowId] = useState(null);
  const [pageSize, setPageSize] = useState(100);

  useEffect(() => {
    axios
      .get(API_URLS.hr_staff)
      .then((res) => {
        setStaff(res.data);
      })
      .catch((error) => {
        console.log("Error");
      });
  }, []);

  const columns = [
    {
      field: "id",
      headerName: "SL",
      type: "number",
      with: 100,
    },
    {
      field: "staff_number",
      headerName: "Stuff Number",
      headerAlign: "center",
      width: 150,
      editable: true,
    },
    {
      field: "first_name",
      headerName: "First Name",
      headerAlign: "center",
      width: 150,
      editable: true,
    },
    {
      field: "last_name",
      headerName: "Last Name",
      headerAlign: "center",
      width: 150,
      editable: true,
    },
    {
      field: "gender",
      headerName: "Gender",
      headerAlign: "center",
      width: 100,
      editable: true,
    },
    {
      field: "age",
      headerName: "Age",
      headerAlign: "center",
      width: 100,
      hide: true,
      editable: true,
    },
    {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      renderCell: (params) => (
        <StaffListActions {...{ params, rowId, setRowId }} />
      ),
    },
  ];

  return (
    <div className="main-content">
      <div className="row">
        <div className="col-12 col-md-12 col-lg-12">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/admin">Home</Link>
              </li>
              <li className="breadcrumb-item">Staff List</li>
            </ol>
          </nav>
        </div>
        <div className="col-12 col-md-12 col-lg-12">
          <div className="shadow-sm p-3 mb-5 bg-white rounded">
            <h5 className="text-center text-dark">Registered Staff List</h5>
            <div style={{ height: 500 }}>
              <DataGridPro
                columns={columns}
                rows={staffs}
                components={{
                  Toolbar: GridToolbar,
                  Pagination: CustomPagination,
                }}
                getRowId={(row) => row.id}
                rowsPerPageOptions={[5, 10, 20]}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                getRowSpacing={(params) => ({
                  top: params.isFirstVisible ? 0 : 5,
                  bottom: params.isLastVisible ? 0 : 5,
                })}
                onCellEditCommit={(params) => setRowId(params.id)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
