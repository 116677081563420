import React, { Component } from "react";
import AdminNavbar from "../../components/shared/AdminNavbar.jsx";
import { Navigate, Outlet } from "react-router-dom";
import AuthService from "../../services/auth.service";
import AdminSidebar from "../../components/sidebar/AdminSidebar.jsx";
import { API_URLS } from "../../utils/api.routes.jsx";

export default class AdminDashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      redirect: null,
      userReady: false,
      currentUser: { username: "" },
    };
  }
  componentDidMount() {
    const currentUser = AuthService.getCurrentUser();
    // ! Check if the logged in user actually has a ADMIN ROLE
    // if (currentUser.roles.shift() !== "ROLE_ADMIN" ) {
    //   this.setState({ redirect: "/login" });
    // }

    // check if person is logged in first
    if (!currentUser) this.setState({ redirect: "/" });
   this.setState({ currentUser: currentUser, userReady: true });
  }

  render() {
    
    if (this.state.redirect) {
      return <Navigate to={this.state.redirect} />;
    }
    const { currentUser } = this.state;

    return (
      <div>
        {this.state.userReady ? (
          <div>
            <AdminNavbar currentUser={currentUser} />
            <AdminSidebar />
            <Outlet />
          </div>
        ) : null}
      </div>
    );
  }
}
