import { Button } from "@mui/material";
import React from "react";

export default function FormParentsDetails(props) {
  const Continue = (e) => {
    e.preventDefault();
    props.nextStep();
  };
  const Back = (e) => {
    e.preventDefault();
    props.prevStep();
  };
  const { values, handleChange } = props;
  return (
    <div>
      <div>
        <h5 className="text-center text-dark">Parents Details</h5>
      </div>
      <div className="col-12 col-md-12 col-lg-12">
        <div className="" style={{ minHeight: 350 }}>
          {/* 1st parent */}
          <div className="">
            <div className="col-12">
              <h6 className="text-dark">Parent</h6>
            </div>
            <div className="d-flex flex-wrap col-12 ">
              <div className="form-group col-3">
                <label>
                  Full Name<span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control "
                  onChange={handleChange("parent_full_name")}
                  value={values.parent_full_name}
                />
              </div>
              <div className="form-group col-3">
                <label>
                  Tel No<span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  onChange={handleChange("parent_tel_no")}
                  value={values.parent_tel_no}
                />
              </div>
              <div className="form-group col-3">
                <label>
                  E-Mail<span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  onChange={handleChange("parent_email")}
                  value={values.parent_email}
                />
              </div>
              <div className="form-group col-3">
                <label>
                  Relation<span className="text-danger">*</span>
                </label>
                <select
                  className="form-control"
                  onChange={handleChange("parent_relation")}
                  value={values.parent_relation}
                >
                  <option value="" disabled>
                    Select Relation
                  </option>
                  <option value="Father">Father</option>
                  <option value="Mother">Mother</option>
                  <option value="Grand Parent">Grand Parent</option>
                  <option value="Gurdian">Gurdian</option>
                  <option value="Sponsor">Sponsor</option>
                </select>
              </div>
            </div>
          </div>
          {/* 2nd Parent */}
          <div className="">
            <div className="col-12">
              <h6 className="text-dark">
                Parent <span className="text-muted">(optional)</span>
              </h6>
            </div>
            <div className="d-flex flex-wrap col-12 ">
              <div className="form-group col-3">
                <label>Full Name</label>
                <input
                  type="text"
                  className="form-control "
                  onChange={handleChange("parent1_full_name")}
                  value={values.parent1_full_name}
                />
              </div>
              <div className="form-group col-3">
                <label>Tel No</label>
                <input
                  type="text"
                  className="form-control"
                  onChange={handleChange("parent1_tel_no")}
                  value={values.parent1_tel_no}
                />
              </div>
              <div className="form-group col-3">
                <label>E-Mail</label>
                <input
                  type="text"
                  className="form-control"
                  onChange={handleChange("parent1_email")}
                  value={values.parent1_email}
                />
              </div>
              <div className="form-group col-3">
                <label>Relation</label>
                <select
                  className="form-control"
                  onChange={handleChange("parent1_relation")}
                  value={values.parent1_relation}
                >
                  <option value="" disabled>
                    Select Relation
                  </option>
                  <option value="Father">Father</option>
                  <option value="Mother">Mother</option>
                  <option value="Grand Parent">Grand Parent</option>
                  <option value="Gurdian">Gurdian</option>
                  <option value="Sponsor">Sponsor</option>
                </select>
              </div>
            </div>
          </div>
          {/* 3rd Parent */}
          <div className="">
            <div className="col-12">
              <h6 className="text-dark">
                Parent<span className="text-muted">(optional)</span>
              </h6>
            </div>
            <div className="d-flex flex-wrap col-12 ">
              <div className="form-group col-3">
                <label>Full Name</label>
                <input
                  type="text"
                  className="form-control "
                  onChange={handleChange("parent2_full_name")}
                  value={values.parent2_full_name}
                />
              </div>

              <div className="form-group col-3">
                <label>Tel No</label>
                <input
                  type="text"
                  className="form-control"
                  onChange={handleChange("parent2_tel_no")}
                  value={values.parent2_tel_no}
                />
              </div>
              <div className="form-group col-3">
                <label>E-Mail</label>
                <input
                  type="text"
                  className="form-control"
                  onChange={handleChange("parent2_email")}
                  value={values.parent2_email}
                />
              </div>
              <div className="form-group col-3">
                <label>Relation</label>
                <select
                  className="form-control"
                  onChange={handleChange("parent2_relation")}
                  value={values.parent2_relation}
                >
                  <option value="" disabled>
                    Select Relation
                  </option>
                  <option value="Father">Father</option>
                  <option value="Mother">Mother</option>
                  <option value="Grand Parent">Grand Parent</option>
                  <option value="Gurdian">Gurdian</option>
                  <option value="Sponsor">Sponsor</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 d-flex justify-content-between">
          <Button color="primary" variant="contained" onClick={Back}>
            Back
          </Button>
          <Button color="primary" variant="contained" onClick={Continue}>
            Next
          </Button>
        </div>
      </div>
    </div>
  );
}
