import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { API_URLS } from "../../../utils/api.routes";
import { Toaster } from "react-hot-toast";
import axios from "axios";
import moment from "moment/moment";

export default function StaffDetails() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const fetchStaffDetails = async () => {
    const { data } = await axios.get(API_URLS.hr_staff.concat(state, "/"));
    return data;
  };
  const { data, error, isError, isLoading } = useQuery(
    "staff",
    fetchStaffDetails
  );

  if (isLoading) {
    return (
      <div className="main-content">
        <div className="row justify-content-center align-items-center">
          <h1>Loading</h1>
        </div>
      </div>
    );
  }
  if (isError) {
    return (
      <div className="main-content">
        <div className="row justify-content-center align-items-center">
          <h4 className="text-danger">Error!!! {error.message}</h4>
        </div>
      </div>
    );
  }
  const editDetails = () => {
    navigate("/admin/hr/edit-staff", { state: data });
  };
  const deleteStaff = () => {};
  return (
    <div className="main-content">
      <div className="row">
        <div className="col-12 col-md-12 col-lg-12">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/admin">Home</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/admin/hr/staff-list">Staff List</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Staff Data
              </li>
            </ol>
          </nav>
        </div>
        <div className="col-md-6 col-lg-12 col-xl-4">
          <div className="shadow-sm p-1 mb-3 bg-white rounded">
            <div className="card-body">
              <div className="d-flex flex-column align-items-center ">
                {" "}
                <img
                  src="https://bootdey.com/img/Content/avatar/avatar7.png"
                  alt="Student Image"
                  className="rounded-circle"
                  style={{ width: "150px" }}
                />
                <div className="mt-4">
                  <h4 className="text-info">
                    {data.first_name} {data.last_name}
                  </h4>
                  <h5 className="text-muted font-size-sm">{data.service_no}</h5>
                  <h6 className="text-muted font-size-sm">{data.gender}</h6>
                </div>
              </div>
            </div>
          </div>
          <div className="shadow-sm p-3 mb-3 bg-white rounded">
            <ul className="list-group list-group-flush">
              <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                <h6 className="mb-0 text-dark">Serial No</h6>
                <span className="text-info font-weight-bold">{data.id}</span>
              </li>
              <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                <h6 className="mb-0  text-dark">National ID</h6>
                <span className="text-info font-weight-bold">
                  {data.national_id}
                </span>
              </li>
              <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                <h6 className="mb-0 text-dark">Driving License</h6>
                <span className="text-info font-weight-bold">
                  {data.driving_license}
                </span>
              </li>
              <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                <h6 className="mb-0 text-dark">Service Number</h6>
                <span className="text-info font-weight-bold">
                  {data.service_no}
                </span>
              </li>
              <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                <h6 className="mb-0 text-dark">Registration Date</h6>
                <span className="text-info font-weight-bold">
                  {data.date_of_joining}
                </span>
              </li>
              <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                <h6 className="mb-0 text-dark">Marital Status</h6>
                <span className="font-weight-bold text-info">
                  {data.marital_status}
                </span>
              </li>
              <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                <h6 className="mb-0 text-dark">Person With Disability</h6>
                <span className="font-weight-bold text-info">
                  {data.with_disability === false ? "No" : "Yes"}
                </span>
              </li>
              <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                <h6 className="mb-0 text-dark">Designation</h6>
                <span className="font-weight-bold text-info">
                  {data.designation}
                </span>
              </li>
            </ul>
          </div>
        </div>
        {/* Info */}
        <div className="col-md-6 col-lg-12 col-xl-8">
          <div className="mb-3 shadow-sm p-1 mb-1 bg-white rounded">
            <div className="card-body">
              <div className="row">
                <div className="col-sm-4">
                  <h6 className="mb-0 text-dark">Staff Number</h6>
                </div>
                <span className="col-sm-8 text-muted font-weight-bold">
                  {data.staff_number}
                </span>
              </div>
              <hr />
              <div className="row">
                <div className="col-sm-4">
                  <h6 className="mb-0 text-dark">Email</h6>
                </div>
                <span className="col-sm-8 text-muted font-weight-bold">
                  {data.email}
                </span>
              </div>
              <hr />
              <div className="row">
                <div className="col-6 col-lg-2">
                  <h6 className="mb-0 text-dark">Phone</h6>
                </div>
                <span className="col-6 col-lg-4 text-muted font-weight-bold">
                  {data.tel_no}
                </span>
                <div className="col-6 col-lg-2">
                  <h6 className="mb-0 text-dark">Mobile</h6>
                </div>
                <span className="col-6 col-lg-4 text-muted font-weight-bold">
                  {data.mobile}
                </span>
              </div>
              <hr />
              <div className="row">
                <div className="col-6 col-lg-2">
                  <h6 className="mb-0 text-dark">County</h6>
                </div>
                <span className="col-6 col-lg-4 text-muted font-weight-bold">
                  {data.home_county}
                </span>
                <div className="col-6 col-lg-2">
                  <h6 className="mb-0 text-dark">Sub County</h6>
                </div>
                <span className="col-6 col-lg-4 text-muted font-weight-bold">
                  {data.home_sub_county}
                </span>
              </div>
              <hr />
              <div className="row">
                <div className="col-6 col-lg-2">
                  <h6 className="mb-0 text-dark">Ward</h6>
                </div>
                <span className="col-6 col-lg-4 text-muted font-weight-bold">
                  {data.home_ward}
                </span>
                <div className="col-6 col-lg-2">
                  <h6 className="mb-0 text-dark">Location</h6>
                </div>
                <span className="col-6 col-lg-4 text-muted font-weight-bold">
                  {data.home_location}
                </span>
              </div>

              <hr />
              <div className="row">
                <div className="col-sm-4">
                  <h6 className="mb-0 text-dark">Permanent Address</h6>
                </div>
                <span className="col-sm-8 text-muted font-weight-bold">
                  {data.permanent_address}
                </span>
              </div>
              <hr />
              <div className="row">
                <div className="col-sm-4 text-dark">
                  <h6 className="mb-0">Current Address</h6>
                </div>
                <span className="col-sm-8 text-muted font-weight-bold">
                  {data.current_address}
                </span>
              </div>
              <hr />
              <div className="row">
                <div className="col-sm-4 text-dark">
                  <h6 className="mb-0">Postal</h6>
                </div>
                <span className="col-sm-8 text-muted font-weight-bold">
                  {`${data.postal_code}-${data.zip_code}   ${data.postal_location}`}
                </span>
              </div>
            </div>
          </div>
          <div className="row gutters-sm">
            <div className="col-sm-6 mb-3">
              <div className="shadow-sm p-1 rounded bg-white ">
                <div className="card-body">
                  <div className="row">
                    <div className="col-sm-6">
                      <h6 className="mb-0 text-dark">Date of Birth</h6>
                    </div>
                    <span className="col-sm-6 text-muted font-weight-bold">
                      {data.dob}
                    </span>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-sm-6">
                      <h6 className="mb-0 text-dark">Birth Certh No</h6>
                    </div>
                    <span className="col-sm-6 text-muted font-weight-bold">
                      {data.birth_cert_no}
                    </span>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-sm-6">
                      <h6 className="mb-0 text-dark">Age</h6>
                    </div>
                    <span className="col-sm-6 text-muted font-weight-bold">
                      {data.age}
                    </span>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-sm-6">
                      <h6 className="mb-0 text-dark">Weight</h6>
                    </div>
                    <span className="col-sm-6 text-muted font-weight-bold">
                      {data.weight} Kg
                    </span>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-sm-6">
                      <h6 className="mb-0 text-dark">Height</h6>
                    </div>
                    <span className="col-sm-6 text-muted font-weight-bold">
                      {data.height} cms
                    </span>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-sm-6">
                      <h6 className="mb-0 text-dark">Blood group</h6>
                    </div>
                    <span className="col-sm-6 text-muted font-weight-bold">
                      {data.blood_group}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-6 mb-3">
              <div className="shadow-sm p-1 rounded bg-white ">
                <h5 className="text-dark text-center mt-2">Contract</h5>
                <div className="card-body">
                  <div className="row">
                    <div className="col-sm-6">
                      <h6 className="mb-0 text-dark">Type</h6>
                    </div>
                    <span className="col-sm-6 text-muted font-weight-bold">
                      {data.contract_type}
                    </span>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-sm-6">
                      <h6 className="mb-0 text-dark">Length</h6>
                    </div>
                    <span className="col-sm-6 text-muted font-weight-bold">
                      {data.contract_length}
                    </span>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-sm-6">
                      <h6 className="mb-0 text-dark">Salary</h6>
                    </div>
                    <span className="col-sm-6 text-muted font-weight-bold">
                      {data.basic_salary}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-lg-12 col-xl-12">
          <div className="d-flex flex-wrap mb-3 shadow-sm bg-white rounded">
            <h5 className="col-12 mt-2 text-center text-dark font-weight-bold">
              Emergency Contact
            </h5>
            <div className="col-12 col-md-6 col-lg-6">
              <div className="card-body">
                <div className="row">
                  <div className="col-sm-4">
                    <h6 className="mb-0 text-dark">Full Name</h6>
                  </div>
                  <span className="col-sm-8 text-muted font-weight-bold">
                    {`${data.emerg_first_name1} ${data.emerg_last_name1}`}
                  </span>
                </div>
                <hr />
                <div className="row">
                  <div className="col-sm-3">
                    <h6 className="mb-0 text-dark">Phone</h6>
                  </div>
                  <span className="col-sm-9 text-muted font-weight-bold">
                    {`${data.emerg_tel_no1}  /  ${data.emerg_mobile_no1}`}
                  </span>
                </div>
                <hr />
                <div className="row">
                  <div className="col-sm-6">
                    <h6 className="mb-0 text-dark">Gender</h6>
                  </div>
                  <span className="col-sm-6 text-muted font-weight-bold">
                    {data.emerg_gender1}
                  </span>
                </div>
                <hr />
                <div className="row">
                  <div className="col-sm-6 text-dark">
                    <h6 className="mb-0 text-dark">Relation</h6>
                  </div>
                  <span className="col-sm-6 text-muted font-weight-bold">
                    {data.emerg_relation1}
                  </span>
                </div>
                <hr />
              </div>
            </div>
            <div className="col-12 col-md-4 col-lg-6">
              <div className="card-body">
                <div className="row">
                  <div className="col-sm-4">
                    <h6 className="mb-0 text-dark">Full Name</h6>
                  </div>
                  <span className="col-sm-8 text-muted font-weight-bold">
                    {`${data.emerg_first_name1} ${data.emerg_last_name2}`}
                  </span>
                </div>
                <hr />
                <div className="row">
                  <div className="col-sm-3">
                    <h6 className="mb-0 text-dark">Phone</h6>
                  </div>
                  <span className="col-sm-9 text-muted font-weight-bold">
                    {`${data.emerg_tel_no2}  /  ${data.emerg_mobile_no2}`}
                  </span>
                </div>
                <hr />
                <div className="row">
                  <div className="col-sm-6">
                    <h6 className="mb-0 text-dark">Gender</h6>
                  </div>
                  <span className="col-sm-6 text-muted font-weight-bold">
                    {data.emerg_gender2}
                  </span>
                </div>
                <hr />
                <div className="row">
                  <div className="col-sm-6 text-dark">
                    <h6 className="mb-0 text-dark">Relation</h6>
                  </div>
                  <span className="col-sm-6 text-muted font-weight-bold">
                    {data.emerg_relation2}
                  </span>
                </div>
                <hr />
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-lg-12 col-xl-12">
          <div className="rounded bg-white mb-5">
            <div className="card-body">
              <div className="row">
                <div className="col-sm-4">
                  <h6 className="mb-0 text-dark">Additional Notes</h6>
                </div>
                <span className="col-sm-8 text-muted font-weight-bold">
                  {data.additional_notes}
                </span>
              </div>
              <hr />
              <div className="row">
                <div>
                  <button
                    onClick={() => {
                      editDetails();
                    }}
                    className="btn btn-sm btn-info float-left"
                  >
                    Edit
                  </button>
                  <button
                    onClick={() => {
                      deleteStaff();
                    }}
                    className="btn btn-sm btn-danger float-right"
                  >
                    Delete
                  </button>
                </div>
              </div>
              <hr />
              <Toaster position="top-right" reverseOrder={false} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
