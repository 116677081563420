import React from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";

const BasicForm = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  return (
    <>
      <div className="d-flex flex-wrap justify-content-center">
        <div className="col-6 col-md-4 col-lg-4">
          <Controller
            control={control}
            name="first_name"
            rules={{ required: "this field is required." }}
            render={({ field }) => (
              <TextField
                id="first-name"
                label="First Name *"
                variant="outlined"
                placeholder="Enter Your First Name"
                fullWidth
                margin="normal"
                {...field}
                error={Boolean(errors?.firstName)}
                helperText={errors.first_name?.message}
              />
            )}
          />
        </div>
        <div className="col-6 col-md-4 col-lg-4">
          <Controller
            control={control}
            name="last_name"
            rules={{ required: "this field is required." }}
            render={({ field }) => (
              <TextField
                id="last-name"
                label="Last Name *"
                variant="outlined"
                placeholder="Enter Your Last Name"
                fullWidth
                margin="normal"
                {...field}
                error={Boolean(errors?.last_name)}
                helperText={errors.last_name?.message}
              />
            )}
          />
        </div>
        <div className="col-6 col-md-4 col-lg-4">
          <Controller
            control={control}
            name="other_name"
            render={({ field }) => (
              <TextField
                id="other_name"
                label="Other Name"
                variant="outlined"
                placeholder="Enter Other Name"
                fullWidth
                margin="normal"
                {...field}
              />
            )}
          />
        </div>
        <div className="col-6 col-md-4 col-lg-4">
          <Controller
            control={control}
            name="national_id"
            rules={{ required: "this field is required." }}
            render={({ field }) => (
              <TextField
                id="national_id"
                label="National ID *"
                variant="outlined"
                placeholder="Enter National Id Number"
                fullWidth
                margin="normal"
                {...field}
                error={Boolean(errors?.national_id)}
                helperText={errors.national_id?.message}
              />
            )}
          />
        </div>
        <div className="col-6 col-md-4 col-lg-4">
          <Controller
            control={control}
            name="email"
            rules={{ required: "this field is required." }}
            render={({ field }) => (
              <TextField
                id="e-mail"
                label="Email Address"
                variant="outlined"
                placeholder="Enter Email Address"
                fullWidth
                margin="normal"
                {...field}
                error={Boolean(errors?.email)}
                helperText={errors.email?.message}
              />
            )}
          />
        </div>
        <div className="col-6 col-md-4 col-lg-4">
          <Controller
            control={control}
            name="tel_no"
            render={({ field }) => (
              <TextField
                id="tel_no"
                label="Tel Number *"
                variant="outlined"
                placeholder="Enter Personal Tel Number"
                fullWidth
                margin="normal"
                {...field}
                error={Boolean(errors?.tel_no)}
                helperText={errors.tel_no?.message}
              />
            )}
          />
        </div>

        <div className="col-6 col-md-4 col-lg-4">
          <Controller
            control={control}
            name="mobile"
            render={({ field }) => (
              <TextField
                id="mobile"
                label="Mobile Number"
                variant="outlined"
                placeholder="Enter Mobile Number"
                fullWidth
                margin="normal"
                {...field}
              />
            )}
          />
        </div>
        <div className="col-6 col-md-4 col-lg-4">
          <Controller
            control={control}
            name="staff_number"
            rules={{ required: "this field is required." }}
            render={({ field }) => (
              <TextField
                id="staff-no"
                label="Staff Number *"
                variant="outlined"
                placeholder="Enter Staff Number"
                fullWidth
                margin="normal"
                {...field}
                error={Boolean(errors?.staff_number)}
                helperText={errors.staff_number?.message}
              />
            )}
          />
        </div>
        <div className="col-6 col-md-6 col-lg-4">
          <Controller
            control={control}
            name="gender"
            rules={{ required: "this field is required." }}
            render={({ field }) => (
              <FormControl fullWidth>
                <InputLabel id="gender-status">Gender</InputLabel>
                <Select
                  labelId="gender-status"
                  id="Gender"
                  label="Age"
                  {...field}
                >
                  <MenuItem value="" disabled>
                    Select Gender
                  </MenuItem>
                  <MenuItem value="Male">Male</MenuItem>
                  <MenuItem value="Female">Female</MenuItem>
                  <MenuItem value="Prefer not to Say">
                    Prefer not to Say
                  </MenuItem>
                </Select>
              </FormControl>
            )}
          />
        </div>
        <div className="col-6 col-md-4 col-lg-4">
          <Controller
            control={control}
            name="dob"
            rules={{ required: "this field is required." }}
            render={({ field }) => (
              <TextField
                id="dob"
                helperText="Enter Date of Birth"
                variant="outlined"
                fullWidth
                type="date"
                margin="normal"
                {...field}
              />
            )}
          />
        </div>
        <div className="col-6 col-md-4 col-lg-4">
          <Controller
            control={control}
            name="marital_status"
            rules={{ required: "this field is required." }}
            render={({ field }) => (
              <FormControl fullWidth>
                <InputLabel id="marital-status">Marital Status</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="marital Status"
                  label="Age"
                  {...field}
                >
                  <MenuItem value="" disabled>
                    Select Marital Status
                  </MenuItem>
                  <MenuItem value="Married">Married</MenuItem>
                  <MenuItem value="Single">Single</MenuItem>
                  <MenuItem value="Prefer not to Say">
                    Prefer not to Say
                  </MenuItem>
                </Select>
              </FormControl>
            )}
          />
        </div>

        <div className="col-6 col-md-6 col-lg-4">
          <Controller
            control={control}
            name="with_disability"
            rules={{ required: "this field is required." }}
            render={({ field }) => (
              <FormControl fullWidth>
                <InputLabel id="gender-status">
                  Living With Disability *
                </InputLabel>
                <Select
                  labelId="gender-status"
                  id="gender-id"
                  label="Person"
                  {...field}
                >
                  <MenuItem value="0">No</MenuItem>
                  <MenuItem value="1">Yes</MenuItem>
                </Select>
              </FormControl>
            )}
          />
        </div>
      </div>
    </>
  );
};

export default BasicForm;
