import React from "react";
import authService from "../../services/auth.service";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { API_URLS } from "../../utils/api.routes";
import { useQuery } from "react-query";
import axios from "axios";
import { useState } from "react";
import UserRoles from "./UserRoles";
import { NoEncryptionOutlined } from "@mui/icons-material";
import { Button } from "@mui/material";
import authHeader from "../../services/auth-header";
import {
  toastDangerStyle,
  toastSuccessStyle,
  toastWarnigStyle,
} from "../../components/shared/Custom.Styles";
import { Toaster, toast } from "react-hot-toast";
import AccessDenied from "../../components/custom/Custom.AccessDenied";

export default function UserDetailsPage() {
  const currentUser = authService.getCurrentUser();
  const [roles, setRoles] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const navigate = useNavigate();

  const { state } = useLocation();

  const fetchUserDetails = async () => {
    const { data } = await axios.get(API_URLS.users.concat(state, "/"));

    for (let i = 0; i < data.roles.length; i++) {
      roles.push(data.roles[i]);
    }
    return data;
  };
  const { data, error, isError, isLoading } = useQuery(
    "users",
    fetchUserDetails,
    {
      refetchOnWindowFocus: false,
      refetchInterval: false,
    }
  );

  const handleSelectionChange = (selectedItems) => {
    setSelectedRoles(selectedItems);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    for (let j = 0; j < selectedRoles.length; j++) {
      await fetch(
        API_URLS.user_roles.concat(`/user/${state}/role/${selectedRoles[j]}`),
        {
          method: "DELETE",
          headers: authHeader(),
        }
      )
        .then((res) => {
          if (res.status === 200) {
            toast.success(`Successfully Unassigned Role`, toastSuccessStyle);
            setTimeout(() => {
              navigate("/admin/roles/assign-roles");
            }, 4000);
          } else if (res.status === 400) {
            toast.error(`Error !!! ${res.statusText}`, toastDangerStyle);
            setTimeout(() => {
              toast(`Seems Grade Already Exists`, toastWarnigStyle);
            }, 1000);
          } else {
            toast.error(
              `${res.status} Error !!! ${res.statusText}`,
              toastDangerStyle
            );
          }
        })
        .catch((err) => {
          toast.error(`Error, Not Found !!!`, toastDangerStyle);
        });
    }
  };

  if (isLoading) {
    return (
      <div className="main-content">
        <div className="row justify-content-center align-items-center">
          <h1>Loading</h1>
        </div>
      </div>
    );
  }
  if (isError) {
    return (
      <div className="main-content">
        <div className="row justify-content-center align-items-center">
          <h4 className="text-danger">Error!!! {error.message}</h4>
        </div>
      </div>
    );
  }
  /* Role based access validation */
  if (
    !currentUser.roles.includes("ASSIGN_ROLE") &&
    !currentUser.roles.includes("UNASSIGN_ROLE") &&
    !currentUser.roles.includes("SUPER_ADMIN")
  ) {
    return <AccessDenied />;
  }
  return (
    <div className="main-content">
      <div className="row">
        <div className="col-12 col-md-12 col-lg-12">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link className="font-weight-bold" to="/admin">
                  Home
                </Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                <span className="font-weight-bold">
                  User Roles and Permissions
                </span>
              </li>
            </ol>
          </nav>
        </div>
        <div className="col-12">
          <div className="card shadow-sm p-3 mb-5 bg-white rounded">
            <h5 className="text-center text-info">User Details</h5>
            <div className="d-flex flex-wrap">
              <div className="col-12">
                <h6 className="text-dark">
                  Username :{" "}
                  <span className="text-muted text-right">{data.username}</span>
                </h6>
                <h6 className="text-dark">
                  Email :{" "}
                  <span className="text-muted text-right">{data.email}</span>
                </h6>
                <h6 className="text-dark">
                  Category :{" "}
                  <span className="text-muted text-right">{data.category}</span>
                </h6>
              </div>
              <div className="col-12">
                <h6 className="text-center text-info">Roles Assigned</h6>
                <form onSubmit={handleSubmit}>
                  <UserRoles
                    data={roles}
                    onSelectionChange={handleSelectionChange}
                  />
                  {/* {selectedRoles && (
                    <p className="text-muted">
                      Selected Roles: {selectedRoles.join(", ")}
                    </p>
                  )} */}
                  <div className="col-12 d-flex justify-content-end mt-2">
                    <Button
                      variant="contained"
                      endIcon={<NoEncryptionOutlined />}
                      type="submit"
                    >
                      Unassign Role
                    </Button>
                    <Toaster position="top-right" reverseOrder={false} />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
