import axios from "axios";
import React, { useState } from "react";
import { toast, Toaster } from "react-hot-toast";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import {
  toastDangerStyle,
  toastSuccessStyle,
  toastWarnigStyle,
} from "../../../components/shared/Custom.Styles";
import { API_URLS } from "../../../utils/api.routes";
import {
  AcademicYearSearchSelectInput,
  ClassSearchSelectInput,
  StudentSearchSelectInput,
} from "../../../components/custom/Custom.SearchInput";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { CustomPagination } from "../../../components/custom/Custom.DataGrid";
import authService from "../../../services/auth.service";
import authHeader from "../../../services/auth-header";

function StudentClassAllocation() {
  const currentUser = authService.getCurrentUser();

  const [year, setYear] = useState("");
  const [classRoom, setClassRoom] = useState("");
  const [student, setStudent] = useState("");

  const [rowId, setRowId] = useState(null);
  const [pageSize, setPageSize] = useState(5);

  const fetchStudentsClassAllocation = async () => {
    const { data } = await axios.get(API_URLS.class_allocation);
    return data;
  };
  const { data, error, isError, isLoading, refetch } = useQuery(
    "students_class_allocation",
    fetchStudentsClassAllocation
  );

  if (isLoading) {
    return (
      <div className="row">
        <h6 className="text-info">Loading...</h6>
      </div>
    );
  }
  if (isError) {
    return (
      <div className="row">
        <h6 className="text-danger">Error!! {error.message}</h6>
      </div>
    );
  }
  // const editPage = (data) => {
  //   navigate("/admin/student-allocation/details", {
  //     state: data,
  //   });
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await fetch(API_URLS.class_allocation, {
      method: "POST",
      body: JSON.stringify({
        academic_year: year.id,
        student: student.id,
        class_room: classRoom.id,
      }),
      headers: authHeader(),
    })
      .then((res) => {
        if (res.status === 201) {
          refetch();
          toast.success(`Class Allocation Successful`, toastSuccessStyle);
        } else if (res.status === 400) {
          toast.error(`Error !!! ${res.statusText}`, toastDangerStyle);
          setTimeout(() => {
            toast(`Seems Class Already Allocated`, toastWarnigStyle);
          }, 1000);
        } else {
          toast.error(`${res.status} ${res.statusText}`, toastDangerStyle);
        }
      })
      .catch((err) => {
        toast.error(`Error Not Found !`, toastDangerStyle);
      });
  };

  const editableColumns = [
    {
      field: "id",
      headerName: "SL",
      headerAlign: "center",
      type: "number",
      width: "80",
    },
    {
      field: "admn_no",
      headerName: "Admission Number",
      headerAlign: "center",
      editable: true,
      width: 120,
    },
    {
      field: "fullName",
      headerName: "Full Name",
      width: 200,
      renderCell: (params) => (
        <span>
          {params.row.first_name} {params.row.last_name}{" "}
          {params.row.other_name || ""}
        </span>
      ),
    },
    {
      field: "class_room_name",
      headerName: "Classroom",
      headerAlign: "center",
      editable: true,
      width: 150,
    },
    {
      field: "year_name",
      headerName: "Year Name",
      headerAlign: "center",
      editable: true,
      width: 120,
    },
    // renderCell: (params) =>
    // {
    //   field: "actions",
    //   headerName: "Actions",
    //   type: "actions",
    //   width: 150,
    //   renderCell: (params) => (
    //     <ClassStreamActions {...{ params, rowId, setRowId, refetch }} />
    //   ),
    // },
  ];

  const columns = [
    {
      field: "id",
      headerName: "SL",
      headerAlign: "center",
      type: "number",
      width: "80",
    },
    {
      field: "admn_no",
      headerName: "Admission Number",
      headerAlign: "center",
      width: 120,
    },
    {
      field: "fullName",
      headerName: "Full Name",
      width: 200,
      renderCell: (params) => (
        <span>
          {params.row.first_name} {params.row.last_name}{" "}
          {params.row.other_name || ""}
        </span>
      ),
    },
    {
      field: "class_room_name",
      headerName: "Classroom",
      headerAlign: "center",
      editable: true,
      width: 150,
    },
    {
      field: "year_name",
      headerName: "Year Name",
      headerAlign: "center",
      editable: true,
      width: 120,
    },
    // renderCell: (params) =>
    // {
    //   field: "actions",
    //   headerName: "Actions",
    //   type: "actions",
    //   width: 150,
    //   renderCell: (params) => (
    //     <ClassStreamActions {...{ params, rowId, setRowId, refetch }} />
    //   ),
    // },
  ];

  return (
    <div className="main-content">
      <div className="row">
        <div className="col-12 col-md-12 col-lg-12">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/admin">Home</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Student ClassRoom Allocation
              </li>
            </ol>
          </nav>
        </div>
        {currentUser.roles.includes("ADD_STUDENT_CLASS_ALLOCATION") ||
        currentUser.roles.includes("SUPER_ADMIN") ? (
          <div className="col-md-12 col-lg-12 col-xl-12">
            <form onSubmit={handleSubmit}>
              <div className="shadow-sm p-3 mb-5 bg-white rounded">
                <h4 className="text-center text-dark">
                  Allocate Student to Class
                </h4>
                <div className="d-flex flex-wrap">
                  <div className="col-12 col-md-3 col-lg-3 form-group">
                    <label htmlFor="stud">Student</label>
                    <StudentSearchSelectInput
                      onSelectedItemChange={(stud) => {
                        setStudent(stud);
                      }}
                      searchFields={["admn_no", "first_name", "last_name"]}
                    />
                    {student && (
                      <p className="font-weight-bold text-info">
                        Student: {student.admn_no}
                      </p>
                    )}
                  </div>
                  <div className="col-12 col-md-3 col-lg-3 form-group">
                    <label htmlFor="stud">Classroom Code</label>
                    <ClassSearchSelectInput
                      onSelectedItemChange={(cls) => {
                        setClassRoom(cls);
                      }}
                      searchFields={["class_room_code", "class_room_code"]}
                    />
                    {classRoom && (
                      <p className="font-weight-bold text-info">
                        Classroom: {classRoom.class_room_name}
                      </p>
                    )}
                  </div>
                  <div className="col-12 col-md-3 col-lg-3 form-group">
                    <label htmlFor="stud">Academic Year</label>
                    <AcademicYearSearchSelectInput
                      onSelectedItemChange={(yr) => {
                        setYear(yr);
                      }}
                      searchFields={["name"]}
                    />
                    {year && (
                      <p className="font-weight-bold text-info">
                        Year: {year.name}
                      </p>
                    )}
                  </div>
                  <div className="col-12 col-md-3 col-lg-3 d-flex justify-content-center">
                    <button
                      className="align-self-center btn btn-primary mr-1"
                      type="submit"
                    >
                      Allocate Student
                    </button>
                  </div>
                </div>
                <Toaster position="top-right" reverseOrder={false} />
              </div>
            </form>
          </div>
        ) : (
          <></>
        )}
        <div className="col-md-12 col-lg-12 col-xl-12">
          <div className="shadow-sm p-3 mb-5 bg-white rounded">
            <h6 className="text-center text-dark">Students Class Room List</h6>
            {currentUser.roles.includes("Upload_STUDENT_CLASS_ALLOCATION") ||
            currentUser.roles.includes("SUPER_ADMIN") ? (
              <>
                {/* Work on Upload */}
                <div>Upload Here</div>
              </>
            ) : (
              <></>
            )}
            <div style={{ width: "100%" }}>
              <DataGrid
                autoHeight
                columns={
                  currentUser.roles.includes(
                    "UPDATE_STUDENT_CLASS_ALLOCATION"
                  ) || currentUser.roles.includes("SUPER_ADMIN")
                    ? editableColumns
                    : columns
                }
                components={{
                  Toolbar: GridToolbar,
                  Pagination: CustomPagination,
                }}
                rows={data}
                getRowId={(row) => row.id}
                rowsPerPageOptions={[5, 10, 20]}
                rowHeight={45}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                getRowSpacing={(params) => ({
                  top: params.isFirstVisible ? 0 : 5,
                  bottom: params.isLastVisible ? 0 : 5,
                })}
                onCellEditCommit={(params) => setRowId(params.id)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StudentClassAllocation;
