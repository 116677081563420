import React, { useState } from "react";
import axios from "axios";
import { toast, Toaster } from "react-hot-toast";
import {
  toastDangerStyle,
  toastSuccessStyle,
  toastWarnigStyle,
} from "../../components/shared/Custom.Styles";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";

import { Link } from "react-router-dom";
import { API_URLS } from "../../utils/api.routes";
import { CustomPagination } from "../../components/custom/Custom.DataGrid";
import BookCategoryActions from "./BookCategoryActions";
import { useQuery } from "react-query";

export default function Categories() {
  const [category_title, setCode] = useState("");
  const [category_description, setName] = useState("");

  const [rowId, setRowId] = useState(null);
  const [pageSize, setPageSize] = useState(5);

  let headersList = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await fetch(API_URLS.library_categories, {
      method: "POST",
      headers: headersList,
      body: JSON.stringify({
        category_title: category_title,
        category_description: category_description,
      }),
    })
      .then((res) => {
        if (res.status === 200) {
          setCode("");
          setName("");
          refetch();
          toast.success(`Successfully Created Category`, toastSuccessStyle);
        } else if (res.status === 400) {
          toast.error(`Error !!! ${res.statusText}`, toastDangerStyle);
          setTimeout(() => {
            toast(`Seems Category Already Exists`, toastWarnigStyle);
          }, 1000);
        } else {
          toast.error(
            `${res.status} Error !!! ${res.statusText}`,
            toastDangerStyle
          );
        }
      })
      .catch((err) => {
        toast.error(`Error, Not Found !!!`, toastDangerStyle);
      });
  };

  async function fetchBookCategory() {
    const { data } = await axios.get(API_URLS.library_categories);
    return data;
  }
  const { data, error, isError, isLoading, refetch } = useQuery(
    "bookcategories",
    fetchBookCategory
  );
  if (isLoading) {
    return <div className="main-content">Loading...</div>;
  }
  if (isError) {
    return (
      <div className="main-content">
        <div>Error!! {error.message}</div>
      </div>
    );
  }

  const columns = [
    {
      field: "id",
      headerName: "SL",
      headerAlign: "center",
      type: "number",
      width: "80",
    },
    {
      field: "category_title",
      headerName: "Category Title",
      headerAlign: "center",
      editable: true,
      width: 120,
    },
    {
      field: "category_description",
      headerName: "Category Description",
      headerAlign: "center",
      editable: true,
      width: 120,
    },
    // {
    //   field: "actions",
    //   headerName: "Actions",
    //   type: "actions",
    //   width: 150,
    //   renderCell: (params) => (
    //     <BookCategoryActions {...{ params, rowId, setRowId }} />
    //   ),
    // },
  ];

  return (
    <div className="main-content">
      <div className="row">
        <div className="col-12 col-md-12 col-lg-12">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/admin">Home</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Book Categories
              </li>
            </ol>
          </nav>
        </div>
        <div className="col-md-6 col-lg-12 col-xl-4">
          <div className="shadow-sm p-3 mb-5 bg-white rounded">
            <div className="text-center text-dark">
              <h5>Add Category</h5>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="card-body">
                <div className="form-group">
                  <label htmlFor="sCode">Category Title</label>
                  <input
                    type="text"
                    id="category title"
                    className="form-control"
                    value={category_title}
                    onChange={(e) => setCode(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="sName">Category Description</label>
                  <input
                    type="text"
                    id="categorydescription"
                    className="form-control"
                    value={category_description}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
              </div>
              <div className="text-center">
                <button className="btn btn-primary mr-1" type="submit">
                  Add New Category
                </button>
                <Toaster position="top-right" reverseOrder={false} />
              </div>
            </form>
          </div>
        </div>
        <div className="col-md-6 col-lg-12 col-xl-8">
          <div className="shadow-sm p-3 mb-5 bg-white rounded">
            <div className="text-center">
              <h5 className="text-center text-dark">Book Catrgories List</h5>
            </div>
            <div style={{ width: "100%" }}>
              <DataGrid
                autoHeight
                columns={columns}
                rows={data}
                components={{
                  Toolbar: GridToolbar,
                  Pagination: CustomPagination,
                }}
                getRowId={(row) => row.id}
                rowsPerPageOptions={[5, 10, 20]}
                rowHeight={45}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                getRowSpacing={(params) => ({
                  top: params.isFirstVisible ? 0 : 5,
                  bottom: params.isLastVisible ? 0 : 5,
                })}
                onCellEditCommit={(params) => setRowId(params.id)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
