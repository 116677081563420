import React from "react";
import { DirectionsBusFilledOutlined } from "@mui/icons-material";
import { Link } from "react-router-dom";
import authService from "../../services/auth.service";

export default function Quizzes() {
  const currentUser = authService.getCurrentUser();
  return (
    <>
      <li className="dropdown">
        <Link to="#" className="menu-toggle nav-link has-dropdown">
          <DirectionsBusFilledOutlined fontSize="medium" className="icon-mr" />
          <span>Quizzes</span>
        </Link>
        <ul className="dropdown-menu">
          <li>
            <Link className="nav-link" to="/admin/quizzes/addquiz">
              Add Quiz
            </Link>
          </li>
        </ul>
      </li>
    </>
  );
}
