import React from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";

const AccountForm = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  return (
    <>
      <div className="d-flex flex-wrap justify-content-center">
        <div className="col-6 col-md-4 col-lg-4">
          <Controller
            control={control}
            name="bankaccount_name"
            render={({ field }) => (
              <TextField
                id="bankaccount-name"
                label="Bank Account Name"
                variant="outlined"
                placeholder="Enter Bank Account Name"
                fullWidth
                margin="normal"
                {...field}
              />
            )}
          />
        </div>
        <div className="col-6 col-md-4 col-lg-4">
          <Controller
            control={control}
            name="account_number"
            render={({ field }) => (
              <TextField
                id="account-number"
                label="Account Number"
                variant="outlined"
                placeholder="Enter Account Number"
                fullWidth
                margin="normal"
                {...field}
              />
            )}
          />
        </div>
        <div className="col-6 col-md-4 col-lg-4">
          <Controller
            control={control}
            name="bank_name"
            render={({ field }) => (
              <TextField
                id="current-address"
                label="Bank Name *"
                variant="outlined"
                placeholder="Enter Bank Name"
                fullWidth
                margin="normal"
                {...field}
              />
            )}
          />
        </div>
        <div className="col-6 col-md-4 col-lg-4">
          <Controller
            control={control}
            name="branch_name"
            render={({ field }) => (
              <TextField
                id="bank-name"
                label="Branch Name"
                variant="outlined"
                placeholder="Enter Branch Name"
                fullWidth
                margin="normal"
                {...field}
              />
            )}
          />
        </div>
        <div className="col-6 col-md-6 col-lg-4">
          <Controller
            control={control}
            name="contract_type"
            rules={{ required: "this field is required." }}
            render={({ field }) => (
              <FormControl fullWidth>
                <InputLabel id="contact-type">Contract Type</InputLabel>
                <Select
                  labelId="contact-type"
                  id="contact_type"
                  label="Contact Type"
                  {...field}
                >
                  <MenuItem value="" disabled>
                    Choose Contact Type
                  </MenuItem>
                  <MenuItem value="Internship">Internship</MenuItem>
                  <MenuItem value="Attachment">Attachment</MenuItem>
                  <MenuItem value="Permanent">Permanent</MenuItem>
                </Select>
              </FormControl>
            )}
          />
        </div>
        <div className="col-6 col-md-6 col-lg-4">
          <Controller
            control={control}
            name="contract_length"
            rules={{ required: "this field is required." }}
            render={({ field }) => (
              <FormControl fullWidth>
                <InputLabel id="contact-type">Contract Length</InputLabel>
                <Select
                  labelId="contact-length"
                  id="contact_length"
                  label="Contact Length"
                  {...field}
                >
                  <MenuItem value="" disabled>
                    Choose Contact Length
                  </MenuItem>
                  <MenuItem value="3 Months">3 Months</MenuItem>
                  <MenuItem value="6 Months">6 Months</MenuItem>
                  <MenuItem value="1 Year">1 Year</MenuItem>
                  <MenuItem value="2 Years">2 Year</MenuItem>
                  <MenuItem value="Permanent">Permanent</MenuItem>
                </Select>
              </FormControl>
            )}
          />
        </div>
        <div className="col-6 col-md-4 col-lg-4">
          <Controller
            control={control}
            name="basic_salary"
            render={({ field }) => (
              <TextField
                id="basic-salary"
                label="Basic Salary"
                variant="outlined"
                placeholder="Enter Salary"
                fullWidth
                margin="normal"
                {...field}
              />
            )}
          />
        </div>
      </div>
    </>
  );
};

export default AccountForm;
