import axios from "axios";
import React from "react";
import { toast, Toaster } from "react-hot-toast";
import { useQuery } from "react-query";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  toastDangerStyle,
  toastSuccessStyle,
} from "../../../components/shared/Custom.Styles";
import { API_URLS } from "../../../utils/api.routes";
import ClassAttendance from "./ClassAttendance";
import { useState } from "react";
import { useEffect } from "react";
import ClassroomTimetable from "../../lessonplan/timetable/ClassroomTimetable";

function ClassRoomDetails() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();
  console.log(id);

  const [students, setStudents] = useState(null);
  useEffect(() => {
    const fetchStudentsPerClass = async () => {
      const res = await fetch(API_URLS.per_classroom_students.concat(id, "/"));
      const data = await res.json();
      setStudents(data);
    };
    fetchStudentsPerClass();
  }, []);
  console.log(students);
  const editClassRoom = () => {
    navigate("/admin/classroom/edit", {
      state: data,
    });
  };

  const deleteClassRoom = async (id) => {
    const headersList = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    await fetch(API_URLS.class_room.concat(id, "/"), {
      method: "DELETE",
      headers: headersList,
    })
      .then((res) => {
        if (res.status === 204) {
          toast.success(`Deleted Classroom Successfully`, toastSuccessStyle);
          setTimeout(() => {
            navigate("/classrooms");
          }, 1000);
        } else {
          toast.error(`Error !!! ${res.statusText}`, toastDangerStyle);
        }
      })
      .catch((err) => {
        toast.error(`Server Error !!! Not Found`, toastDangerStyle);
      });
  };

  const fetchClassRoomDetails = async () => {
    const { data } = await axios.get(API_URLS.class_room.concat(id, "/"));
    return data;
  };
  const { data, error, isError, isLoading } = useQuery(
    "classroom",
    fetchClassRoomDetails
  );

  if (isLoading) {
    return (
      <div className="row">
        <h6>Loading...</h6>
      </div>
    );
  }
  if (isError) {
    return (
      <div className="row">
        <h6 className="text-danger">Error!!! {error.message}</h6>
      </div>
    );
  }
  return (
    <div className="main-content">
      <div className="row ">
        <div className="col-12 col-sm-12 col-md-8 ">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/admin">Home</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/admin/classroom/list">Classrooms</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Classroom Details
              </li>
            </ol>
          </nav>
        </div>
        <div className="col-12 col-sm-12 col-md-12 justify-content-center">
          <div className="shadow-sm p-3 mb-5 bg-white rounded">
            <h6 className="text-center text-dark">Class Room Details</h6>
            <div className="row card-body">
              <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                <p className="clearfix">
                  <span className="text-dark float-left font-weight-bold">
                    Class ID
                  </span>
                  <span className="float-right text-info font-weight-bold">
                    {data.id}
                  </span>
                </p>
                <p className="clearfix">
                  <span className="text-dark float-left font-weight-bold">
                    Room Code
                  </span>
                  <span className="float-right text-info font-weight-bold">
                    {data.class_room_code}
                  </span>
                </p>
                <p className="clearfix">
                  <span className="text-dark float-left font-weight-bold">
                    Room Name
                  </span>
                  <span className="float-right text-info font-weight-bold">
                    {data.class_room_name}
                  </span>
                </p>
                <p className="clearfix">
                  <span className="text-dark float-left font-weight-bold">
                    Capacity
                  </span>
                  <span className="float-right text-info font-weight-bold">
                    {data.capacity} Seats
                  </span>
                </p>
              </div>
              <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                <p className="clearfix">
                  <span className="text-dark float-left font-weight-bold">
                    Stream
                  </span>
                  <span className="float-right text-info font-weight-bold">
                    {data.stream_name}
                  </span>
                </p>
                <p className="clearfix">
                  <span className="text-dark float-left font-weight-bold">
                    Room Alocated
                  </span>
                  <span className="float-right text-info font-weight-bold">
                    {data.room_allocated}
                  </span>
                </p>
                <p className="clearfix">
                  <span className="text-dark float-left font-weight-bold">
                    Academic Year
                  </span>
                  <span className="float-right text-info font-weight-bold">
                    {data.year_name}
                  </span>
                </p>
                <p className="clearfix">
                  <span className="text-dark float-left font-weight-bold">
                    Class Teacher
                  </span>
                  <span className="float-right text-info font-weight-bold">
                    {data.class_teacher}
                  </span>
                </p>
              </div>
              <div className="col-12">
                <button
                  onClick={() => {
                    editClassRoom();
                  }}
                  className="btn btn-primary float-left"
                >
                  Edit
                </button>
                <button
                  onClick={() => {
                    deleteClassRoom(data.id);
                  }}
                  className="btn btn-danger float-right"
                >
                  Delete
                </button>
                <Toaster position="top-right" reverseOrder={false} />
              </div>
            </div>
          </div>
        </div>
        {data && (
          <div className="col-12 col-sm-12 col-md-12 justify-content-center">
            <ClassroomTimetable classroom={data.class_room_code} />
          </div>
        )}
        {students && (
          <div className="col-12 col-sm-12 col-md-12 justify-content-center">
            <ClassAttendance students={students} class_id={data.id} />
          </div>
        )}
        <div className="col-12 col-sm-12 col-md-12 justify-content-center"></div>
      </div>
      <div className="pb-5">
        <span className="text-info">@GICT Systems</span>
      </div>
    </div>
  );
}

export default ClassRoomDetails;
