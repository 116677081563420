import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { API_URLS } from "../../../utils/api.routes";

function SubjectTaughtDetails() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [message, setMessage] = useState("");

  const editSubject = () => {
    navigate("/admin/subject/edit", {
      state: state,
    });
  };
  const deleteSubject = async () => {
    const headersList = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    await fetch(API_URLS.subjects.concat(state.sub_id, "/"), {
      method: "DELETE",
      headers: headersList,
    })
      .then((res) => {
        if (res.status === 204) {
          setMessage("Deleted Successfully");
          navigate("/subjects");
        } else {
          setMessage("Error");
        }
      })
      .catch((err) => {
        setMessage("An Error Occured");
        console.log(message);
      });
  };
  console.log(state);
  return (
    <div className="main-content">
      <div className="row justify-content-center">
        <div className="col-12 col-md-6 col-sm-12">
          <div className="card">
            <div className="card-header">
              <h4>Subject Info</h4>
            </div>
            <div className="card-body">
              <div className="py-4">
                <p className="clearfix">
                  <span className="float-left">Subject ID</span>
                  <span className="float-right text-muted">{state.sub_id}</span>
                </p>
                <p className="clearfix">
                  <span className="float-left">Subject Code</span>
                  <span className="float-right text-muted">
                    {state.sub_code}
                  </span>
                </p>
                <p className="clearfix">
                  <span className="float-left">Subject Name</span>
                  <span className="float-right text-muted">
                    {state.sub_name}
                  </span>
                </p>
                <div>
                  <button
                    onClick={() => {
                      editSubject();
                    }}
                    className="btn btn-sm btn-info float-left"
                  >
                    Edit
                  </button>
                  <button
                    onClick={() => {
                      deleteSubject();
                    }}
                    className="btn btn-sm btn-danger float-right"
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SubjectTaughtDetails;
