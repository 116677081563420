import React, { Component } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { isEmail } from "validator";

import AuthService from "../../services/auth.service";
import { Link } from "react-router-dom";

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

const email = (value) => {
  if (!isEmail(value)) {
    return (
      <div className="alert alert-danger" role="alert">
        This is not a valid email.
      </div>
    );
  }
};

const vusername = (value) => {
  if (value.length < 3 || value.length > 20) {
    return (
      <div className="alert alert-danger" role="alert">
        The username must be between 3 and 20 characters.
      </div>
    );
  }
};

const vpassword = (value) => {
  if (value.length < 6 || value.length > 40) {
    return (
      <div className="alert alert-danger" role="alert">
        The password must be between 6 and 40 characters.
      </div>
    );
  }
};

export default class Register extends Component {
  constructor(props) {
    super(props);
    this.handleRegister = this.handleRegister.bind(this);
    this.onChangeUsername = this.onChangeUsername.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);
    this.handleOptionChange = this.handleOptionChange.bind(this);
    this.handleStudCheckboxChange = this.handleStudCheckboxChange.bind(this);
    this.handleStaffCheckboxChange = this.handleStaffCheckboxChange.bind(this);
    this.handleParentCheckboxChange =
      this.handleParentCheckboxChange.bind(this);
    this.onChangeStudInputValue = this.onChangeStudInputValue.bind(this);
    this.handleTogglePassword = this.handleTogglePassword.bind(this);

    this.state = {
      username: "",
      email: "",
      password: "",
      confirm_password: "",
      category: "student",
      successful: false,
      message: "",
      // students: [],
      // studInputValue: "",
      selectedOption: "option1",
      showStudent: true,
      showStaff: false,
      showParent: false,
      showPassword: false,
      passwordsMatch: true,
    };
  }

  onChangeUsername(e) {
    this.setState({
      username: e.target.value,
    });
  }

  onChangeEmail(e) {
    this.setState({
      email: e.target.value,
    });
  }

  onChangeStudInputValue(e) {
    // this.setState({
    //   studInputValue: e.target.value,
    // });
    const value = e.target.value;
    const students = value.split(",");
    this.setState({ students: students });
  }

  onChangePassword(e) {
    const target = e.target;
    const value = target.value;
    const name = target.name;

    if (name === "password") {
      this.setState({ password: value }, () => {
        if (this.state.confirm_password !== "") {
          this.setState({
            passwordsMatch: this.state.password === this.state.confirm_password,
          });
        }
      });
    } else if (name === "confirmPassword") {
      this.setState({
        confirm_password: value,
        passwordsMatch: this.state.password === value,
      });
    }
  }

  handleOptionChange(e) {
    this.setState({ selectedOption: e.target.value });
  }

  handleStudCheckboxChange(e) {
    this.setState({
      showStudent: e.target.checked,
      category: "student",
      showStaff: false,
      showParent: false,
    });
  }

  handleStaffCheckboxChange(e) {
    this.setState({
      showStaff: e.target.checked,
      category: "staff",
      showStudent: false,
      showParent: false,
    });
  }

  handleParentCheckboxChange(e) {
    this.setState({
      showParent: e.target.checked,
      category: "parent",
      showStudent: false,
      showStaff: false,
    });
  }
  handleTogglePassword() {
    this.setState((prevState) => ({ showPassword: !prevState.showPassword }));
  }

  handleRegister(e) {
    e.preventDefault();

    this.setState({
      message: "",
      successful: false,
    });

    this.form.validateAll();

    if (this.checkBtn.context._errors.length === 0) {
      AuthService.register(
        this.state.username,
        this.state.email,
        this.state.password,
        this.state.category
        // this.state.students
      ).then(
        (response) => {
          this.setState({
            message: response.data.message,
            successful: true,
          });
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          this.setState({
            successful: false,
            message: resMessage,
          });
        }
      );
    }
  }

  render() {
    const {
      showPassword,
      passwordsMatch,
      // students,
      showStudent,
      showStaff,
      showParent,
    } = this.state;
    return (
      <div
        className="col-md-12  d-flex justify-content-center"
        style={{ top: 80 }}
      >
        <div style={{ width: 450 }}>
          <div className="shadow-sm p-3 mb-5 bg-white rounded">
            {/* <img
              src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
              alt="profile-img"
              className="profile-img-card"
            /> */}

            <Form
              onSubmit={this.handleRegister}
              ref={(c) => {
                this.form = c;
              }}
            >
              {!this.state.successful && (
                <div>
                  <div className="form-group d-flex flex-wrap">
                    <label className="col-12">Choose User Category</label>
                    <div className="pretty p-default p-round d-flex col-4">
                      <input
                        type="radio"
                        name="radio1"
                        checked={showStudent}
                        onChange={this.handleStudCheckboxChange}
                      />
                      <label className="text-dark ml-1">Student</label>
                    </div>
                    <div className="pretty p-default p-round d-flex col-4">
                      <input
                        type="radio"
                        name="radio1"
                        checked={showStaff}
                        onChange={this.handleStaffCheckboxChange}
                      />
                      <label className="text-dark ml-1">Staff</label>
                    </div>
                    <div className="pretty p-default p-round d-flex col-4">
                      <input
                        type="radio"
                        name="radio1"
                        checked={showParent}
                        onChange={this.handleParentCheckboxChange}
                      />
                      <label className="text-dark ml-1">Parent</label>
                    </div>
                  </div>
                  {showStudent && (
                    <div className="form-group">
                      <label htmlFor="password">
                        Username (Admission Number)
                      </label>
                      <Input
                        type="text"
                        className="form-control"
                        name="username"
                        value={this.state.username}
                        onChange={this.onChangeUsername}
                        validations={[required, vusername]}
                      />
                    </div>
                  )}
                  {showStaff && (
                    <div className="form-group">
                      <label htmlFor="password">Username (Staff Number)</label>
                      <Input
                        type="text"
                        className="form-control"
                        name="username"
                        value={this.state.username}
                        onChange={this.onChangeUsername}
                        validations={[required, vusername]}
                      />
                    </div>
                  )}
                  {showParent && (
                    <div className="form-group">
                      <label htmlFor="password">Parent username</label>
                      <Input
                        type="text"
                        className="form-control"
                        name="username"
                        value={this.state.username}
                        onChange={this.onChangeUsername}
                        validations={[required, vusername]}
                      />
                    </div>
                  )}
                  {/* <div className="form-group">
                    <label htmlFor="username">Username</label>
                    <Input
                      type="text"
                      className="form-control"
                      name="username"
                      value={this.state.username}
                      onChange={this.onChangeUsername}
                      validations={[required, vusername]}
                    />
                  </div> */}

                  <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <Input
                      type="text"
                      className="form-control"
                      name="email"
                      value={this.state.email}
                      onChange={this.onChangeEmail}
                      validations={[required, email]}
                    />
                  </div>
                  {/* {showParent && (
                    <div className="form-group">
                      <label htmlFor="password">Students</label>
                      <Input
                        type="text"
                        className="form-control"
                        name="students"
                        value={this.state.studInputValue}
                        onChange={this.onChangeStudInputValue}
                      />
                    </div>
                  )} */}

                  <div className="form-group">
                    <label htmlFor="password">Password</label>
                    <Input
                      type={showPassword ? "text" : "password"}
                      className="form-control"
                      name="password"
                      value={this.state.password}
                      onChange={this.onChangePassword}
                      validations={[required, vpassword]}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="password">Confirm Password</label>
                    <Input
                      type="password"
                      className="form-control"
                      name="confirmPassword"
                      value={this.state.confirm_password}
                      onChange={this.onChangePassword}
                      validations={[required, vpassword]}
                    />
                  </div>
                  <div className="form-group">
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="defaultCheck1"
                        checked={showPassword}
                        onChange={this.handleTogglePassword}
                      />
                      <label
                        className="form-check-label text-dark ml-1"
                        for="defaultCheck1"
                      >
                        Show Password
                      </label>
                    </div>
                  </div>
                  {passwordsMatch ? null : (
                    <div style={{ color: "red" }}>Passwords do not match</div>
                  )}
                  {/* <ul>
                    {students.map((value) => (
                      <li key={value} className="text-dark">
                        {value}
                      </li>
                    ))}
                  </ul> */}

                  <div className="form-group">
                    <button
                      className="btn btn-primary btn-block"
                      style={{ backgroundColor: "#556ee6" }}
                    >
                      Sign Up
                    </button>
                    <div className="justify-content-between mt-2 d-flex small">
                      <Link to="/account/login" style={{ color: "#556ee6" }}>
                        Already have an account?
                      </Link>
                    </div>
                  </div>
                </div>
              )}

              {this.state.message && (
                <div className="form-group">
                  <div
                    className={
                      this.state.successful
                        ? "alert alert-success"
                        : "alert alert-danger"
                    }
                    role="alert"
                  >
                    {this.state.message}
                  </div>
                </div>
              )}
              <CheckButton
                style={{ display: "none" }}
                ref={(c) => {
                  this.checkBtn = c;
                }}
              />
            </Form>
          </div>
        </div>
      </div>
    );
  }
}
