import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import axios from "axios";
import React, { useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { Link } from "react-router-dom";
import { useQuery } from "react-query";
import { Button } from "@mui/material";
import { Send } from "@mui/icons-material";
import { ClassSearchSelectInput } from "../../../components/custom/Custom.SearchInput";
import { API_URLS } from "../../../utils/api.routes";
import MarksGradeActions from "./MarksGradeActions";
import {
  toastDangerStyle,
  toastSuccessStyle,
  toastWarnigStyle,
} from "../../../components/shared/Custom.Styles";
import { CustomPagination } from "../../../components/custom/Custom.DataGrid";
import {
  CustomIsError,
  CustomIsLoading,
} from "../../../components/custom/Custom.UseQuery";
import authService from "../../../services/auth.service";

export default function MarksGrade() {
  const currentUser = authService.getCurrentUser();

  // input fields change to respective lesson plan fields
  const [grade, setGrade] = useState("");
  const [GPA, setGPA] = useState("");
  const [percent_from, setPercentfrom] = useState("");
  const [percent_to, setPercentto] = useState("");
  const [GPA_from, setGPAfrom] = useState("");
  const [GPA_to, setGPAto] = useState("");
  const [description, setDescription] = useState("");

  //import file hook
  const [file, setFile] = useState();

  // for pagination
  const [rowId, setRowId] = useState(null);
  const [pageSize, setPageSize] = useState(5);

  let headersList = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };

  // change to respective lesson plan body
  let bodyContent = JSON.stringify({
    grade: grade,
    GPA: GPA,
    percent_from: percent_from,
    percent_to: percent_to,
    GPA_from: GPA_from,
    GPA_to: GPA_to,
    description: description,
  });
  const handleSubmit = async (e) => {
    e.preventDefault();
    await fetch(API_URLS.exam_marksgrade, {
      method: "POST",
      headers: headersList,
      body: bodyContent,
    })
      .then((res) => {
        if (res.status === 201) {
          setGrade("");
          setGPA("");
          setPercentfrom("");
          setPercentto("");
          setGPAfrom("");
          setGPAto("");
          setDescription("");

          refetch();
          toast.success(`Successfully Created Marks Grade`, toastSuccessStyle);
        } else if (res.status === 400) {
          toast.error(`Error !!! ${res.statusText}`, toastDangerStyle);
          setTimeout(() => {
            toast(`Seems Marks Grade Already Exists`, toastWarnigStyle);
          }, 1000);
        } else {
          toast.error(
            `${res.status} Error !!! ${res.statusText}`,
            toastDangerStyle
          );
        }
      })
      .catch((err) => {
        toast.error(`Error, Not Found !!!`, toastDangerStyle);
      });
  };

  //import
  const handleFileSelection = (e) => {
    const selectedFile = e.target.files[0];
    if (
      selectedFile.type === "text/csv" ||
      selectedFile.type === "application/json" ||
      selectedFile.type === "application/vnd.ms-excel" ||
      selectedFile.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      setFile(selectedFile);
    } else {
      toast("Please select a CSV, JSON or Excel file.", toastWarnigStyle);
    }
  };

  const UploadFile = () => {
    const formData = new FormData();
    formData.append("file", file);

    fetch(API_URLS.upload_marksgrade, {
      method: "POST",
      body: formData,
    })
      .then((res) => {
        if (res.status === 200) {
          refetch();
          setFile(null);
          toast.success("Upload Successful", toastSuccessStyle);
        }
      })
      .catch((error) => {
        toast.error(`Upload Error ${error}`, toastDangerStyle);
      });
  };

  //

  async function fetchMarksGrade() {
    const { data } = await axios.get(API_URLS.exam_marksgrade);
    return data;
  }
  const { data, error, isError, isLoading, refetch } = useQuery(
    "exam_marksgrade",
    fetchMarksGrade
  );
  if (isLoading) {
    return <CustomIsLoading />;
  }
  if (isError) {
    return <CustomIsError message={error.message} />;
  }

  const editableColumns = [
    {
      field: "id",
      headerName: "SL",
      headerAlign: "center",
      type: "number",
      width: "80",
    },
    {
      field: "grade",
      headerName: "Grade",
      headerAlign: "center",
      editable: true,
      width: 120,
    },
    {
      field: "GPA",
      headerName: "GPA",
      headerAlign: "center",
      editable: true,
      width: 120,
    },
    {
      field: "percent_from",
      headerName: "Percent_from",
      headerAlign: "center",
      editable: true,
      width: 120,
    },
    {
      field: "percent_to",
      headerName: "Percent_to",
      headerAlign: "center",
      editable: true,
      width: 120,
    },
    {
      field: "GPA_from",
      headerName: "GPA_from",
      headerAlign: "center",
      editable: true,
      width: 120,
    },
    {
      field: "GPA_to",
      headerName: "GPA_to",
      headerAlign: "center",
      editable: true,
      width: 120,
    },
    {
      field: "description",
      headerName: "Description",
      headerAlign: "center",
      editable: true,
      width: 120,
    },

    {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      renderCell: (params) => (
        <MarksGradeActions {...{ params, rowId, setRowId }} />
      ),
    },
  ];
  const columns = [
    {
      field: "id",
      headerName: "SL",
      headerAlign: "center",
      type: "number",
      width: "80",
    },
    {
      field: "grade",
      headerName: "Grade",
      headerAlign: "center",
      width: 120,
    },
    {
      field: "GPA",
      headerName: "GPA",
      headerAlign: "center",
      width: 120,
    },
    {
      field: "percent_from",
      headerName: "Percent_from",
      headerAlign: "center",
      width: 120,
    },
    {
      field: "percent_to",
      headerName: "Percent_to",
      headerAlign: "center",
      width: 120,
    },
    {
      field: "GPA_from",
      headerName: "GPA_from",
      headerAlign: "center",
      width: 120,
    },
    {
      field: "GPA_to",
      headerName: "GPA_to",
      headerAlign: "center",
      width: 120,
    },
    {
      field: "description",
      headerName: "Description",
      headerAlign: "center",
      width: 120,
    },

    {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      width: 150,
      renderCell: (params) => (
        <MarksGradeActions {...{ params, rowId, setRowId }} />
      ),
    },
  ];
  return (
    <div className="main-content">
      <div className="row">
        <div className="col-12 col-md-12 col-lg-12">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/admin">Home</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Marks Grade
              </li>
            </ol>
          </nav>
        </div>
        {currentUser.roles.includes("ADD_EXAM_MARKS_GRADE") ||
        currentUser.roles.includes("SUPER_ADMIN") ? (
          <div className="col-md-6 col-lg-12 col-xl-4">
            <div className="shadow-sm p-3 mb-5 bg-white rounded">
              <div className="text-center text-dark">
                <h5>Add Marks Grade</h5>
              </div>
              <form onSubmit={handleSubmit}>
                <div className="card-body">
                  <div className="form-group">
                    <label htmlFor="sGrade">Grade</label>
                    <ClassSearchSelectInput
                      onSelectedItemChange={(item) => {
                        setGrade(item.id);
                      }}
                      searchFields={["class_room_code"]}
                    />
                    <p className="text-dark">Selected Grade: {grade}</p>
                  </div>
                  <div className="form-group">
                    <label htmlFor="sGPA">GPA</label>
                    <input
                      type="text"
                      id="sGPA"
                      className="form-control"
                      value={GPA}
                      onChange={(e) => setGPA(e.target.value)}
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="sPercentFrom">Percent From</label>
                    <input
                      type="text"
                      id="sPercentFrom"
                      className="form-control"
                      value={percent_from}
                      onChange={(e) => setPercentfrom(e.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="sPercentTo">Percent To</label>
                    <input
                      type="text"
                      id="sPercentTo"
                      className="form-control"
                      value={percent_to}
                      onChange={(e) => setPercentto(e.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="sGPAFrom">GPA From</label>
                    <input
                      type="text"
                      id="sGPAFrom"
                      className="form-control"
                      value={GPA_from}
                      onChange={(e) => setGPAfrom(e.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="sGPATo">GPA To</label>
                    <input
                      type="text"
                      id="sGPATo"
                      className="form-control"
                      value={GPA_to}
                      onChange={(e) => setGPAto(e.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="sDescription">Description</label>
                    <input
                      type="text"
                      id="sDescription"
                      className="form-control"
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                    />
                  </div>
                </div>
                <div className="text-center">
                  <button className="btn btn-primary mr-1" type="submit">
                    Add New Marks Grade
                  </button>
                  <Toaster position="top-right" reverseOrder={false} />
                </div>
              </form>
            </div>
          </div>
        ) : (
          <></>
        )}
        <div className="col-md-6 col-lg-12 col-xl-8">
          <div className="shadow-sm p-3 mb-5 bg-white rounded">
            <div className="text-center">
              <h5 className="text-center text-dark">Exam Schedule List</h5>
              {currentUser.roles.includes("UPLOAD_EXAM_MARKS_GRADE") ||
              currentUser.roles.includes("SUPER_ADMIN") ? (
                <div className="d-flex justify-content-end">
                  <div className="d-flex m-1">
                    <Button variant="text" component="label">
                      Import
                      <input
                        hidden
                        type={"file"}
                        id={"csvFileInput"}
                        accept=".csv,.json,.xlsx,application/vnd.ms-excel"
                        onChange={handleFileSelection}
                      />
                    </Button>
                    {file && (
                      <div className="text-info align-self-center">
                        File: {file.name}
                      </div>
                    )}
                  </div>
                  <Button
                    size="small"
                    variant="contained"
                    endIcon={<Send />}
                    onClick={UploadFile}
                  >
                    Submit
                  </Button>
                </div>
              ) : (
                <></>
              )}
            </div>

            <div style={{ height: 420 }}>
              <DataGrid
                columns={
                  currentUser.roles.includes("UPDATE_EXAM_MARKS_GRADE") ||
                  currentUser.roles.includes("SUPER_ADMIN")
                    ? editableColumns
                    : columns
                }
                components={{
                  Toolbar: GridToolbar,
                  Pagination: CustomPagination,
                }}
                rows={data}
                getRowId={(row) => row.id}
                rowsPerPageOptions={[5, 10, 20]}
                rowHeight={45}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                getRowSpacing={(params) => ({
                  top: params.isFirstVisible ? 0 : 5,
                  bottom: params.isLastVisible ? 0 : 5,
                })}
                onCellEditCommit={(params) => setRowId(params.id)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
