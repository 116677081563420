import React from "react";
import { NoteAltOutlined } from "@mui/icons-material";
import { Link } from "react-router-dom";
import authService from "../../services/auth.service";

export default function LessonPlan() {
  const currentUser = authService.getCurrentUser();
  return (
    <>
      {/* Lesson Plan Section */}
      <li className="dropdown">
        <Link to="#" className="menu-toggle nav-link has-dropdown">
          <NoteAltOutlined fontSize="medium" className="icon-mr" />
          <span>Lesson Plan</span>
        </Link>
        <ul className="dropdown-menu">
          {currentUser.roles.includes("VIEW_TOPIC") ||
          currentUser.roles.includes("SUPER_ADMIN") ? (
            <li>
              <Link className="nav-link" to="/admin/lesson-plan/topic">
                Topic
              </Link>
            </li>
          ) : (
            <></>
          )}
          {currentUser.roles.includes("VIEW_SUB_TOPIC") ||
          currentUser.roles.includes("SUPER_ADMIN") ? (
            <li>
              <Link className="nav-link" to="/admin/lesson-plan/subtopic">
                Sub Topic
              </Link>
            </li>
          ) : (
            <></>
          )}
          {currentUser.roles.includes("VIEW_LESSON") ||
          currentUser.roles.includes("SUPER_ADMIN") ? (
            <li>
              {/*  */}
              <Link className="nav-link" to="/admin/lesson-plan/lesson">
                Lesson
              </Link>
            </li>
          ) : (
            <></>
          )}
          {currentUser.roles.includes("VIEW_SCHEMES_OF_WORK") ||
          currentUser.roles.includes("SUPER_ADMIN") ? (
            <li>
              <Link className="nav-link" to="/admin/lesson-plan/schemesofwork">
                Schemes of Work
              </Link>
            </li>
          ) : (
            <></>
          )}
          {currentUser.roles.includes("VIEW_TIMEPLAN") ||
          currentUser.roles.includes("SUPER_ADMIN") ? (
            <li>
              <Link className="nav-link" to="/admin/lesson-plan/timeplan">
                TimePlan
              </Link>
            </li>
          ) : (
            <></>
          )}
          {/* {currentUser.roles.includes("VIEW_TIMETABLE") ||
          currentUser.roles.includes("SUPER_ADMIN") ? (
            <li>
              <Link className="nav-link" to="/admin/lesson-plan/timetable">
                Timetable
              </Link>
            </li>
          ) : (
            <></>
          )} */}
          {currentUser.roles.includes("VIEW_TIMETABLE") ||
          currentUser.roles.includes("SUPER_ADMIN") ? (
            <li>
              <Link className="nav-link" to="/admin/lesson-plan/new-timetable">
                Timetable
              </Link>
            </li>
          ) : (
            <></>
          )}
          {currentUser.roles.includes("VIEW_ASSESSMENT_DETAILS") ||
          currentUser.roles.includes("SUPER_ADMIN") ? (
            <li>
              <Link
                className="nav-link"
                to="/admin/lesson-plan/assessmentdetails"
              >
                Assessment Details
              </Link>
            </li>
          ) : (
            <></>
          )}
          {currentUser.roles.includes("VIEW_ASSESSMENT_RUBRICS") ||
          currentUser.roles.includes("SUPER_ADMIN") ? (
            <li>
              <Link
                className="nav-link"
                to="/admin/lesson-plan/assessmentrubrics"
              >
                Assessment Rubrics
              </Link>
            </li>
          ) : (
            <></>
          )}
          {currentUser.roles.includes("VIEW_DIARY") ||
          currentUser.roles.includes("SUPER_ADMIN") ? (
            <li>
              <Link className="nav-link" to="/admin/lesson-plan/diary">
                Diary
              </Link>
            </li>
          ) : (
            <></>
          )}
        </ul>
      </li>
    </>
  );
}
