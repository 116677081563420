import {
  DataGrid,
  GridToolbar,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import {
  CustomPagination,
  StyledDataGrid,
} from "../../../components/custom/Custom.DataGrid";
import { API_URLS } from "../../../utils/api.routes";
import ClassRoomActions from "./ClassRoomActions";
import "./styles.css";
import { DataGridPremium } from "@mui/x-data-grid-premium";
import authService from "../../../services/auth.service";

export default function ClassRoom() {
  const currentUser = authService.getCurrentUser();

  const [rowId, setRowId] = useState(null);
  const [pageSize, setPageSize] = useState(5);

  async function fetchClassRooms() {
    const { data } = await axios.get(API_URLS.class_room);
    return data;
  }
  const { data, error, isError, isLoading } = useQuery(
    "classrooms",
    fetchClassRooms
  );
  if (isLoading) {
    return <div className="main-content">Loading...</div>;
  }
  if (isError) {
    return (
      <div className="main-content">
        <div>Error!! {error.message}</div>
      </div>
    );
  }

  const editableColumns = [
    {
      field: "id",
      headerName: "SL",
      type: "number",
      hide: true,
      headerAlign: "center",
      width: 80,
    },
    {
      field: "stream_code",
      headerName: "Stream Code",
      headerAlign: "center",
      hide: true,
      editable: true,
      width: 100,
    },
    {
      field: "stream_name",
      headerName: "Stream",
      headerAlign: "center",
      hidable: false,
      editable: false,
      width: 100,
    },
    {
      field: "class_room_code",
      headerName: "Classroom Code",
      headerAlign: "center",
      hidable: false,
      editable: true,
      width: 130,
    },
    {
      field: "class_room_name",
      headerName: "Classroom Name",
      headerAlign: "center",
      hidable: false,
      editable: true,
      width: 130,
    },
    {
      field: "class_teacher",
      headerName: "Class Teacher",
      headerAlign: "center",
      hide: true,
      editable: true,
      width: 150,
    },
    {
      field: "year_name",
      headerName: "Academic Year",
      headerAlign: "center",
      editable: false,
      width: 130,
    },
    {
      field: "academic_year",
      headerName: "Year ID",
      headerAlign: "center",
      editable: true,
      hide: true,
      width: 130,
    },
    {
      field: "capacity",
      headerName: "Capacity",
      headerAlign: "center",
      editable: true,
      width: 100,
    },
    {
      field: "room_allocated",
      headerName: "Room",
      headerAlign: "center",
      editable: true,
      width: 100,
    },
    {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      width: 150,
      renderCell: (params) => (
        <ClassRoomActions {...{ params, rowId, setRowId }} />
      ),
    },
  ];
  const columns = [
    {
      field: "id",
      headerName: "SL",
      type: "number",
      hide: true,
      headerAlign: "center",
      width: 80,
    },
    {
      field: "stream_name",
      headerName: "Stream",
      headerAlign: "center",
      hidable: false,
      width: 100,
    },
    {
      field: "class_room_code",
      headerName: "Classroom Code",
      headerAlign: "center",
      hidable: false,
      width: 130,
    },
    {
      field: "class_room_name",
      headerName: "Classroom Name",
      headerAlign: "center",
      hidable: false,
      width: 130,
    },
    {
      field: "class_teacher",
      headerName: "Class Teacher",
      headerAlign: "center",
      hide: true,
      width: 150,
    },
    {
      field: "year_name",
      headerName: "Academic Year",
      headerAlign: "center",
      width: 130,
    },
    {
      field: "capacity",
      headerName: "Capacity",
      headerAlign: "center",
      width: 100,
    },
    {
      field: "room_allocated",
      headerName: "Room",
      headerAlign: "center",
      width: 100,
    },
  ];
  return (
    // <ThemeProvider theme={customTheme}>
    <div className="main-content">
      <div className="row justify-content-center">
        <div className="col-12 col-md-12 col-lg-12">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/admin">Home</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Class Rooms
              </li>
              {currentUser.roles.includes("ADD_CLASS_ROOM") ||
              currentUser.roles.includes("SUPER_ADMIN") ? (
                <div className="col-4">
                  <Link to="/admin/classroom/add" className="font-weight-bold">
                    Add New Class Room
                  </Link>
                  <Link to="/admin/classroom/custom-1">Custom Fields</Link>
                </div>
              ) : (
                <></>
              )}
            </ol>
          </nav>
        </div>
        <div className="col-md-12 col-lg-12 col-xl-12">
          <div className="shadow-sm p-3 mb-5 bg-white rounded">
            <div className="text-center">
              <h5 className="text-center text-dark">Class Rooms List</h5>
            </div>
            <div style={{ width: "100%" }}>
              <DataGrid
                autoHeight
                columns={
                  currentUser.roles.includes("UPDATE_GRADE") ||
                  currentUser.roles.includes("SUPER_ADMIN")
                    ? editableColumns
                    : columns
                }
                rows={data}
                components={{
                  Toolbar: GridToolbar,
                  // Toolbar: CustomToolbar,
                  Pagination: CustomPagination,
                }}
                componentsProps={{
                  toolbar: {
                    printOptions: {
                      hideToolbar: true,
                      fileHead: "Registered Classrooms",
                      fields: [
                        "id",
                        "stream_name",
                        "class_room_code",
                        "class_room_name",
                        "year_name",
                        "capacity",
                        "room_allocated",
                      ],
                    },
                    csvOptions: {
                      fields: [
                        "id",
                        "stream_name",
                        "class_room_code",
                        "class_room_name",
                        "year_name",
                        "capacity",
                        "room_allocated",
                      ],
                    },
                  },
                }}
                options={{
                  print: {
                    columns: ["id", "stream_name"],
                  },
                }}
                getRowId={(row) => row.id}
                rowsPerPageOptions={[5, 10, 20]}
                rowHeight={45}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                getRowSpacing={(params) => ({
                  top: params.isFirstVisible ? 0 : 5,
                  bottom: params.isLastVisible ? 0 : 5,
                })}
                onCellEditCommit={(params) => setRowId(params.id)}
              />
            </div>
          </div>
          {/* <div className="shadow-sm p-3 mb-5 bg-white rounded">
            <div className="text-center">
              <h5 className="text-center text-dark">Class Rooms List</h5>
            </div>
            <div style={{ width: "100%" }}>
              <StyledDataGrid
                autoHeight
                checkboxSelection
                columns={columns}
                components={{
                  Toolbar: GridToolbar,
                  Pagination: CustomPagination,
                }}
                rows={data}
                getRowId={(row) => row.id}
                rowsPerPageOptions={[5, 10, 20]}
                rowHeight={45}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                getRowSpacing={(params) => ({
                  top: params.isFirstVisible ? 0 : 5,
                  bottom: params.isLastVisible ? 0 : 5,
                })}
                onCellEditCommit={(params) => setRowId(params.id)}
              />
            </div>
          </div> */}
        </div>
      </div>
    </div>
    // </ThemeProvider>
  );
}
