import { LibraryAddOutlined } from "@mui/icons-material";
import React from "react";
import { Link } from "react-router-dom";
//import { PointOfSaleOutlined } from "@mui/icons-material";
//import { Link } from "react-router-dom";

export default function Library() {
  return (
    <>
      <li className="dropdown">
        <Link to="#" className="menu-toggle nav-link has-dropdown">
          <LibraryAddOutlined fontSize="medium" className="icon-mr" />
          <span>Library</span>
        </Link>
        <ul className="dropdown-menu">
          <li>
            <Link className="nav-link" to="/admin/library/add-book">
              Add Book
            </Link>
          </li>
          <li>
            <Link className="" to="/admin/library/add-member">
              Add Member
            </Link>
          </li>
          <li>
            <Link className="nav-link" to="/admin/library/all-issued-book">
              All Issued Books
            </Link>
          </li>
          <li>
            <Link className="nav-link" to="/admin/library/book-category">
              Book Category
            </Link>
          </li>
          <li>
            <Link className="nav-link" to="/admin/library/book-list">
              Book List
            </Link>
          </li>
          <li>
            <Link className="nav-link" to="/admin/library/book-subject">
              Book Subject
            </Link>
          </li>
          <li>
            <Link className="nav-link" to="/admin/library/issue-return-book">
              Issue/Return Books
            </Link>
          </li>
          <li>
            <Link className="nav-link" to="/admin/library/lost-books">
              Lost Books
            </Link>
          </li>
          <li>
            <Link className="nav-link" to="/admin/library/e-books">
              E-Books
            </Link>
          </li>
          <li>
            <Link className="nav-link" to="calendar.html">
              Report
            </Link>
          </li>
        </ul>
      </li>
    </>
  );
}
