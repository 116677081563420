import React, { useEffect } from "react";
import { API_URLS } from "../../../../utils/api.routes";
import { Toaster, toast } from "react-hot-toast";
import {
  toastDangerStyle,
  toastSuccessStyle,
  toastWarnigStyle,
} from "../../../../components/shared/Custom.Styles";
import { Button } from "@mui/material";
import { useState } from "react";

export default function Confirm(props) {
  const [designations, setDesignations] = useState([]);
  const [departments, setDepartments] = useState([]);

  const Back = (e) => {
    e.preventDefault();
    props.prevStep();
  };

  useEffect(() => {
    async function fetchDesignations() {
      const response = await fetch(API_URLS.designation);
      const data = await response.json();
      setDesignations(data);
    }
    async function fetchDepartments() {
      const response = await fetch(API_URLS.department);
      const data = await response.json();
      setDepartments(data);
    }
    fetchDesignations();
    fetchDepartments();
  }, []);
  const AddStaff = async (e) => {
    e.preventDefault();
    const headersList = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    await fetch(API_URLS.hr_staff, {
      method: "POST",
      headers: headersList,
      body: JSON.stringify(values),
    })
      .then((res) => {
        if (res.status === 201) {
          toast.success(`Staff Registered Successfully`, toastSuccessStyle);
          setTimeout(() => {
            props.nextStep();
          }, 2000);
        } else if (res.status === 400) {
          toast.error(`${res.statusText}`, toastDangerStyle);
          setTimeout(() => {
            toast(`Check And Compulsory Fields are added`, toastWarnigStyle);
          }, 1000);
        } else {
          toast.error(`${res.status} ${res.statusText}`, toastDangerStyle);
        }
      })
      .catch((err) => {
        toast.error(`Error Not Found !`, toastDangerStyle);
      });
    // PROCESS FORM //
  };
  const { values, handleChange } = props;
  console.log(values);
  return (
    <>
      <div>
        <h5 className="text-center text-dark">Confirm Staff Details</h5>
      </div>
      <div className="col-12 col-md-12 col-lg-12">
        <div className="form-group col-4">
          <label>Designation</label>
          <select
            className="form-control"
            onChange={handleChange("designation")}
            value={values.designation}
          >
            {designations.map((des, index) => {
              return (
                <option key={index} value={des.id}>
                  {des.title}
                </option>
              );
            })}
          </select>
        </div>
        <div className="form-group col-4">
          <label>Department</label>
          <select
            className="form-control"
            onChange={handleChange("department")}
            value={values.department}
          >
            {departments.map((dep, index) => {
              return (
                <option key={index} value={dep.id}>
                  {dep.name}
                </option>
              );
            })}
          </select>
        </div>

        <div className="">
          <div className="text-dark">admn {values.admn_no}</div>
          <div className="text-dark">First Name {values.first_name}</div>
          <div className="text-dark">First Name {values.last_name}</div>
          <div className="text-dark">First Name {values.other_name}</div>
          <div className="text-dark">First Name {values.gender}</div>
          <div className="text-dark">First Name {values.dob}</div>
          <div className="text-dark">First Name {values.blood_group}</div>
          <div className="text-dark">First Name {values.religion}</div>
          <div className="text-dark">First Name {values.birth_cert_no}</div>
        </div>
        <div className="col-12 d-flex justify-content-between">
          <Button color="primary" variant="contained" onClick={Back}>
            Back
          </Button>
          <Button color="primary" variant="contained" onClick={AddStaff}>
            Register Staff
          </Button>
          <Toaster position="top-right" reverseOrder={false} />
        </div>
      </div>
    </>
  );
}
