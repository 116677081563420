import { Button, IconButton, Popover, Typography } from "@mui/material";
import { useState } from "react";
import { CustomDeleteIcon } from "./CustomButtons";

const DeletePopover = ({ anchorEl, onClose, onDelete }) => {
  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
    >
      <div className="m-1">
        <Typography>Are you sure you want to delete this item?</Typography>
        <div className="d-flex justify-content-between">
          <Button className="text-warning font-weight-bold" onClick={onClose}>
            Cancel
          </Button>
          <Button className="text-danger font-weight-bold" onClick={onDelete}>
            Delete
          </Button>
        </div>
      </div>
    </Popover>
  );
};

export const DeleteButton = ({ onDelete }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleDeleteClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button onClick={handleDeleteClick}>
        {/* <IconButton> */}
        <CustomDeleteIcon />
        {/* </IconButton> */}
      </Button>
      <DeletePopover
        anchorEl={anchorEl}
        onClose={handleClose}
        onDelete={onDelete}
      />
    </>
  );
};
