import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import axios from "axios";
import React, { useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { Link } from "react-router-dom";
import { useQuery } from "react-query";
import { API_URLS } from "../../utils/api.routes";
import {
  toastDangerStyle,
  toastSuccessStyle,
  toastWarnigStyle,
} from "../../components/shared/Custom.Styles";
import AssessmentcommentsActions from "./AssessmentcommentsActions";
import { CustomPagination } from "../../components/custom/Custom.DataGrid";
import { Button } from "@mui/material";
import {
  ClassSearchSelectInput,
  SubjectSearchSelectInput,
  TopicSearchSelectInput,
  SubtopicSearchSelectInput,
  AssessmentSearchSelectInput,
  StudentSearchSelectInput,
} from "../../components/custom/Custom.SearchInput";
import { Send } from "@mui/icons-material";
import authService from "../../services/auth.service";
import AccessDenied from "../../components/custom/Custom.AccessDenied";

export default function Assessmentcomments() {
  const currentUser = authService.getCurrentUser();

  // input fields change to respective lesson plan fields

  const [assessment_rubrics, setAssessmentRubrics] = useState("");
  const [comments, setComments] = useState("");
  const [approval, setApproval] = useState("");

  // for pegination
  const [rowId, setRowId] = useState(null);
  const [pageSize, setPageSize] = useState(5);

  let headersList = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };

  // change to respective lesson plan body
  let bodyContent = JSON.stringify({
    assessment_rubrics: assessment_rubrics,
    parent_remarks: comments,
    approval: approval,
  });

  //  This is a post request function depending on the submission of the form
  const handleSubmit = async (e) => {
    e.preventDefault();
    await fetch(API_URLS.assessmentrubrics.concat(assessment_rubrics, "/"), {
      method: "PATCH",
      headers: headersList,
      body: bodyContent,
    })
      .then((res) => {
        if (res.status === 200) {
          setAssessmentRubrics("");
          setComments("");
          setApproval("");
          refetch();
          toast.success(
            `Successfully Created Assessment Rubrics Comments`,
            toastSuccessStyle
          );
        } else if (res.status === 400) {
          toast.error(`Error !!! ${res.statusText}`, toastDangerStyle);
          setTimeout(() => {
            toast(
              `Seems Assessment Rubrics Comments Already Exists`,
              toastWarnigStyle
            );
          }, 1000);
        } else {
          toast.error(
            `${res.status} Error !!! ${res.statusText}`,
            toastDangerStyle
          );
        }
      })
      .catch((err) => {
        toast.error(`Error, Not Found !!!`, toastDangerStyle);
      });
  };

  async function fetchAssessmentrubricscomments() {
    const { data } = await axios.get(API_URLS.assessmentrubrics);
    return data;
  }
  const { data, error, isError, isLoading, refetch } = useQuery(
    "assessmentrubrics",
    fetchAssessmentrubricscomments
  );
  if (isLoading) {
    return <div className="main-content">Loading...</div>;
  }
  if (isError) {
    return (
      <div className="main-content">
        <div>Error!! {error.message}</div>
      </div>
    );
  }

  const columns = [
    {
      field: "id",
      headerName: "SL",
      headerAlign: "center",
      type: "number",
      width: "80",
    },
    {
      field: "student",
      headerName: "Assessment Rubrics",
      headerAlign: "center",
      editable: true,
      width: 120,
    },
    {
      field: "parent_remarks",
      headerName: "Comments",
      headerAlign: "center",
      editable: true,
      width: 120,
    },

    {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      hide: true,
      renderCell: (params) => (
        <AssessmentcommentsActions {...{ params, rowId, setRowId }} />
      ),
    },
  ];

  if (!currentUser.roles.includes("COMMENT_ASSESSMENT_RUBRICS")) {
    return <AccessDenied />;
  }

  return (
    <div className="main-content">
      <div className="row">
        <div className="col-12 col-lg-4 col-xl-4">
          <div className="shadow-sm p-3 mb-5 bg-white rounded">
            <div className="text-center text-dark">
              <h5>Add Assessment Comments</h5>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="card-body">
                <div className="form-group">
                  <label htmlFor="sStudent">Student</label>
                  <StudentSearchSelectInput
                    onSelectedItemChange={(item) => {
                      setAssessmentRubrics(item.admn_no);
                    }}
                    searchFields={["admn_no"]}
                  />
                  <p className="text-dark">
                    Selected Student: {assessment_rubrics}
                  </p>
                </div>

                <div className="form-group">
                  <label htmlFor="sComment">Comment</label>
                  <input
                    type="text"
                    id="sComment"
                    className="form-control"
                    value={comments}
                    onChange={(e) => setComments(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label>Approval</label>
                  <select
                    type="text"
                    id="sApproval"
                    className="form-control"
                    onChange={(e) => setApproval(e.target.value)}
                    value={approval}
                  >
                    <option value="" disabled>
                      Approval
                    </option>
                    <option value="1">Approved</option>
                    <option value="0">Not Approved</option>
                  </select>
                </div>
              </div>
              <div className="text-center">
                <button className="btn btn-primary mr-1" type="submit">
                  Comment
                </button>
                <Toaster position="top-right" reverseOrder={false} />
              </div>
            </form>
          </div>
        </div>
        <div className="col-12 col-lg-8 col-xl-8">
          <div className="shadow-sm p-3 mb-5 bg-white rounded">
            <div className="text-center">
              <h5 className="text-center text-dark">
                Assessment Rubrics Comments List
              </h5>
            </div>

            <div style={{ width: "100%" }}>
              <DataGrid
                autoHeight
                columns={columns}
                components={{
                  Toolbar: GridToolbar,
                  Pagination: CustomPagination,
                }}
                rows={data}
                getRowId={(row) => row.id}
                rowsPerPageOptions={[5, 10, 20]}
                rowHeight={45}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                getRowSpacing={(params) => ({
                  top: params.isFirstVisible ? 0 : 5,
                  bottom: params.isLastVisible ? 0 : 5,
                })}
                onCellEditCommit={(params) => setRowId(params.id)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
