import React, { useState } from "react";
import axios from "axios";
import { MailOutlineOutlined } from "@mui/icons-material";

import { Link, useNavigate } from "react-router-dom";
import { API_URLS } from "../../utils/api.routes";
import { toast, Toaster } from "react-hot-toast";
import {
  toastDangerStyle,
  toastSuccessStyle,
  toastWarnigStyle,
} from "../../components/shared/Custom.Styles";

const CreateInvoiceForm = () => {
  const [name, setname] = useState("");
  const [price, setprice] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    const bodyContent = JSON.stringify({
      name: name,
      price: price,
    });
    await fetch("http://localhost:9000/invoices", {
      method: "POST",

      body: bodyContent,
      // headers: headersList,
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (res.status === 200) {
          // refetch();
          // setCode("");
          // setName("");
          toast.success(`Created Category Successfully`, toastSuccessStyle);
        } else if (res.status === 400) {
          toast.error(`${res.statusText}`, toastDangerStyle);
          setTimeout(() => {
            toast(`Check Category Already Exists`, toastWarnigStyle);
          }, 1000);
        } else {
          toast.error(`${res.status} ${res.statusText}`, toastDangerStyle);
        }
      })
      .catch((err) => {
        toast.error(`Error Not Found !`, toastDangerStyle);
      });
  };
  return (
    <div className="main-content">
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="title"> Name:</label>
          <input
            type="text"
            id="name"
            value={name}
            onChange={(event) => setname(event.target.value)}
          />
        </div>
        <div>
          <label htmlFor="author">PRice:</label>
          <input
            type="number"
            id="price"
            value={price}
            onChange={(event) => setprice(event.target.value)}
          />
        </div>

        <button type="submit">Add Item</button>
        <Toaster position="top-right" reverseOrder={false} />
      </form>
    </div>
  );
};

export default CreateInvoiceForm;
