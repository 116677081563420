import {
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useState } from "react";

export default function StudentAttendance({ students, onAttendanceChange }) {
  const [attendance, setAttendance] = useState(
    students.map((student) => ({
      ...student,
      present: false,
    }))
  );

  const handleAttendanceChange = (event, studentId) => {
    // const updatedAttendance = attendance.map((student) => ({
    //   ...student,
    //   present:
    //     student.student === studentId ? event.target.checked : student.present,
    // }));
    const updatedAttendance = attendance.map((student) => {
      if (student.student === studentId) {
        return {
          ...student,
          present: event.target.checked,
        };
      } else {
        return student;
      }
    });
    setAttendance(updatedAttendance);
    onAttendanceChange(updatedAttendance);
  };

  const handleCheckAllChange = (event) => {
    const updatedAttendance = attendance.map((student) => ({
      ...student,
      present: event.target.checked,
    }));
    setAttendance(updatedAttendance);
    onAttendanceChange(updatedAttendance);
  };

  const allStudentsPresent = attendance.every((student) => student.present);
  const someStudentsPresent = attendance.some((student) => student.present);

  return (
    <>
      <TableContainer component={Paper}>
        <Table aria-label="customized attendance Table">
          <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight: "bold" }}>SN</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Student Name</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>
                Admission Number
              </TableCell>
              <TableCell style={{ fontWeight: "bold" }}>
                <Checkbox
                  type="checkbox"
                  checked={allStudentsPresent}
                  onChange={handleCheckAllChange}
                />
                All Present
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody style={{ height: "60px" }}>
            {attendance.map((student, index) => (
              <TableRow key={index}>
                <TableCell style={{ fontWeight: "bold" }}>
                  {index + 1}
                </TableCell>
                <TableCell>
                  {student.first_name} {student.last_name}
                </TableCell>
                <TableCell>{student.admn_no}</TableCell>
                <TableCell>
                  <Checkbox
                    type="checkbox"
                    checked={student.present}
                    onChange={(event) =>
                      handleAttendanceChange(event, student.student)
                    }
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
