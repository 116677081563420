import react, { useState } from "react";

import { Button, Step, StepLabel, Stepper, Typography } from "@mui/material";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import BasicForm from "./steps/BasicForm";
import ContactForm from "./steps/ContactForm";
import ConfirmationForm from "./steps/ConfirmationForm";
import AccountForm from "./steps/AccountForm";
import ProfessionForm from "./steps/ProfessionForm";
import OtherDetails from "./steps/OtherDetails";
import EmergencyContact from "./steps/EmergencyContact";
import { Link } from "react-router-dom";

function getSteps() {
  return [
    "Basic information",
    "Contact Information",
    "Professional Qualification",
    "Account Information",
    "Emergency Contact",
    "Confirmation",
  ];
}

function getStepContent(step) {
  switch (step) {
    case 0:
      return <BasicForm />;
    case 1:
      return <ContactForm />;
    case 2:
      return <ProfessionForm />;
    case 3:
      return <AccountForm />;
    case 4:
      return <EmergencyContact />;
    case 5:
      return <ConfirmationForm />;
    default:
      return "unknown step";
  }
}

export default function ParentRegistration() {
  const methods = useForm({
    defaultValues: {
      staff_number: null,
      role: "",
      first_name: "",
      last_name: "",
      email: "",
      gender: "",
      dob: null,
      mobile: "",
      marital_status: "",
      emergency_mobile: "",
      driving_license: "",
      current_address: "",
      permanent_address: "",
      prof_qualification_1: "", // change in database
      experience: "",
      service_no: null, // change in the api
      basic_salary: null,
      contract_type: "",
      location: "",
      bankaccount_name: "",
      account_number: null,
      bank_name: "",
      branch_name: "",
      // added
      contract_length: "",
      // end addes
      facebook_url: "",
      twitter_url: "",
      linkedin_url: "",
      instagram_url: "",
      designation: null,
      department: null,
      category: null,
      // added
      with_disability: "",
      tel_no: "",
      other_name: "",
      postal_code: "",
      zip_code: "",
      home_county: "",
      home_sub_county: "",
      home_ward: "",
      home_location: "",
      home_village: "",
      prev_empl1: "",
      prev_empl_email1: "",
      prev_empl_tel1: "",
      prev_empl_address1: "",
      prev_empl2: "",
      prev_empl_email2: "",
      prev_empl_tel2: "",
      prev_empl_address2: "",
      prof_qualification_2: "",
      prof_qualification_3: "",
      prof_qualification_4: "",
      // emergency contact
      emerg_first_name1: "",
      emerg_last_name1: "",
      emerg_phone_no1: "",
      emerg_mobile_no1: "",
      emerg_gender1: "",
      emerg_relation1: "",
      emerg_first_name2: "",
      emerg_last_name2: "",
      emerg_phone_no2: "",
      emerg_mobile_no2: "",
      emerg_gender2: "",
      emerg_relation2: "",

      // ! To add documents uploading
    },
  });
  const [activeStep, setActiveStep] = useState(0);
  const [skippedSteps, setSkippedSteps] = useState([]);
  const steps = getSteps();
  const isStepOptional = (step) => {
    return step === 2 || step === 3 || step === 4;
  };
  const isStepFalied = () => {
    return Boolean(Object.keys(methods.formState.errors).length);
  };
  const isStepSkipped = (step) => {
    return skippedSteps.includes(step);
  };

  const handleNext = (data) => {
    console.log(data);
    if (activeStep == steps.length - 1) {
      fetch("https://jsonplaceholder.typicode.com/comments")
        .then((data) => data.json())
        .then((res) => {
          console.log(res);
          setActiveStep(activeStep + 1);
        });
    } else {
      setActiveStep(activeStep + 1);
      setSkippedSteps(
        skippedSteps.filter((skipItem) => skipItem !== activeStep)
      );
    }
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const handleSkip = () => {
    if (!isStepSkipped(activeStep)) {
      setSkippedSteps([...skippedSteps, activeStep]);
    }
    setActiveStep(activeStep + 1);
  };

  return (
    <div className="main-content">
      <div className="row">
        <div className="col-12 col-md-12 col-lg-12">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link className="font-weight-bold" to="/admin">
                  Home
                </Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                <span className="font-weight-bold">Register Staff</span>
              </li>
            </ol>
          </nav>
        </div>
        <div className="col-md-12 col-lg-12 col-xl-12">
          <div className="shadow p-3 mb-5 bg-white rounded">
            <Stepper alternativeLabel activeStep={activeStep}>
              {steps.map((step, index) => {
                const labelProps = {};
                const stepProps = {};
                if (isStepOptional(index)) {
                  labelProps.optional = (
                    <Typography
                      variant="caption"
                      align="center"
                      style={{ display: "block" }}
                    >
                      optional
                    </Typography>
                  );
                }
                if (isStepFalied() && activeStep == index) {
                  labelProps.error = true;
                }
                if (isStepSkipped(index)) {
                  stepProps.completed = false;
                }
                return (
                  <Step {...stepProps} key={index}>
                    <StepLabel {...labelProps}>{step}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>

            {activeStep === steps.length ? (
              <Typography variant="h3" align="center">
                Thank You
              </Typography>
            ) : (
              <>
                <FormProvider {...methods}>
                  <form onSubmit={methods.handleSubmit(handleNext)}>
                    {getStepContent(activeStep)}

                    <Button disabled={activeStep === 0} onClick={handleBack}>
                      back
                    </Button>
                    {isStepOptional(activeStep) && (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSkip}
                      >
                        skip
                      </Button>
                    )}
                    <Button
                      variant="contained"
                      color="primary"
                      // onClick={handleNext}
                      type="submit"
                    >
                      {activeStep === steps.length - 1 ? "Finish" : "Next"}
                    </Button>
                  </form>
                </FormProvider>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
