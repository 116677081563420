import { Autocomplete, TextField } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast, Toaster } from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import {
  toastDangerStyle,
  toastSuccessStyle,
  toastWarnigStyle,
} from "../../../components/shared/Custom.Styles";
import { API_URLS } from "../../../utils/api.routes";
import authHeader from "../../../services/auth-header";

const ClassRoomAdd = () => {
  const navigate = useNavigate();

  const [rCode, setRCode] = useState("");
  const [rName, setRName] = useState("");
  const [capacity, setCapacity] = useState("");
  const [sCode, setSCode] = useState("");
  const [room, setRoom] = useState("");
  const [accYear, setAccYear] = useState("");
  const [teacher, setTeacher] = useState("");
  const [stream, setStream] = useState("");

  const [streams, setStreams] = useState([]);
  const [years, setYears] = useState([]);
  const [teachers, setTeachers] = useState([]);

  useEffect(() => {
    const fetchStreams = async () => {
      const res = await fetch(API_URLS.class_stream);
      const data = await res.json();
      setStreams(data);
    };
    const fetchAcademicYears = async () => {
      const res = await fetch(API_URLS.academic_year);
      const data = await res.json();
      setYears(data);
    };
    const fetchTeachers = async () => {
      const res = await fetch(API_URLS.hr_teachers_odoo);
      const data = await res.json();
      setTeachers(data);
    };
    fetchStreams();
    fetchAcademicYears();
    fetchTeachers();
  }, []);

  const headersList = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };
  const bodyContent = JSON.stringify({
    class_room_code: rCode,
    class_room_name: rName,
    capacity: capacity,
    room_allocated: room,
    stream_code: sCode,
    academic_year: accYear,
    // class_teacher: teacher,
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    await fetch(API_URLS.class_room, {
      method: "POST",
      body: bodyContent,
      headers: headersList,
    })
      .then((res) => {
        if (res.status === 201) {
          toast.success(`Successfully Created ClassRoom`, toastSuccessStyle);
          setTimeout(() => {
            navigate("/admin/classroom/list");
          }, 2000);
        } else if (res.status === 400) {
          toast.error(`Error !!! ${res.statusText}`, toastDangerStyle);
          setTimeout(() => {
            toast(`Seems Classroom Already Exists`, toastWarnigStyle);
          }, 1000);
        } else {
          toast.error(
            `${res.status} Error !!! ${res.statusText}`,
            toastDangerStyle
          );
        }
      })
      .catch((err) => {
        toast.error(`Error !!! Not Found}`, {
          style: {
            color: "crimson",
            fontSize: "1.2em",
          },
          duration: 3000,
        });
      });
  };
  return (
    <div className="main-content">
      <div className="row">
        <div className="col-12 col-md-12 col-lg-12">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/admin/administration/dashboard">Home</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/admin/classroom/list">Classrooms</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                New Classroom
              </li>
            </ol>
          </nav>
        </div>
        <div className="col-12 col-md-12 col-lg-12">
          <form
            className="d-flex justify-content-center align-items-center"
            onSubmit={handleSubmit}
          >
            <div className="col-md-4 col-lg-4 col-xl-4">
              <div className="card shadow-sm p-3 mb-5 bg-body rounded">
                <div className="card-header">
                  <h4>className Room </h4>
                </div>
                <div className="card-body">
                  <div className="form-group">
                    <Autocomplete
                      freeSolo
                      disableClearable
                      options={streams.map((s) => s.stream_name)}
                      value={stream}
                      onChange={(e, value) => {
                        setStream(value);
                        for (var i = 0; i < streams.length; i++) {
                          if (value === streams[i]["stream_name"]) {
                            setSCode(streams[i]["id"]);
                            break;
                          }
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Stream"
                          variant="outlined"
                          InputProps={{
                            ...params.InputProps,
                            type: "search",
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className="form-group">
                    {/* <label htmlFor="cRoomCode">className Room Code </label>
                  <input
                    type="text"
                    id="cRoomCode"
                    className="form-control"
                    value={rCode}
                    onChange={(e) => setRCode(e.target.value)}
                  /> */}
                    <TextField
                      fullWidth
                      label="Classroom Code"
                      variant="outlined"
                      value={rCode}
                      onChange={(e) => setRCode(e.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    {/* <label htmlFor="cStream">className Room Name </label>
                  <input
                    type="text"
                    id="cStream"
                    className="form-control"
                    value={rName}
                    onChange={(e) => setRName(e.target.value)}
                  /> */}
                    <TextField
                      fullWidth
                      label="Classroom Name"
                      variant="outlined"
                      value={rName}
                      onChange={(e) => setRName(e.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <Autocomplete
                      freeSolo
                      disableClearable
                      options={years.map((year) => year.name)}
                      onChange={(e, value) => {
                        for (var i = 0; i < years.length; i++) {
                          if (value === years[i]["name"]) {
                            setAccYear(years[i]["id"]);
                            break;
                          }
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label="Academic Year"
                          InputProps={{
                            ...params.InputProps,
                            type: "search",
                          }}
                        />
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-lg-4 col-xl-4">
              <div className="card shadow-sm p-3 mb-5 bg-body rounded">
                <div className="card-header">
                  <h4>Other Details</h4>
                </div>
                <div className="card-body">
                  <div className="form-group">
                    {/* <label htmlFor="cStream">className Capacity</label>
                  <input
                    type="text"
                    id="cStream"
                    className="form-control"
                    value={capacity}
                    onChange={(e) => setCapacity(e.target.value)}
                  /> */}
                    <TextField
                      fullWidth
                      label="Classroom Capacity"
                      variant="outlined"
                      value={capacity}
                      onChange={(e) => setCapacity(e.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    {/* <label htmlFor="acdYear">Room Allocated</label>
                  <input
                    type="text"
                    id="acdYear"
                    className="form-control"
                    value={room}
                    onChange={(e) => setRoom(e.target.value)}
                  /> */}
                    <TextField
                      fullWidth
                      label="Room Allocation"
                      variant="outlined"
                      value={room}
                      onChange={(e) => setRoom(e.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <label>
                      Class Teacher<span className="text-danger">*</span>
                    </label>
                    <select
                      className="form-control"
                      onChange={(e) => setTeacher(e.target.value)}
                      value={teacher}
                    >
                      <option value="" disabled>
                        Choose Class Teacher
                      </option>
                      {teachers.map((teacher, index) => {
                        return (
                          <option key={index} value={teacher.x_staff_id}>
                            {teacher.name} {teacher.x_staff_id}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div className="card-footer text-center">
                  <button className="btn btn-primary mr-1" type="submit">
                    Create Classroom
                  </button>
                </div>
                <Toaster position="top-right" reverseOrder={false} />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ClassRoomAdd;
