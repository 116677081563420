import { Button } from "@mui/material";
import React from "react";

export default function ProfessionForm(props) {
  const Continue = (e) => {
    e.preventDefault();
    props.nextStep();
  };
  const Back = (e) => {
    e.preventDefault();
    props.prevStep();
  };
  const { values, handleChange } = props;
  return (
    <>
      <div>
        <h5 className="text-center text-dark">Professional Qualifications</h5>
      </div>
      <div className="col-12 col-md-12 col-lg-12">
        <div className="d-flex flex-wrap" style={{ minHeight: 350 }}>
          <div className="d-flex flex-wrap col-12">
            <h5 className="col-12 text-muted">Qualifications</h5>
            <div className="form-group col-6">
              <label>Qualification 1</label>
              <input
                type="text"
                className="form-control"
                onChange={handleChange("prof_qualification_1")}
                value={values.prof_qualification_1}
              />
            </div>
            <div className="form-group col-6">
              <label>Qualification 2</label>
              <input
                type="text"
                className="form-control"
                onChange={handleChange("prof_qualification_2")}
                value={values.prof_qualification_2}
              />
            </div>
            <div className="form-group col-6">
              <label>Qualification 3</label>
              <input
                type="text"
                className="form-control"
                onChange={handleChange("prof_qualification_3")}
                value={values.prof_qualification_3}
              />
            </div>
            <div className="form-group col-6">
              <label>Qualification 4</label>
              <input
                type="text"
                className="form-control"
                onChange={handleChange("prof_qualification_4")}
                value={values.prof_qualification_4}
              />
            </div>
          </div>
          <div className="d-flex flex-wrap col-12">
            <h5 className="col-12 text-muted">Previous Employer</h5>
            <div className="col-12 d-flex flex-wrap">
              <h6 className="col-12 text-muted">Employer</h6>
              <div className="form-group col-3">
                <label>Employer Name</label>
                <input
                  type="text"
                  className="form-control"
                  onChange={handleChange("prev_empl1")}
                  value={values.prev_empl1}
                />
              </div>
              <div className="form-group col-3">
                <label>Employer Email</label>
                <input
                  type="text"
                  className="form-control"
                  onChange={handleChange("prev_empl_email1")}
                  value={values.prev_empl_email1}
                />
              </div>
              <div className="form-group col-3">
                <label>Employer Tel No</label>
                <input
                  type="text"
                  className="form-control"
                  onChange={handleChange("prev_empl_tel1")}
                  value={values.prev_empl_tel1}
                />
              </div>
              <div className="form-group col-3">
                <label>Employer Address</label>
                <input
                  type="text"
                  className="form-control"
                  onChange={handleChange("prev_empl_address1")}
                  value={values.prev_empl_address1}
                />
              </div>
            </div>
            <div className="col-12 d-flex flex-wrap">
              <h6 className="col-12 text-muted">Employer</h6>
              <div className="form-group col-3">
                <label>Employer Name</label>
                <input
                  type="text"
                  className="form-control"
                  onChange={handleChange("prev_empl2")}
                  value={values.prev_empl2}
                />
              </div>
              <div className="form-group col-3">
                <label>Employer Email</label>
                <input
                  type="text"
                  className="form-control"
                  onChange={handleChange("prev_empl_email2")}
                  value={values.prev_empl_email2}
                />
              </div>
              <div className="form-group col-3">
                <label>Employer Tel No</label>
                <input
                  type="text"
                  className="form-control"
                  onChange={handleChange("prev_empl_tel2")}
                  value={values.prev_empl_tel2}
                />
              </div>
              <div className="form-group col-3">
                <label>Employer Address</label>
                <input
                  type="text"
                  className="form-control"
                  onChange={handleChange("prev_empl_address2")}
                  value={values.prev_empl_address2}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 d-flex justify-content-between">
          <Button color="primary" variant="contained" onClick={Back}>
            Back
          </Button>
          <Button color="primary" variant="contained" onClick={Continue}>
            Next
          </Button>
        </div>
      </div>
    </>
  );
}
