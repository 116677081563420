import { EnhancedEncryption } from "@mui/icons-material";
import React from "react";
import { Link, Navigate } from "react-router-dom";
import authService from "../../services/auth.service";

export default function PermissionsRoles() {
  const currentUser = authService.getCurrentUser();
  // if (
  //   !currentUser.roles.includes("VIEW_ROLES") &&
  //   !currentUser.roles.includes("SUPER_ADMIN")
  // ) {
  //   return <Navigate to="/admin" replace={true} />;
  // }
  return (
    <>
      {(currentUser.roles.includes("VIEW_ROLES") ||
        currentUser.roles.includes("SUPER_ADMIN")) && (
        <li className="dropdown">
          <Link to="#" className="menu-toggle nav-link has-dropdown">
            <EnhancedEncryption fontSize="medium" className="icon-mr" />
            <span>Role & Permission</span>
          </Link>
          <ul className="dropdown-menu">
            {currentUser.roles.includes("ADD_GRADE") ||
            currentUser.roles.includes("SUPER_ADMIN") ? (
              <li>
                <Link className="nav-link" to="/admin/roles/assign-roles">
                  Assign Roles
                </Link>
              </li>
            ) : (
              <></>
            )}
          </ul>
        </li>
      )}
    </>
  );
}
