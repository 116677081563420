import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import axios from "axios";
import React, { useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { Link } from "react-router-dom";
import { useQuery } from "react-query";
import { API_URLS } from "../../utils/api.routes";
import {
  toastDangerStyle,
  toastSuccessStyle,
  toastWarnigStyle,
} from "../../components/shared/Custom.Styles";
import AssignmentsActions from "./AssignmentsActions";
import { CustomPagination } from "../../components/custom/Custom.DataGrid";
import { Button } from "@mui/material";
import { Send } from "@mui/icons-material";
import { ClassSearchSelectInput, SubjectSearchSelectInput } from "../../components/custom/Custom.SearchInput";

export default function Assignments() {
  // input fields change to respective lesson plan fields
  const [name, setName] = useState("");
  const [assignment_class, setClass] = useState("");
  const [subject, setSubject] = useState("");
  const [section, setSection] = useState("");
  const [start_date, setStartdate] = useState("");
  const [submission_date, setSubmissiondate] = useState("");
  const [marks, setMarks] = useState("");
  const [comment, setComment] = useState("");

  //import file hook
  const [file, setFile] = useState();

  // for pegination
  const [rowId, setRowId] = useState(null);
  const [pageSize, setPageSize] = useState(5);

  let headersList = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };

  // change to respective lesson plan body
  let bodyContent = JSON.stringify({
    name: name,
    assignment_class: assignment_class,
    subject: subject,
    section: section,
    start_date: start_date,
    submission_date: submission_date,
    marks: marks,
    comment: comment,
  });
  const handleSubmit = async (e) => {
    e.preventDefault();
    await fetch(API_URLS.assignments, {
      method: "POST",
      headers: headersList,
      body: bodyContent,
    })
      .then((res) => {
        if (res.status === 201) {
          setName("");
          setClass("");
          setSubject("");
          setSection("");
          setStartdate("");
          setSubmissiondate("");
          setMarks("");
          setComment("");
          refetch();
          toast.success(`Successfully Created `, toastSuccessStyle);
        } else if (res.status === 400) {
          toast.error(`Error !!! ${res.statusText}`, toastDangerStyle);
          setTimeout(() => {
            toast(`Seems Quiz Exists`, toastWarnigStyle);
          }, 1000);
        } else {
          toast.error(
            `${res.status} Error !!! ${res.statusText}`,
            toastDangerStyle
          );
        }
      })
      .catch((err) => {
        toast.error(`Error, Not Found !!!`, toastDangerStyle);
      });
  };

  //import
  const handleFileSelection = (e) => {
    const selectedFile = e.target.files[0];
    if (
      selectedFile.type === "text/csv" ||
      selectedFile.type === "application/json" ||
      selectedFile.type === "application/vnd.ms-excel" ||
      selectedFile.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      setFile(selectedFile);
    } else {
      toast("Please select a CSV, JSON or Excel file.", toastWarnigStyle);
    }
  };

  const UploadFile = () => {
    const formData = new FormData();
    formData.append("file", file);

    fetch(API_URLS.upload_assignments, {
      method: "POST",
      body: formData,
    })
      .then((res) => {
        if (res.status === 200) {
          refetch();
          setFile(null);
          toast.success("Upload Successfull", toastSuccessStyle);
        }
      })
      .catch((error) => {
        toast.error(`Upload Error ${error}`, toastDangerStyle);
      });
  };

  //

  async function fetchAssignments() {
    const { data } = await axios.get(API_URLS.assignments);
    return data;
  }
  const { data, error, isError, isLoading, refetch } = useQuery(
    "assignments",
    fetchAssignments
  );
  if (isLoading) {
    return <div className="main-content">Loading...</div>;
  }
  if (isError) {
    return (
      <div className="main-content">
        <div>Error!! {error.message}</div>
      </div>
    );
  }
  console.log(data);

  const handleSelectedSubjChange = (item) => {
    setSubject(item.subject_name);
  };

  const columns = [
    {
      field: "id",
      headerName: "SL",
      headerAlign: "center",
      type: "number",
      width: "80",
    },
    {
      field: "name",
      headerName: "Name",
      headerAlign: "center",
      editable: true,
      width: 120,
    },
    {
      field: "assignment_class",
      headerName: "Assignmentclass",
      headerAlign: "center",
      editable: true,
      width: 120,
    },
    {
      field: "subject",
      headerName: "Subject",
      headerAlign: "center",
      editable: true,
      width: 120,
    },
    {
      field: "section",
      headerName: "Section",
      headerAlign: "center",
      editable: true,
      width: 120,
    },
    {
      field: "start_date",
      headerName: "Startdate",
      headerAlign: "center",
      editable: true,
      width: 120,
    },
    {
      field: "submission_date",
      headerName: "Submission_date",
      headerAlign: "center",
      editable: true,
      width: 120,
    },
    {
      field: "marks",
      headerName: "Marks",
      headerAlign: "center",
      editable: true,
      width: 120,
    },
    {
      field: "comment",
      headerName: "Comment",
      headerAlign: "center",
      editable: true,
      width: 120,
    },

    {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      renderCell: (params) => (
        <AssignmentsActions {...{ params, rowId, setRowId }} />
      ),
    },
  ];
  return (
    <div className="main-content">
      <div className="row">
        <div className="col-12 col-md-12 col-lg-12">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/admin">Home</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Assignments
              </li>
            </ol>
          </nav>
        </div>
        <div className="col-md-6 col-lg-12 col-xl-4">
          <div className="shadow-sm p-3 mb-5 bg-white rounded">
            <div className="text-center text-dark">
              <h5>Add Assignment</h5>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="card-body">
                <div className="form-group">
                  <label htmlFor="sName">Name</label>
                  <input
                    type="text"
                    id="sName"
                    className="form-control"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="sLessonClass">Lesson Class</label>
                  <ClassSearchSelectInput
                    onSelectedItemChange={(item) => {
                      setClass(item.class_room_name);
                    }}
                    searchFields={["class_room_code", "class_room_name"]}
                  />
                  <p className="text-dark">Selected Class: {assignment_class}</p>
                </div>
                <div className="form-group">
                  <label htmlFor="sSubject">Subject</label>
                  <SubjectSearchSelectInput
                    onSelectedItemChange={handleSelectedSubjChange}
                    searchFields={["subject_name", "subject_code"]}
                  />
                  <p className="text-dark">Subject: {subject}</p>
                </div>
                <div className="form-group">
                  <label htmlFor="sSection">
                    Section<span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    id="sSection"
                    className="form-control"
                    value={section}
                    onChange={(e) => setSection(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="sStart">Start Date</label>
                  <input
                    type="date"
                    id="sStart"
                    className="form-control"
                    value={start_date}
                    onChange={(e) => setStartdate(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="sSubmission">Submission Date</label>
                  <input
                    type="date"
                    id="sSubmission"
                    className="form-control"
                    value={submission_date}
                    onChange={(e) => setSubmissiondate(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="sMarks">Marks</label>
                  <input
                    type="number"
                    id="sMarks"
                    className="form-control"
                    value={marks}
                    onChange={(e) => setMarks(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="sComment">Comment</label>
                  <input
                    type="text"
                    id="sComment"
                    className="form-control"
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                  />
                </div>
              </div>
              <div className="text-center">
                <button className="btn btn-primary mr-1" type="submit">
                  Add New Assignment
                </button>
                <Toaster position="top-right" reverseOrder={false} />
              </div>
            </form>
          </div>
        </div>
        <div className="col-md-6 col-lg-12 col-xl-8">
          <div className="shadow-sm p-3 mb-5 bg-white rounded">
            <div className="text-center">
              <h5 className="text-center text-dark">Assignments List</h5>
            </div>
              {/* import */}
              <div className="d-flex justify-content-end">
                <div className="d-flex m-1">
                  <Button variant="text" component="label">
                  Import
                    <input
                      hidden
                      type={"file"}
                      id={"csvFileInput"}
                      accept=".csv,.json,.xlsx,application/vnd.ms-excel"
                      onChange={handleFileSelection}
                    />
                  </Button>
                  {file && (
                    <div className="text-info align-self-center">
                      File: {file.name}
                    </div>
                  )}
                </div>
                <Button
                  size="small"
                  variant="contained"
                  endIcon={<Send />}
                  onClick={UploadFile}
                >
                  Submit
                </Button>
              </div>
            </div>
            {/*  */}
            <div style={{ height: 420 }}>
              <DataGrid
                columns={columns}
                components={{
                  Toolbar: GridToolbar,
                  Pagination: CustomPagination,
                }}
                rows={data}
                getRowId={(row) => row.id}
                rowsPerPageOptions={[5, 10, 20]}
                rowHeight={45}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                getRowSpacing={(params) => ({
                  top: params.isFirstVisible ? 0 : 5,
                  bottom: params.isLastVisible ? 0 : 5,
                })}
                onCellEditCommit={(params) => setRowId(params.id)}
              />
            </div>
          </div>
        </div>
      </div>
    
  );
}
