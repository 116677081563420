import { Check, Delete, Save } from "@mui/icons-material";
import { Box, CircularProgress, Fab, IconButton, Tooltip } from "@mui/material";
import { green } from "@mui/material/colors";
import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import {
  toastDangerStyle,
  toastSuccessStyle,
} from "../../components/shared/Custom.Styles";
import { API_URLS } from "../../utils/api.routes";
import moment from "moment";

const headersList = {
  Accept: "application/json",
  "Content-Type": "application/json",
};

export default function InventoryActions({ params, rowId, setRowId }) {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleSubmit = async () => {
    setLoading(true);
    const { id, lst_price, name } = params.row;
    await fetch(API_URLS.inventory_get.concat("/", id), {
      method: "PUT",
      body: JSON.stringify({
        id: id,
        lst_price: lst_price, // modify this to match the key in your JSON response
        name: name,
      }),
      headers: headersList,
    })
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            setSuccess(true);
            setRowId(null);
            setLoading(false);
            toast.success("Update Successful", toastSuccessStyle);
          });
        } else {
          toast.error("Update Error", toastDangerStyle);
          setLoading(false);
        }
      })
      .catch((err) => {
        toast.error("An Error Occured", toastDangerStyle);
      });
  };

  useEffect(() => {
    if (rowId === params.id && success) setSuccess(false);
  }, [rowId]);

  const deleteAcademicYear = async () => {
    const { id } = params.row;
    await fetch(API_URLS.inventory_delete.concat("/", id), {
      method: "DELETE",
      headers: headersList,
    })
      .then((res) => {
        if (res.status === 204) {
          toast.success("Deleted Successful", toastSuccessStyle);
        } else {
          toast.error("Deleting Error", toastDangerStyle);
        }
      })
      .catch((err) => {
        toast.error("An Error Occured", toastDangerStyle);
      });
  };
  return (
    <Box
      sx={{
        m: 1,
        position: "relative",
      }}
    >
      {success ? (
        <Fab
          color="primary"
          sx={{
            width: 40,
            height: 40,
            bgcolor: green[500],
            "&:hover": { bgcolor: green[700] },
          }}
        >
          <Check />
        </Fab>
      ) : (
        <Fab
          color="primary"
          sx={{
            width: 40,
            height: 40,
          }}
          disabled={params.id !== rowId || loading}
          onClick={handleSubmit}
        >
          <Save />
        </Fab>
      )}
      {loading && (
        <CircularProgress
          size={52}
          sx={{
            color: green[500],
            position: "absolute",
            top: -6,
            left: -6,
            zIndex: 1,
          }}
        />
      )}
      <Tooltip title="Delete this room">
        <IconButton onClick={() => deleteAcademicYear(params.row)}>
          <Delete color="secondary" />
        </IconButton>
      </Tooltip>
    </Box>
  );
}
