import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { API_URLS } from "../../utils/api.routes";
import InventoryActions from "./InventoryActions";

function Viewinventory() {
  const [inventory, setInventory] = useState([]);
  const [rowId, setRowId] = useState(null);
  const [pageSize, setPageSize] = useState(5);

  useEffect(() => {
    const fetchInventory = async () => {
      const res = await fetch(API_URLS.inventory_get);
      const data = await res.json();
      setInventory(data);
    };
    fetchInventory();
  }, []);
  console.log(inventory);
  const columns = [
    {
      field: "id",
      headerName: "ID",
      type: "number",
      hide: false,
      headerAlign: "center",
      width: 200,
    },
    {
      field: "name",
      headerName: "Name",
      type: "number",
      hide: false,
      headerAlign: "center",
      width: 200,
    },
    {
      field: "lst_price",
      headerName: "Price",
      headerAlign: "center",
      contentAlign: "right",
      hide: false,
      editable: true,
      width: 200,
    },

    {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      renderCell: (params) => (
        <InventoryActions {...{ params, rowId, setRowId }} />
      ),
    },
  ];

  return (
    <div className="main-content">
      <div className="row justify-content-center">
        <div className="col-12 col-md-12 col-lg-12">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/admin">Home</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Inventory list
              </li>
              <div className="col-4">
                <Link
                  to="/admin/inventory/Addinventory"
                  className="font-weight-bold"
                >
                  Add New items
                </Link>
              </div>
            </ol>
          </nav>
        </div>
        <div className="col-md-12 col-lg-12 col-xl-12">
          <div className="shadow-sm p-3 mb-5 bg-white rounded">
            <div className="text-center">
              <h5 className="text-center text-dark">Class Rooms List</h5>
            </div>
            <div style={{ height: 420 }}>
              <DataGrid
                columns={columns}
                components={{
                  Toolbar: GridToolbar,
                }}
                rows={inventory}
                getRowId={(row) => row.id}
                rowsPerPageOptions={[5, 10, 20]}
                rowHeight={45}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                getRowSpacing={(params) => ({
                  top: params.isFirstVisible ? 0 : 5,
                  bottom: params.isLastVisible ? 0 : 5,
                })}
                onCellEditCommit={(params) => setRowId(params.id)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Viewinventory;
