import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { API_URLS } from "../../../../utils/api.routes";

export default function FormAcademics(props) {
  const [grades, setGrades] = useState([]);
  const [classrooms, setClassRooms] = useState([]);
  const [studCategories, setStudCategories] = useState([]);

  useEffect(() => {
    async function fetchGrades() {
      const response = await fetch(API_URLS.grade);
      const data = await response.json();
      setGrades(data);
    }
    async function fetchStudentCategories() {
      const response = await fetch(API_URLS.student_category);
      const data = await response.json();
      setStudCategories(data);
    }
    async function fetchClassRooms() {
      const response = await fetch(API_URLS.class_room);
      const data = await response.json();
      setClassRooms(data);
    }
    fetchGrades();
    fetchStudentCategories();
    fetchClassRooms();
  }, []);

  const Continue = (e) => {
    e.preventDefault();
    props.nextStep();
  };
  const Back = (e) => {
    e.preventDefault();
    props.prevStep();
  };
  const { values, handleChange } = props;

  return (
    <div>
      <h5 className="text-center text-dark">Academics Information</h5>
      <div className="col-12 col-md-12 col-lg-12">
        <div className="d-flex flex-wrap" style={{ minHeight: 350 }}>
          <div className="form-group col-4">
            <label>
              Student Category<span className="text-danger">*</span>
            </label>
            <select
              className="form-control"
              onChange={handleChange("category")}
              value={values.category}
            >
              <option value="" disabled>
                Choose Student Category
              </option>
              {studCategories.map((category, index) => {
                return (
                  <option key={index} value={category.id}>
                    {category.name}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="form-group col-4">
            <label>
              Start Grade<span className="text-danger">*</span>
            </label>
            <select
              className="form-control"
              onChange={handleChange("start_grade")}
              value={values.start_grade}
            >
              <option value="" disabled>
                Choose Start Grade
              </option>
              {grades.map((grade, index) => {
                return (
                  <option key={index} value={grade.id}>
                    {grade.name}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="form-group col-4">
            <label>
              Current Grade<span className="text-danger">*</span>
            </label>
            <select
              className="form-control"
              onChange={handleChange("current_grade")}
              value={values.current_grade}
            >
              <option value="" disabled>
                Choose Current Grade
              </option>
              {grades.map((grade, index) => {
                return (
                  <option key={index} value={grade.id}>
                    {grade.name}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="form-group col-4">
            <label>
              Allocate Class<span className="text-danger">*</span>
            </label>
            <select
              className="form-control"
              onChange={handleChange("class_room_allocated")}
              value={values.class_room_allocated}
            >
              <option value="" disabled>
                Choose Classroom
              </option>
              {classrooms.map((cl, index) => {
                return (
                  <option key={index} value={cl.id}>
                    {cl.class_room_name}
                  </option>
                );
              })}
            </select>
          </div>

          <div className="form-group col-4">
            <label>Previous School</label>
            <input
              type="text"
              className="form-control"
              onChange={handleChange("prev_school")}
              value={values.prev_school}
            />
          </div>
          <div className="form-group col-4">
            <label>Dormitory</label>
            <input
              type="text"
              className="form-control"
              onChange={handleChange("dorm_name")}
              value={values.dorm_name}
            />
          </div>
          <div className="form-group col-4">
            <label>Room Number</label>
            <input
              type="text"
              className="form-control"
              onChange={handleChange("room_no")}
              value={values.room_no}
            />
          </div>
          <div className="form-group col-4">
            <label>Vehicle</label>
            <input
              type="text"
              className="form-control"
              onChange={handleChange("vehicle_no")}
              value={values.vehicle_no}
            />
          </div>
          <div className="form-group col-4">
            <label>Route</label>
            <input
              type="text"
              className="form-control"
              onChange={handleChange("route_list")}
              value={values.route_list}
            />
          </div>
        </div>
        <div className="col-12 d-flex justify-content-between">
          <Button color="primary" variant="contained" onClick={Back}>
            Back
          </Button>
          <Button color="primary" variant="contained" onClick={Continue}>
            Next
          </Button>
        </div>
      </div>
    </div>
  );
}
