import axios from "axios";
import React, { useState } from "react";
import { toast, Toaster } from "react-hot-toast";
import { useQuery } from "react-query";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  toastDangerStyle,
  toastSuccessStyle,
} from "../../../components/shared/Custom.Styles";
import { API_URLS } from "../../../utils/api.routes";
import { useEffect } from "react";
import authService from "../../../services/auth.service";
import AccessDenied from "../../../components/custom/Custom.AccessDenied";
import {
  CustomIsError,
  CustomIsLoading,
} from "../../../components/custom/Custom.UseQuery";
import { Cell, Pie, PieChart } from "recharts";

export const ATTENDANCE_COLORS = ["#32CD32", "#DC143C"];

const RADIAN = Math.PI / 180;

function StudentDetails() {
  const currentUser = authService.getCurrentUser();
  const [timeInstance, setTimeInstance] = useState([]);
  const [time, setTime] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const { state } = useLocation();
  const navigate = useNavigate();

  const [attendance, setAttendance] = useState([]);
  const [daysPresent, setDaysPresent] = useState("");
  const [daysAbsent, setDaysAbsent] = useState("");

  useEffect(() => {
    const fetchAttendanceInstance = async () => {
      const response = await fetch(API_URLS.attendance_instance);
      const data = await response.json();
      setTimeInstance(data);
    };
    fetchAttendanceInstance();
  }, []);
  // Runs Whenever the attendance changes

  useEffect(() => {
    const fetchAttendanceReport = async () => {
      const response = await fetch(
        `${API_URLS.stud_attendance_report}?start_date=${startDate}&end_date=${endDate}&time=${time}&student=${state}`
      );
      const data = await response.json();
      setAttendance(data);
      console.log(data);
      setDaysPresent(data[0]["value"]);
      setDaysAbsent(data[1]["value"]);
    };
    if (timeInstance && startDate && endDate) {
      fetchAttendanceReport();
    }
  }, [timeInstance, startDate, endDate]);

  const renderCustomizedAttendance = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="black"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {`${(percent * 100).toFixed(0)}% (${attendance[index].name})`}
      </text>
    );
  };

  const fetchStudentDetails = async () => {
    const { data } = await axios.get(API_URLS.students_info.concat(state, "/"));
    return data;
  };
  const { data, error, isError, isLoading } = useQuery(
    "student",
    fetchStudentDetails
  );
  if (isLoading) {
    return <CustomIsLoading />;
  }
  if (isError) {
    return <CustomIsError message={error.message} />;
  }

  const editStudentDetails = () => {
    navigate("/admin/student/edit", {
      state: {
        data: data,
      },
    });
  };

  const deleteStudent = async () => {
    const headersList = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    await fetch(API_URLS.students_info.concat(data.id, "/"), {
      method: "DELETE",
      headers: headersList,
    })
      .then((res) => {
        if (res.status === 204) {
          toast.success(`Successfully Deleted Category`, toastSuccessStyle);
          setTimeout(() => {
            navigate("/admin/students/list");
          }, 1000);
        } else {
          toast.error(
            `${res.status} Error !!! ${res.statusText}`,
            toastDangerStyle
          );
        }
      })
      .catch((err) => {
        toast.error(`Error !!! Not Found`, toastDangerStyle);
      });
  };

  if (
    !currentUser.roles.includes("VIEW_STUDENT") &&
    !currentUser.roles.includes("SUPER_ADMIN")
  ) {
    return <AccessDenied />;
  }
  return (
    <div className="main-content">
      <div className="row">
        <div className="col-12 col-md-12 col-lg-12">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/admin">Home</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/admin/students/list">Student List</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Student Data
              </li>
            </ol>
          </nav>
        </div>
        <div className="col-md-6 col-lg-12 col-xl-4">
          <div className="shadow-sm p-1 mb-3 bg-white rounded">
            <div className="card-body">
              <div className="d-flex flex-column align-items-center ">
                {" "}
                <img
                  src={
                    data.pass_port === null
                      ? "/assets/img/users/default-img.png"
                      : data.pass_port
                  }
                  alt="student-passport"
                  className="rounded-circle"
                  style={{ width: "150px" }}
                />
                {/* <img
                alt="image"
                src="/assets/img/user.png"
                className="user-img-radious-style"
              /> */}
                <div className="mt-4">
                  <h5 className="text-info">
                    {data.first_name} {data.last_name}
                  </h5>
                  <h5 className="text-muted font-size-sm">{data.category}</h5>
                  <h6 className="text-muted font-size-sm">{data.gender}</h6>
                </div>
              </div>
            </div>
          </div>
          <div className="shadow-sm p-3 mb-5 bg-white rounded">
            <ul className="list-group list-group-flush">
              <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                <h6 className="mb-0 text-dark">Serial No</h6>
                <span className="text-info font-weight-bold">{data.id}</span>
              </li>
              <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                <h6 className="mb-0 text-dark">Current Grade</h6>
                <span className="text-info font-weight-bold">
                  {data.current_grade}
                </span>
              </li>
              <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                <h6 className="mb-0 text-dark">Start Grade</h6>
                <span className="text-info font-weight-bold">
                  {data.start_grade}
                </span>
              </li>
              <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                <h6 className="mb-0 text-dark">Admission Date</h6>
                <span className="text-info font-weight-bold">
                  {data.admission_date}
                </span>
              </li>
              <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                <h6 className="mb-0 text-dark">Fee Status</h6>
                <span className="text-success font-weight-bold">Cleared</span>
              </li>
            </ul>
          </div>
        </div>
        {/* Info */}
        <div className="col-md-6 col-lg-12 col-xl-8">
          <div className="mb-3 shadow-sm p-1 mb-1 bg-white rounded">
            <div className="card-body">
              <div className="row">
                <div className="col-sm-4">
                  <h6 className="mb-0 text-dark">Admission No</h6>
                </div>
                <span className="col-sm-8 text-muted font-weight-bold">
                  {data.admn_no}
                </span>
              </div>
              <hr />
              <div className="row">
                <div className="col-sm-4">
                  <h6 className="mb-0 text-dark">Email</h6>
                </div>
                <span className="col-sm-8 text-muted font-weight-bold">
                  {data.e_mail}
                </span>
              </div>
              <hr />
              <div className="row">
                <div className="col-sm-4">
                  <h6 className="mb-0 text-dark">Phone</h6>
                </div>
                <span className="col-sm-8 text-muted font-weight-bold">
                  {data.tel_no}
                </span>
              </div>
              <hr />
              <div className="row">
                <div className="col-sm-4">
                  <h6 className="mb-0 text-dark">Permanent Address</h6>
                </div>
                <span className="col-sm-8 text-muted font-weight-bold">
                  {data.permanent_address}
                </span>
              </div>
              <hr />
              <div className="row">
                <div className="col-sm-4">
                  <h6 className="mb-0 text-dark">Current Address</h6>
                </div>
                <span className="col-sm-8 text-muted font-weight-bold">
                  {data.current_address}
                </span>
              </div>
              <hr />
            </div>
          </div>
          <div className="row gutters-sm">
            <div className="col-sm-6 mb-3">
              <div className="shadow-sm p-1 mb-3 bg-white rounded">
                <div className="card-body">
                  <div className="row">
                    <div className="col-sm-6">
                      <h6 className="mb-0 text-dark">Date of Birth</h6>
                    </div>
                    <span className="col-sm-6 text-muted font-weight-bold">
                      {data.dob}
                    </span>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-sm-6">
                      <h6 className="mb-0 text-dark">Birth Certh No</h6>
                    </div>
                    <span className="col-sm-6 text-muted font-weight-bold">
                      {data.birth_cert_no}
                    </span>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-sm-6">
                      <h6 className="mb-0 text-dark">Weight</h6>
                    </div>
                    <span className="col-sm-6 text-muted font-weight-bold">
                      {data.weight} Kg
                    </span>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-sm-6">
                      <h6 className="mb-0 text-dark">Height</h6>
                    </div>
                    <span className="col-sm-6 text-muted font-weight-bold">
                      {data.height} cms
                    </span>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-sm-6">
                      <h6 className="mb-0 text-dark">Blood group</h6>
                    </div>
                    <span className="col-sm-6 text-muted font-weight-bold">
                      {data.blood_group}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-6 mb-3">
              <div className="shadow-sm p-1 mb-3 bg-white rounded">
                <div className="card-body">
                  <div className="row">
                    <div className="col-sm-6">
                      <h6 className="mb-0 text-dark">Religion</h6>
                    </div>
                    <span className="col-sm-6 text-muted font-weight-bold">
                      {data.religion}
                    </span>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-sm-6">
                      <h6 className="mb-0 text-dark">Dormitory</h6>
                    </div>
                    <span className="col-sm-6 text-muted font-weight-bold">
                      {data.dorm_name}
                    </span>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-sm-6">
                      <h6 className="mb-0 text-dark">Room</h6>
                    </div>
                    <span className="col-sm-6 text-muted font-weight-bold">
                      {data.room_no}
                    </span>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-sm-6">
                      <h6 className="mb-0 text-dark">Route</h6>
                    </div>
                    <span className="col-sm-6 text-muted font-weight-bold">
                      {data.route_list}
                    </span>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-sm-6">
                      <h6 className="mb-0 text-dark">Vehicle</h6>
                    </div>
                    <span className="col-sm-6 text-muted font-weight-bold">
                      {data.vehicle_no}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-12 col-lg-12">
          <div className="shadow-sm p-1 mb-3 bg-white rounded">
            <h6 className="text-muted text-center mt-2">
              Last 5 days Attendance
            </h6>
            <div className="d-flex flex-wrap">
              <div className="form-group col-4">
                <label>Instance</label>
                <select
                  style={{ width: "95%" }}
                  className="form-control"
                  value={time}
                  onChange={(e) => setTime(e.target.value)}
                >
                  <option value="" disabled>
                    Time
                  </option>
                  {/* {getSelectOptions(subjects, ["subject_name"])} */}
                  {timeInstance.map((t) => (
                    <option key={t.id} value={t.time}>
                      {t.time}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group col-4">
                <label>Start Date</label>
                <input
                  type="date"
                  className="form-control datepicker"
                  onChange={(e) => setStartDate(e.target.value)}
                  value={startDate}
                />
              </div>
              <div className="form-group col-4">
                <label>End Date</label>
                <input
                  type="date"
                  className="form-control datepicker"
                  onChange={(e) => setEndDate(e.target.value)}
                  value={endDate}
                />
              </div>
              <div className="col-6">
                {attendance && (
                  <PieChart width={400} height={400}>
                    <Pie
                      data={attendance}
                      cx="50%"
                      cy="50%"
                      labelLine={false}
                      label={renderCustomizedAttendance}
                      outerRadius={80}
                      fill="#8884d8"
                      dataKey="value"
                    >
                      {attendance.map((entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={
                            ATTENDANCE_COLORS[index % ATTENDANCE_COLORS.length]
                          }
                          className="text-muted"
                        />
                      ))}
                    </Pie>
                  </PieChart>
                )}
              </div>
              <div className="col-6 d-flex flex-column justify-content-center">
                {attendance && (
                  <>
                    <h6 className="text-dark">
                      Present :{" "}
                      <span className="text-success">{daysPresent}</span>
                    </h6>
                    <hr />
                    <h6 className="text-dark">
                      Absent :{" "}
                      <span className="text-success">{daysAbsent}</span>
                    </h6>
                    <hr />
                  </>
                )}
              </div>
              {/* {attendance.map((a, index) => {
                return (
                  <div key={index} className="row">
                    <div className="col-sm-6">
                      <h6 className="mb-0 text-dark">{a.date}</h6>
                    </div>
                    <span className="col-sm-6">
                      {a.reported === true ? (
                        <span className="text-success font-weight-bold">
                          Present
                        </span>
                      ) : (
                        <span className="text-danger font-weight-bold">
                          Absent
                        </span>
                      )}
                    </span>
                  </div>
                );
              })} */}
            </div>
          </div>
        </div>
        {/* Work on PIechart for the attendance */}
        {/* <div className="col-6 col-md-4 col-lg-4">
          <PieChart
            data={[
              { title: "Present", value: days_present, color: "#E38627" },
              { title: "Absent", value: days_absent, color: "#C13C37" },
            ]}
            labelPosition={50}
            lengthAngle={360}
            lineWidth={15}
            paddingAngle={0}
            radius={50}
            rounded
            startAngle={0}
            viewBoxSize={[100, 100]}
          />
        </div> */}
        <div className="col-md-6 col-lg-12 col-xl-12">
          <div className="shadow-sm p-1 mb-3 bg-white rounded">
            <div className="card-body">
              <div className="row">
                <div className="col-sm-4">
                  <h6 className="mb-0 text-dark">Additional Notes</h6>
                </div>
                <span className="col-sm-8 text-muted font-weight-bold">
                  {data.additional_notes}
                </span>
              </div>
              <hr />
              <div>
                {currentUser.roles.includes("UPDATE_STUDENT") ||
                currentUser.roles.includes("SUPER_ADMIN") ? (
                  <button
                    onClick={() => {
                      editStudentDetails();
                    }}
                    className="btn btn-sm btn-info float-left"
                  >
                    Edit
                  </button>
                ) : (
                  <></>
                )}
                {currentUser.roles.includes("DELETE_STUDENT") ||
                currentUser.roles.includes("SUPER_ADMIN") ? (
                  <button
                    onClick={() => {
                      deleteStudent();
                    }}
                    className="btn btn-sm btn-danger float-right"
                  >
                    Delete
                  </button>
                ) : (
                  <></>
                )}
              </div>
              <Toaster position="top-right" reverseOrder={false} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StudentDetails;
