import React from "react";
import { useState } from "react";
import Tour from "reactour";
import { IconButton } from "@mui/material";
import { InfoOutlined } from "@mui/icons-material";
import authService from "../../../services/auth.service";
import { API_URLS } from "../../../utils/api.routes";
import { useEffect } from "react";
import AccessDenied from "../../../components/custom/Custom.AccessDenied";
import TeacherTimeTable from "../../lessonplan/timetable/TeacherTimeTable";
import { Link } from "react-router-dom";

export default function TeacherBoard() {
  const currentUser = authService.getCurrentUser();

  const [showIntro, setShowIntro] = useState(false);
  const [classrooms, setClassrooms] = useState([]);

  useEffect(() => {
    const fetchAllocatedClasses = async () => {
      const res = await fetch(
        API_URLS.teacher_classrooms.concat(currentUser.username, "/")
      );
      const data = await res.json();
      setClassrooms(data);
    };
    fetchAllocatedClasses();
  }, []);
  console.log(classrooms);
  const steps = [
    {
      selector: "#students",
      content: "Number of Students registered",
    },
    {
      selector: "#staff",
      content: "This is the number of staff",
    },
    {
      selector: "#streams",
      content: "This is the number of streams registered",
    },
    {
      selector: "#classrooms",
      content: "This is a list of classes registered",
    },
  ];

  if (
    !currentUser.roles.includes("TEACHER") &&
    !currentUser.roles.includes("SUPER_ADMIN")
  ) {
    return <AccessDenied />;
  }
  return (
    <>
      <div className="row">
        <div className="col-12">
          <h4 className="text-center text-dark">
            Welcome Teacher {currentUser.username}
            <IconButton
              onClick={() => setShowIntro(true)}
              color="primary"
              aria-label="add to shopping cart"
            >
              <InfoOutlined size="small" />
            </IconButton>
          </h4>
        </div>
      </div>
      <Tour
        steps={steps}
        isOpen={showIntro}
        onRequestClose={() => setShowIntro(false)}
      />

      <div className="row">
        <DashboardCards title="Subjects" subtitle="Total Subjects" count={0} />
        <DashboardCards title="Notice" subtitle="Total Notice" count={3} />
        <DashboardCards title="Exam" subtitle="Total Exam" count={0} />
        <DashboardCards
          title="Online Exam"
          subtitle="Total Online Exam"
          count={0}
        />
        <DashboardCards title="Teachers" subtitle="Total Teachers" count={3} />
        <DashboardCards
          title="Issued Books"
          subtitle="Total Issued Books"
          count={3}
        />
        <DashboardCards
          title="Homeworks"
          subtitle="Total Pending Homework"
          count={1}
        />{" "}
        <DashboardCards
          title="Attendance"
          subtitle="Current month's attendance"
          count={1}
        />
        <div className="col-12 d-flex flex-wrap justify-content-around">
          <h5 className="col-12 text-info text-center">
            Class Rooms Allocated
          </h5>

          {classrooms.map((clr, index) => (
            <div
              key={index}
              className="col-4 m-1 shadow-sm p-3 mb-3 bg-white rounded"
            >
              <Link to={`/admin/classroom/details/${clr.id}`}>
                <h6 className="text-dark d-flex justify-content-between">
                  Classroom{"  "}
                  <span className="text-muted text-right">
                    {clr.class_room_name}
                  </span>
                </h6>
              </Link>
              <h6 className="text-dark d-flex justify-content-between">
                Stream{"  "}
                <span className="text-muted text-right">{clr.stream_name}</span>
              </h6>
              <h6 className="text-dark d-flex justify-content-between">
                Class Room Code{"  "}
                <span className="text-muted text-right">
                  {clr.class_room_code}
                </span>
              </h6>
              <h6 className="text-dark d-flex justify-content-between">
                Academic Year{"  "}
                <span className="text-muted text-right"> {clr.year_name}</span>
              </h6>
            </div>
          ))}
        </div>
        <TeacherTimeTable staff_id={currentUser.username} />
      </div>
    </>
  );
}

const DashboardCards = (props) => {
  return (
    <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-xs-12">
      <div id="students" className="card shadow-sm p-3 mb-3 bg-white rounded">
        <div className="align-items-center justify-content-between">
          <div className="row ">
            <div id="studentslist" className="card-content col-12 d-flex">
              <div className="col-10 pr-1 pl-1">
                <span
                  className="text-dark font-weight-bold"
                  style={{ fontSize: "18px", maxWidth: "100%" }}
                >
                  {props.title || "Title"}
                </span>
                <br />
                <span
                  className="text-muted"
                  style={{ fontSize: "14px", maxWidth: "100%" }}
                >
                  {props.subtitle || "Sample Sub-title"}
                </span>
              </div>
              <div className="col-2 pr-1 pl-1 align-self-center">
                <span
                  className="text-info"
                  style={{ fontSize: "30px", maxWidth: "100%" }}
                >
                  {props.count}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
