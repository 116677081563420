import { InfoOutlined } from "@mui/icons-material";
import {
  Checkbox,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export default function UsersTable({ data, onSelectionChange }) {
  const [selectAll, setSelectAll] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const navigate = useNavigate();

  const handleToggle = (id) => {
    if (selectedUsers.includes(id)) {
      setSelectedUsers(selectedUsers.filter((item) => item !== id));
    } else {
      setSelectedUsers([...selectedUsers, id]);
    }
  };

  const handleSelectAll = (event) => {
    const selected = event.target.checked;
    setSelectAll(selected);

    if (selected) {
      setSelectedUsers(filteredData.map((user) => user.id));
    } else {
      setSelectedUsers([]);
    }
  };

  const handleSearch = (event) => {
    const searchTerm = event.target.value;
    setSearchTerm(searchTerm);

    const filteredData = data.filter(
      (item) =>
        item.username.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.category.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredData(filteredData);
  };

  useEffect(() => {
    onSelectionChange(selectedUsers);
  }, [selectedUsers, onSelectionChange]);

  const detailsPage = (userId) => {
    navigate("/admin/roles/user-details", { state: userId });
  };
  return (
    <TableContainer component={Paper}>
      {searchTerm === "" && (
        <div className="col-8 p-2">
          <h6>Type something to start searching user.</h6>
        </div>
      )}

      <div className="col-10 m-1">
        <input
          type="text"
          className="form-control"
          style={{ width: "60%" }}
          placeholder="Search... user"
          onChange={handleSearch}
        />
      </div>
      {searchTerm !== "" && (
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <TableCell>
                <Checkbox checked={selectAll} onChange={handleSelectAll} />
              </TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Category</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Details</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData.map((user) => (
              <TableRow key={user.id}>
                <TableCell className="m-3">
                  <Checkbox
                    type="checkbox"
                    checked={selectedUsers.includes(user.id)}
                    onChange={() => handleToggle(user.id)}
                  />
                </TableCell>
                <TableCell>{user.username}</TableCell>
                <TableCell>{user.category}</TableCell>
                <TableCell>{user.email}</TableCell>
                <TableCell>
                  <Tooltip title="To Details Page">
                    <IconButton onClick={() => detailsPage(user.id)}>
                      <InfoOutlined color="secondary" />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </TableContainer>
    // <table>
    //   <thead>
    //     <tr>
    //       <th>Select</th>
    //       <th>Name</th>
    //       <th>Email</th>
    //     </tr>
    //   </thead>
    //   <tbody>
    //     {data.map((user) => (
    //       <tr key={user.id}>
    //         <td className="m-3">
    //           <input
    //             type="checkbox"
    //             checked={selectedUsers.includes(user.id)}
    //             onChange={() => handleToggle(user.id)}
    //           />
    //         </td>
    //         <td>{user.username}</td>
    //         <td>{user.email}</td>
    //       </tr>
    //     ))}
    //   </tbody>
    // </table>
  );
}
