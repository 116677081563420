import React from "react";
import { useState } from "react";
import axios from "axios";
import { useQuery } from "react-query";
import Tour from "reactour";
import { IconButton } from "@mui/material";
import { InfoOutlined } from "@mui/icons-material";
import authService from "../../../services/auth.service";
import { useEffect } from "react";
import { API_URLS } from "../../../utils/api.routes";
import {
  CustomIsError,
  CustomIsLoading,
} from "../../../components/custom/Custom.UseQuery";
import AccessDenied from "../../../components/custom/Custom.AccessDenied";
import StudentTimeTable from "../../lessonplan/timetable/StudentTimeTable";

export default function StudentBoard() {
  const currentUser = authService.getCurrentUser();

  const [showIntro, setShowIntro] = useState(false);
  const [classrooms, setClassrooms] = useState([]);
  const [gradeSubjects, setGradeSubjects] = useState([]);
  const [currentClass, setCurrentClass] = useState({});

  useEffect(() => {
    const fetchAllocatedClasses = async () => {
      const res = await fetch(
        API_URLS.stud_class_allocation.concat(currentUser.username, "/")
      );
      const data = await res.json();
      setClassrooms(data);
      setCurrentClass(data[0]);
    };
    const fetchSubjectsInClass = async () => {
      const res = await fetch(
        API_URLS.per_grade_subjects_admn.concat(currentUser.username, "/")
      );
      const data = await res.json();
      setGradeSubjects(data);
    };
    fetchAllocatedClasses();
    fetchSubjectsInClass();
  }, []);

  console.log(currentClass);

  const steps = [
    {
      selector: "#students",
      content: "Number of Students registered",
    },
    {
      selector: "#staff",
      content: "This is the number of staff",
    },
    {
      selector: "#streams",
      content: "This is the number of streams registered",
    },
    {
      selector: "#classrooms",
      content: "This is a list of classes registered",
    },
  ];

  const fetchStudentData = async () => {
    const { data } = await axios.get(
      `${API_URLS.custom_student_admn}${currentUser.username}`
    );
    return data;
  };
  const { data, error, isError, isLoading } = useQuery(
    "studentData",
    fetchStudentData
  );
  if (isLoading) {
    return <CustomIsLoading />;
  }
  if (isError) {
    return <CustomIsError message={error.message} />;
  }
  if (
    !currentUser.roles.includes("STUDENT") &&
    !currentUser.roles.includes("SUPER_ADMIN")
  ) {
    return <AccessDenied />;
  }
  return (
    <>
      <div className="row">
        <div className="col-12">
          <h4 className="text-center text-dark">
            Welcome {`${data.first_name} ${data.last_name} `}
            {data.other_name !== null ? <span>{data.other_name}</span> : null}
            <IconButton
              onClick={() => setShowIntro(true)}
              color="primary"
              aria-label="add to shopping cart"
            >
              <InfoOutlined size="small" />
            </IconButton>
          </h4>
        </div>
      </div>
      <Tour
        steps={steps}
        isOpen={showIntro}
        onRequestClose={() => setShowIntro(false)}
      />

      <div className="row">
        <DashboardCards
          title="Subjects"
          subtitle="Total Subjects"
          count={gradeSubjects.length}
        />
        <DashboardCards title="Notice" subtitle="Total Notice" count={3} />
        <DashboardCards title="Exam" subtitle="Total Exam" count={0} />
        <DashboardCards
          title="Online Exam"
          subtitle="Total Online Exam"
          count={0}
        />
        <DashboardCards title="Teachers" subtitle="Total Teachers" count={3} />
        <DashboardCards
          title="Issued Books"
          subtitle="Total Issued Books"
          count={3}
        />
        <DashboardCards
          title="Pending Homework"
          subtitle="Total Pending Homework"
          count={1}
        />{" "}
        <DashboardCards
          title="Current Month Attendance"
          subtitle="Total attendance Current month"
          count={1}
        />
        <div className="col-12 d-flex flex-wrap">
          {classrooms.map((clr, index) => (
            <div
              key={index}
              className="col-4 m-1 card shadow-sm p-3 mb-3 bg-white rounded"
            >
              <h6 className="text-dark">
                Classroom :{" "}
                <span className="text-muted">
                  {clr.class_room_name}{" "}
                  {clr.current_year === "True" ? (
                    <span className="text-info">(Current)</span>
                  ) : (
                    <></>
                  )}
                </span>
              </h6>
              <span className="font-weight-bold text-muted">
                Stream {clr.stream}
              </span>
              <br />
              <span className="font-weight-bold text-muted">
                Class Room Code {clr.class_room_code}
              </span>
              <br />
              <span className="font-weight-bold text-muted">
                Acadeic Year {clr.year_name}
              </span>
              <br />
            </div>
          ))}
        </div>
        {currentClass && (
          <StudentTimeTable classroom={currentClass.class_room_code} />
        )}
      </div>
    </>
  );
}

const DashboardCards = (props) => {
  return (
    <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-xs-12">
      <div id="students" className="card shadow-sm p-3 mb-3 bg-white rounded">
        <div className="align-items-center justify-content-between">
          <div className="row ">
            <div id="studentslist" className="card-content col-12 d-flex">
              <div className="col-10 pr-1 pl-1">
                <span
                  className="text-dark font-weight-bold"
                  style={{ fontSize: "18px", maxWidth: "100%" }}
                >
                  {props.title || "Title"}
                </span>
                <br />
                <span
                  className="text-muted"
                  style={{ fontSize: "14px", maxWidth: "100%" }}
                >
                  {props.subtitle || "Sample Sub-title"}
                </span>
              </div>
              <div className="col-2 pr-1 pl-1 align-self-center">
                <span
                  className="text-info"
                  style={{ fontSize: "30px", maxWidth: "100%" }}
                >
                  {props.count}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
