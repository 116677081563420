import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { API_URLS } from "../../../../utils/api.routes";
import authService from "../../../../services/auth.service";
import {
  CustomIsError,
  CustomIsLoading,
} from "../../../../components/custom/Custom.UseQuery";
import axios from "axios";
import { useQuery } from "react-query";
import { Toaster, toast } from "react-hot-toast";
import {
  toastDangerStyle,
  toastSuccessStyle,
} from "../../../../components/shared/Custom.Styles";
import AccessDenied from "../../../../components/custom/Custom.AccessDenied";

export default function StudProfile() {
  const currentUser = authService.getCurrentUser();

  const { state } = useLocation();
  const navigate = useNavigate();

  const [attendance, setAttendance] = useState([]);
  var days_present = 0;
  var days_absent = 0;

  useEffect(() => {
    // const fetchDailyAttendance = async () => {
    //   const response = await fetch(
    //     API_URLS.concat("?student_id=", state)
    //   );
    //   const data = await response.json();
    //   setAttendance(data);
    // };
    // fetchDailyAttendance();
  }, []);
  // Runs Whenever the attendance changes
  useEffect(() => {
    const daysCalculation = () => {
      for (var i = 0; i < attendance.length; i++) {
        if (attendance[i]["reported"]) {
          days_present = days_present + 1;
          continue;
        }
        days_absent += 1;
      }
    };
    daysCalculation();
    // console.log("Absent: " + days_absent);
    // console.log("Present: " + days_present);
  }, [attendance]);

  const fetchStudentDetails = async () => {
    const { data } = await axios.get(
      API_URLS.full_student_admn.concat(`${currentUser.username}/`)
    );
    return data;
  };
  const { data, error, isError, isLoading } = useQuery(
    "student",
    fetchStudentDetails
  );
  if (isLoading) {
    return <CustomIsLoading />;
  }
  if (isError) {
    return <CustomIsError />;
  }

  const editStudentDetails = () => {
    navigate("/admin/student/edit-self", {
      state: {
        data: data,
      },
    });
  };

  if (
    !currentUser.roles.includes("VIEW_STUDENT") &&
    !currentUser.roles.includes("STUDENT")
  ) {
    return <AccessDenied />;
  }

  return (
    <>
      <div className="col-md-6 col-lg-12 col-xl-4">
        <div className="shadow-sm p-1 mb-3 bg-white rounded">
          <div className="card-body">
            <div className="d-flex flex-column align-items-center ">
              {" "}
              <img
                src={
                  data.pass_port === null
                    ? "/assets/img/users/default-img.png"
                    : data.pass_port
                }
                alt="Student Image"
                className="rounded-circle"
                style={{ width: "100px" }}
              />
              <div className="mt-4">
                <h5 className="text-info">
                  {data.first_name} {data.last_name}
                </h5>
                <h5 className="text-muted font-size-sm">{data.category}</h5>
                <h6 className="text-muted font-size-sm">{data.gender}</h6>
              </div>
            </div>
          </div>
        </div>
        <div className="shadow-sm p-3 mb-5 bg-white rounded">
          <ul className="list-group list-group-flush">
            <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
              <h6 className="mb-0 text-dark">Serial No</h6>
              <span className="text-info font-weight-bold">{data.id}</span>
            </li>
            <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
              <h6 className="mb-0 text-dark">Current Grade</h6>
              <span className="text-info font-weight-bold">
                {data.current_grade}
              </span>
            </li>
            <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
              <h6 className="mb-0 text-dark">Start Grade</h6>
              <span className="text-info font-weight-bold">
                {data.start_grade}
              </span>
            </li>
            <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
              <h6 className="mb-0 text-dark">Admission Date</h6>
              <span className="text-info font-weight-bold">
                {data.admission_date}
              </span>
            </li>
            <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
              <h6 className="mb-0 text-dark">Fee Status</h6>
              <span className="text-success font-weight-bold">Cleared</span>
            </li>
          </ul>
        </div>
      </div>
      {/* Info */}
      <div className="col-md-6 col-lg-12 col-xl-8">
        <div className="mb-3 shadow-sm p-1 mb-1 bg-white rounded">
          <div className="card-body">
            <div className="row">
              <div className="col-sm-4">
                <h6 className="mb-0 text-dark">Admission No</h6>
              </div>
              <span className="col-sm-8 text-muted font-weight-bold">
                {data.admn_no}
              </span>
            </div>
            <hr />
            <div className="row">
              <div className="col-sm-4">
                <h6 className="mb-0 text-dark">Email</h6>
              </div>
              <span className="col-sm-8 text-muted font-weight-bold">
                {data.e_mail}
              </span>
            </div>
            <hr />
            <div className="row">
              <div className="col-sm-4">
                <h6 className="mb-0 text-dark">Phone</h6>
              </div>
              <span className="col-sm-8 text-muted font-weight-bold">
                {data.tel_no}
              </span>
            </div>
            <hr />
            <div className="row">
              <div className="col-sm-4">
                <h6 className="mb-0 text-dark">Permanent Address</h6>
              </div>
              <span className="col-sm-8 text-muted font-weight-bold">
                {data.permanent_address}
              </span>
            </div>
            <hr />
            <div className="row">
              <div className="col-sm-4">
                <h6 className="mb-0 text-dark">Current Address</h6>
              </div>
              <span className="col-sm-8 text-muted font-weight-bold">
                {data.current_address}
              </span>
            </div>
            <hr />
          </div>
        </div>
        <div className="row gutters-sm">
          <div className="col-sm-6 mb-3">
            <div className="shadow-sm p-1 mb-3 bg-white rounded">
              <div className="card-body">
                <div className="row">
                  <div className="col-sm-6">
                    <h6 className="mb-0 text-dark">Date of Birth</h6>
                  </div>
                  <span className="col-sm-6 text-muted font-weight-bold">
                    {data.dob}
                  </span>
                </div>
                <hr />
                <div className="row">
                  <div className="col-sm-6">
                    <h6 className="mb-0 text-dark">Birth Certh No</h6>
                  </div>
                  <span className="col-sm-6 text-muted font-weight-bold">
                    {data.birth_cert_no}
                  </span>
                </div>
                <hr />
                <div className="row">
                  <div className="col-sm-6">
                    <h6 className="mb-0 text-dark">Weight</h6>
                  </div>
                  <span className="col-sm-6 text-muted font-weight-bold">
                    {data.weight} Kg
                  </span>
                </div>
                <hr />
                <div className="row">
                  <div className="col-sm-6">
                    <h6 className="mb-0 text-dark">Height</h6>
                  </div>
                  <span className="col-sm-6 text-muted font-weight-bold">
                    {data.height} cms
                  </span>
                </div>
                <hr />
                <div className="row">
                  <div className="col-sm-6">
                    <h6 className="mb-0 text-dark">Blood group</h6>
                  </div>
                  <span className="col-sm-6 text-muted font-weight-bold">
                    {data.blood_group}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-6 mb-3">
            <div className="shadow-sm p-1 mb-3 bg-white rounded">
              <div className="card-body">
                <div className="row">
                  <div className="col-sm-6">
                    <h6 className="mb-0 text-dark">Religion</h6>
                  </div>
                  <span className="col-sm-6 text-muted font-weight-bold">
                    {data.religion}
                  </span>
                </div>
                <hr />
                <div className="row">
                  <div className="col-sm-6">
                    <h6 className="mb-0 text-dark">Dormitory</h6>
                  </div>
                  <span className="col-sm-6 text-muted font-weight-bold">
                    {data.dorm_name}
                  </span>
                </div>
                <hr />
                <div className="row">
                  <div className="col-sm-6">
                    <h6 className="mb-0 text-dark">Room</h6>
                  </div>
                  <span className="col-sm-6 text-muted font-weight-bold">
                    {data.room_no}
                  </span>
                </div>
                <hr />
                <div className="row">
                  <div className="col-sm-6">
                    <h6 className="mb-0 text-dark">Route</h6>
                  </div>
                  <span className="col-sm-6 text-muted font-weight-bold">
                    {data.route_list}
                  </span>
                </div>
                <hr />
                <div className="row">
                  <div className="col-sm-6">
                    <h6 className="mb-0 text-dark">Vehicle</h6>
                  </div>
                  <span className="col-sm-6 text-muted font-weight-bold">
                    {data.vehicle_no}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-6 col-md-4 col-lg-4">
        <div className="shadow-sm p-1 mb-3 bg-white rounded">
          <h6 className="text-muted text-center mt-2">
            Last 5 days Attendance
          </h6>
          <div className="card-body">
            {attendance.map((a, index) => {
              return (
                <div key={index} className="row">
                  <div className="col-sm-6">
                    <h6 className="mb-0 text-dark">{a.date}</h6>
                  </div>
                  <span className="col-sm-6">
                    {a.reported === true ? (
                      <span className="text-success font-weight-bold">
                        Present
                      </span>
                    ) : (
                      <span className="text-danger font-weight-bold">
                        Absent
                      </span>
                    )}
                  </span>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      {/* Work on PIechart for the attendance */}
      {/* <div className="col-6 col-md-4 col-lg-4">
          <PieChart
            data={[
              { title: "Present", value: days_present, color: "#E38627" },
              { title: "Absent", value: days_absent, color: "#C13C37" },
            ]}
            labelPosition={50}
            lengthAngle={360}
            lineWidth={15}
            paddingAngle={0}
            radius={50}
            rounded
            startAngle={0}
            viewBoxSize={[100, 100]}
          />
        </div> */}
      <div className="col-md-6 col-lg-12 col-xl-12">
        <div className="shadow-sm p-1 mb-3 bg-white rounded">
          <div className="card-body">
            <div className="row">
              <div className="col-sm-4">
                <h6 className="mb-0 text-dark">Additional Notes</h6>
              </div>
              <span className="col-sm-8 text-muted font-weight-bold">
                {data.additional_notes}
              </span>
            </div>
            <hr />
            {/* Need to abstract edit details functionality */}
            <div>
              <button
                onClick={() => {
                  editStudentDetails();
                }}
                className="btn btn-sm btn-info float-left"
              >
                Edit
              </button>
            </div>
            <Toaster position="top-right" reverseOrder={false} />
          </div>
        </div>
      </div>
    </>
  );
}
