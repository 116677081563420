import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import authService from "../../services/auth.service";
import { API_URLS } from "../../utils/api.routes";
function Invoices() {
  // const [currentUser, setCurrentUser] = useState(null);
  const currentUser = authService.getCurrentUser();

  const [invoice, setInvoice] = useState([]);
  const [rowId, setRowId] = useState(null);
  const [pageSize, setPageSize] = useState(5);

  // useEffect(() => {
  //   const fetchCurrentUser = async () => {
  //     const user = await authService.getCurrentUser();
  //     setCurrentUser(user);
  //   };
  //   fetchCurrentUser();
  // }, []);

  useEffect(() => {
    const fetchInvoices = async () => {
      if (currentUser.roles.includes("SUPER_ADMIN")) {
        const res = await fetch("http://34.201.161.63:8009/Allpayment");
        console.log("Admin");
        const data = await res.json();
        setInvoice(data);
      } else if (currentUser.roles.includes("STUDENT")) {
        const res = await fetch(
          `http://34.201.161.63:8009/payment/${currentUser.username}`
        );
        console.log("Student");
        const data = await res.json();
        setInvoice(data);
      }
    };
    fetchInvoices();
  }, []);

  console.log(currentUser?.category);
  console.log(invoice);

  const columns = [
    {
      field: "__last_update",
      headerName: "last update",
      hide: false,
      headerAlign: "center",
      width: 80,
    },
    {
      field: "amount",
      headerName: "amount ",
      type: "number",
      hide: false,
      headerAlign: "center",
      width: 80,
    },
    {
      field: "amount_paid",
      headerName: "amount_paid",
      headerAlign: "center",
      hide: false,
      editable: true,
      width: 100,
    },
    {
      field: "amount_residual",
      headerName: "amount_residual",
      headerAlign: "center",
      hidable: false,
      editable: true,
      width: 130,
    },
    {
      field: "payment_state",
      headerName: "payment state",
      headerAlign: "center",
      hidable: false,
      editable: true,
      width: 130,
    },
    {
      field: "bank_partner_id",
      headerName: "partner id",
      headerAlign: "center",
      hidable: false,
      editable: true,
      width: 130,
    },
    {
      field: "highest_name",
      headerName: "pname",
      headerAlign: "center",
      hidable: false,
      editable: true,
      width: 130,
    },
    {
      field: "amount_total",
      headerName: "total price",
      headerAlign: "center",
      hidable: false,
      editable: true,
      width: 130,
    },
    {
      field: "quantity",
      headerName: "qunatity",
      headerAlign: "center",
      hidable: false,
      editable: true,
      width: 130,
    },
  ];

  return (
    <div className="main-content">
      <div className="row justify-content-center">
        <div className="col-12 col-md-12 col-lg-12">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/admin">Home</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                All payments
              </li>
            </ol>
          </nav>
        </div>
        <div className="col-md-12 col-lg-12 col-xl-12">
          <div className="shadow-sm p-3 mb-5 bg-white rounded">
            <div className="text-center">
              <h5 className="text-center text-dark">Payments list</h5>
            </div>
            <div style={{ height: 420 }}>
              {invoice && (
                <DataGrid
                  columns={columns}
                  components={{
                    Toolbar: GridToolbar,
                  }}
                  rows={invoice}
                  getRowId={(row) => row.id}
                  rowsPerPageOptions={[5, 10, 20]}
                  rowHeight={45}
                  pageSize={pageSize}
                  onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                  getRowSpacing={(params) => ({
                    top: params.isFirstVisible ? 0 : 5,
                    bottom: params.isLastVisible ? 0 : 5,
                  })}
                  onCellEditCommit={(params) => setRowId(params.id)}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Invoices;
