import React from "react";
import { Link } from "react-router-dom";
import Academics from "./Academics";
import Admin from "./Admin";
import Accounts from "./Accounts";
import Dormitory from "./Dormitory";
import Examination from "./Examination";
import Assignments from "./Assignments";
import Quizzes from "./Quizzes";
import Fee from "./Fee";
import HumanResource from "./HumanResource";
import LessonPlan from "./LessonPlan";
import Student from "./Student";
import StudyMaterials from "./StudyMaterials";
import Transport from "./Transport";
import Library from "./Library";
import SystemSettings from "./SystemSettings";
import FrontSettings from "./FrontSettings";
import authService from "../../services/auth.service";
import { useState } from "react";
import { useEffect } from "react";
import { API_URLS } from "../../utils/api.routes";
import PermissionsRoles from "./PermissionsRoles";
import { DashboardOutlined } from "@mui/icons-material";
import Inventory from "./Inventory";

export default function AdminSidebar() {
  const currentUser = authService.getCurrentUser();
  const [school, setSchool] = useState({});

  useEffect(() => {
    const fetchSchoolInfo = async () => {
      const res = await fetch(API_URLS.school);
      const data = await res.json();
      setSchool(data[0]);
    };
    fetchSchoolInfo();
  }, []);
  return (
    <div className="main-sidebar sidebar-style-2">
      <aside id="sidebar-wrapper">
        <div className="sidebar-brand">
          <Link to="/admin" className="d-flex justify-content-center">
            <img
              alt="logo-image"
              src={school["logo"]}
              className="header-logo"
              style={{ height: 60 }}
            />
            <span className="logo-name text-white ml-1">{school["code"]}</span>
          </Link>
        </div>
        <ul className="sidebar-menu">
          {/* <li className="menu-header">Main</li> */}
          {currentUser.roles.includes("VIEW_DASHBOARD_SIDEBAR") ? (
            // <li className="dropdown">
            <Link to="/admin/dashboard" className="menu-toggle nav-link">
              <DashboardOutlined fontSize="medium" className="icon-mr" />
              <span>Dashboard</span>
            </Link>
          ) : (
            // </li>
            <></>
          )}
          {currentUser.roles.includes("SUPER_ADMIN") ||
          currentUser.roles.includes("VIEW_ADMIN_SECTION_SIDEBAR") ? (
            <Admin />
          ) : (
            <></>
          )}
          {currentUser.roles.includes("SUPER_ADMIN") ||
          currentUser.roles.includes("VIEW_STUDENT_INFO_SIDEBAR") ? (
            <Student />
          ) : (
            <></>
          )}
          {currentUser.roles.includes("SUPER_ADMIN") ||
          currentUser.roles.includes("VIEW_ACADEMICS_SIDEBAR") ? (
            <Academics />
          ) : (
            <></>
          )}
          {currentUser.roles.includes("SUPER_ADMIN") ||
          currentUser.roles.includes("VIEW_LESSON_PLAN_SIDEBAR") ? (
            <LessonPlan />
          ) : (
            <></>
          )}
          {currentUser.roles.includes("SUPER_ADMIN") ||
          currentUser.roles.includes("VIEW_EXAMINATION_SIDEBAR") ? (
            <Examination />
          ) : (
            <></>
          )}
          {currentUser.roles.includes("SUPER_ADMIN") ||
          currentUser.roles.includes("VIEW_FEES_SIDEBAR") ? (
            <Fee />
          ) : (
            <></>
          )}
          {currentUser.roles.includes("SUPER_ADMIN") ||
          currentUser.roles.includes("VIEW_ACCOUNTS_SIDEBAR") ? (
            <Accounts />
          ) : (
            <></>
          )}

          {currentUser.roles.includes("SUPER_ADMIN") ||
          currentUser.roles.includes("VIEW_TRANSPORT_SIDEBAR") ? (
            <Transport />
          ) : (
            <></>
          )}
          {currentUser.roles.includes("SUPER_ADMIN") ||
          currentUser.roles.includes("VIEW_DORMITORY_SIDEBAR") ? (
            <Dormitory />
          ) : (
            <></>
          )}
          {currentUser.roles.includes("SUPER_ADMIN") ||
          currentUser.roles.includes("VIEW_HR_SIDEBAR") ? (
            <HumanResource />
          ) : (
            <></>
          )}
          {currentUser.roles.includes("SUPER_ADMIN") ||
          currentUser.roles.includes("VIEW_ASSIGNMENT_SIDEBAR") ? (
            <Assignments />
          ) : (
            <></>
          )}
          {currentUser.roles.includes("SUPER_ADMIN") ||
          currentUser.roles.includes("VIEW_QUIZZES_SIDEBAR") ? (
            <Quizzes />
          ) : (
            <></>
          )}
          {/* {currentUser.roles.includes("SUPER_ADMIN") ||
          currentUser.roles.includes("VIEW_STUDY_MATERIAL_SIDEBAR") ? (
            <StudyMaterials />
          ) : (
            <></>
          )} */}
          {currentUser.roles.includes("SUPER_ADMIN") ? <Library /> : <></>}
          {currentUser.roles.includes("SUPER_ADMIN") ||
          currentUser.roles.includes("VIEW_ROLES_SIDEBAR") ? (
            <PermissionsRoles />
          ) : (
            <></>
          )}
          {/* {currentUser.roles.includes("SUPER_ADMIN") ||
          currentUser.roles.includes("VIEW_FRONT_SETTINGS_SIDEBAR") ? (
            <FrontSettings />
          ) : (
            <></>
          )} */}
          {currentUser.roles.includes("SUPER_ADMIN") ||
          currentUser.roles.includes("VIEW_SYSTEM_SETTINGS_SIDEBAR") ? (
            <SystemSettings />
          ) : (
            <></>
          )}
        </ul>
      </aside>
    </div>
  );
}
