import React, { Component } from "react";
import { Routes, Route, Link, Navigate } from "react-router-dom";
// import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import AuthService from "./services/auth.service";
import Login from "./pages/auth/login.component";
import Register from "./pages/auth/register.component";
import Home from "./pages/auth/home.component";
import Profile from "./pages/auth/profile.component";
import BoardUser from "./pages/auth/board-user.component";
import BoardModerator from "./pages/auth/board-moderator.component";
import BoardAdmin from "./pages/auth/board-admin.component";

// import AuthVerify from "./common/auth-verify";
import EventBus from "./common/EventBus";
import PrivatePages from "./pages/auth/PrivatePages";
import { PRIVATE_ROUTES } from "./utils/private.routes";
import AdminDashboard from "./pages/admin/AdminDashboard";
import AdminOutline from "./pages/admin/AdminOutline";
import HomeDashboard from "./pages/public/HomeDashboard";
import AboutUs from "./pages/website/AboutUs";
import ContactUs from "./pages/website/ContactUs";
import AccessDeniedPage from "./components/custom/Custom.AccessDeniedPage";
import UserDashboard from "./pages/admin/UserDashboard";
import StudProfile from "./pages/students/stud/profile/StudProfile";
import StudAssignments from "./pages/students/stud/profile/StudAssignments";

import StudExam from "./pages/students/stud/profile/StudExam";
import StudentProfile from "./pages/students/stud/profile/StudentProfile";
import Homepage from "./pages/website/home";

const ProtectedRoute = ({ component: Component, isAuthenticated, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isAuthenticated ? (
        <Component {...props} />
      ) : (
        <Navigate to="/access-denied" />
      )
    }
  />
);

class App extends Component {
  constructor(props) {
    super(props);
    this.logOut = this.logOut.bind(this);

    this.state = {
      showModeratorBoard: false,
      showAdminBoard: false,
      currentUser: undefined,
    };
  }

  componentDidMount() {
    const user = AuthService.getCurrentUser();

    if (user) {
      this.setState({
        currentUser: user,
        showModeratorBoard: user.roles.includes("ROLE_MODERATOR"),
        showAdminBoard: user.roles.includes("ROLE_ADMIN"),
      });
    }

    EventBus.on("logout", () => {
      this.logOut();
    });
  }

  componentWillUnmount() {
    EventBus.remove("logout");
  }

  logOut() {
    AuthService.logout();
    this.setState({
      showModeratorBoard: false,
      showAdminBoard: false,
      currentUser: undefined,
    });
  }

  render() {
    const { currentUser, showModeratorBoard, showAdminBoard } = this.state;

    return (
      <div id="app">
        <div className="main-wrapper main-wrapper-1">
          <Routes>
            <Route path="/" element={<HomeDashboard />}>
              <Route index element={<Homepage />} />
              {/* <Route index element={<Home />} /> */}
              <Route path="/about-us" element={<AboutUs />} />
              <Route path="/contact-us" element={<ContactUs />} />
              <Route path="/access-denied" element={<AccessDeniedPage />} />
              <Route path="account/login" element={<Login />} />
              <Route path="account/register" element={<Register />} />
              <Route path="account/profile" element={<Profile />} />
              <Route path="/user" element={<BoardUser />} />
              <Route path="/mod" element={<BoardModerator />} />
            </Route>
            <Route path="/admin" element={<AdminDashboard />}>
              <Route index element={<AdminOutline />} />
              <Route path="/admin/dashboard" element={<UserDashboard />} />
              <Route path="/admin/user/profile" element={<StudentProfile />}>
                <Route index element={<StudProfile />} />
                <Route path="exams" element={<StudExam />} />
                <Route path="assignments" element={<StudAssignments />} />
              </Route>
              {PRIVATE_ROUTES.map((route, index) => {
                if (currentUser) {
                  return (
                    <Route
                      element={<route.element />}
                      key={index}
                      path={`${route.layout}/${route.path}`}
                    />
                  );
                } else {
                  return null;
                }
                // return (
                //   <Route
                //     element={<route.element />}
                //     key={index}
                //     path={`${route.layout}/${route.path}`}
                //   />
                //   // <ProtectedRoute
                //   //   component={route.element}
                //   //   key={index}
                //   //   path={`${route.layout}/${route.path}`}
                //   //   isAuthenticated={currentUser}
                //   // />
                // );
              })}
            </Route>
            <Route path="*" element={<AccessDeniedPage />} />
          </Routes>
        </div>
      </div>
    );
  }
}

export default App;
