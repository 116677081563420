import React, { useState } from "react";
import { Button, Step, StepLabel, Stepper, Typography } from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import BasicForm from "./old_steps/BasicForm";
import ContactForm from "./old_steps/ContactForm";
import ConfirmationForm from "./old_steps/ConfirmationForm";
import AccountForm from "./old_steps/AccountForm";
import ProfessionForm from "./old_steps/ProfessionForm";
import EmergencyContact from "./old_steps/EmergencyContact";
import { Link, useNavigate } from "react-router-dom";

function getSteps() {
  return [
    "Basic information",
    "Contact Information",
    "Professional Qualification",
    "Account Information",
    "Emergency Contact",
    "Confirmation",
  ];
}

function getStepContent(step) {
  switch (step) {
    case 0:
      return <BasicForm />;
    case 1:
      return <ContactForm />;
    case 2:
      return <ProfessionForm />;
    case 3:
      return <AccountForm />;
    case 4:
      return <EmergencyContact />;
    case 5:
      return <ConfirmationForm />;
    default:
      return "unknown step";
  }
}

export default function AddStaff() {
  const navigate = useNavigate();

  const methods = useForm({
    defaultValues: {
      staff_number: null,
      role: "",
      first_name: "",
      last_name: "",
      other_name: "",
      email: "",
      gender: "",
      dob: "",
      mobile: "",
      marital_status: "",
      emergency_mobile: "",
      driving_license: "",
      current_address: "",
      permanent_address: "",
      experience: "",
      service_no: "",
      basic_salary: "",
      contract_type: "",
      contract_length: "",
      location: "",
      bankaccount_name: "",
      account_number: "",
      bank_name: "",
      branch_name: "",
      facebook_url: "",
      twitter_url: "",
      linkedin_url: "",
      instagram_url: "",
      with_disability: "",
      tel_no: "",
      postal_code: "",
      zip_code: "",
      home_county: "",
      home_sub_county: "",
      home_ward: "",
      home_location: "",
      home_village: "",
      prev_empl1: "",
      prev_empl_email1: "",
      prev_empl_tel1: "",
      prev_empl_address1: "",
      prev_empl2: "",
      prev_empl_email2: "",
      prev_empl_tel2: "",
      prev_empl_address2: "",
      prof_qualification_1: "",
      prof_qualification_2: "",
      prof_qualification_3: "",
      prof_qualification_4: "",
      emerg_first_name1: "",
      emerg_last_name1: "",
      emerg_tel_no1: "",
      emerg_mobile_no1: "",
      emerg_gender1: "",
      emerg_relation1: "",
      emerg_first_name2: "",
      emerg_last_name2: "",
      emerg_tel_no2: "",
      emerg_mobile_no2: "",
      emerg_gender2: "",
      emerg_relation2: "",
      designation: "",
      department: "",
      category: "",
    },
  });

  const [activeStep, setActiveStep] = useState(0);
  const [skippedSteps, setSkippedSteps] = useState([]);
  const steps = getSteps();
  const isStepOptional = (step) => {
    return step === 0 || step === 1 || step === 2 || step === 3 || step === 4;
  };
  const isStepFalied = () => {
    return Boolean(Object.keys(methods.formState.errors).length);
  };
  const isStepSkipped = (step) => {
    return skippedSteps.includes(step);
  };

  const handleNext = (data) => {
    if (activeStep == steps.length - 1) {
      fetch("https://jsonplaceholder.typicode.com/comments")
        .then((data) => data.json())
        .then((res) => {
          console.log(res);
          setActiveStep(activeStep + 1);
        });
      console.log("Submitted Data");
      navigate("/admin");
    } else {
      setActiveStep(activeStep + 1);
      setSkippedSteps(
        skippedSteps.filter((skipItem) => skipItem !== activeStep)
      );
    }
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const handleSkip = () => {
    if (!isStepSkipped(activeStep)) {
      setSkippedSteps([...skippedSteps, activeStep]);
    }
    setActiveStep(activeStep + 1);
  };

  // // Posting Data Handler
  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   await fetch(API_URLS.students_info, {
  //     method: "POST",
  //     headers: headersList,
  //     body: bodyContent,
  //   })
  //     .then((res) => {
  //       setMessage("Student Registered Successfully");
  //       console.log(message);
  //       navigate("/admin/students/list");
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       setMessage("Some Error Occured");
  //     });
  // };

  // Posting Data Handler
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Submitted");
  };

  return (
    <div className="main-content">
      <div className="row">
        <div className="col-12 col-md-12 col-lg-12">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link className="font-weight-bold" to="/admin">
                  Home
                </Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                <span className="font-weight-bold">Register Staff</span>
              </li>
            </ol>
          </nav>
        </div>
        <div className="col-md-12 col-lg-12 col-xl-12">
          <div className="shadow p-3 mb-5 bg-white rounded">
            <Stepper alternativeLabel activeStep={activeStep}>
              {steps.map((step, index) => {
                const labelProps = {};
                const stepProps = {};
                if (isStepOptional(index)) {
                  labelProps.optional = (
                    <Typography
                      variant="caption"
                      align="center"
                      style={{ display: "block" }}
                    >
                      optional
                    </Typography>
                  );
                }
                if (isStepFalied() && activeStep == index) {
                  labelProps.error = true;
                }
                if (isStepSkipped(index)) {
                  stepProps.completed = false;
                }
                return (
                  <Step {...stepProps} key={index}>
                    <StepLabel {...labelProps}>{step}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>

            {activeStep === steps.length ? (
              <Typography variant="h3" align="center">
                Thank You
              </Typography>
            ) : (
              <>
                <FormProvider {...methods}>
                  <form onSubmit={methods.handleSubmit(handleNext)}>
                    {getStepContent(activeStep)}

                    <Button disabled={activeStep === 0} onClick={handleBack}>
                      back
                    </Button>
                    {isStepOptional(activeStep) && (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSkip}
                      >
                        skip
                      </Button>
                    )}
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleNext}
                      type="submit"
                    >
                      {activeStep === steps.length - 1 ? "Register" : "Next"}
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleSubmit}
                      type="submit"
                    >
                      Finish
                    </Button>
                  </form>
                </FormProvider>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
