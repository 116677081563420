import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import axios from "axios";
import React, { useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import {
  toastSuccessStyle,
  toastDangerStyle,
  toastWarnigStyle,
} from "../../../components/shared/Custom.Styles";
import { API_URLS } from "../../../utils/api.routes";
import { CustomPagination } from "../../../components/custom/Custom.DataGrid";
import { Button } from "@mui/material";
import { Send } from "@mui/icons-material";
import {
  CustomIsError,
  CustomIsLoading,
} from "../../../components/custom/Custom.UseQuery";
import authHeader from "../../../services/auth-header";
import authService from "../../../services/auth.service";
import AccessDenied from "../../../components/custom/Custom.AccessDenied";
import AttendanceInstanceActions from "./AttendanceInstanceActions";

export default function AttendanceInstance() {
  const currentUser = authService.getCurrentUser();

  const [time, setTime] = useState("");
  const [status, setStatus] = useState(false);

  const [file, setFile] = useState();

  const [rowId, setRowId] = useState(null);
  const [pageSize, setPageSize] = useState(5);

  const handleFileUpload = (e) => {
    const selectedFile = e.target.files[0];
    if (
      selectedFile.type === "text/csv" ||
      selectedFile.type === "application/json" ||
      selectedFile.type === "application/vnd.ms-excel" ||
      selectedFile.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      setFile(selectedFile);
    } else {
      toast("Please select a CSV, JSON or Excel file.", toastWarnigStyle);
    }
  };

  const UploadFile = () => {
    const formData = new FormData();
    formData.append("file", file);

    fetch(API_URLS.upload_grade, {
      method: "POST",
      body: formData,
    })
      .then((res) => {
        if (res.status === 200) {
          refetch();
          setFile(null);
          toast.success("Upload Successfull", toastSuccessStyle);
        }
      })
      .catch((error) => {
        toast.error(`Upload Error ${error}`, toastDangerStyle);
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await fetch(API_URLS.attendance_instance, {
      method: "POST",
      headers: authHeader(),
      body: JSON.stringify({
        time: time,
        status: status,
      }),
    })
      .then((res) => {
        if (res.status === 201) {
          setTime("");
          setStatus(false);
          refetch();
          toast.success(`Successfully Created Grade`, toastSuccessStyle);
        } else if (res.status === 400) {
          toast.error(`Error !!! ${res.statusText}`, toastDangerStyle);
          setTimeout(() => {
            toast(`Seems Grade Already Exists`, toastWarnigStyle);
          }, 1000);
        } else {
          toast.error(
            `${res.status} Error !!! ${res.statusText}`,
            toastDangerStyle
          );
        }
      })
      .catch((err) => {
        toast.error(`Error, Not Found !!!`, toastDangerStyle);
      });
  };

  async function fetchClassStream() {
    const { data } = await axios.get(API_URLS.attendance_instance);
    return data;
  }
  const { data, error, isError, isLoading, refetch } = useQuery(
    "stream",
    fetchClassStream
  );

  const editableColumns = [
    {
      field: "id",
      headerName: "SL",
      headerAlign: "center",
      type: "number",
      width: "80",
    },
    {
      field: "time",
      headerName: "Time",
      type: "time",
      headerAlign: "center",
      editable: true,
      width: 120,
    },
    {
      field: "status",
      headerName: "Status",
      headerAlign: "center",
      type: "boolean",
      editable: true,
      width: 120,
    },
    {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      width: 150,
      renderCell: (params) => (
        <AttendanceInstanceActions {...{ params, rowId, setRowId }} />
      ),
    },
  ];
  const columns = [
    {
      field: "id",
      headerName: "SL",
      headerAlign: "center",
      type: "number",
      width: "80",
    },
    {
      field: "time",
      headerName: "Time",
      type: "time",
      headerAlign: "center",
      width: 120,
    },
    {
      field: "status",
      headerName: "Status",
      type: "boolean",
      headerAlign: "center",
      width: 120,
    },
  ];
  if (isLoading) {
    return <CustomIsLoading />;
  }
  if (isError) {
    return <CustomIsError message={error.message} />;
  }
  if (
    !currentUser.roles.includes("VIEW_GRADE") &&
    !currentUser.roles.includes("SUPER_ADMIN")
  ) {
    return <AccessDenied />;
  }

  return (
    <div className="main-content">
      <div className="row">
        <div className="col-12 col-md-12 col-lg-12">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/admin">Home</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Attendance Instances
              </li>
            </ol>
          </nav>
        </div>
        {currentUser.roles.includes("ADD_ATTENDANCE_INSTANCE") ||
        currentUser.roles.includes("SUPER_ADMIN") ? (
          <div className="col-md-6 col-lg-12 col-xl-4">
            <div className="shadow-sm p-3 mb-5 bg-white rounded">
              <div>
                <h5 className="text-center text-dark">Add Instance</h5>
              </div>
              <form onSubmit={handleSubmit}>
                <div className="card-body">
                  {/* <div className="form-group">
                    <label htmlFor="sCode">Grade Code</label>
                    <input
                      type="text"
                      id="sCode"
                      className="form-control"
                      value={code}
                      onChange={(e) => setCode(e.target.value)}
                    />
                  </div> */}
                  <div class="form-group">
                    <label>Time</label>
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <div class="input-group-text">
                          <i class="fas fa-clock"></i>
                        </div>
                      </div>
                      <input
                        type="time"
                        class="form-control timepicker"
                        value={time}
                        onChange={(e) => setTime(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <div class="form-control">
                      <input
                        type="checkbox"
                        value={status}
                        checked={status}
                        onChange={(e) => setStatus(e.target.checked)}
                      />
                      <span className="text-dark ml-3">In Use</span>
                    </div>
                  </div>
                </div>
                <div className="text-center">
                  <button className="btn btn-primary mr-1" type="submit">
                    Create
                  </button>
                  <Toaster position="top-right" reverseOrder={false} />
                </div>
              </form>
            </div>
          </div>
        ) : (
          <></>
        )}
        <div className="col-md-6 col-lg-12 col-xl-8">
          <div className="shadow-sm p-3 mb-5 bg-white rounded">
            <h5 className="text-center text-dark">Attendance Instances</h5>
            {currentUser.roles.includes("UPLOAD_GRADE") ||
            currentUser.roles.includes("SUPER_ADMIN") ? (
              <div className="d-flex justify-content-end">
                <div className="d-flex m-1">
                  <Button variant="text" component="label">
                    Upload
                    <input
                      hidden
                      type={"file"}
                      id={"csvFileInput"}
                      accept=".csv,.json,.xlsx,application/vnd.ms-excel"
                      onChange={handleFileUpload}
                    />
                  </Button>
                  {file && (
                    <div className="text-info align-self-center">
                      File: {file.name}
                    </div>
                  )}
                </div>
                <Button
                  size="small"
                  variant="contained"
                  endIcon={<Send />}
                  onClick={UploadFile}
                >
                  Submit
                </Button>
              </div>
            ) : (
              <></>
            )}
            <div style={{ width: "100%" }}>
              <DataGrid
                autoHeight
                columns={
                  currentUser.roles.includes("UPDATE_GRADE") ||
                  currentUser.roles.includes("SUPER_ADMIN")
                    ? editableColumns
                    : columns
                }
                components={{
                  Toolbar: GridToolbar,
                  Pagination: CustomPagination,
                }}
                rows={data}
                getRowId={(row) => row.id}
                rowsPerPageOptions={[5, 10, 20]}
                rowHeight={45}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                getRowSpacing={(params) => ({
                  top: params.isFirstVisible ? 0 : 5,
                  bottom: params.isLastVisible ? 0 : 5,
                })}
                onCellEditCommit={(params) => setRowId(params.id)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
