import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { API_URLS } from "../../../utils/api.routes";
import { Button } from "@mui/material";
import { toast } from "react-hot-toast";
import {
  toastDangerStyle,
  toastSuccessStyle,
} from "../../../components/shared/Custom.Styles";
import axios from "axios";

export default function ViewTimetable({ data, times }) {
  const [days, setDays] = useState([]);

  useEffect(() => {
    const fetchDays = async () => {
      const res = await fetch(API_URLS.days);
      const data = await res.json();
      setDays(data);
    };
    fetchDays();
  }, []);

  const submitTimetable = () => {
    var message = "not exists";
    for (let i = 0; i < data.length; i++) {
      if (data[i].message === "exists") {
        message = "exists";
        break;
      }
    }
    if (message === "not exists") {
      axios
        .post(API_URLS.timetable, data)
        .then((res) => {
          if (res.status === 201) {
            toast.success(
              "Time Tabletable Created Successfully",
              toastSuccessStyle
            );
          } else {
            toast.error("An Error Occured", toastDangerStyle);
          }
        })
        .catch((err) => {
          console.log("Error posting data:", err);
        });
    } else {
      toast.error("Ensure that teacher is not Busy", toastDangerStyle);
    }
  };
  console.log(data);
  return (
    <div className="row">
      <div className="col-12">
        <h4 className="text-info text-center">Time table View</h4>
        <div className="col-12">
          {data && days && (
            <table>
              <thead style={{ border: "2px solid grey" }}>
                <tr>
                  <th
                    style={{
                      height: "80px",
                      borderRight: "1.5px solid #222",
                      fontSize: "20px",
                      fontWeight: "bold",
                      borderRight: "1.5px solid #222",
                    }}
                    className="text-center p-2"
                  >
                    Day
                  </th>
                  {times.map((time) => (
                    <th
                      style={{
                        height: "80px",
                        borderRight: "1.5px solid #222",
                      }}
                      key={time.id}
                    >
                      <span
                        className="text-warning font-weight-bold ml-2"
                        style={{ fontSize: "16px" }}
                      >
                        {time.name}
                      </span>
                      <br />
                      <span className="text-muted ml-2">
                        {time.start.substring(5, 0)} -
                      </span>
                      <span className="text-muted ml-2">
                        {time.end.substring(5, 0)}
                      </span>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody
                style={{
                  border: "2px solid #222",
                }}
              >
                {days.map((day) => (
                  <tr key={day.id} style={{ borderBottom: "1.5px solid #222" }}>
                    <td
                      style={{
                        fontSize: "20px",
                        fontWeight: "bold",
                        borderRight: "1.5px solid #222",
                      }}
                      className="text-center p-2"
                    >
                      {day.code}
                    </td>
                    {times.map((time) => {
                      const classData = data.find(
                        (item) => item.day === day.code && item.time === time.id
                      );
                      const teacher = classData ? classData.teacher : "";
                      const subject = classData ? classData.subject : "";
                      const message = classData ? classData.message : "";
                      return (
                        <td
                          style={{
                            fontSize: "15px",
                            fontWeight: "bold",
                            borderRight: "1.5px solid #222",
                          }}
                          className="text-center p-2"
                          key={time.id}
                        >
                          <span className={"text-info"}>{subject}</span>
                          <br />
                          <span
                            className={
                              message === "exists"
                                ? "text-danger"
                                : "text-success"
                            }
                          >
                            {teacher}{" "}
                            {message === "exists" ? (
                              <span style={{ color: "crimson" }}>"Busy"</span>
                            ) : (
                              ""
                            )}
                          </span>
                        </td>
                      );
                    })}
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
        <div className="col-12 p-2">
          <div className="text-right">
            <Button variant="contained" onClick={submitTimetable}>
              Create Time Table
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
