import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import axios from "axios";
import React, { useState, useEffect } from "react";
import toast, { Toaster } from "react-hot-toast";
import { Link } from "react-router-dom";
import { useQuery } from "react-query";
import { Autocomplete, Button, TextField } from "@mui/material";
import { API_URLS } from "../../utils/api.routes";
import {
  toastDangerStyle,
  toastSuccessStyle,
  toastWarnigStyle,
} from "../../components/shared/Custom.Styles";
import DiaryActions from "./DiaryActions";
import { CustomPagination } from "../../components/custom/Custom.DataGrid";

import { Send } from "@mui/icons-material";
import {
  StudentSearchSelectInput,
  TermSearchSelectInput,
} from "../../components/custom/Custom.SearchInput";
import authService from "../../services/auth.service";

export default function Diary() {
  const currentUser = authService.getCurrentUser();

  // input fields change to respective lesson plan fields

  const [student, setStudent] = useState("");
  const [term, setTerm] = useState("");
  const [week, setWeek] = useState("");
  const [date, setDate] = useState("");
  const [task, setTask] = useState("");
  const [teacher_comment, setTeachercomment] = useState("");

  //import file hook
  const [file, setFile] = useState();

  // for pagination
  const [rowId, setRowId] = useState(null);
  const [pageSize, setPageSize] = useState(5);

  const [regStudents, setRegStudents] = useState([]);

  useEffect(() => {
    const fetchStudents = async () => {
      const res = await fetch(API_URLS.students_info);
      const data = await res.json();
      setRegStudents(data);
    };

    fetchStudents();
  }, []);

  let headersList = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };

  // change to respective lesson plan body
  let bodyContent = JSON.stringify({
    student: student,
    term: term,
    week: week,
    date: date,
    task: task,
    teacher_comment: teacher_comment,
  });
  const handleSubmit = async (e) => {
    e.preventDefault();
    await fetch(API_URLS.diary, {
      method: "POST",
      headers: headersList,
      body: bodyContent,
    })
      .then((res) => {
        if (res.status === 201) {
          setStudent("");
          setTerm("");
          setWeek("");
          setDate("");
          setTask("");
          setTeachercomment("");
          refetch();
          toast.success(`Successfully Created `, toastSuccessStyle);
        } else if (res.status === 400) {
          toast.error(`Error !!! ${res.statusText}`, toastDangerStyle);
          setTimeout(() => {
            toast(`Seems Diary Exists`, toastWarnigStyle);
          }, 1000);
        } else {
          toast.error(
            `${res.status} Error !!! ${res.statusText}`,
            toastDangerStyle
          );
        }
      })
      .catch((err) => {
        toast.error(`Error, Not Found !!!`, toastDangerStyle);
      });
  };

  //import
  const handleFileSelection = (e) => {
    const selectedFile = e.target.files[0];
    if (
      selectedFile.type === "text/csv" ||
      selectedFile.type === "application/json" ||
      selectedFile.type === "application/vnd.ms-excel" ||
      selectedFile.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      setFile(selectedFile);
    } else {
      toast("Please select a CSV, JSON or Excel file.", toastWarnigStyle);
    }
  };

  const UploadFile = () => {
    const formData = new FormData();
    formData.append("file", file);

    fetch(API_URLS.upload_diary, {
      method: "POST",
      body: formData,
    })
      .then((res) => {
        if (res.status === 200) {
          refetch();
          setFile(null);
          toast.success("Upload Successfull", toastSuccessStyle);
        }
      })
      .catch((error) => {
        toast.error(`Upload Error ${error}`, toastDangerStyle);
      });
  };

  //

  async function fetchDiary() {
    const { data } = await axios.get(API_URLS.diary);
    return data;
  }
  const { data, error, isError, isLoading, refetch } = useQuery(
    "diary",
    fetchDiary
  );
  if (isLoading) {
    return <div className="main-content">Loading...</div>;
  }
  if (isError) {
    return (
      <div className="main-content">
        <div>Error!! {error.message}</div>
      </div>
    );
  }
  console.log(data);

  const editableColumns = [
    {
      field: "id",
      headerName: "SL",
      headerAlign: "center",
      type: "number",
      width: "80",
    },

    {
      field: "student",
      headerName: "Student",
      headerAlign: "center",
      editable: true,
      width: 120,
    },

    {
      field: "term",
      headerName: "Term",
      headerAlign: "center",
      editable: true,
      width: 120,
    },
    {
      field: "week",
      headerName: "Week",
      headerAlign: "center",
      editable: true,
      width: 120,
    },
    {
      field: "date",
      headerName: "Date",
      headerAlign: "center",
      editable: true,
      width: 120,
    },
    {
      field: "task",
      headerName: "Task",
      headerAlign: "center",
      editable: true,
      width: 120,
    },

    {
      field: "teacher_comment",
      headerName: "Teachercomment",
      headerAlign: "center",
      editable: true,
      width: 120,
    },
    {
      field: "parents_comment",
      headerName: "Parentscomment",
      headerAlign: "center",

      width: 120,
    },

    {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      renderCell: (params) => <DiaryActions {...{ params, rowId, setRowId }} />,
    },
  ];
  const columns = [
    {
      field: "id",
      headerName: "SL",
      headerAlign: "center",
      type: "number",
      width: "80",
    },

    {
      field: "student",
      headerName: "Student",
      headerAlign: "center",
      width: 120,
    },

    {
      field: "term",
      headerName: "Term",
      headerAlign: "center",
      width: 120,
    },
    {
      field: "week",
      headerName: "Week",
      headerAlign: "center",
      width: 120,
    },
    {
      field: "date",
      headerName: "Date",
      headerAlign: "center",
      width: 120,
    },
    {
      field: "task",
      headerName: "Task",
      headerAlign: "center",
      width: 120,
    },

    {
      field: "teacher_comment",
      headerName: "Teachercomment",
      headerAlign: "center",
      width: 120,
    },
    {
      field: "parents_comment",
      headerName: "Parentscomment",
      headerAlign: "center",
      width: 120,
    },

    {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      renderCell: (params) => <DiaryActions {...{ params, rowId, setRowId }} />,
    },
  ];
  return (
    <div className="main-content">
      <div className="row">
        <div className="col-12 col-md-12 col-lg-12">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/admin">Home</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Diary
              </li>
            </ol>
          </nav>
        </div>

        {currentUser.roles.includes("ADD_DIARY") ||
        currentUser.roles.includes("SUPER_ADMIN") ? (
          <div className="col-md-6 col-lg-12 col-xl-4">
            <div className="shadow-sm p-3 mb-5 bg-white rounded">
              <div className="text-center text-dark">
                <h5>Add Diary</h5>
              </div>
              <form onSubmit={handleSubmit}>
                <div className="card-body">
                  <div className="form-group">
                    <label htmlFor="stud">Student</label>
                    <StudentSearchSelectInput
                      onSelectedItemChange={(item) => {
                        setStudent(item.admn_no);
                      }}
                      searchFields={["admn_no", "first_name", "last_names"]}
                    />
                    <p className="text-dark">Student: {student}</p>
                  </div>

                  <div className="form-group">
                    <label htmlFor="sTerm">Term</label>
                    <TermSearchSelectInput
                      onSelectedItemChange={(item) => {
                        setTerm(item.name);
                      }}
                      searchFields={["name"]}
                    />
                    <p className="text-dark">Selected Term: {term}</p>
                  </div>
                  <div className="form-group">
                    <label htmlFor="sWeek">Week</label>
                    <input
                      type="text"
                      id="sWeek"
                      className="form-control"
                      value={week}
                      onChange={(e) => setWeek(e.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="sDate">Date</label>
                    <input
                      type="date"
                      id="sDate"
                      className="form-control"
                      value={date}
                      onChange={(e) => setDate(e.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="sTask">Task</label>
                    <input
                      type="text"
                      id="sTask"
                      className="form-control"
                      value={task}
                      onChange={(e) => setTask(e.target.value)}
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="sTeachercomment">Teacher's Comments</label>
                    <input
                      type="text"
                      id="sTeachercomment"
                      className="form-control"
                      value={teacher_comment}
                      onChange={(e) => setTeachercomment(e.target.value)}
                    />
                  </div>
                </div>
                <div className="text-center">
                  <button className="btn btn-primary mr-1" type="submit">
                    Add New Diary
                  </button>
                  <Toaster position="top-right" reverseOrder={false} />
                </div>
              </form>
            </div>
          </div>
        ) : (
          <></>
        )}

        <div className="col-md-6 col-lg-12 col-xl-8">
          <div className="shadow-sm p-3 mb-5 bg-white rounded">
            <div className="text-center">
              {/* import */}

              {currentUser.roles.includes("UPLOAD_DIARY") ||
              currentUser.roles.includes("SUPER_ADMIN") ? (
                <div className="d-flex justify-content-end">
                  <div className="d-flex m-1">
                    <Button variant="text" component="label">
                      Import
                      <input
                        hidden
                        type={"file"}
                        id={"csvFileInput"}
                        accept=".csv,.json,.xlsx,application/vnd.ms-excel"
                        onChange={handleFileSelection}
                      />
                    </Button>
                    {file && (
                      <div className="text-info align-self-center">
                        File: {file.name}
                      </div>
                    )}
                  </div>
                  <Button
                    size="small"
                    variant="contained"
                    endIcon={<Send />}
                    onClick={UploadFile}
                  >
                    Submit
                  </Button>
                </div>
              ) : (
                <></>
              )}
            </div>
            {/*  */}
            <div style={{ height: 420 }}>
              <DataGrid
                columns={
                  currentUser.roles.includes("UPDATE_DIARY") ||
                  currentUser.roles.includes("SUPER_ADMIN")
                    ? editableColumns
                    : columns
                }
                components={{
                  Toolbar: GridToolbar,
                  Pagination: CustomPagination,
                }}
                rows={data}
                getRowId={(row) => row.id}
                rowsPerPageOptions={[5, 10, 20]}
                rowHeight={45}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                getRowSpacing={(params) => ({
                  top: params.isFirstVisible ? 0 : 5,
                  bottom: params.isLastVisible ? 0 : 5,
                })}
                onCellEditCommit={(params) => setRowId(params.id)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
