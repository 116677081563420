import axios from 'axios';
import React from 'react'
import { useQuery } from 'react-query';
import { Link, useLocation } from 'react-router-dom';
import {API_URLS} from '../../utils/api.routes'

export default function Quizdetails() {
  const { state } = useLocation();
  const fetchQuizDetails = async () => {
    const { data } = await axios.get(API_URLS.quizzes.concat(state, "/"));
    return data;
  };
  const { data, error, isError, isLoading } = useQuery(
    "quizzes",
    fetchQuizDetails
  );
  if (isLoading) {
    return (
      <div className="main-content">
        <div className="row justify-content-center align-items-center">
          <h1>Loading</h1>
        </div>
      </div>
    );
  }
  if (isError) {
    return (
      <div className="main-content">
        <div className="row justify-content-center align-items-center">
          <h4 className="text-danger">Error!!! {error.message}</h4>
        </div>
      </div>
    );
  }
  console.log(data)
  return (
    <div className='main-content'>
      <div className="col-12 col-md-12 col-lg-12">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/admin">Home</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/admin/quizzes/addquiz">Quizzes</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Quiz Details
              </li>
            </ol>
          </nav>
        </div>
      <div className="col-8">
        <div className="card shadow-sm p-3 mb-5 bg-white rounded">
          <h3 className="text-center text-info">Quiz Details</h3>
          <div className='text-dark'>ID: {data.id}</div>
          <div className='text-dark'>Name: {data.name}</div>
          <div className='text-dark'>Class: {data.assignment_class}</div>
          <div className='text-dark'>Subject: {data.subject}</div>
          <div className='text-dark'>Section: {data.section}</div>
          <div className='text-dark'>Start Date: {data.start_date}</div>
          <div className='text-dark'>Submission Date: {data.submission_date}</div>
          <div className='text-dark'>Marks: {data.marks}</div>
          <div className='text-dark'>Comments: {data.comment}</div>
         
        </div>
      </div>
    </div>
  )
}
