export const toastSuccessStyle = {
  style: {
    color: "green",
    fontSize: "1.2em",
  },
  duration: 5000,
};
export const toastWarnigStyle = {
  style: {
    color: "orange",
    fontSize: "1.2em",
  },
  duration: 6000,
};
export const toastDangerStyle = {
  style: {
    color: "crimson",
    fontSize: "1.2em",
  },
  duration: 8000,
};
