import React from "react";
import { DirectionsBusFilledOutlined } from "@mui/icons-material";
import { Link } from "react-router-dom";
import authService from "../../services/auth.service";

export default function Transport() {
  const currentUser = authService.getCurrentUser();

  return (
    <>
      <li className="dropdown">
        <Link to="#" className="menu-toggle nav-link has-dropdown">
          <DirectionsBusFilledOutlined fontSize="medium" className="icon-mr" />
          <span>Transport</span>
        </Link>
        <ul className="dropdown-menu">
          {currentUser.roles.includes("SUPER_ADMIN") ? (
            <>
              <li>
                <Link className="nav-link" to="/admin/transport/vehicle-routes">
                  Routes
                </Link>
              </li>
              <li>
                <Link className="nav-link" to="/admin/transport/vehicles">
                  Vehicle
                </Link>
              </li>
            </>
          ) : (
            <></>
          )}
          {/* <li>
            <Link className="nav-link" to="/transport-assign-vehicle">
              Assign Vehicle
            </Link>
          </li>*/}
          {/* <li>
            <Link className="nav-link" to="/admin/transport/stud-report">
              Student Transport Report
            </Link>
          </li> */}
        </ul>
      </li>
    </>
  );
}
