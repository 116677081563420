import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import axios from "axios";
import React, { useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { Link } from "react-router-dom";
import { useQuery } from "react-query";
import { API_URLS } from "../../utils/api.routes";
import {
  toastDangerStyle,
  toastSuccessStyle,
  toastWarnigStyle,
} from "../../components/shared/Custom.Styles";
import SchemesofworkActions from "./SchemesofworkActions";
import { CustomPagination } from "../../components/custom/Custom.DataGrid";
import { Button } from "@mui/material";
import {
  ClassSearchSelectInput,
  LessonSearchSelectInput,
  TermSearchSelectInput,
  YearSearchSelectInput,
} from "../../components/custom/Custom.SearchInput";
import { Send } from "@mui/icons-material";
import authService from "../../services/auth.service";

export default function Schemesofwork() {

  const currentUser = authService.getCurrentUser();

  // input fields change to respective lesson plan fields
  const [teacher, setTeacher] = useState("");
  const [grade, setGrade] = useState("");
  const [date, setDate] = useState("");
  const [learning_area, setLearningarea] = useState("");
  const [term, setTerm] = useState("");
  const [year, setYear] = useState("");
  const [week, setWeek] = useState("");
  const [lesson, setLesson] = useState("");
  const [strand, setStrand] = useState("");
  const [substrand, setSubstrand] = useState("");
  const [specific_learning_outcome, setSpecificlearningoutcome] = useState("");
  const [key_inquiry_question, setKeyinquiryquestion] = useState("");
  const [learning_experience, setLearningexperience] = useState("");
  const [learning_resource, setLearningresource] = useState("");
  const [assessment_method, setAssessmentmethod] = useState("");
  const [remarks, setRemarks] = useState("");

  //import file hook
  const [file, setFile] = useState();

  // for pegination
  const [rowId, setRowId] = useState(null);
  const [pageSize, setPageSize] = useState(5);

  let headersList = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };

  // change to respective lesson plan body
  let bodyContent = JSON.stringify({
    teacher: teacher,
    grade: grade,
    date: date,
    learning_area: learning_area,
    term: term,
    year: year,
    week: week,
    Lesson: lesson,
    strand: strand,
    substrand: substrand,
    specific_learning_outcome: specific_learning_outcome,
    key_inquiry_question: key_inquiry_question,
    learning_experience: learning_experience,
    learning_resource: learning_resource,
    assessment_method: assessment_method,
    remarks: remarks,
  });
  const handleSubmit = async (e) => {
    e.preventDefault();
    await fetch(API_URLS.schemes_of_work, {
      method: "POST",
      headers: headersList,
      body: bodyContent,
    })
      .then((res) => {
        if (res.status === 201) {
          setTeacher("");
          setGrade("");
          setDate("");
          setLearningarea("");
          setTerm("");
          setYear("");
          setWeek("");
          setLesson("");
          setStrand("");
          setSubstrand("");
          setSpecificlearningoutcome("");
          setKeyinquiryquestion("");
          setLearningexperience("");
          setLearningresource("");
          setAssessmentmethod("");
          setRemarks("");
          refetch();
          toast.success(
            `Successfully Created Schemes of Work`,
            toastSuccessStyle
          );
        } else if (res.status === 400) {
          toast.error(`Error !!! ${res.statusText}`, toastDangerStyle);
          setTimeout(() => {
            toast(`Seems Schemes of Work Already Exists`, toastWarnigStyle);
          }, 1000);
        } else {
          toast.error(
            `${res.status} Error !!! ${res.statusText}`,
            toastDangerStyle
          );
        }
      })
      .catch((err) => {
        toast.error(`Error, Not Found !!!`, toastDangerStyle);
      });
  };

  //import
  const handleFileSelection = (e) => {
    const selectedFile = e.target.files[0];
    if (
      selectedFile.type === "text/csv" ||
      selectedFile.type === "application/json" ||
      selectedFile.type === "application/vnd.ms-excel" ||
      selectedFile.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      setFile(selectedFile);
    } else {
      toast("Please select a CSV, JSON or Excel file.", toastWarnigStyle);
    }
  };

  const UploadFile = () => {
    const formData = new FormData();
    formData.append("file", file);

    fetch(API_URLS.upload_schemes, {
      method: "POST",
      body: formData,
    })
      .then((res) => {
        if (res.status === 200) {
          refetch();
          setFile(null);
          toast.success("Upload Successfull", toastSuccessStyle);
        }
      })
      .catch((error) => {
        toast.error(`Upload Error ${error}`, toastDangerStyle);
      });
  };

  //

  async function fetchSchemesofwork() {
    const { data } = await axios.get(API_URLS.schemes_of_work);
    return data;
  }
  const { data, error, isError, isLoading, refetch } = useQuery(
    "schemes_of_work",
    fetchSchemesofwork
  );
  if (isLoading) {
    return <div className="main-content">Loading...</div>;
  }
  if (isError) {
    return (
      <div className="main-content">
        <div>Error!! {error.message}</div>
      </div>
    );
  }
  console.log(data);

  const editableColumns = [
    {
      field: "id",
      headerName: "SL",
      headerAlign: "center",
      type: "number",
      width: "80",
    },
    {
      field: "teacher",
      headerName: "Teacher",
      headerAlign: "center",
      editable: true,
      width: 120,
    },
    {
      field: "grade",
      headerName: "Grade",
      headerAlign: "center",
      editable: true,
      width: 120,
    },
    {
      field: "date",
      headerName: "Date",
      headerAlign: "center",
      editable: true,
      width: 120,
    },
    {
      field: "learning_area",
      headerName: "Learningarea",
      headerAlign: "center",
      editable: true,
      width: 120,
    },
    {
      field: "term",
      headerName: "Term",
      headerAlign: "center",
      editable: true,
      width: 120,
    },
    {
      field: "year",
      headerName: "Year",
      headerAlign: "center",
      editable: true,
      width: 120,
    },
    {
      field: "week",
      headerName: "Week",
      headerAlign: "center",
      editable: true,
      width: 120,
    },
    {
      field: "lesson",
      headerName: "Lesson",
      headerAlign: "center",
      editable: true,
      width: 120,
    },
    {
      field: "strand",
      headerName: "Strand",
      headerAlign: "center",
      editable: true,
      width: 120,
    },
    {
      field: "substrand",
      headerName: "Substrand",
      headerAlign: "center",
      editable: true,
      width: 120,
    },
    {
      field: "specific_learning_outcome",
      headerName: "Specificlearningoutcome",
      headerAlign: "center",
      editable: true,
      width: 120,
    },
    {
      field: "key_inquiry_question",
      headerName: "Keyinquiryquestion",
      headerAlign: "center",
      editable: true,
      width: 120,
    },
    {
      field: "learning_experience",
      headerName: "Learningexperience",
      headerAlign: "center",
      editable: true,
      width: 120,
    },
    {
      field: "learning_resource",
      headerName: "Learningresource",
      headerAlign: "center",
      editable: true,
      width: 120,
    },
    {
      field: "assessment_method",
      headerName: "Assessmentmethod",
      headerAlign: "center",
      editable: true,
      width: 120,
    },
    {
      field: "remarks",
      headerName: "Remarks",
      headerAlign: "center",
      editable: true,
      width: 120,
    },
    {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      renderCell: (params) => (
        <SchemesofworkActions {...{ params, rowId, setRowId }} />
      ),
    },
  ];
  const columns = [
    {
      field: "id",
      headerName: "SL",
      headerAlign: "center",
      type: "number",
      width: "80",
    },
    {
      field: "teacher",
      headerName: "Teacher",
      headerAlign: "center",
      width: 120,
    },
    {
      field: "grade",
      headerName: "Grade",
      headerAlign: "center",
      width: 120,
    },
    {
      field: "date",
      headerName: "Date",
      headerAlign: "center",
      width: 120,
    },
    {
      field: "learning_area",
      headerName: "Learningarea",
      headerAlign: "center",
      width: 120,
    },
    {
      field: "term",
      headerName: "Term",
      headerAlign: "center",
      width: 120,
    },
    {
      field: "year",
      headerName: "Year",
      headerAlign: "center",
      width: 120,
    },
    {
      field: "week",
      headerName: "Week",
      headerAlign: "center",
      width: 120,
    },
    {
      field: "lesson",
      headerName: "Lesson",
      headerAlign: "center",
      width: 120,
    },
    {
      field: "strand",
      headerName: "Strand",
      headerAlign: "center",
      width: 120,
    },
    {
      field: "substrand",
      headerName: "Substrand",
      headerAlign: "center",
      width: 120,
    },
    {
      field: "specific_learning_outcome",
      headerName: "Specificlearningoutcome",
      headerAlign: "center",
      width: 120,
    },
    {
      field: "key_inquiry_question",
      headerName: "Keyinquiryquestion",
      headerAlign: "center",
      width: 120,
    },
    {
      field: "learning_experience",
      headerName: "Learningexperience",
      headerAlign: "center",
      width: 120,
    },
    {
      field: "learning_resource",
      headerName: "Learningresource",
      headerAlign: "center",
      width: 120,
    },
    {
      field: "assessment_method",
      headerName: "Assessmentmethod",
      headerAlign: "center",
      width: 120,
    },
    {
      field: "remarks",
      headerName: "Remarks",
      headerAlign: "center",
      width: 120,
    },
    {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      renderCell: (params) => (
        <SchemesofworkActions {...{ params, rowId, setRowId }} />
      ),
    },
  ];
  return (
    <div className="main-content">
      <div className="row">
        <div className="col-12 col-md-12 col-lg-12">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/admin">Home</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Schemes of Work
              </li>
            </ol>
          </nav>
        </div>

        {currentUser.roles.includes("ADD_SCHEMES_OF_WORK") ||
        currentUser.roles.includes("SUPER_ADMIN") ? (

        <div className="col-md-6 col-lg-12 col-xl-4">
          <div className="shadow-sm p-3 mb-5 bg-white rounded">
            <div className="text-center text-dark">
              <h5>Add Schemes of Work</h5>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="card-body">
              <div className="form-group">
                  <label htmlFor="sTeacher">Teacher</label>
                  <input
                    type="text"
                    id="sTeacher"
                    className="form-control"
                    value={teacher}
                    onChange={(e) => setTeacher(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="sLessonClass">Class</label>
                  <ClassSearchSelectInput
                    onSelectedItemChange={(item) => {
                      setGrade(item.class_room_code);
                    }}
                    searchFields={["class_room_code","class_room_name"]}
                  />
                  <p className="text-dark">Selected Class: {grade}</p>
                </div>
             
                <div className="form-group">
                  <label htmlFor="sDate">Date</label>
                  <input
                    type="date"
                    id="sDate"
                    className="form-control"
                    value={date}
                    onChange={(e) => setDate(e.target.value)}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="sLearningarea">Learning Area</label>
                  <input
                    type="text"
                    id="sLearningarea"
                    className="form-control"
                    value={learning_area}
                    onChange={(e) => setLearningarea(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="sTerm">Term</label>
                  <TermSearchSelectInput
                    onSelectedItemChange={(item) => {
                      setTerm(item.name);
                    }}
                    searchFields={["name"]}
                  />
                  <p className="text-dark">Selected Term: {term}</p>
                </div>
                <div className="form-group">
                  <label htmlFor="sYear">Year</label>
                  <YearSearchSelectInput
                    onSelectedItemChange={(item) => {
                      setYear(item.id);
                    }}
                    searchFields={["name"]}
                  />
                  <p className="text-dark">Selected Year: {lesson}</p>
                </div>
                <div className="form-group">
                  <label htmlFor="sWeek">Week</label>
                  <input
                    type="text"
                    id="sWeek"
                    className="form-control"
                    value={week}
                    onChange={(e) => setWeek(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="sLesson">Lesson</label>
                  <LessonSearchSelectInput
                    onSelectedItemChange={(item) => {
                      setLesson(item.id);
                    }}
                    searchFields={["name"]}
                  />
                  <p className="text-dark">Selected Lesson: {lesson}</p>
                </div>
                <div className="form-group">
                  <label htmlFor="sStrand">Strand</label>
                  <input
                    type="text"
                    id="sStrand"
                    className="form-control"
                    value={strand}
                    onChange={(e) => setStrand(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="sSubstrand">Substrand</label>
                  <input
                    type="text"
                    id="sSubstrand"
                    className="form-control"
                    value={substrand}
                    onChange={(e) => setSubstrand(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="sSpecificlearningoutcome">
                    Specific Learning Outcome
                  </label>
                  <input
                    type="text"
                    id="sSpecificlearningoutcome"
                    className="form-control"
                    value={specific_learning_outcome}
                    onChange={(e) => setSpecificlearningoutcome(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="sKeyinquiryquestion">
                    Key Inquiry Question
                  </label>
                  <input
                    type="text"
                    id="sKeyinquiryquestion"
                    className="form-control"
                    value={key_inquiry_question}
                    onChange={(e) => setKeyinquiryquestion(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="sLearningexperience">
                    Learning Experience
                  </label>
                  <input
                    type="text"
                    id="sLearningexperience"
                    className="form-control"
                    value={learning_experience}
                    onChange={(e) => setLearningexperience(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="sLearningresource">Learning Resource</label>
                  <input
                    type="text"
                    id="sLearningresource"
                    className="form-control"
                    value={learning_resource}
                    onChange={(e) => setLearningresource(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="sAssessmentmethod">Assessment Method</label>
                  <input
                    type="text"
                    id="sAssessmentmethod"
                    className="form-control"
                    value={assessment_method}
                    onChange={(e) => setAssessmentmethod(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="sRemarks">Remarks</label>
                  <input
                    type="text"
                    id="sRemarks"
                    className="form-control"
                    value={remarks}
                    onChange={(e) => setRemarks(e.target.value)}
                  />
                </div>
              </div>
              <div className="text-center">
                <button className="btn btn-primary mr-1" type="submit">
                  Add New Shemes of Work
                </button>
                <Toaster position="top-right" reverseOrder={false} />
              </div>
            </form>
          </div>
        </div>

):<></>}

        <div className="col-md-6 col-lg-12 col-xl-8">
          <div className="shadow-sm p-3 mb-5 bg-white rounded">
            <div className="text-center">
              <h5 className="text-center text-dark">Schemes of Work List</h5>

              {/* import */}
              {currentUser.roles.includes("UPLOAD_SCHEMES_OF_WORK") ||
            currentUser.roles.includes("SUPER_ADMIN") ? (
              <div className="d-flex justify-content-end">
                <div className="d-flex m-1">
                  <Button variant="text" component="label">
                    Import
                    <input
                      hidden
                      type={"file"}
                      id={"csvFileInput"}
                      accept=".csv,.json,.xlsx,application/vnd.ms-excel"
                      onChange={handleFileSelection}
                    />
                  </Button>
                  {file && (
                    <div className="text-info align-self-center">
                      File: {file.name}
                    </div>
                  )}
                </div>
                <Button
                  size="small"
                  variant="contained"
                  endIcon={<Send />}
                  onClick={UploadFile}
                >
                  Submit
                </Button>
              </div>
              
              ):<></>}

            </div>
            {/*  */}
            <div style={{ height: 420 }}>
              <DataGrid
                columns={currentUser.roles.includes("UPDATE_SCHEMES_OF_WORK") ||
                currentUser.roles.includes("SUPER_ADMIN")
                  ? editableColumns
                  : columns}
                components={{
                  Toolbar: GridToolbar,
                  Pagination: CustomPagination,
                }}
                rows={data}
                getRowId={(row) => row.id}
                rowsPerPageOptions={[5, 10, 20]}
                rowHeight={45}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                getRowSpacing={(params) => ({
                  top: params.isFirstVisible ? 0 : 5,
                  bottom: params.isLastVisible ? 0 : 5,
                })}
                onCellEditCommit={(params) => setRowId(params.id)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
