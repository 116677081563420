import { Autocomplete, TextField } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { API_URLS } from "../../../utils/api.routes";

function StudentClassAllocationEdit() {
  const { state } = useLocation();
  const [student, setStudent] = useState(state.student);
  const [classRoom, setClassRoom] = useState(state.class_room);
  const [year, setYear] = useState(state.academic_year);
  const [years, setYears] = useState([]);
  const [classRooms, setClassRooms] = useState([]);
  const [students, setStudents] = useState([]);

  const fetchStudents = async () => {
    try {
      const response = await axios.get(API_URLS.student_custom);
      if (response.status === 200) {
        setStudents(response.data);
      } else {
        console.log("Error Encounterd while fetching data");
      }
    } catch (error) {
      console.log("Error Caught");
    }
  };
  const fetchAcademicYears = async () => {
    try {
      const response = await axios.get(API_URLS.academic_year);
      if (response.status === 200) {
        setYears(response.data);
      } else {
        console.log("Error Fetching the Data");
      }
    } catch (error) {
      console.log("Error Caught fetching Academic Years");
    }
  };

  const fetchClassRooms = async () => {
    try {
      const response = await axios.get(API_URLS.class_room);
      if (response.status === 200) {
        setClassRooms(response.data);
      } else {
        console.log("Error Fetching ClassRooms");
      }
    } catch (error) {
      console.log("An Error was Caught");
    }
  };
  useEffect(() => {
    fetchAcademicYears();
    fetchClassRooms();
    fetchStudents();
  }, []);

  //   console.log(state);
  //   console.log(student);
  //   console.log(classRoom);
  //   console.log(year);
  return (
    <div className="main-content">
      <div className="row">
        <div className="col-12 col-md-12 col-lg-12">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/admin/administration/dashboard">Home</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/student-class-allocation">Class Allocation</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Edit Class Allocation
              </li>
            </ol>
          </nav>
        </div>
        <div className="col-12 col-md-12 col-lg-12">
          <h4 className="text-danger">
            TO DO Passing of initial passed values to the input element
          </h4>
        </div>
        <div className="col-md-6 col-lg-12 col-xl-4">
          <div className="card shadow-sm p-3 mb-5 bg-body rounded">
            <div className="card-header">
              <h4>Allocate Student to Class </h4>
            </div>
            <div className="card-body">
              <div className="form-group">
                <Autocomplete
                  freeSolo
                  options={students}
                  value={student}
                  getOptionLabel={(option) =>
                    `${option.admn_no} : ${option.first_name} ${option.last_name}`
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Student"
                      variant="outlined"
                      InputProps={{ ...params.InputProps, type: "search" }}
                    />
                  )}
                />
              </div>
              <div className="form-group">
                <Autocomplete
                  freeSolo
                  options={classRooms.map(
                    (cl) =>
                      `${cl.stream_name} : ${cl.class_room_name} ${cl.year_name}`
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Classroom"
                      variant="outlined"
                      InputProps={{ ...params.InputProps, type: "search" }}
                    />
                  )}
                />
              </div>
              <div className="form-group">
                <Autocomplete
                  freeSolo
                  options={years.map((yr) => `${yr.name}`)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Academic Year"
                      variant="outlined"
                      InputProps={{ ...params.InputProps, type: "search" }}
                    />
                  )}
                />
              </div>
            </div>
            <div className="card-footer text-center">
              <button className="btn btn-primary mr-1" type="submit">
                Allocate Student
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StudentClassAllocationEdit;
