import React, { useEffect, useState } from "react";
import StudentAttendance from "../../../components/custom/StudentAttendance";
import { Button } from "@mui/material";
import { API_URLS } from "../../../utils/api.routes";
import { toast } from "react-hot-toast";
import {
  toastDangerStyle,
  toastSuccessStyle,
} from "../../../components/shared/Custom.Styles";

export default function ClassAttendance({ students, class_id }) {
  const [attendanceStatus, setAttendanceStatus] = useState("");
  const [instanceTime, setInstanceTime] = useState("08:00:00");
  const [attendanceInstance, setAttendanceInstance] = useState([]);
  const [attendanceReport, setAttendanceReport] = useState(null);
  const [attendance, setAttendance] = useState(
    students.map((student) => ({
      ...student,
      present: false,
    }))
  );

  useEffect(() => {
    const fetchAttendanceInstances = async () => {
      const res = await fetch(API_URLS.attendance_instance);
      const data = await res.json();
      setAttendanceInstance(data);
    };
    fetchAttendanceInstances();
  }, []);
  useEffect(() => {
    const fetchReportStatus = async () => {
      let admn_no = students[0]["admn_no"];
      let c_date = new Date().toISOString().slice(0, 10);
      const res = await fetch(
        API_URLS.check_attendance_taken.concat(
          `?date=${c_date}&time=${instanceTime}&admn_no=${admn_no}`,
          ""
        )
      );
      const data = await res.json();
      setAttendanceStatus(data.message);
    };
    const fetchClassAttendanceReport = async () => {
      let c_date = new Date().toISOString().slice(0, 10);
      const res = await fetch(
        API_URLS.check_attendance_report.concat(
          `?date=${c_date}&time=${instanceTime}&class_id=${class_id}`
        )
      );
      const data = await res.json();
      setAttendanceReport(data);
    };
    fetchReportStatus();
    fetchClassAttendanceReport();
  }, [instanceTime]);

  const handleAttendanceInstanceChange = (event) => {
    setInstanceTime(event.target.value);
  };

  const handleAttendanceChange = (updatedAttendance) => {
    setAttendance(updatedAttendance);
  };
  const handleSubmit = async () => {
    // Filter the attendance data to include only the IDs of students who are present
    const attendanceReport = attendance.map((student) => ({
      student: student.student,
      reported: student.present,
      time: instanceTime,
      date: new Date().toISOString().slice(0, 10),
    }));

    for (let i = 0; i < attendanceReport.length; i++) {
      await fetch(API_URLS.stud_daily_attendance, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(attendanceReport[i]),
      })
        .then((res) => {
          if (i === attendanceReport.length - 1) {
            if (res.status === 201) {
              toast.success("Successfully Marked Register", toastSuccessStyle);
              // setInstanceTime("08:00:00");
              setAttendanceStatus("Taken");
            }
          }
        })
        .catch((err) => {
          toast.error("Failed to mark Register", toastDangerStyle);
        });
    }
  };
  return (
    <div>
      <div className="col-12 d-flex flex-wrap justify-content-between">
        <div className="form-group col-4">
          <select
            className="form-control"
            value={instanceTime}
            onChange={handleAttendanceInstanceChange}
          >
            <option value="" disabled>
              Choose Time
            </option>
            {attendanceInstance.map((instance, index) => {
              return (
                <option key={index} value={instance.time}>
                  {instance.time}
                </option>
              );
            })}
          </select>
        </div>
        <div className="col-8">
          <h6 className="text-muted text-right" style={{ fontSize: "20px" }}>
            At {instanceTime} :{" "}
            {attendanceStatus === "Not Taken" ? (
              <span className="text-danger font-weight-bold">
                Attendance Register Not Marked
              </span>
            ) : (
              <span className="text-success font-weight-bold">
                Attendance Register Marked
              </span>
            )}
          </h6>
        </div>
      </div>

      {attendanceStatus === "Taken" ? (
        <></>
      ) : (
        <>
          <StudentAttendance
            students={students}
            onAttendanceChange={handleAttendanceChange}
          />
          <div className="text-right m-2">
            <Button variant="contained" onClick={handleSubmit}>
              Submit Attendance
            </Button>
          </div>
        </>
      )}
      {attendanceReport && (
        <div className="col-12">
          <h5 className="text-muted">
            Register Report{" "}
            {attendanceReport.status === "All Present" ? (
              <span className="text-success">Everyone Present</span>
            ) : (
              <span className="text-warning">{attendanceReport.status}</span>
            )}
          </h5>
          <h6 className="text-dark">
            <span style={{ fontSize: "22px" }}>
              {attendanceReport.present_count}
            </span>{" "}
            Students Present
          </h6>
          <h6 className="text-dark">
            <span style={{ fontSize: "22px" }}>
              {attendanceReport.absent_count}
            </span>{" "}
            Students Absent{" "}
            {attendanceReport.absent_count > 0 ? (
              <span className="text-info">
                {attendanceReport.attendance.absent}
              </span>
            ) : (
              <></>
            )}
          </h6>
        </div>
      )}
    </div>
  );
}
