import axios from "axios";
import React, { useState } from "react";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { Toaster, toast } from "react-hot-toast";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import VehicleRoutesActions from "./VehicleRoutesActions";
import {
  toastDangerStyle,
  toastWarnigStyle,
} from "../../../components/shared/Custom.Styles";
import { API_URLS } from "../../../utils/api.routes";
import { CustomPagination } from "../../../components/custom/Custom.DataGrid";

export default function VehicleRoutes() {
  const [title, setTitle] = useState("");
  const [fare, setFare] = useState("");

  const [rowId, setRowId] = useState(null);
  const [pageSize, setPageSize] = useState(5);

  let headersList = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };

  let bodyContent = JSON.stringify({
    title: title,
    fare: fare,
  });
  const handleSubmit = async (e) => {
    e.preventDefault();
    await fetch(API_URLS.routes, {
      method: "POST",
      body: bodyContent,
      headers: headersList,
    })
      .then((res) => {
        if (res.status === 201) {
          setTitle("");
          setFare("");
          refetch();
          toast.success("Successfully Created Route");
          refetch();
        } else if (res.status === 400) {
          toast.error(`Error !!! ${res.statusText}`, toastDangerStyle);
          setTimeout(() => {
            toast(`Seems Route Already Exists`, toastWarnigStyle);
          }, 1000);
        } else {
          toast.error(
            `${res.status} Error !!! ${res.statusText}`,
            toastDangerStyle
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  async function fetchRoutes() {
    const { data } = await axios.get(API_URLS.routes);
    return data;
  }
  const { data, error, isError, isLoading, refetch } = useQuery(
    "vehicle_routes",
    fetchRoutes
  );
  if (isLoading) {
    return <div className="main-content">Loading...</div>;
  }
  if (isError) {
    return (
      <div className="main-content">
        <div>Error!! {error.message}</div>
      </div>
    );
  }
  const columns = [
    {
      field: "id",
      headerName: "SL",
      headerAlign: "center",
      type: "number",
      width: "80",
    },
    {
      field: "title",
      headerName: "Route Name",
      headerAlign: "center",
      editable: true,
      width: 250,
    },
    {
      field: "fare",
      headerName: "Fare Amount",
      headerAlign: "center",
      editable: true,
      width: 120,
    },
    {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      width: 150,
      renderCell: (params) => (
        <VehicleRoutesActions {...{ params, rowId, setRowId, refetch }} />
      ),
    },
  ];
  return (
    <div className="main-content">
      <div className="row">
        <div className="col-12 col-md-12 col-lg-12">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/admin">Home</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Vehicle Routes
              </li>
            </ol>
          </nav>
        </div>
        <div className="col-md-6 col-lg-12 col-xl-4">
          <div className="shadow-sm p-3 mb-5 bg-white rounded">
            <div className="text-center">
              <h5 className="text-dark">Add Vehicle Route</h5>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="card-body">
                <div className="form-group">
                  <label htmlFor="rTitle">Route Title</label>
                  <input
                    type="text"
                    id="rTitle"
                    className="form-control"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="rFare">Route Fare</label>
                  <input
                    type="text"
                    id="rFare"
                    className="form-control"
                    value={fare}
                    onChange={(e) => setFare(e.target.value)}
                  />
                </div>
              </div>
              <div className="text-center">
                <button className="btn btn-primary mr-1" type="submit">
                  Add Route
                </button>
                <Toaster position="top-right" reverseOrder={false} />
              </div>
            </form>
          </div>
        </div>
        <div className="col-md-6 col-lg-12 col-xl-8">
          <div className="shadow-sm p-3 mb-5 bg-white rounded">
            <h5 className="text-center text-dark">Vehicle Route List</h5>
            <div style={{ height: 420 }}>
              <DataGrid
                columns={columns}
                components={{
                  Toolbar: GridToolbar,
                  Pagination: CustomPagination,
                }}
                rows={data}
                getRowId={(row) => row.id}
                rowHeight={45}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                getRowSpacing={(params) => ({
                  top: params.isFirstVisible ? 0 : 5,
                  bottom: params.isLastVisible ? 0 : 5,
                })}
                onCellEditCommit={(params) => setRowId(params.id)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
