import React, { useEffect, useState } from "react";
import { FeedOutlined } from "@mui/icons-material";
import { Link } from "react-router-dom";
import authService from "../../services/auth.service";

export default function Academics() {
  const currentUser = authService.getCurrentUser();
  return (
    <>
      {/* {(currentUser.roles.includes("STUDENT") ||
        currentUser.roles.includes("TEACHER") ||
        currentUser.roles.includes("SUPER_ADMIN")) && ( */}

      <li className="dropdown">
        <Link to="#" className="menu-toggle nav-link has-dropdown">
          <FeedOutlined fontSize="medium" className="icon-mr" />
          <span>Academics</span>
        </Link>
        <ul className="dropdown-menu">
          {currentUser.roles.includes("ADMIN") ||
          currentUser.roles.includes("SUPER_ADMIN") ? (
            <li>
              <Link className="nav-link" to="/admin/school/list">
                School
              </Link>
            </li>
          ) : (
            <></>
          )}
          {currentUser.roles.includes("VIEW_ACADEMIC_YEAR") ||
          currentUser.roles.includes("SUPER_ADMIN") ? (
            <li>
              <Link className="nav-link" to="/admin/academic-year/list">
                Academic Years
              </Link>
            </li>
          ) : (
            <></>
          )}
          {currentUser.roles.includes("VIEW_ACADEMIC_TERM") ||
          currentUser.roles.includes("SUPER_ADMIN") ? (
            <li>
              <Link className="nav-link" to="/admin/academic-term/list">
                Academic Terms
              </Link>
            </li>
          ) : (
            <></>
          )}
          {currentUser.roles.includes("VIEW_GRADE") ||
          currentUser.roles.includes("SUPER_ADMIN") ? (
            <li>
              <Link className="nav-link" to="/admin/grade/list">
                Grades
              </Link>
            </li>
          ) : (
            <></>
          )}
          {currentUser.roles.includes("VIEW_STREAM") ||
          currentUser.roles.includes("SUPER_ADMIN") ? (
            <li>
              <Link className="nav-link" to="/admin/stream/list/">
                Streams
              </Link>
            </li>
          ) : (
            <></>
          )}
          {currentUser.roles.includes("VIEW_CLASS_ROOM") ||
          currentUser.roles.includes("SUPER_ADMIN") ? (
            <li>
              <Link className="nav-link" to="/admin/classroom/list">
                Classrooms
              </Link>
            </li>
          ) : (
            <></>
          )}
          {currentUser.roles.includes("VIEW_STUDENT_CLASS_ALLOCATION") ||
          currentUser.roles.includes("SUPER_ADMIN") ? (
            <li>
              <Link
                className="nav-link"
                to="/admin/classroom/student-allocation"
              >
                Student Class Allocation
              </Link>
            </li>
          ) : (
            <></>
          )}
          {currentUser.roles.includes("SUPER_ADMIN") ? (
            <li>
              <Link className="nav-link" to="/admin/attendance/instance">
                Attendance Instance
              </Link>
            </li>
          ) : (
            <></>
          )}
          {currentUser.roles.includes("VIEW_SUBJECT") ||
          currentUser.roles.includes("SUPER_ADMIN") ? (
            <li>
              <Link className="nav-link" to="/admin/subjects/list">
                Subjects
              </Link>
            </li>
          ) : (
            <></>
          )}
          {/* {currentUser.roles.includes("VIEW_ASSIGN_SUBJECT") ||
          currentUser.roles.includes("SUPER_ADMIN") ? (
            <li>
              <Link className="nav-link" to="/admin/subject/per-grade">
                Assign Subject
              </Link>
            </li>
          ) : (
            <></>
          )} */}
          {currentUser.roles.includes("VIEW_ASSIGN_SUBJECT_TEACHER") ||
          currentUser.roles.includes("SUPER_ADMIN") ? (
            <li>
              <Link className="nav-link" to="/admin/subject/teacher">
                Assign Subject Teacher
              </Link>
            </li>
          ) : (
            <></>
          )}
        </ul>
      </li>
      {/* )} */}
    </>
  );
}
