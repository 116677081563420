import { AspectRatioOutlined, MenuOpenOutlined } from "@mui/icons-material";
import React from "react";
import { Link } from "react-router-dom";
import authService from "../../services/auth.service";
import { Dialog, DialogContent, Paper, Slide } from "@mui/material";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AdminNavbar(props) {
  const currentUser = authService.getCurrentUser();
  const [open, setOpen] = React.useState(false);

  console.log(currentUser);
  const logout = () => {
    authService.logout();
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div>
      <div className="navbar-bg"></div>
      <nav className="navbar navbar-expand-lg main-navbar sticky">
        <div className="form-inline mr-auto">
          <ul className="navbar-nav mr-3">
            <li>
              <Link
                to="#"
                data-toggle="sidebar"
                className="nav-link nav-link-lg collapse-btn"
              >
                <MenuOpenOutlined fontSize="medium" className="icon-mr" />
              </Link>
            </li>
            <li>
              <Link to="#" className="nav-link nav-link-lg fullscreen-btn">
                <AspectRatioOutlined fontSize="medium" className="icon-mr" />
              </Link>
            </li>
            <li>
              <form className="form-inline mr-auto">
                <div className="search-element">
                  <input
                    className="form-control"
                    type="search"
                    placeholder="Search"
                    aria-label="Search"
                    data-width="200"
                  />
                  <button className="btn" type="submit">
                    <i className="fas fa-search"></i>
                  </button>
                </div>
              </form>
            </li>
            <li>
              <div className="text-center ml-5">
                <Link to="/">
                  <h6 className="text-white">Home Page</h6>
                </Link>
              </div>
            </li>
          </ul>
        </div>
        <ul className="navbar-nav navbar-right">
          <li>
            <Link
              className="nav-link"
              onClick={() => {
                setOpen(true);
              }}
            >
              Initial Setup
            </Link>
          </li>
          <li>
            <Link to="/" className="nav-link" onClick={logout}>
              Logout
            </Link>
          </li>
          {/* <li>
            <Link to="/admin/datatable/filters" className="nav-link">
              DataFilters
            </Link>
          </li>
          <li>
            <Link to="/admin/datatable/full" className="nav-link">
              Full GRUD
            </Link>
          </li>
          <li>
            <Link to="/admin/academic-year/generic-list" className="nav-link">
              Academic Year
            </Link>
          </li>
          <li>
            <Link to="/admin/stepper/form" className="nav-link">
              Stepper
            </Link>
          </li> */}
          <li className="dropdown show">
            <a
              href="#"
              data-toggle="dropdown"
              className="nav-link dropdown-toggle nav-link-lg nav-link-user"
              aria-expanded="true"
            >
              {" "}
              <img
                alt="image"
                src="/assets/img/user.png"
                className="user-img-radious-style"
              />{" "}
              <span className="d-sm-none d-lg-inline-block"></span>
            </a>
            <div className="dropdown-menu dropdown-menu-right pullDown">
              <div className="dropdown-title">Hello {currentUser.username}</div>
              {currentUser.roles.includes("STUDENT") && (
                <Link
                  to="/admin/user/profile"
                  className="dropdown-item has-icon"
                >
                  {" "}
                  <i
                    className="far
										fa-user"
                  ></i>{" "}
                  Profile
                </Link>
              )}
              {/* <a href="timeline.html" className="dropdown-item has-icon">
                {" "}
                <i className="fas fa-bolt"></i>
                Activities
              </a>{" "}
              <a href="#" className="dropdown-item has-icon">
                {" "}
                <i className="fas fa-cog"></i>
                Settings
              </a> */}
              <div className="dropdown-divider"></div>
              <Link
                to="/"
                className="dropdown-item has-icon text-danger"
                onClick={logout}
              >
                {" "}
                <i className="fas fa-sign-out-alt"></i>
                Logout
              </Link>
            </div>
          </li>
          <Dialog
            PaperProps={{
              style: {
                backgroundColor: "transparent",
                width: 300,
                height: 400,
                boxShadow: "none",
                position: "absolute",
                top: 24,
                right: 8,
                zIndex: 999,
              },
            }}
            open={open}
            onClose={handleClose}
          >
            <Paper
              variant="outlined"
              style={{
                backgroundColor: "rgba(42, 48, 66, 0.8)",
                padding: 8,
              }}
            >
              <span
                className="text-center font-weight-bold text-white"
                style={{ fontSize: 18 }}
              >
                Onboarding Process
              </span>
              <DialogContent style={{ padding: "4px" }}>
                {/* General */}
                <span className="text-white">
                  Welcome! To get the most out of this system, follow the steps
                  provided below.
                </span>
                <div>
                  <span className="text-left font-weight-bold text-info mt-1">
                    School
                  </span>
                  <ol>
                    <li style={{ lineHeight: "20px" }}>
                      <Link to="/admin/page/school-info">
                        Manage School Settings
                      </Link>
                    </li>
                    <li style={{ lineHeight: "20px" }}>
                      <Link to="/admin/page/settings">
                        Manage School Values and Motto etc
                      </Link>
                    </li>
                    <li style={{ lineHeight: "20px" }}>
                      <Link to="/admin/student-category/list">
                        Manage Student Category
                      </Link>
                    </li>
                  </ol>
                  {/* Academics */}
                  <span className="text-left font-weight-bold text-info mt-1">
                    Academics
                  </span>
                  <ol>
                    <li style={{ lineHeight: "20px" }}>
                      <Link to="/admin/academic-year/list">
                        Manage Academic Year
                      </Link>
                    </li>
                    <li style={{ lineHeight: "20px" }}>
                      <Link to="/admin/academic-term/list">
                        Manage Academic Term
                      </Link>
                    </li>
                    <li style={{ lineHeight: "20px" }}>
                      <Link to="/admin/grade/list">Manage Grades</Link>
                    </li>
                    <li style={{ lineHeight: "20px" }}>
                      <Link to="/admin/stream/list">Manage Streams</Link>
                    </li>
                    <li style={{ lineHeight: "20px" }}>
                      <Link to="/admin/classroom/add">Manage Classrooms</Link>
                    </li>
                  </ol>
                  {/* Students */}
                  <span className="text-left font-weight-bold text-info mt-1">
                    Student
                  </span>
                  <ol>
                    <li style={{ lineHeight: "20px" }}>
                      <Link to="/admin/students/new-student-1">
                        Register New Student
                      </Link>
                    </li>
                    <li style={{ lineHeight: "20px" }}>
                      <Link to="/admin/students/list">
                        Student Class Allocation
                      </Link>
                    </li>
                    <li style={{ lineHeight: "20px" }}>
                      <Link to="/admin/students/list">Students List</Link>
                    </li>
                  </ol>
                  {/* Subjects */}
                  <span className="text-left font-weight-bold text-info mt-1">
                    Subject
                  </span>
                  <ol>
                    <li style={{ lineHeight: "20px" }}>
                      <Link to="/admin/subjects/list">
                        Manage Taught Subjects
                      </Link>
                    </li>
                    <li style={{ lineHeight: "20px" }}>
                      <Link to="/admin/subject/per-grade">
                        Assign Subject to Grade
                      </Link>
                    </li>
                    <li style={{ lineHeight: "20px" }}>
                      <Link to="/admin/subject/teacher">
                        Assign Subject to Teacher
                      </Link>
                    </li>
                  </ol>
                </div>
              </DialogContent>
              {/* <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={handleClose}>Subscribe</Button>
              </DialogActions> */}
            </Paper>
          </Dialog>
        </ul>
      </nav>
    </div>
  );
}
