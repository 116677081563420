import React, { useState } from "react";
import { Link } from "react-router-dom";
import { API_URLS } from "../../utils/api.routes";
import authService from "../../services/auth.service";

export default function PublicNavbar() {
  const currentUser = authService.getCurrentUser();
  // const [logos, setLogos] = useState({});
  const [school, setSchool] = useState({});

  useState(() => {
    // const fetchLogos = async () => {
    //   const res = await fetch(API_URLS.school_logos.concat(1, "/"));
    //   const data = await res.json();
    //   setLogos(data);
    // };
    const fetchSchoolInfo = async () => {
      const res = await fetch(API_URLS.school);
      const data = await res.json();
      setSchool(data[0]);
    };
    fetchSchoolInfo();
    // fetchLogos();
  }, []);
  return (
    <>
      <nav
        className="navbar-public navbar-expand-lg sticky main-navbar"
        style={{ backgroundColor: "#353c48" }}
      >
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav mr-auto d-flex align-items-center">
            <li className="nav-item">
              <div style={{ marginLeft: 10, height: 60 }}>
                <img
                  style={{ height: "inherit" }}
                  src={school["logo"]}
                  alt={"Logo image"}
                />
              </div>
            </li>
            <li className="nav-item active">
              <Link className="nav-link" to="/">
                Home
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/about-us">
                About Us
              </Link>
            </li>
            {!currentUser ? (
              <li className="nav-item">
                <Link className="nav-link" to="account/login">
                  Login
                </Link>
              </li>
            ) : (
              <></>
            )}
            {!currentUser ? (
              <li className="nav-item">
                <Link className="nav-link" to="account/register">
                  Register Account
                </Link>
              </li>
            ) : (
              <></>
            )}
            <li className="nav-item">
              <a className="nav-link" href="http://44.211.90.56:8069/web/login">
                Accounts
              </a>
            </li>

            {currentUser ? (
              <li className="nav-item">
                <a className="nav-link" href="/admin">
                  Admin Dashboard
                </a>
              </li>
            ) : (
              <></>
            )}
          </ul>
          <form className="form-inline my-2 my-lg-0">
            <input
              className="form-control mr-sm-2"
              type="search"
              placeholder="Search"
              aria-label="Search"
            />
            <button
              className="btn btn-outline-success my-2 my-sm-0"
              type="submit"
            >
              Search
            </button>
          </form>
        </div>
      </nav>
    </>
  );
}
