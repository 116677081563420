import React from "react";
import { NightShelterOutlined } from "@mui/icons-material";
import { Link } from "react-router-dom";

export default function Dormitory() {
  return (
    <>
      <li className="dropdown">
        <Link to="#" className="menu-toggle nav-link has-dropdown">
          <NightShelterOutlined fontSize="medium" className="icon-mr" />
          <span>Dormitory</span>
        </Link>
        <ul className="dropdown-menu">
          {/* <li>
            <Link className="nav-link" to="/admin/dormitory/-room-type">
              Room Type
            </Link>
          </li> */}
          <li>
            <Link className="nav-link" to="/admin/dormitory/dorms">
              Dormitory
            </Link>
          </li>
          <li>
            <Link className="nav-link" to="/admin/dormitory/rooms">
              Dormitory Rooms
            </Link>
          </li>
          <li>
            <Link className="nav-link" to="/admin/dormitory/bed">
              Bed
            </Link>
          </li>
          {/* <li>
            <Link className="nav-link" to="/student-dormitory-report">
              Student Dormitory Report
            </Link>
          </li> */}
        </ul>
      </li>
    </>
  );
}
