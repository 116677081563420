import axios from "axios";
import authHeader from "./auth-header";

const API_URL = "http://34.203.201.215:8080/api/v1/";
// const API_URL = "http://localhost:8080/api/v1/";

class UserService {
  getPublicContent() {
    return axios.get(API_URL + "all");
  }

  getStudentBoard() {
    return axios.get(API_URL + "student", { headers: authHeader() });
  }

  getParentBoard() {
    return axios.get(API_URL + "parent", { headers: authHeader() });
  }

  getTeacherBoard() {
    return axios.get(API_URL + "teacher", { headers: authHeader() });
  }

  getAccountantBoard() {
    return axios.get(API_URL + "accountant", { headers: authHeader() });
  }

  getAdminBoard() {
    return axios.get(API_URL + "admin", { headers: authHeader() });
  }
}

export default new UserService();
