import React from "react";
import { AccountBalanceOutlined } from "@mui/icons-material";
import { Link } from "react-router-dom";

export default function Accounts() {
  return (
    <>
      <li className="dropdown">
        <Link to="#" className="menu-toggle nav-link has-dropdown">
          <AccountBalanceOutlined fontSize="medium" className="icon-mr" />
          <span>Accounts</span>
        </Link>
        <ul className="dropdown-menu">
          <li>
            <Link className="nav-link" to="/admin/account/Income">
              Income/Payments
            </Link>
          </li>
          <li>
            <Link className="nav-link" to="/admin/account/profitloss">
              Profit & Loss
            </Link>
          </li>
          <li>
            <Link className="nav-link" to="/admin/account/Expense">
              Expense
            </Link>
          </li>
          <li>
            <Link className="nav-link" to="/admin/account/Transfers">
              Payroll report
            </Link>
          </li>
        </ul>
      </li>
    </>
  );
}
