export const CustomIsError = (props) => {
  return (
    <>
      <div className="main-content">
        <div className="row">
          <h6 className="text-danger">Error!! {props.message}</h6>
        </div>
      </div>
    </>
  );
};

export const CustomIsLoading = () => {
  return (
    <>
      <div className="main-content">
        <div className="row">
          <h6 className="text-info">Loading...</h6>
        </div>
      </div>
    </>
  );
};
