import { MailOutlineOutlined } from "@mui/icons-material";
import React, { useState } from "react";
import { toast, Toaster } from "react-hot-toast";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  toastDangerStyle,
  toastSuccessStyle,
  toastWarnigStyle,
} from "../../../components/shared/Custom.Styles";
import { API_URLS } from "../../../utils/api.routes";

function EditStudent() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const data = state.data;

  const initialValues = {
    admnNo: data.admn_no,
    firstName: data.first_name,
    lastName: data.last_name,
    telNo: data.tel_no === null ? "" : data.tel_no,
    email: data.e_mail === null ? "" : data.e_mail,
    startGrade: data.start_grade === null ? "" : data.start_grade,
    currentGrade: data.current_grade === null ? "" : data.current_grade,
    dob: data.dob === null ? "" : data.dob,
    birthCertNo: data.birth_cert_no === null ? "" : data.birth_cert_no,
    passport: "",
    // Other Details
    currAddress: data.current_address === null ? "" : data.current_address,
    permAddress: data.permanent_address === null ? "" : data.permanent_address,
    height: data.height === null ? 0 : data.height,
    weight: data.weight === null ? 0 : data.weight,
    prevSchool: data.prev_school === null ? "" : data.prevSchool,
    dorm: data.dorm_name === null ? "" : data.dorm_name,
    roomNo: data.room_no === null ? "" : data.room_no,
    notes: data.additional_notes === null ? "" : data.additional_notes,
  };
  const [gender, setGender] = useState(data.gender);
  const [studCategory, setStudCategory] = useState(data.category);
  const [religion, setReligion] = useState(data.religion);
  const [bloodGroup, setBloodGroup] = useState(data.blood_group);
  const [message, setMessage] = useState("");
  const [values, setValues] = useState(initialValues);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };
  const headersList = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };
  const bodyContent = JSON.stringify({
    id: data.id,
    admn_no: values.admnNo,
    first_name: values.firstName,
    last_name: values.lastName,
    gender: gender,
    dob: values.dob,
    category: studCategory,
    blood_group: bloodGroup,
    religion: religion,
    e_mail: values.email,
    tel_no: values.telNo,
    start_grade: values.startGrade,
    current_grade: values.currentGrade,
    height: values.height,
    weight: values.weight,
    current_address: values.currAddress,
    permanent_address: values.permAddress,
    dorm_name: values.dorm,
    room_no: values.roomNo,
    birth_cert_no: values.birthCertNo,
    prev_school: values.prevSchool,
    additional_notes: values.notes,
  });
  const handleSubmit = async (e) => {
    e.preventDefault();
    await fetch(API_URLS.students_info.concat(data.id, "/"), {
      method: "PUT",
      headers: headersList,
      body: bodyContent,
    })
      .then((res) => {
        console.log(message);
        if (res.status === 200) {
          toast.success(`Successfully Updated Student`, toastSuccessStyle);
          setTimeout(() => {
            navigate("/admin/student/details", {
              state: data.id,
            });
          }, 2000);
        } else if (res.status === 400) {
          toast.error(
            `${res.status} Error !!! ${res.statusText}`,
            toastDangerStyle
          );
          setTimeout(() => {
            toast(`Seems Admission Number Already Taken`, toastWarnigStyle);
          }, 1000);
        } else {
          toast.error(
            `${res.status} Error !!! ${res.statusText}`,
            toastDangerStyle
          );
        }
      })
      .catch((err) => {
        toast.error(`Error !!! Not Found}`, toastDangerStyle);
      });
  };
  console.log(state.data.id);
  return (
    <div className="main-content">
      <div className="section-body">
        <div className="col-12 col-md-12 col-lg-12">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/admin">Home</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Edit Student
              </li>
            </ol>
          </nav>
        </div>
        <form className="row" onSubmit={handleSubmit}>
          <div className="col-12 col-md-6 col-lg-6">
            <div className="card shadow-sm p-3 mb-5 bg-body rounded">
              <div className="card-header">
                <h4>Students Personal Info</h4>
              </div>
              <div className="card-body">
                <div className="form-group">
                  <label>Admission Number </label>
                  <input
                    type="text"
                    className="form-control"
                    onChange={handleInputChange}
                    value={values.admnNo}
                    name="admnNo"
                  />
                </div>
                <div className="form-group">
                  <label>First Name</label>
                  <input
                    type="text"
                    className="form-control"
                    onChange={handleInputChange}
                    value={values.firstName}
                    name="firstName"
                  />
                </div>
                <div className="form-group">
                  <label>Last Name</label>
                  <input
                    type="text"
                    className="form-control"
                    onChange={handleInputChange}
                    value={values.lastName}
                    name="lastName"
                  />
                </div>
                <div className="form-group">
                  <label>Select Gender</label>
                  <select
                    className="form-control"
                    onChange={(e) => setGender(e.target.value)}
                    value={gender}
                  >
                    <option value="" disabled>
                      Select Gender
                    </option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                    <option value="Other">Other</option>
                  </select>
                </div>
                <div className="form-group">
                  <label>Phone Number</label>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <div className="input-group-text">
                        <i className="fas fa-phone"></i>
                      </div>
                    </div>
                    <input
                      type="text"
                      className="form-control phone-number"
                      onChange={handleInputChange}
                      value={values.telNo}
                      name="telNo"
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label>Email Address</label>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <div className="input-group-text">
                        <i>
                          <MailOutlineOutlined />
                        </i>
                      </div>
                    </div>
                    <input
                      type="text"
                      className="form-control phone-number"
                      onChange={handleInputChange}
                      value={values.email}
                      name="email"
                    />
                  </div>
                </div>
                {/* NEED ACTION */}
                <div className="form-group">
                  <label>Student Category</label>
                  <select
                    className="form-control"
                    onChange={(e) => setStudCategory(e.target.value)}
                    value={studCategory}
                  >
                    <option value="" disabled>
                      Choose Student Category
                    </option>
                    <option value="KD">Kindergarten</option>
                    <option value="JR">Junior Primary</option>
                    <option value="SR">Senior Primary</option>
                    {/* TO IMPLEMENT WHEN CREATED THE TABLE CATEGORY
                   {data.map((category, index) => {
                    return (
                      <option key={index} value={category.id}>
                        {category.name}
                      </option>
                    );
                  })} */}
                  </select>
                </div>
                <div className="form-group">
                  <label>Start Grade</label>
                  <input
                    type="text"
                    className="form-control"
                    onChange={handleInputChange}
                    value={values.startGrade}
                    name="startGrade"
                  />
                </div>
                <div className="form-group">
                  <label>Current Grade</label>
                  <input
                    type="text"
                    className="form-control"
                    onChange={handleInputChange}
                    value={values.currentGrade}
                    name="currentGrade"
                  />
                </div>
                <div className="form-group">
                  <label>Date Of Birth</label>
                  <input
                    type="text"
                    className="form-control datepicker"
                    onChange={handleInputChange}
                    value={values.dob}
                    name="dob"
                  />
                </div>
                <div className="form-group">
                  <label>Birth Certificate NO:</label>
                  <input
                    type="text"
                    className="form-control"
                    onChange={handleInputChange}
                    value={values.birthCertNo}
                    name="birthCertNo"
                  />
                </div>
                <div className="form-group">
                  <label>Passport:</label>
                  <input
                    type="text"
                    className="form-control"
                    onChange={handleInputChange}
                    value={values.passport}
                    name="passport"
                  />
                </div>
              </div>
            </div>
          </div>
          {/* Other Details Section */}
          <div className="col-12 col-md-6 col-lg-6">
            <div className="card shadow-sm p-3 mb-5 bg-body rounded">
              <div className="card-header">
                <h4>Student Other Details</h4>
              </div>
              <div className="card-body">
                <div className="form-group">
                  <label>Current Address</label>
                  <input
                    type="text"
                    className="form-control"
                    onChange={handleInputChange}
                    value={values.currAddress}
                    name="currAddress"
                  />
                </div>
                <div className="form-group">
                  <label>Parmanent Address</label>
                  <input
                    type="text"
                    className="form-control"
                    onChange={handleInputChange}
                    value={values.permAddress}
                    name="permAddress"
                  />
                </div>
                <div className="form-group">
                  <label>National ID</label>
                  <input
                    type="text"
                    className="form-control"
                    onChange={handleInputChange}
                    value={values.nationalID}
                    name="nationalID"
                  />
                </div>
                <div className="form-group">
                  <label>Religion</label>
                  <select
                    className="form-control"
                    onChange={(e) => setReligion(e.target.value)}
                    value={religion}
                  >
                    <option value="" disabled>
                      Select Religion
                    </option>
                    <option value="christian">Christian</option>
                    <option value="muslim">Muslim</option>
                    <option value="hindu">Hindu</option>
                    <option value="prefer not to say">Prefer not to say</option>
                  </select>
                </div>
                <div className="form-group">
                  <label>Height</label>
                  <input
                    type="text"
                    className="form-control"
                    onChange={handleInputChange}
                    value={values.height}
                    name="height"
                  />
                </div>
                <div className="form-group">
                  <label>Weight</label>
                  <input
                    type="text"
                    className="form-control"
                    onChange={handleInputChange}
                    value={values.weight}
                    name="weight"
                  />
                </div>
              </div>
              <div className="card-header">
                <h4>Other Information</h4>
              </div>
              <div className="card-body">
                <div className="form-group">
                  <label>Blood Group</label>
                  <select
                    className="form-control"
                    onChange={(e) => setBloodGroup(e.target.value)}
                    value={bloodGroup}
                  >
                    <option value="" disabled>
                      Choose Blood Group
                    </option>
                    <option value="A+">A+</option>
                    <option value="A-">A-</option>
                    <option value="AB">AB</option>
                    <option value="B+">B+</option>
                    <option value="B-">B-</option>
                    <option value="O+">O+</option>
                    <option value="O-">O-</option>
                  </select>
                </div>
                <div className="form-group">
                  <label>Previous School</label>
                  <input
                    type="text"
                    className="form-control"
                    onChange={handleInputChange}
                    value={values.prevSchool}
                    name="prevSchool"
                  />
                </div>
                <div className="form-group">
                  <label>Dormitory Allocation</label>
                  <input
                    type="text"
                    className="form-control"
                    onChange={handleInputChange}
                    value={values.dorm}
                    name="dorm"
                  />
                </div>
                <div className="form-group">
                  <label>Room Number</label>
                  <input
                    type="text"
                    className="form-control"
                    onChange={handleInputChange}
                    value={values.roomNo}
                    name="roomNo"
                  />
                </div>
                <div className="form-group">
                  <label>Other Notes</label>
                  <input
                    type="text"
                    className="form-control"
                    onChange={handleInputChange}
                    value={values.notes}
                    name="notes"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 d-flex justify-content-end">
            <div className="card shadow-sm p-3 mb-5 bg-body rounded">
              <button className="btn btn-primary" type="submit">
                Update Student Info
              </button>
            </div>
            <Toaster position="top-right" reverseOrder={false} />
          </div>
        </form>
      </div>
    </div>
  );
}

export default EditStudent;
