import React, { useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  toastDangerStyle,
  toastSuccessStyle,
  toastWarnigStyle,
} from "../../../components/shared/Custom.Styles";
import { API_URLS } from "../../../utils/api.routes";

function StreamEdit() {
  const { state } = useLocation();
  const navigate = useNavigate();

  const [name, setName] = useState(state.stream_name);
  const [code, setCode] = useState(state.stream_code);
  const [message, setMessage] = useState("");

  const headersList = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };

  const bodyContent = JSON.stringify({
    id: state.stream_id,
    stream_code: code,
    stream_name: name,
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    await fetch(API_URLS.class_stream.concat(state.stream_id, "/"), {
      method: "PUT",
      body: bodyContent,
      headers: headersList,
    })
      .then((res) => {
        if (res.status === 200) {
          toast.success(`Class Stream Update Successful`, toastSuccessStyle);
          setTimeout(() => {
            navigate("/streams");
          }, 2000);
        } else if (res.status === 400) {
          toast.error(`Error !!! ${res.statusText}`, toastDangerStyle);
          setTimeout(() => {
            toast(`Seems Stream Already Exists`, toastWarnigStyle);
          }, 1000);
        } else {
          toast.error(
            `${res.status} Error !!! ${res.statusText}`,
            toastDangerStyle
          );
        }
      })
      .catch((err) => {
        toast.error(`Error !!! Not Found}`, toastDangerStyle);
      });
  };
  return (
    <div className="main-content">
      <div className="row">
        <div class="col-12 col-md-12 col-lg-12">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <Link to="/admin">Home</Link>
              </li>
              <li class="breadcrumb-item">
                <Link to="/admin/stream/list">Streams</Link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                Edit Stream
              </li>
            </ol>
          </nav>
        </div>
        <div className="col-md-6 col-lg-12 col-xl-4">
          <div className="card shadow-sm p-3 mb-5 bg-body rounded">
            <div className="card-header">
              <h4>Class Stream Edit Page</h4>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="card-body">
                <div className="form-group">
                  <label>Stream Code</label>
                  <input
                    type="text"
                    className="form-control"
                    value={code}
                    onChange={(e) => setCode(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label>Stream Name</label>
                  <input
                    type="text"
                    className="form-control"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
              </div>
              <div className="card-footer text-center">
                <button className="btn btn-primary mr-1" type="submit">
                  Save Changes
                </button>
                <Toaster position="top-right" reverseOrder={false} />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StreamEdit;
