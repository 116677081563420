import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import axios from "axios";
import React, { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { Link } from "react-router-dom";
import { useQuery } from "react-query";
import { API_URLS } from "../../utils/api.routes";
import {
  toastDangerStyle,
  toastSuccessStyle,
  toastWarnigStyle,
} from "../../components/shared/Custom.Styles";
import AssessmentdetailsActions from "./AssessmentdetailsActions";
import { CustomPagination } from "../../components/custom/Custom.DataGrid";
import { Autocomplete, Button, TextField } from "@mui/material";
import { Send, UploadFile } from "@mui/icons-material";
import {
  LessonSearchSelectInput,
  StrandSearchSelectInput,
  StudentSearchSelectInput,
  SubjectSearchSelectInput,
  SubstrandSearchSelectInput,
  TopicSearchSelectInput,
} from "../../components/custom/Custom.SearchInput";
import authService from "../../services/auth.service";

export default function Assessmentdetails() {
  const currentUser = authService.getCurrentUser();

  // input fields change to respective lesson plan fields

  const [date, setDate] = useState("");
  const [subject, setSubject] = useState("");
  const [lesson, setLesson] = useState("");
  const [topic, setTopic] = useState("");
  const [strand, setStrand] = useState("");
  const [substrand, setSubstrand] = useState("");

  //import file hook
  const [file, setFile] = useState();

  // for pagination
  const [rowId, setRowId] = useState(null);
  const [pageSize, setPageSize] = useState(5);

  let headersList = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };

  // change to respective lesson plan body
  let bodyContent = JSON.stringify({
    date: date,
    subject: subject,
    lesson: lesson,
    topic: topic,
    strand: strand,
    substrand: substrand,
  });
  const handleSubmit = async (e) => {
    e.preventDefault();
    await fetch(API_URLS.assessmentdetails, {
      method: "POST",
      headers: headersList,
      body: bodyContent,
    })
      .then((res) => {
        if (res.status === 201) {
          setDate("");
          setSubject("");
          setLesson("");
          setTopic("");
          setStrand("");
          setSubstrand("");

          refetch();
          toast.success(
            `Successfully Created Assessment Detail`,
            toastSuccessStyle
          );
        } else if (res.status === 400) {
          toast.error(`Error !!! ${res.statusText}`, toastDangerStyle);
          setTimeout(() => {
            toast(`Seems Assessment Detail Already Exists`, toastWarnigStyle);
          }, 1000);
        } else {
          toast.error(
            `${res.status} Error !!! ${res.statusText}`,
            toastDangerStyle
          );
        }
      })
      .catch((err) => {
        toast.error(`Error, Not Found !!!`, toastDangerStyle);
      });
  };

  //import
  const handleFileSelection = (e) => {
    const selectedFile = e.target.files[0];
    if (
      selectedFile.type === "text/csv" ||
      selectedFile.type === "application/json" ||
      selectedFile.type === "application/vnd.ms-excel" ||
      selectedFile.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      setFile(selectedFile);
    } else {
      toast("Please select a CSV, JSON or Excel file.", toastWarnigStyle);
    }
  };

  const UploadFile = () => {
    const formData = new FormData();
    formData.append("file", file);

    fetch(API_URLS.upload_rubrics, {
      method: "POST",
      body: formData,
    })
      .then((res) => {
        if (res.status === 200) {
          refetch();
          setFile(null);
          toast.success("Upload Successfull", toastSuccessStyle);
        }
      })
      .catch((error) => {
        toast.error(`Upload Error ${error}`, toastDangerStyle);
      });
  };

  //

  async function fetchAssessmentdetails() {
    const { data } = await axios.get(API_URLS.assessmentdetails);
    return data;
  }
  const { data, error, isError, isLoading, refetch } = useQuery(
    "assessment_details",
    fetchAssessmentdetails
  );
  if (isLoading) {
    return <div className="main-content">Loading...</div>;
  }
  if (isError) {
    return (
      <div className="main-content">
        <div>Error!! {error.message}</div>
      </div>
    );
  }

  const editableColumns = [
    {
      field: "id",
      headerName: "SL",
      headerAlign: "center",
      type: "number",
      width: "80",
    },

    {
      field: "date",
      headerName: "Date",
      headerAlign: "center",
      editable: true,
      width: 120,
    },
    {
      field: "subject",
      headerName: "Subject",
      headerAlign: "center",
      editable: true,
      width: 120,
    },
    {
      field: "lesson",
      headerName: "Lesson",
      headerAlign: "center",
      editable: true,
      width: 120,
    },
    {
      field: "topic",
      headerName: "Topic",
      headerAlign: "center",
      editable: true,
      width: 120,
    },
    {
      field: "strand",
      headerName: "Strand",
      headerAlign: "center",
      editable: true,
      width: 120,
    },
    {
      field: "substrand",
      headerName: "Substrand",
      headerAlign: "center",
      editable: true,
      width: 120,
    },

    {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      renderCell: (params) => (
        <AssessmentdetailsActions {...{ params, rowId, setRowId }} />
      ),
    },
  ];
  const columns = [
    {
      field: "id",
      headerName: "SL",
      headerAlign: "center",
      type: "number",
      width: "80",
    },

    {
      field: "date",
      headerName: "Date",
      headerAlign: "center",
      width: 120,
    },
    {
      field: "subject",
      headerName: "Subject",
      headerAlign: "center",
      width: 120,
    },
    {
      field: "lesson",
      headerName: "Lesson",
      headerAlign: "center",
      width: 120,
    },
    {
      field: "topic",
      headerName: "Topic",
      headerAlign: "center",
      width: 120,
    },
    {
      field: "strand",
      headerName: "Strand",
      headerAlign: "center",
      width: 120,
    },
    {
      field: "substrand",
      headerName: "Substrand",
      headerAlign: "center",
      width: 120,
    },

    {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      renderCell: (params) => (
        <AssessmentdetailsActions {...{ params, rowId, setRowId }} />
      ),
    },
  ];

  // const { values, handleChange } = props;

  return (
    <div className="main-content">
      <div className="row">
        <div className="col-12 col-md-12 col-lg-12">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/admin">Home</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Assessment Details
              </li>
            </ol>
          </nav>
        </div>

        {currentUser.roles.includes("ADD_ASSESSMENT_DETAILS") ||
        currentUser.roles.includes("SUPER_ADMIN") ? (
          <div className="col-md-6 col-lg-12 col-xl-4">
            <div className="shadow-sm p-3 mb-5 bg-white rounded">
              <div className="text-center text-dark">
                <h5>Add Assessment Detail</h5>
              </div>
              <form onSubmit={handleSubmit}>
                <div className="card-body">
                  {/* <div className="form-group">
                  <label htmlFor="sStudent">Student</label>
                  <StudentSearchSelectInput
                    onSelectedItemChange={(item) => {
                      setStudent(item.admn_no);
                    }}
                    searchFields={["admn_no"]}
                  />
                  <p className="text-dark">Selected Student: {student}</p>
                </div> */}
                  <div className="form-group">
                    <label htmlFor="sDate">Date</label>
                    <input
                      type="date"
                      id="sDate"
                      className="form-control"
                      value={date}
                      onChange={(e) => setDate(e.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="sSubject">Subject</label>
                    <SubjectSearchSelectInput
                      onSelectedItemChange={(item) => {
                        setSubject(item.id);
                      }}
                      searchFields={["subject_name", "subject_code"]}
                    />
                    <p className="text-dark"> Selected Subject: {subject}</p>
                  </div>
                  <div className="form-group">
                    <label htmlFor="sLesson">Lesson</label>
                    <LessonSearchSelectInput
                      onSelectedItemChange={(item) => {
                        setLesson(item.id);
                      }}
                      searchFields={["name"]}
                    />
                    <p className="text-dark">Selected Lesson: {lesson}</p>
                  </div>
                  <div className="form-group">
                    <label htmlFor="sTopic">Topic</label>
                    <TopicSearchSelectInput
                      onSelectedItemChange={(item) => {
                        setTopic(item.id);
                      }}
                      searchFields={["name"]}
                    />
                    <p className="text-dark">Selected Topic: {topic}</p>
                  </div>

                  <div className="form-group">
                    <label htmlFor="sStrand">Strand</label>
                    <StrandSearchSelectInput
                      onSelectedItemChange={(item) => {
                        setStrand(item.strand);
                      }}
                      searchFields={["strand"]}
                    />
                    <p className="text-dark">Selected Strand: {strand}</p>
                  </div>
                  <div className="form-group">
                    <label htmlFor="sSubstrand">Substrand</label>
                    <SubstrandSearchSelectInput
                      onSelectedItemChange={(item) => {
                        setSubstrand(item.substrand);
                      }}
                      searchFields={["substrand"]}
                    />
                    <p className="text-dark">Selected Substrand: {substrand}</p>
                  </div>
                </div>
                <div className="text-center">
                  <button className="btn btn-primary mr-1" type="submit">
                    Add Assessment Detail
                  </button>
                  <Toaster position="top-right" reverseOrder={false} />
                </div>
              </form>
            </div>
          </div>
        ) : (
          <></>
        )}

        <div className="col-md-6 col-lg-12 col-xl-8">
          <div className="shadow-sm p-3 mb-5 bg-white rounded">
            <div className="text-center">
              <h5 className="text-center text-dark">Assessment Details List</h5>

              {/* import */}

              {currentUser.roles.includes("UPLOAD_ASSESSMENT_DETAILS") ||
              currentUser.roles.includes("SUPER_ADMIN") ? (
                <div className="d-flex justify-content-end">
                  <div className="d-flex m-1">
                    <Button variant="text" component="label">
                      Import
                      <input
                        hidden
                        type={"file"}
                        id={"csvFileInput"}
                        accept=".csv,.json,.xlsx,application/vnd.ms-excel"
                        onChange={handleFileSelection}
                      />
                    </Button>
                    {file && (
                      <div className="text-info align-self-center">
                        File: {file.name}
                      </div>
                    )}
                  </div>
                  <Button
                    size="small"
                    variant="contained"
                    endIcon={<Send />}
                    onClick={UploadFile}
                  >
                    Submit
                  </Button>
                </div>
              ) : (
                <></>
              )}
            </div>
            {/*  */}
            <div style={{ height: 420 }}>
              <DataGrid
                columns={
                  currentUser.roles.includes("UPDATE_ASSESSMENT_DETAILS") ||
                  currentUser.roles.includes("SUPER_ADMIN")
                    ? editableColumns
                    : columns
                }
                components={{
                  Toolbar: GridToolbar,
                  Pagination: CustomPagination,
                }}
                rows={data}
                getRowId={(row) => row.id}
                rowsPerPageOptions={[5, 10, 20]}
                rowHeight={45}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                getRowSpacing={(params) => ({
                  top: params.isFirstVisible ? 0 : 5,
                  bottom: params.isLastVisible ? 0 : 5,
                })}
                onCellEditCommit={(params) => setRowId(params.id)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
