import React from "react";

export default function Success(props) {
  const { firstName, lastName, email, occupation, city, bio } = props.values;

  const Back = (e) => {
    e.preventDefault();
    props.prevStep();
  };
  return (
    <div>
      <div>
        <h1 className="text-dark text-center">Thank You For Your Submission</h1>
        <p>You will get an email with further instructions.</p>
      </div>
    </div>
  );
}
