import axios from "axios";
import { API_URLS } from "../utils/api.routes";
// import * as https from "https-browserify";
// const https = require("https");

// const API_URL = "http://localhost:8080/api/auth/";

// const instance = axios.create({
//   httpsAgent: new https.Agent({
//     rejectUnauthorized: false,
//   }),
// });
// const https = require("https");
// const httpsAgent = new https.request({ rejectUnauthorized: false });

// instance.get('https://api.example.com/data')
//   .then(response => {
//     // handle success
//   })
//   .catch(error => {
//     // handle error
//   });

class AuthService {
  login(username, password) {
    return axios
      .post(API_URLS.signin, {
        username,
        password,
      })
      .then((response) => {
        if (response.data.accessToken) {
          localStorage.setItem("user", JSON.stringify(response.data));
        }

        return response.data;
      });
  }

  logout() {
    localStorage.removeItem("user");
  }

  register(username, email, password, category) {
    console.log(username, email, password, category);
    return axios.post(API_URLS.register, {
      username,
      email,
      password,
      category,
    });
  }

  getCurrentUser() {
    return JSON.parse(localStorage.getItem("user"));
  }

  getRoles() {
    return axios.get(API_URLS.fetchRoles).then((res) => {
      return res.data;
    });
  }
}

export default new AuthService();
