import { TextField } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { API_URLS } from "../../utils/api.routes";
import { Toaster, toast } from "react-hot-toast";
import {
  toastDangerStyle,
  toastSuccessStyle,
  toastWarnigStyle,
} from "../../components/shared/Custom.Styles";

export default function PageSettings() {
  const [motto, setMotto] = useState("");
  const [mission, setMission] = useState("");
  const [introMessage, setIntroMessage] = useState("");

  const [messages, setMessages] = useState([]);

  useEffect(() => {
    const fetchSchoolMessages = async () => {
      const res = await fetch(API_URLS.school_messages);
      const data = await res.json();
      setMotto(data[0]["motto"]);
      setMission(data[0]["mission"]);
      setIntroMessage(data[0]["intro_message"]);
      setMessages(data);
    };
    fetchSchoolMessages();
  }, []);

  let headersList = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await fetch(API_URLS.school_messages.concat(messages[0]["id"], "/"), {
      method: "PUT",
      headers: headersList,
      body: JSON.stringify({
        motto: motto,
        mission: mission,
        intro_message: introMessage,
      }),
    })
      .then((res) => {
        if (res.status === 200) {
          toast.success(`Successfully Updated`, toastSuccessStyle);
        } else {
          toast.error(
            `${res.status} Error !!! ${res.statusText}`,
            toastDangerStyle
          );
        }
      })
      .catch((err) => {
        toast.error(`Error, Not Found !!!`, toastDangerStyle);
      });
  };
  return (
    <div className="main-content">
      <div className="row">
        <div className="col-12 col-md-12 col-lg-12">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/admin">Home</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Page Settings
              </li>
            </ol>
          </nav>
        </div>
        <div className="col-12 col-md-12 col-lg-12">
          <form onSubmit={handleSubmit}>
            <div className="col-8 pb-5">
              <TextField
                id="outlined-textarea"
                label="School Motto"
                placeholder="School Motto/Slogan"
                value={motto}
                onChange={(e) => setMotto(e.target.value)}
              />
            </div>
            <div className="col-8 pb-5">
              <TextField
                id="outlined-textarea"
                label="School Mission"
                placeholder="School Mission"
                multiline
                value={mission}
                onChange={(e) => setMission(e.target.value)}
              />
            </div>
            <div className="col-8 pb-5">
              <TextField
                id="outlined-textarea"
                label="Intro Message"
                placeholder="Introduction Message"
                multiline
                value={introMessage}
                onChange={(e) => setIntroMessage(e.target.value)}
              />
            </div>
            <div className="col-8 pb-5">
              <div className="text-center">
                <button className="btn btn-primary mr-1" type="submit">
                  Update
                </button>
                <Toaster position="top-right" reverseOrder={false} />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
