import { MailOutlineOutlined } from "@mui/icons-material";
import { Button } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

export default function FormPersonalDetails(props) {
  const navigate = useNavigate();

  const Continue = (e) => {
    e.preventDefault();
    props.nextStep();
  };

  const Abort = (e) => {
    e.preventDefault();
    navigate("/admin");
  };
  const { values, handleChange } = props;

  return (
    <div>
      <h5 className="text-center text-dark">Personal Information</h5>
      <div className="col-12 col-md-12 col-lg-12">
        <div className="d-flex flex-wrap">
          <div className="form-group col-4">
            <label>
              First Name <span className="text-danger">*</span>
            </label>
            <input
              type="text"
              className="form-control "
              onChange={handleChange("first_name")}
              value={values.first_name}
            />
          </div>
          <div className="form-group col-4">
            <label>
              Middle Name<span className="text-danger">*</span>
            </label>
            <input
              type="text"
              className="form-control"
              onChange={handleChange("last_name")}
              value={values.last_name}
            />
          </div>
          <div className="form-group col-4">
            <label>Other Name</label>
            <input
              type="text"
              className="form-control"
              onChange={handleChange("other_name")}
              value={values.other_name}
            />
          </div>
          <div className="form-group col-4">
            <label>
              Select Gender<span className="text-danger">*</span>
            </label>
            <select
              className="form-control"
              onChange={handleChange("gender")}
              value={values.gender}
            >
              <option value="" disabled>
                Select Gender
              </option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
              <option value="Other">Other</option>
            </select>
          </div>
          <div className="form-group col-4">
            <label>Phone Number</label>
            <div className="input-group">
              <div className="input-group-prepend">
                <div className="input-group-text">
                  <i className="fas fa-phone"></i>
                </div>
              </div>
              <input
                type="text"
                className="form-control phone-number"
                onChange={handleChange("tel_no")}
                value={values.tel_no}
              />
            </div>
          </div>
          <div className="form-group col-4">
            <label>
              Email Address<span className="text-danger">*</span>
            </label>
            <div className="input-group">
              <div className="input-group-prepend">
                <div className="input-group-text">
                  <i>
                    <MailOutlineOutlined />
                  </i>
                </div>
              </div>
              <input
                type="text"
                className="form-control phone-number"
                onChange={handleChange("e_mail")}
              />
            </div>
          </div>
          <div className="form-group col-4">
            <label>
              Date Of Birth<span className="text-danger">*</span>
            </label>
            <input
              type="date"
              className="form-control datepicker"
              onChange={handleChange("dob")}
              value={values.dob}
            />
          </div>
          <div className="form-group col-4">
            <label>
              Birth Certificate Number<span className="text-danger">*</span>
            </label>
            <input
              type="text"
              className="form-control"
              onChange={handleChange("birth_cert_no")}
              value={values.birth_cert_no}
            />
          </div>
          <div className="form-group col-4">
            <label>National ID Number</label>
            <input
              type="text"
              className="form-control"
              onChange={handleChange("national_id")}
              value={values.national_id}
            />
          </div>
          <div className="form-group col-4">
            <label>Blood Group</label>
            <select
              className="form-control"
              onChange={handleChange("blood_group")}
              value={values.blood_group}
            >
              <option value="" disabled>
                Choose Blood Group
              </option>
              <option value="A+">A+</option>
              <option value="A-">A-</option>
              <option value="AB">AB</option>
              <option value="B+">B+</option>
              <option value="B-">B-</option>
              <option value="O+">O+</option>
              <option value="O-">O-</option>
            </select>
          </div>
          <div className="form-group col-4">
            <label>Special Medical Condition</label>
            <input
              type="text"
              className="form-control"
              onChange={handleChange("special_med")}
              value={values.special_med}
            />
          </div>
          <div className="form-group col-4">
            <label>
              Person Wiith Disability<span className="text-danger">*</span>
            </label>
            <select
              className="form-control"
              onChange={handleChange("person_disability")}
              value={values.person_disability}
            >
              <option value="" disabled>
                Person With Disability?
              </option>
              <option value={false}>No</option>
              <option value={true}>Yes</option>
            </select>
          </div>
        </div>
        <div className="col-12 d-flex justify-content-between">
          <Button color="secondary" variant="contained" onClick={Abort}>
            Abort
          </Button>
          <Button color="primary" variant="contained" onClick={Continue}>
            Next
          </Button>
        </div>
      </div>
    </div>
  );
}
