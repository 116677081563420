import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, Navigate } from "react-router-dom";
import { CustomPagination } from "../../../components/custom/Custom.DataGrid";
import { API_URLS } from "../../../utils/api.routes";
import img1 from "./u7.jpg";
import StudentsListActions from "./StudentsListActions";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import authService from "../../../services/auth.service";

export default function StudentsList() {
  const currentUser = authService.getCurrentUser();

  const [students, setStudents] = useState([]);

  const [rowId, setRowId] = useState(null);
  const [pageSize, setPageSize] = useState(100);

  useEffect(() => {
    axios
      .get(API_URLS.students_info)
      .then((res) => {
        setStudents(res.data);
      })
      .catch((error) => {
        console.log("Error");
      });
  }, []);

  const editableColumns = [
    {
      field: "id",
      headerName: "SL",
      type: "number",
      hide: true,
      with: 100,
    },
    {
      field: "admn_no",
      headerName: "Admission Number",
      headerAlign: "center",
      width: 150,
      editable: true,
    },
    {
      field: "first_name",
      headerName: "First Name",
      headerAlign: "center",
      width: 150,
      editable: true,
    },
    {
      field: "last_name",
      headerName: "Last Name",
      headerAlign: "center",
      width: 150,
      editable: true,
    },
    {
      field: "gender",
      headerName: "Gender",
      headerAlign: "center",
      width: 100,
      editable: true,
    },
    {
      field: "current_grade",
      headerName: "Current Grade",
      headerAlign: "center",
      width: 100,
      editable: true,
    },
    {
      field: "start_grade",
      headerName: "Start Grade",
      headerAlign: "center",
      width: 100,
      hide: true,
    },

    {
      field: "category",
      headerName: "Category",
      headerAlign: "center",
      width: 100,
      hide: true,
      editable: true,
    },
    {
      field: "person_disability",
      headerName: "Disability",
      headerAlign: "center",
      width: 100,
      type: "boolean",
      editable: true,
    },
    {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      renderCell: (params) => (
        <StudentsListActions {...{ params, rowId, setRowId }} />
      ),
    },
  ];
  const columns = [
    {
      field: "id",
      headerName: "SL",
      type: "number",
      hide: true,
      with: 100,
    },
    {
      field: "admn_no",
      headerName: "Admission Number",
      headerAlign: "center",
      width: 150,
      editable: true,
    },
    {
      field: "first_name",
      headerName: "First Name",
      headerAlign: "center",
      width: 150,
      editable: true,
    },
    {
      field: "last_name",
      headerName: "Last Name",
      headerAlign: "center",
      width: 150,
    },
    {
      field: "gender",
      headerName: "Gender",
      headerAlign: "center",
      width: 100,
    },
    {
      field: "current_grade",
      headerName: "Current Grade",
      headerAlign: "center",
      width: 100,
    },
    {
      field: "start_grade",
      headerName: "Start Grade",
      headerAlign: "center",
      width: 100,
      hide: true,
    },

    {
      field: "category",
      headerName: "Category",
      headerAlign: "center",
      width: 100,
      hide: true,
      editable: true,
    },
    {
      field: "person_disability",
      headerName: "Disability",
      headerAlign: "center",
      width: 100,
      type: "boolean",
    },
  ];

  if (
    !currentUser.roles.includes("VIEW_STUDENT") &&
    !currentUser.roles.includes("SUPER_ADMIN")
  ) {
    return <Navigate to="/admin" replace={true} />;
  }

  return (
    <div className="main-content">
      <div className="row">
        <div className="col-12 col-md-12 col-lg-12">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/admin">Home</Link>
              </li>
              <li className="breadcrumb-item">Student List</li>
            </ol>
          </nav>
        </div>
        <div className="col-12 col-md-12 col-lg-12">
          <div className="shadow-sm p-3 mb-5 bg-white rounded">
            <h5 className="text-center text-dark">Registered Students List</h5>
            <div style={{ height: 500 }}>
              <DataGrid
                columns={
                  currentUser.roles.includes("SUPER_ADMIN") ||
                  currentUser.roles.includes("VIEW_STUDENT")
                    ? editableColumns
                    : columns
                }
                rows={students}
                components={{
                  Toolbar: GridToolbar,
                  Pagination: CustomPagination,
                }}
                getRowId={(row) => row.id}
                rowsPerPageOptions={[5, 10, 20]}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                getRowSpacing={(params) => ({
                  top: params.isFirstVisible ? 0 : 5,
                  bottom: params.isLastVisible ? 0 : 5,
                })}
                onCellEditCommit={(params) => setRowId(params.id)}
              />
            </div>
          </div>
        </div>
        <div className="col-12 col-md-12 col-lg-12">
          <div className="col-lg-4 col-xl-3 col-xxl-3">
            <div className="card mt-7">
              <div className="card-body text-center">
                <div className="image mb-3 d-inline-flex mt-n8">
                  <img
                    src={img1}
                    className="img-fluid rounded-circle d-inline-block"
                    alt="Avatar Image"
                  />
                </div>
                <span
                  className="card-title font-weight-bold text-info"
                  style={{ fontSize: "1.2em" }}
                >
                  Mwanafunzi Msomi
                </span>
                <br />
                <span
                  className="text-muted font-size-sm font-weight-bold"
                  style={{ fonstSize: "1.2em" }}
                >
                  Kindergarten
                </span>
                <span className="d-flex justify-content-between align-items-center flex-wrap">
                  <span className="text-muted font-size-sm  font-weight-bold float-left">
                    Gender
                  </span>
                  <span className="text-muted font-size-sm font-weight-bold float-right">
                    Male
                  </span>
                </span>
                <span className="d-flex justify-content-between align-items-center flex-wrap">
                  <span className="text-muted font-size-sm  font-weight-bold float-left">
                    Fee Status
                  </span>
                  <span className="text-success font-size-sm font-weight-bold float-right">
                    Cleared
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
