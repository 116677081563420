import { Button } from "@mui/material";
import React from "react";

export default function AccountForm(props) {
  const Continue = (e) => {
    e.preventDefault();
    props.nextStep();
  };
  const Back = (e) => {
    e.preventDefault();
    props.prevStep();
  };
  const { values, handleChange } = props;
  return (
    <>
      <div>
        <h5 className="text-center text-dark">Account Information</h5>
      </div>
      <div className="col-12 col-md-12 col-lg-12">
        <div className="col-12 d-flex flex-wrap">
          <div className="form-group col-4">
            <label>Bank Account Name</label>
            <input
              type="text"
              className="form-control"
              onChange={handleChange("bankaccount_name")}
              value={values.bankaccount_name}
            />
          </div>
          <div className="form-group col-4">
            <label>Bank Account Number</label>
            <input
              type="text"
              className="form-control"
              onChange={handleChange("account_number")}
              value={values.account_number}
            />
          </div>
          <div className="form-group col-4">
            <label>Bank Name</label>
            <input
              type="text"
              className="form-control"
              onChange={handleChange("bank_name")}
              value={values.bank_name}
            />
          </div>
          <div className="form-group col-4">
            <label>Branch</label>
            <input
              type="text"
              className="form-control"
              onChange={handleChange("branch_name")}
              value={values.branch_name}
            />
          </div>
          <div className="form-group col-4">
            <label>Contract Type</label>
            <select
              className="form-control"
              onChange={handleChange("contract_type")}
              value={values.contract_type}
            >
              <option value="Internship">Internship</option>
              <option value="Attachment">Attachment</option>
              <option value="Permanent">Permanent</option>
            </select>
          </div>
          <div className="form-group col-4">
            <label>Contract Type</label>
            <select
              className="form-control"
              onChange={handleChange("contract_length")}
              value={values.contract_length}
            >
              <option value="3 Months">3 Months</option>
              <option value="6 Months">6 Months</option>
              <option value="1 Year">1 Year</option>
              <option value="2 Years">2 Year</option>
              <option value="Permanent">Permanent</option>
            </select>
          </div>
          <div className="form-group col-4">
            <label>Basic Salary</label>
            <input
              type="text"
              className="form-control"
              onChange={handleChange("basic_salary")}
              value={values.basic_salary}
            />
          </div>
        </div>
        <div className="col-12 d-flex justify-content-between">
          <Button color="primary" variant="contained" onClick={Back}>
            Back
          </Button>
          <Button color="primary" variant="contained" onClick={Continue}>
            Next
          </Button>
        </div>
      </div>
    </>
  );
}
