import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { API_URLS } from "../../../utils/api.routes";
import "./selectInput.css";
import {
  toastDangerStyle,
  toastSuccessStyle,
} from "../../../components/shared/Custom.Styles";
import axios from "axios";
import { Toaster, toast } from "react-hot-toast";
import ViewTimetable from "./ViewTimetable";

export default function NewTimeTable() {
  const [academicTerm, setAcademicTerm] = useState({});
  const [classRooms, setClassRooms] = useState([]);
  const [teachers, setTeachers] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [timeplan, setTimeplan] = useState([]);
  const days = ["MON", "TUE", "WED", "THUR", "FRI"];
  const [timetableData, setTimetableData] = useState({});
  const [tClassRoom, setTClassRoom] = useState("");

  const [timeTable, setTimeTable] = useState([]);
  const [previewTT, setPreviewTT] = useState(false);
  const [previewData, setPreviewData] = useState([]);

  useEffect(() => {
    const fetchClassrooms = async () => {
      const res = await fetch(API_URLS.class_room);
      const data = await res.json();
      setClassRooms(data);
    };
    const fetchTimePlan = async () => {
      const res = await fetch(API_URLS.timeplan);
      const data = await res.json();
      setTimeplan(data);
    };
    const fetchCurrentAcademicTerm = async () => {
      const res = await fetch(API_URLS.current_academic_term);
      const data = await res.json();
      setAcademicTerm(data);
    };
    const fetchSubjects = async () => {
      const res = await fetch(API_URLS.subjects);
      const data = await res.json();
      setSubjects(data);
    };
    const fetchTeachers = async () => {
      const res = await fetch(API_URLS.hr_teachers_odoo);
      const data = await res.json();
      setTeachers(data);
    };

    fetchClassrooms();
    fetchTimePlan();
    fetchCurrentAcademicTerm();
    fetchSubjects();
    fetchTeachers();
  }, []);

  useEffect(() => {
    const fetchClassTimeTable = async () => {
      const res = await fetch(
        `${API_URLS.class_timetable}?grade=${tClassRoom}`
      );
      const data = await res.json();
      setTimeTable(data);
    };
    fetchClassTimeTable();
  }, [tClassRoom]);

  // useEffect(() => {
  //   if (previewData.length > 0) {
  //     setPreviewTT(true);
  //   }
  // }, [previewData]);

  const handleSelectChange = (e, day, timeid, field) => {
    const { value } = e.target;
    setTimetableData((prevData) => ({
      ...prevData,
      [day]: {
        ...prevData[day],
        [timeid]: {
          ...prevData[day]?.[timeid],
          [field]: value,
        },
      },
    }));
  };

  const handleSaveClick = () => {
    const data = [];
    Object.entries(timetableData).forEach(([day, dayData]) => {
      Object.entries(dayData).forEach(([timeid, cellData]) => {
        data.push({
          day: day,
          time: parseInt(timeid),
          subject: cellData.subject,
          teacher: cellData.teacher,
          grade: tClassRoom,
          term_id: academicTerm.id,
          term: academicTerm.name,
          year: academicTerm.year_name,
        });
      });
    });
    const checkRecordExists = async (record) => {
      const response = await fetch(
        `${API_URLS.teacher_tt_records}?staff_id=${record.teacher}&term_id=${record.term_id}&time=${record.time}&day=${record.day}`
      );
      const data = await response.json();
      return data.exists;
    };

    const processData = async () => {
      for (let i = 0; i < data.length; i++) {
        const record = data[i];
        const exists = await checkRecordExists(record);
        if (exists) {
          data[i].message = "exists";
        } else {
          data[i].message = "not exist";
        }
      }
      setPreviewData(data);
    };
    processData();
    setPreviewTT(true);
  };

  const togglePreview = () => {
    setPreviewTT(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const lessondata = [];
    Object.entries(timetableData).forEach(([day, dayData]) => {
      Object.entries(dayData).forEach(([timeid, cellData]) => {
        lessondata.push({
          day: day,
          time: parseInt(timeid),
          subject: cellData.subject,
          teacher: cellData.teacher,
          grade: tClassRoom,
          term_id: academicTerm.id,
          term: academicTerm.name,
          year: academicTerm.year_name,
        });
      });
    });
    console.log(timetableData);
    console.log(lessondata);

    // axios
    //   .post(API_URLS.timetable, lessondata)
    //   .then((res) => {
    //     if (res.status === 201) {
    //       toast.success(
    //         "Time Tabletable Created Successfully",
    //         toastSuccessStyle
    //       );
    //     } else {
    //       toast.error("An Error Occured", toastDangerStyle);
    //     }
    //   })
    //   .catch((err) => {
    //     console.log("Error posting data:", err);
    //   });
  };

  return (
    <div className="main-content">
      <div className="row">
        <div className="col-12">
          <h1 className="text-center text-muted">New Timetable</h1>
        </div>
        <div className="col-12">
          <div className="col-3">
            <div className="form-group">
              <select
                className="text-muted"
                value={tClassRoom}
                onChange={(e) => setTClassRoom(e.target.value)}
              >
                <option value="" disabled>
                  Select Classroom
                </option>
                {classRooms.map((cl) => (
                  <option key={cl.id} value={cl.class_room_code}>
                    {cl.class_room_code} {cl.class_room_name}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <div className="col-12">
          <form onSubmit={handleSubmit}>
            <table>
              <thead style={{ border: "2px solid grey" }}>
                <tr>
                  <th
                    style={{ height: "80px", borderRight: "1.5px solid #222" }}
                  ></th>
                  {timeplan.map((tp) => (
                    <th
                      key={tp.id}
                      style={{
                        height: "80px",
                        borderRight: "1px solid #222",
                      }}
                    >
                      <span className="text-center text-dark ml-2">
                        {tp.name}
                      </span>
                      <br />
                      <span className="text-muted ml-2">
                        {tp.start.substring(5, 0)} - {tp.end.substring(5, 0)}
                      </span>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody
                className="col-12"
                style={{
                  border: "2px solid #222",
                }}
              >
                {days.map((day) => (
                  <tr key={day} style={{ borderBottom: "1.5px solid #222" }}>
                    <td
                      style={{
                        fontSize: "20px",
                        fontWeight: "bold",
                        borderRight: "1.5px solid #222",
                      }}
                      className="text-center p-2"
                    >
                      {day}
                    </td>
                    {timeplan.map((lesson) => (
                      <td
                        key={`${day}-${lesson.id}`}
                        style={{
                          border: "1px solid #222",
                          padding: "4px",
                          height: "100px",
                          width: "150px",
                        }}
                      >
                        <div className="col-12 d-flex flex-wrap pr-0 pl-0">
                          <div className="col-12 pr-0 pl-0">
                            <select
                              style={{ width: "95%" }}
                              className="text-muted"
                              id="select-input"
                              value={
                                timetableData[day]?.[lesson.id]?.subject ?? ""
                              }
                              onChange={(e) =>
                                handleSelectChange(e, day, lesson.id, "subject")
                              }
                            >
                              <option value="">Subject</option>
                              {/* {getSelectOptions(subjects, ["subject_name"])} */}
                              {subjects.map((subject) => (
                                <option
                                  key={subject.id}
                                  value={subject.subject_name}
                                >
                                  {subject.subject_name}
                                </option>
                              ))}
                            </select>
                            <select
                              style={{ width: "95%" }}
                              className="text-muted"
                              id="select-input"
                              value={
                                timetableData[day]?.[lesson.id]?.teacher ?? ""
                              }
                              onChange={(e) =>
                                handleSelectChange(e, day, lesson.id, "teacher")
                              }
                            >
                              <option value="">Teacher</option>
                              {/* {getSelectOptions(teachers, ["name"])} */}
                              {teachers.map((teacher) => (
                                <option
                                  key={teacher.id}
                                  value={teacher.x_staff_id}
                                >
                                  {teacher.x_staff_id} {teacher.name}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className="col-12 pr-1 pl-1">
                            {timetableData[day]?.[lesson.id]?.subject && (
                              <>
                                <span
                                  className="text-info font-weight-bold"
                                  style={{ fontSize: "15px" }}
                                >
                                  {timetableData[day]?.[lesson.id]?.subject}
                                </span>
                              </>
                            )}
                            <br />
                            {timetableData[day]?.[lesson.id]?.teacher && (
                              <span
                                className="text-success font-weight-bold"
                                style={{ fontSize: "15px" }}
                              >
                                {timetableData[day]?.[lesson.id]?.teacher}
                              </span>
                            )}
                          </div>
                        </div>
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="m-2">
              {!previewTT ? (
                <div className="col-12 text-right">
                  <Button
                    variant="contained"
                    onClick={handleSaveClick}
                    type="button"
                  >
                    Preview
                  </Button>
                </div>
              ) : (
                <div className="col-12 text-right">
                  <Button
                    variant="contained"
                    onClick={togglePreview}
                    type="button"
                  >
                    Stop Preview
                  </Button>
                </div>
              )}
            </div>

            {/* <div className="col-12">
              <button variant="contained" type="submit">
                Preview
              </button>
              <Toaster position="top-right" reverseOrder={false} />
            </div> */}
          </form>
        </div>
        <div className="col-12">
          {previewTT && previewData && (
            <ViewTimetable data={previewData} times={timeplan} />
          )}
        </div>
      </div>
    </div>
  );
}
