import React from "react";

export function CustomTeacherTimetable({ timeplan, days, tableData }) {
  return (
    <>
      <table>
        <thead style={{ border: "2px solid grey" }}>
          <tr>
            <th
              style={{
                height: "80px",
                borderRight: "1.5px solid #222",
                fontSize: "20px",
                fontWeight: "bold",
                borderRight: "1.5px solid #222",
              }}
              className="text-center p-2"
            >
              Day
            </th>
            {timeplan.map((time) => (
              <th
                style={{ height: "80px", borderRight: "1.5px solid #222" }}
                key={time.id}
              >
                <div style={{ width: 100 }}>
                  <span
                    className="text-warning font-weight-bold ml-2"
                    style={{ fontSize: "16px" }}
                  >
                    {time.name}
                  </span>
                  <br />
                  <span className="text-muted ml-2">
                    {time.start.substring(5, 0)} -
                  </span>
                  <span className="text-muted ml-2">
                    {time.end.substring(5, 0)}
                  </span>
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody
          style={{
            border: "2px solid #222",
          }}
        >
          {days.map((day) => (
            <tr key={day.id} style={{ borderBottom: "1.5px solid #222" }}>
              <td
                style={{
                  fontSize: "20px",
                  fontWeight: "bold",
                  borderRight: "1.5px solid #222",
                }}
                className="text-center p-2"
              >
                {day.code}
              </td>
              {timeplan.map((time) => {
                const classData = tableData.find(
                  (item) => item.day === day.code && item.time === time.id
                );
                const grade = classData ? classData.grade : "";
                const subject = classData ? classData.subject : "";
                return (
                  <td
                    style={{
                      fontSize: "15px",
                      fontWeight: "bold",
                      borderRight: "1.5px solid #222",
                    }}
                    className="text-center p-2"
                    key={time.id}
                  >
                    <span className="text-info">{subject}</span>
                    <br />
                    <span className="text-success">{grade}</span>
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
}

export function CustomStudentTimetable({ timeplan, days, tableData }) {
  return (
    <>
      <table>
        <thead style={{ border: "2px solid grey" }}>
          <tr>
            <th
              style={{
                height: "80px",
                borderRight: "1.5px solid #222",
                fontSize: "20px",
                fontWeight: "bold",
                borderRight: "1.5px solid #222",
              }}
              className="text-center p-2"
            >
              Day
            </th>
            {timeplan.map((time) => (
              <th
                style={{ height: "80px", borderRight: "1.5px solid #222" }}
                key={time.id}
              >
                <div style={{ width: 100 }}>
                  <span
                    className="text-warning font-weight-bold ml-2"
                    style={{ fontSize: "16px" }}
                  >
                    {time.name}
                  </span>
                  <br />
                  <span className="text-muted ml-2">
                    {time.start.substring(5, 0)} -
                  </span>
                  <span className="text-muted ml-2">
                    {time.end.substring(5, 0)}
                  </span>
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody
          style={{
            border: "2px solid #222",
          }}
        >
          {days.map((day) => (
            <tr key={day.id} style={{ borderBottom: "1.5px solid #222" }}>
              <td
                style={{
                  fontSize: "20px",
                  fontWeight: "bold",
                  borderRight: "1.5px solid #222",
                }}
                className="text-center p-2"
              >
                {day.code}
              </td>
              {timeplan.map((time) => {
                const classData = tableData.find(
                  (item) => item.day === day.code && item.time === time.id
                );
                const teacher = classData ? classData.teacher : "";
                const subject = classData ? classData.subject : "";
                return (
                  <td
                    style={{
                      fontSize: "15px",
                      fontWeight: "bold",
                      borderRight: "1.5px solid #222",
                    }}
                    className="text-center p-2"
                    key={time.id}
                  >
                    <span className="text-info">{subject}</span>
                    <br />
                    <span className="text-success">{teacher}</span>
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
}

export function CustomClassroomTimetable({ timeplan, days, tableData }) {
  return (
    <>
      <table>
        <thead style={{ border: "2px solid grey" }}>
          <tr>
            <th
              style={{
                height: "80px",
                borderRight: "1.5px solid #222",
                fontSize: "20px",
                fontWeight: "bold",
                borderRight: "1.5px solid #222",
              }}
              className="text-center p-2"
            >
              Day
            </th>
            {timeplan.map((time) => (
              <th
                style={{ height: "80px", borderRight: "1.5px solid #222" }}
                key={time.id}
              >
                <div style={{ width: 100 }}>
                  <span
                    className="text-warning font-weight-bold ml-2"
                    style={{ fontSize: "16px" }}
                  >
                    {time.name}
                  </span>
                  <br />
                  <span className="text-muted ml-2">
                    {time.start.substring(5, 0)} -
                  </span>
                  <span className="text-muted ml-2">
                    {time.end.substring(5, 0)}
                  </span>
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody
          style={{
            border: "2px solid #222",
          }}
        >
          {days.map((day) => (
            <tr key={day.id} style={{ borderBottom: "1.5px solid #222" }}>
              <td
                style={{
                  fontSize: "20px",
                  fontWeight: "bold",
                  borderRight: "1.5px solid #222",
                }}
                className="text-center p-2"
              >
                {day.code}
              </td>
              {timeplan.map((time) => {
                const classData = tableData.find(
                  (item) => item.day === day.code && item.time === time.id
                );
                const teacher = classData ? classData.teacher : "";
                const subject = classData ? classData.subject : "";
                return (
                  <td
                    style={{
                      fontSize: "15px",
                      fontWeight: "bold",
                      borderRight: "1.5px solid #222",
                    }}
                    className="text-center p-2"
                    key={time.id}
                  >
                    <span className="text-info">{subject}</span>
                    <br />
                    <span className="text-success">{teacher}</span>
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
}
