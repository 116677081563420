import React from "react";
import authService from "../../services/auth.service";
import StudentBoard from "./boards/StudentBoard";
import TeacherBoard from "./boards/TeacherBoard";

export default function UserDashboard() {
  const currentUser = authService.getCurrentUser();

  if (currentUser.roles.includes("STUDENT")) {
    return (
      <div className="main-content">
        <StudentBoard />
      </div>
    );
  } else if (currentUser.roles.includes("TEACHER")) {
    return (
      <div className="main-content">
        <TeacherBoard />
      </div>
    );
  }
}
