import { Button } from "@mui/material";
import React from "react";
import LoremIpsum from "react-lorem-ipsum";
import { useNavigate } from "react-router-dom";

export default function WelcomeForm(props) {
  const navigate = useNavigate();
  const Continue = (e) => {
    e.preventDefault();
    props.nextStep();
  };

  const Abort = (e) => {
    e.preventDefault();
    navigate("/admin");
  };

  const { values, handleChange } = props;

  return (
    <div>
      <h4 className="text-dark text-center">New Staff Registration Form</h4>
      <div className="d-flex flex-wrap" style={{}}>
        <div
          className="col-6"
          style={{
            backgroundImage:
              'url("https://news.workforce.com/wp-content/uploads/sites/2/2020/01/Sector-Report-RPOs-Do-More-Than-You-Think-8b38574.jpg")',
            height: 400,
            backgroundPosition: "center center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        ></div>
        <div className="col-6 d-flex flex-column justify-content-end">
          <div className="text-muted">
            <LoremIpsum p={1} />
          </div>
          <div className="text-center mt-3">
            <Button color="primary" variant="contained" onClick={Continue}>
              Start Registration
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
