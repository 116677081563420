import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast, Toaster } from "react-hot-toast";
import { useQuery } from "react-query";
import { Link, useNavigate } from "react-router-dom";
import {
  toastDangerStyle,
  toastSuccessStyle,
  toastWarnigStyle,
} from "../../../components/shared/Custom.Styles";
import { API_URLS } from "../../../utils/api.routes";
import ExamTypeAction from "./ExamTypeAction";
import { CustomPagination } from "../../../components/custom/Custom.DataGrid";
import { Button } from "@mui/material";
import { Send } from "@mui/icons-material";
import authService from "../../../services/auth.service";
import AccessDenied from "../../../components/custom/Custom.AccessDenied";

const ExamType = () => {
  const currentUser = authService.getCurrentUser();

  const [rowId, setRowId] = useState(null);

  useEffect(() => {
    // setSelectedLink(link);
  }, []);

  const [name, setName] = useState("");

  const [pageSize, setPageSize] = useState(5);
  const navigate = useNavigate();

  //import file hook
  const [file, setFile] = useState();

  let headersList = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };

  let bodyContent = JSON.stringify({
    name: name,
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    await fetch(API_URLS.exam_type, {
      method: "POST",
      body: bodyContent,
      headers: headersList,
    })
      .then((res) => {
        if (res.status === 201) {
          refetch();
          setName("");
          toast.success("Successfully Created Exam Type", toastSuccessStyle);
        } else if (res.status === 400) {
          toast.error(`Error !!! ${res.statusText}`, toastDangerStyle);
          setTimeout(() => {
            toast(`Seems Exam Type Already Exists`, toastWarnigStyle);
          }, 1000);
        } else {
          toast.error(
            `${res.status} Error !!! ${res.statusText}`,
            toastDangerStyle
          );
        }
      })
      .catch((err) => {
        toast.error(`Error, Not Found !!!`, toastDangerStyle);
      });
  };

  //import
  const handleFileSelection = (e) => {
    const selectedFile = e.target.files[0];
    if (
      selectedFile.type === "text/csv" ||
      selectedFile.type === "application/json" ||
      selectedFile.type === "application/vnd.ms-excel" ||
      selectedFile.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      setFile(selectedFile);
    } else {
      toast("Please select a CSV, JSON or Excel file.", toastWarnigStyle);
    }
  };

  const UploadFile = () => {
    const formData = new FormData();
    formData.append("file", file);

    fetch(API_URLS.upload_examtype, {
      method: "POST",
      body: formData,
    })
      .then((res) => {
        if (res.status === 200) {
          refetch();
          setFile(null);
          toast.success("Upload Successfull", toastSuccessStyle);
        }
      })
      .catch((error) => {
        toast.error(`Upload Error ${error}`, toastDangerStyle);
      });
  };

  //
  const fetchExamType = async () => {
    const { data } = await axios.get(API_URLS.exam_type);
    return data;
  };
  const { data, error, isError, isLoading, refetch } = useQuery(
    "exam_type",
    fetchExamType
  );

  if (isLoading) {
    return <div>Loading...</div>;
  }
  if (isError) {
    return <div>Error!! {error.message}</div>;
  }
  const editableColumns = [
    {
      field: "id",
      headerName: "SL",
      headerAlign: "center",
      type: "number",
      width: 100,
    },
    {
      field: "name",
      headerName: "Exam Type",
      headerAlign: "center",
      width: 250,
      editable: true,
    },

    {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      width: 150,
      renderCell: (params) => (
        <ExamTypeAction {...{ params, rowId, setRowId, refetch }} />
      ),
    },
  ];
  const columns = [
    {
      field: "id",
      headerName: "SL",
      headerAlign: "center",
      type: "number",
      width: 100,
    },
    {
      field: "name",
      headerName: "Exam Type",
      headerAlign: "center",
      width: 250,
    },
  ];
  if (
    !currentUser.roles.includes("VIEW_EXAM_TYPE") &&
    !currentUser.roles.includes("SUPER_ADMIN")
  ) {
    return <AccessDenied />;
  }
  return (
    <div className="main-content">
      <div className="row">
        <div className="col-12 col-md-12 col-lg-12">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link className="font-weight-bold" to="/super-admin-dashboard">
                  Home
                </Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                <span className="font-weight-bold">Exam Type</span>
              </li>
            </ol>
          </nav>
        </div>
        {currentUser.roles.includes("ADD_EXAM_TYPE") ||
        currentUser.roles.includes("SUPER_ADMIN") ? (
          <div className="col-md-12 col-lg-4 col-xl-4">
            <div className="shadow-sm p-3 mb-5 bg-white rounded">
              <form onSubmit={handleSubmit}>
                <h5 className="text-center text-dark">Add Exam Type</h5>
                <div className="card-body">
                  <div className="form-group">
                    <label htmlFor="examName">Exam Type</label>
                    <input
                      type="text"
                      id="examName"
                      value={name}
                      className="form-control"
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                </div>
                <div className="text-center">
                  <button className="btn btn-primary mr-1" type="submit">
                    Add Exam Type
                  </button>
                  <Toaster position="top-right" reverseOrder={false} />
                </div>
              </form>
            </div>
          </div>
        ) : (
          <></>
        )}
        <div className="col-md-12 col-lg-8 col-xl-8">
          <div className="shadow-sm p-3 mb-5 bg-white rounded">
            <h5 className="text-center text-dark">Exam Type List</h5>
            {currentUser.roles.includes("UPLOAD_EXAM_TYPE") ||
            currentUser.roles.includes("SUPER_ADMIN") ? (
              <div className="d-flex justify-content-end">
                <div className="d-flex m-1">
                  <Button variant="text" component="label">
                    Import
                    <input
                      hidden
                      type={"file"}
                      id={"csvFileInput"}
                      accept=".csv,.json,.xlsx,application/vnd.ms-excel"
                      onChange={handleFileSelection}
                    />
                  </Button>
                  {file && (
                    <div className="text-info align-self-center">
                      File: {file.name}
                    </div>
                  )}
                </div>
                <Button
                  size="small"
                  variant="contained"
                  endIcon={<Send />}
                  onClick={UploadFile}
                >
                  Submit
                </Button>
              </div>
            ) : (
              <></>
            )}
            <div style={{ width: "100%" }}>
              <DataGrid
                autoHeight
                columns={
                  currentUser.roles.includes("UPDATE_EXAM_TYPE") ||
                  currentUser.roles.includes("SUPER_ADMIN")
                    ? editableColumns
                    : columns
                }
                rows={data}
                components={{
                  Toolbar: GridToolbar,
                  Pagination: CustomPagination,
                }}
                getRowId={(row) => row.id}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                getRowSpacing={(params) => ({
                  top: params.isFirstVisible ? 0 : 5,
                  bottom: params.isLastVisible ? 0 : 5,
                })}
                onCellEditCommit={(params) => setRowId(params.id)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExamType;
