import React from "react";
import { Button } from "@mui/material";

export default function EmergencyContactForm(props) {
  const Continue = (e) => {
    e.preventDefault();
    props.nextStep();
  };
  const Back = (e) => {
    e.preventDefault();
    props.prevStep();
  };
  const { values, handleChange } = props;

  return (
    <>
      <div>
        <h5 className="text-center text-dark">Emergency Contact</h5>
        <div className="col-12 col-md-12 col-lg-12">
          <div className="col-12 d-flex flex-wrap">
            <h6 className="col-12 text-muted">Contact</h6>
            <div className="form-group col-4">
              <label>First Name</label>
              <input
                type="text"
                className="form-control"
                onChange={handleChange("emerg_first_name1")}
                value={values.emerg_first_name1}
              />
            </div>
            <div className="form-group col-4">
              <label>Last Name</label>
              <input
                type="text"
                className="form-control"
                onChange={handleChange("emerg_last_name1")}
                value={values.emerg_last_name1}
              />
            </div>
            <div className="form-group col-4">
              <label>Phone Number</label>
              <input
                type="text"
                className="form-control"
                onChange={handleChange("emerg_tel_no1")}
                value={values.emerg_tel_no1}
              />
            </div>
            <div className="form-group col-4">
              <label>Mobile Number</label>
              <input
                type="text"
                className="form-control"
                onChange={handleChange("emerg_mobile_no1")}
                value={values.emerg_mobile_no1}
              />
            </div>
            <div className="form-group col-4">
              <label>Gender</label>
              <select
                className="form-control"
                onChange={handleChange("emerg_gender1")}
                value={values.emerg_gender1}
              >
                <option value="Male">Male</option>
                <option value="Female">Female</option>
                <option value="Prefer not to say">Prefer not to say</option>
              </select>
            </div>
            <div className="form-group col-4">
              <label>Relation</label>
              <select
                className="form-control"
                onChange={handleChange("emerg_relation1")}
                value={values.emerg_relation1}
              >
                <option value="Father">Father</option>
                <option value="Mother">Mother</option>
                <option value="Sibling">Sibling</option>
                <option value="Spouse">Spouse</option>
                <option value="FriendP">Friend</option>
              </select>
            </div>
          </div>
          <div className="col-12 d-flex flex-wrap">
            <h6 className="col-12 text-muted">Contact</h6>
            <div className="form-group col-4">
              <label>First Name</label>
              <input
                type="text"
                className="form-control"
                onChange={handleChange("emerg_first_name2")}
                value={values.emerg_first_name2}
              />
            </div>
            <div className="form-group col-4">
              <label>Last Name</label>
              <input
                type="text"
                className="form-control"
                onChange={handleChange("emerg_last_name2")}
                value={values.emerg_last_name2}
              />
            </div>
            <div className="form-group col-4">
              <label>Phone Number</label>
              <input
                type="text"
                className="form-control"
                onChange={handleChange("emerg_tel_no2")}
                value={values.emerg_tel_no2}
              />
            </div>
            <div className="form-group col-4">
              <label>Mobile Number</label>
              <input
                type="text"
                className="form-control"
                onChange={handleChange("emerg_mobile_no2")}
                value={values.emerg_mobile_no2}
              />
            </div>
            <div className="form-group col-4">
              <label>Gender</label>
              <select
                className="form-control"
                onChange={handleChange("emerg_gender2")}
                value={values.emerg_gender2}
              >
                <option value="Male">Male</option>
                <option value="Female">Female</option>
                <option value="prefer not to say">Prefer not to say</option>
              </select>
            </div>
            <div className="form-group col-4">
              <label>Relation</label>
              <select
                className="form-control"
                onChange={handleChange("emerg_relation2")}
                value={values.emerg_relation2}
              >
                <option value="Father">Father</option>
                <option value="Mother">Mother</option>
                <option value="Sibling">Sibling</option>
                <option value="Spouse">Spouse</option>
                <option value="FriendP">Friend</option>
              </select>
            </div>
          </div>
        </div>
        <div className="col-12 d-flex justify-content-between">
          <Button color="primary" variant="contained" onClick={Back}>
            Back
          </Button>
          <Button color="primary" variant="contained" onClick={Continue}>
            Next
          </Button>
        </div>
      </div>
    </>
  );
}
