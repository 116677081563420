import React, { useEffect, useState } from "react";
import { API_URLS } from "../../../utils/api.routes";
import {
  toastDangerStyle,
  toastSuccessStyle,
} from "../../../components/shared/Custom.Styles";
import StudentAttendance from "../../../components/custom/StudentAttendance";
import { toast } from "react-hot-toast";
import { Button } from "@mui/material";
import axios from "axios";

export default function ExamStudentAttendance({
  students,
  class_code,
  exam_setup_id,
}) {
  const [attendanceStatus, setAttendanceStatus] = useState("");
  const [instanceTime, setInstanceTime] = useState("08:00:00");
  const [attendanceReport, setAttendanceReport] = useState(null);
  const [attendance, setAttendance] = useState(
    students.map((student) => ({
      ...student,
      present: false,
    }))
  );

  useEffect(() => {
    const fetchReportStatus = async () => {
      let admn_no = students[0]["admn_no"];
      const res = await fetch(
        API_URLS.check_exam_attendance_taken.concat(
          `?admn_no=${admn_no}&exam_setup=${exam_setup_id}&class_room=${class_code}`,
          ""
        )
      );
      const data = await res.json();
      setAttendanceStatus(data.message);
    };
    const fetchClassAttendanceReport = async () => {
      const res = await fetch(
        API_URLS.check_exam_attendance_report.concat(
          `?exam_setup=${exam_setup_id}&class_room=${class_code}`
        )
      );
      const data = await res.json();
      setAttendanceReport(data);
    };
    fetchReportStatus();
    fetchClassAttendanceReport();
  }, [class_code, exam_setup_id]);

  const fetchClassAttendanceReport = async () => {
    const res = await fetch(
      API_URLS.check_exam_attendance_report.concat(
        `?exam_setup=${exam_setup_id}&class_code=${class_code}`
      )
    );
    const data = await res.json();
    setAttendanceReport(data);
  };

  const handleAttendanceChange = (updatedAttendance) => {
    setAttendance(updatedAttendance);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    // Filter the attendance data to include only the IDs of students who are present
    const attendanceList = attendance.map((student) => ({
      setup: exam_setup_id,
      class_room: class_code,
      admn_no: student.admn_no,
      status: student.present,
    }));
    console.log(attendanceList);
    axios
      .post(API_URLS.exam_attendance, attendanceList)
      .then((res) => {
        if (res.status === 201) {
          fetchClassAttendanceReport();
          console.log(attendanceReport);
          toast.success("Register Marked Successfully", toastSuccessStyle);
        } else {
          toast.error("An Error Occured", toastDangerStyle);
        }
      })
      .catch((err) => {
        console.log("Error posting data:", err);
      });

    // for (let i = 0; i < attendanceReport.length; i++) {
    //   await fetch(API_URLS.exam_attendance, {
    //     method: "POST",
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //     body: JSON.stringify(attendanceReport[i]),
    //   })
    //     .then((res) => {
    //       if (i === attendanceReport.length - 1) {
    //         if (res.status === 201) {
    //           toast.success("Successfully Marked Register", toastSuccessStyle);
    //           // setInstanceTime("08:00:00");
    //           setAttendanceStatus("Taken");
    //         }
    //       }
    //     })
    //     .catch((err) => {
    //       toast.error("Failed to mark Register", toastDangerStyle);
    //     });
    // }
  };
  console.log(students);
  return (
    <div>
      <div className="col-12 d-flex flex-wrap justify-content-between">
        {/* <div className="form-group col-4">
          <select
            className="form-control"
            value={instanceTime}
            onChange={handleAttendanceInstanceChange}
          >
            <option value="" disabled>
              Choose Time
            </option>
            {attendanceInstance.map((instance, index) => {
              return (
                <option key={index} value={instance.time}>
                  {instance.time}
                </option>
              );
            })}
          </select>
        </div> */}
        <div className="col-12">
          <h6 className="text-muted text-center" style={{ fontSize: "20px" }}>
            {attendanceStatus === "Not Taken" ? (
              <span className="text-danger font-weight-bold">
                {class_code} Examination Attendance for {exam_setup_id} Register
                not Marked Not Marked
              </span>
            ) : (
              <span className="text-success font-weight-bold">
                Attendance Register Marked
              </span>
            )}
          </h6>
        </div>
      </div>

      {attendanceStatus === "Taken" ? (
        <></>
      ) : (
        <>
          <StudentAttendance
            students={students}
            onAttendanceChange={handleAttendanceChange}
          />
          <div className="text-right m-2">
            <Button variant="contained" onClick={handleSubmit}>
              Submit Attendance
            </Button>
          </div>
        </>
      )}
      {attendanceReport && attendanceStatus != "" && (
        <div className="col-12">
          <h5 className="text-muted">
            Register Report <br />
            {attendanceReport.status === "All Present" ? (
              <span className="text-success">Everyone Present</span>
            ) : (
              <span className="text-warning">{attendanceReport.status}</span>
            )}
          </h5>
          <h6 className="text-dark">
            <span style={{ fontSize: "22px" }}>
              {attendanceReport.present_count}
            </span>{" "}
            Students Present
            <br />
            {attendanceReport.present_count > 0 ? (
              <ul>
                {attendanceReport.attendance.present.map((stud, index) => (
                  <ol key={index} className="text-info p-2 mr-4">
                    {stud}
                  </ol>
                ))}
              </ul>
            ) : (
              <></>
            )}
          </h6>
          <h6 className="text-dark">
            <span style={{ fontSize: "22px" }}>
              {attendanceReport.absent_count}
            </span>{" "}
            Students Absent{" "}
            {attendanceReport.absent_count > 0 ? (
              <span className="text-info">
                {attendanceReport.attendance.absent}
              </span>
            ) : (
              <></>
            )}
          </h6>
        </div>
      )}
    </div>
  );
}
