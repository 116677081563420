import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { API_URLS } from "../../utils/api.routes";

export function StudentSearchSelectInput(props) {
  const { onSelectedItemChange, searchFields } = props;
  const [selectedItem, setSelectedItem] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [options, setOptions] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    async function fetchStudents() {
      const res = await fetch(API_URLS.students_info);
      const data = await res.json();
      setOptions(data);
    }
    fetchStudents();
  }, []);

  const handleSelect = (option) => {
    setSelectedItem(option);
    setSearchTerm("");
    onSelectedItemChange(option);
    setIsOpen(false);
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setSearchTerm(event.target.value);
    setIsOpen(!!event.target.value);
  };

  const filteredOptions = options.filter((option) =>
    searchFields.some((field) =>
      option[field].toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  return (
    <>
      <input
        className="form-control"
        type="text"
        value={searchTerm}
        onChange={handleSearch}
        placeholder={
          selectedItem ? `${selectedItem.admn_no}` : "Search Student ..."
        }
      />
      {isOpen && (
        <select
          className="form-control"
          value={selectedItem ? selectedItem.admn_no : ""}
          onChange={(event) =>
            handleSelect(
              options.find((option) => option.admn_no === event.target.value)
            )
          }
        >
          <option value="">Select an option</option>
          {filteredOptions.map((stud, index) => (
            <option key={index} value={stud.admn_no}>
              ({stud.admn_no})- {stud.first_name} {stud.last_name}
            </option>
          ))}
        </select>
      )}
    </>
  );
}

export function TeacherSearchSelectInput(props) {
  const { onSelectedItemChange, searchFields } = props;
  const [selectedItem, setSelectedItem] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [options, setOptions] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    async function fetchTeachers() {
      const res = await fetch(API_URLS.hr_teachers_odoo);
      const data = await res.json();
      setOptions(data);
    }
    fetchTeachers();
  }, []);

  const handleSelect = (option) => {
    setSelectedItem(option);
    setSearchTerm("");
    onSelectedItemChange(option);
    setIsOpen(false);
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setSearchTerm(event.target.value);
    setIsOpen(!!event.target.value);
  };

  const filteredOptions = options.filter((option) =>
    searchFields.some((field) =>
      option[field].toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  return (
    <>
      <input
        className="form-control"
        type="text"
        value={searchTerm}
        onChange={handleSearch}
        placeholder={
          selectedItem ? `${selectedItem.name}` : "Search Teacher ..."
        }
      />
      {isOpen && (
        <select
          className="form-control"
          value={selectedItem ? selectedItem.x_staff_id : ""}
          onChange={(event) =>
            handleSelect(
              options.find((option) => option.x_staff_id === event.target.value)
            )
          }
        >
          <option value="">Select an option</option>
          {filteredOptions.map((teacher, index) => (
            <option key={index} value={teacher.x_staff_id}>
              ({teacher.x_staff_id})- {teacher.name}
            </option>
          ))}
        </select>
      )}
    </>
  );
}

export function AcademicYearSearchSelectInput(props) {
  const { onSelectedItemChange, searchFields } = props;
  const [selectedItem, setSelectedItem] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [options, setOptions] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    async function fetchClass() {
      const res = await fetch(API_URLS.academic_year);
      const data = await res.json();
      setOptions(data);
    }
    fetchClass();
  }, []);

  const handleSelect = (option) => {
    setSelectedItem(option);
    setSearchTerm("");
    onSelectedItemChange(option);
    setIsOpen(false);
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setSearchTerm(event.target.value);
    setIsOpen(!!event.target.value);
  };

  const filteredOptions = options.filter((option) =>
    searchFields.some((field) =>
      option[field].toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  return (
    <>
      <input
        className="form-control"
        type="text"
        value={searchTerm}
        onChange={handleSearch}
        placeholder={selectedItem ? selectedItem.name : "Search Academic Year"}
      />

      {isOpen && (
        <select
          className="form-control"
          value={selectedItem ? selectedItem.name : ""}
          onChange={(event) =>
            handleSelect(
              options.find((option) => option.name === event.target.value)
            )
          }
        >
          <option value="">Select an option</option>
          {filteredOptions.map((sub, index) => (
            <option key={index} value={sub.name}>
              {sub.name}
            </option>
          ))}
        </select>
      )}
    </>
  );
}

export function ClassSearchSelectInput({ onSelectedItemChange, searchFields }) {
  const [selectedItem, setSelectedItem] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [options, setOptions] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    async function fetchClass() {
      const res = await fetch(API_URLS.class_room);
      const data = await res.json();
      setOptions(data);
    }
    fetchClass();
  }, []);

  const handleSelect = (option) => {
    setSelectedItem(option);
    setSearchTerm("");
    onSelectedItemChange(option);
    setIsOpen(false);
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setSearchTerm(event.target.value);
    setIsOpen(!!event.target.value);
  };

  const filteredOptions = options.filter((option) =>
    searchFields.some((field) =>
      option[field].toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  return (
    <>
      <input
        className="form-control"
        type="text"
        value={searchTerm}
        onChange={handleSearch}
        placeholder={
          selectedItem
            ? `${selectedItem.class_room_name}`
            : "Search Classroom ..."
        }
      />
      {isOpen && (
        <select
          className="form-control"
          value={selectedItem ? selectedItem.class_room_code : ""}
          onChange={(event) =>
            handleSelect(
              options.find(
                (option) => option.class_room_code === event.target.value
              )
            )
          }
        >
          <option value="">Select an option</option>
          {filteredOptions.map((sub, index) => (
            <option key={index} value={sub.class_room_code}>
              {sub.class_room_name}
            </option>
          ))}
        </select>
      )}
    </>
  );
}

export function GradeSearchSelectInput({ onSelectedItemChange, searchFields }) {
  const [selectedItem, setSelectedItem] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [options, setOptions] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    async function fetchClass() {
      const res = await fetch(API_URLS.grade);
      const data = await res.json();
      setOptions(data);
    }
    fetchClass();
  }, []);

  const handleSelect = (option) => {
    setSelectedItem(option);
    setSearchTerm("");
    onSelectedItemChange(option);
    setIsOpen(false);
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setSearchTerm(event.target.value);
    setIsOpen(!!event.target.value);
  };

  const filteredOptions = options.filter((option) =>
    searchFields.some((field) =>
      option[field].toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  return (
    <>
      <input
        className="form-control"
        type="text"
        value={searchTerm}
        onChange={handleSearch}
        placeholder={selectedItem ? `${selectedItem.name}` : "Search Grade ..."}
      />
      {isOpen && (
        <select
          className="form-control"
          value={selectedItem ? selectedItem.code : ""}
          onChange={(event) =>
            handleSelect(
              options.find((option) => option.code === event.target.value)
            )
          }
        >
          <option value="">Select an option</option>
          {filteredOptions.map((grade, index) => (
            <option key={index} value={grade.code}>
              ({grade.code}) {grade.name}
            </option>
          ))}
        </select>
      )}
    </>
  );
}
export function SubjectSearchSelectInput({
  onSelectedItemChange,
  searchFields,
}) {
  const [selectedItem, setSelectedItem] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [options, setOptions] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    async function fetchSubjects() {
      const res = await fetch(API_URLS.subjects);
      const data = await res.json();
      setOptions(data);
    }
    fetchSubjects();
  }, []);

  const handleSelect = (option) => {
    setSelectedItem(option);
    setSearchTerm("");
    onSelectedItemChange(option);
    setIsOpen(false);
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setSearchTerm(event.target.value);
    setIsOpen(!!event.target.value);
  };

  const filteredOptions = options.filter((option) =>
    searchFields.some((field) =>
      option[field].toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  return (
    <>
      <input
        className="form-control"
        type="text"
        value={searchTerm}
        onChange={handleSearch}
        placeholder={
          selectedItem ? `${selectedItem.subject_name}` : "Search Subject ..."
        }
      />
      {isOpen && (
        <select
          className="form-control"
          value={selectedItem ? selectedItem.subject_name : ""}
          onChange={(event) =>
            handleSelect(
              options.find(
                (option) => option.subject_name === event.target.value
              )
            )
          }
        >
          <option value="">Select an option</option>
          {filteredOptions.map((sub, index) => (
            <option key={index} value={sub.subject_name}>
              {sub.subject_name}
            </option>
          ))}
        </select>
      )}
    </>
  );
}
export function LessonSearchSelectInput({
  onSelectedItemChange,
  searchFields,
}) {
  const [selectedItem, setSelectedItem] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [options, setOptions] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    async function fetchLesson() {
      const res = await fetch(API_URLS.lesson);
      const data = await res.json();
      setOptions(data);
    }
    fetchLesson();
  }, []);

  const handleSelect = (option) => {
    setSelectedItem(option);
    setSearchTerm("");
    onSelectedItemChange(option);
    setIsOpen(false);
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setSearchTerm(event.target.value);
    setIsOpen(!!event.target.value);
  };

  const filteredOptions = options.filter((option) =>
    searchFields.some((field) =>
      option[field].toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  return (
    <>
      <input
        className="form-control"
        type="text"
        value={searchTerm}
        onChange={handleSearch}
      />
      {isOpen && (
        <select
          className="form-control"
          value={selectedItem ? selectedItem.name : ""}
          onChange={(event) =>
            handleSelect(
              options.find((option) => option.name === event.target.value)
            )
          }
        >
          <option value="">Select an option</option>
          {filteredOptions.map((lesson, index) => (
            <option key={index} value={lesson.name}>
              {lesson.name}
            </option>
          ))}
        </select>
      )}
    </>
  );
}

export function YearSearchSelectInput({ onSelectedItemChange, searchFields }) {
  const [selectedItem, setSelectedItem] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [options, setOptions] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    async function fetchYear() {
      const res = await fetch(API_URLS.academic_year);
      const data = await res.json();
      setOptions(data);
    }
    fetchYear();
  }, []);

  const handleSelect = (option) => {
    setSelectedItem(option);
    setSearchTerm("");
    onSelectedItemChange(option);
    setIsOpen(false);
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setSearchTerm(event.target.value);
    setIsOpen(!!event.target.value);
  };

  const filteredOptions = options.filter((option) =>
    searchFields.some((field) =>
      option[field].toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  return (
    <>
      <input
        className="form-control"
        type="text"
        value={searchTerm}
        onChange={handleSearch}
      />
      {isOpen && (
        <select
          className="form-control"
          value={selectedItem ? selectedItem.name : ""}
          onChange={(event) =>
            handleSelect(
              options.find((option) => option.name === event.target.value)
            )
          }
        >
          <option value="">Select an option</option>
          {filteredOptions.map((year, index) => (
            <option key={index} value={year.name}>
              {year.name}
            </option>
          ))}
        </select>
      )}
    </>
  );
}

export function TermSearchSelectInput({ onSelectedItemChange, searchFields }) {
  const [selectedItem, setSelectedItem] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [options, setOptions] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    async function fetchTerm() {
      const res = await fetch(API_URLS.academic_term);
      const data = await res.json();
      setOptions(data);
    }
    fetchTerm();
  }, []);

  const handleSelect = (option) => {
    setSelectedItem(option);
    setSearchTerm("");
    onSelectedItemChange(option);
    setIsOpen(false);
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setSearchTerm(event.target.value);
    setIsOpen(!!event.target.value);
  };

  const filteredOptions = options.filter((option) =>
    searchFields.some((field) =>
      option[field].toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  return (
    <>
      <input
        className="form-control"
        type="text"
        value={searchTerm}
        onChange={handleSearch}
      />
      {isOpen && (
        <select
          className="form-control"
          value={selectedItem ? selectedItem.name : ""}
          onChange={(event) =>
            handleSelect(
              options.find((option) => option.name === event.target.value)
            )
          }
        >
          <option value="">Select an option</option>
          {filteredOptions.map((term, index) => (
            <option key={index} value={term.name}>
              {term.name}
            </option>
          ))}
        </select>
      )}
    </>
  );
}

export function VenueSearchSelectInput({ onSelectedItemChange, searchFields }) {
  const [selectedItem, setSelectedItem] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [options, setOptions] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    async function fetchVenue() {
      const res = await fetch(API_URLS.timetable);
      const data = await res.json();
      setOptions(data);
    }
    fetchVenue();
  }, []);

  const handleSelect = (option) => {
    setSelectedItem(option);
    setSearchTerm("");
    onSelectedItemChange(option);
    setIsOpen(false);
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setSearchTerm(event.target.value);
    setIsOpen(!!event.target.value);
  };

  const filteredOptions = options.filter((option) =>
    searchFields.some((field) =>
      option[field].toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  return (
    <>
      <input
        className="form-control"
        type="text"
        value={searchTerm}
        onChange={handleSearch}
      />
      {isOpen && (
        <select
          className="form-control"
          value={selectedItem ? selectedItem.venue : ""}
          onChange={(event) =>
            handleSelect(
              options.find((option) => option.venue === event.target.value)
            )
          }
        >
          <option value="">Select an option</option>
          {filteredOptions.map((timetable, index) => (
            <option key={index} value={timetable.venue}>
              {timetable.venue}
            </option>
          ))}
        </select>
      )}
    </>
  );
}

export function StrandSearchSelectInput({
  onSelectedItemChange,
  searchFields,
}) {
  const [selectedItem, setSelectedItem] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [options, setOptions] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    async function fetchStrand() {
      const res = await fetch(API_URLS.schemes_of_work);
      const data = await res.json();
      setOptions(data);
    }
    fetchStrand();
  }, []);

  const handleSelect = (option) => {
    setSelectedItem(option);
    setSearchTerm("");
    onSelectedItemChange(option);
    setIsOpen(false);
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setSearchTerm(event.target.value);
    setIsOpen(!!event.target.value);
  };

  const filteredOptions = options.filter((option) =>
    searchFields.some((field) =>
      option[field].toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  return (
    <>
      <input
        className="form-control"
        type="text"
        value={searchTerm}
        onChange={handleSearch}
      />
      {isOpen && (
        <select
          className="form-control"
          value={selectedItem ? selectedItem.strand : ""}
          onChange={(event) =>
            handleSelect(
              options.find((option) => option.strand === event.target.value)
            )
          }
        >
          <option value="">Select an option</option>
          {filteredOptions.map((sw, index) => (
            <option key={index} value={sw.strand}>
              {sw.strand}
            </option>
          ))}
        </select>
      )}
    </>
  );
}

export function SubstrandSearchSelectInput({
  onSelectedItemChange,
  searchFields,
}) {
  const [selectedItem, setSelectedItem] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [options, setOptions] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    async function fetchSubstrand() {
      const res = await fetch(API_URLS.schemes_of_work);
      const data = await res.json();
      setOptions(data);
    }
    fetchSubstrand();
  }, []);

  const handleSelect = (option) => {
    setSelectedItem(option);
    setSearchTerm("");
    onSelectedItemChange(option);
    setIsOpen(false);
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setSearchTerm(event.target.value);
    setIsOpen(!!event.target.value);
  };

  const filteredOptions = options.filter((option) =>
    searchFields.some((field) =>
      option[field].toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  return (
    <>
      <input
        className="form-control"
        type="text"
        value={searchTerm}
        onChange={handleSearch}
      />
      {isOpen && (
        <select
          className="form-control"
          value={selectedItem ? selectedItem.substrand : ""}
          onChange={(event) =>
            handleSelect(
              options.find((option) => option.substrand === event.target.value)
            )
          }
        >
          <option value="">Select an option</option>
          {filteredOptions.map((sw, index) => (
            <option key={index} value={sw.substrand}>
              {sw.substrand}
            </option>
          ))}
        </select>
      )}
    </>
  );
}

export function TopicSearchSelectInput({ onSelectedItemChange, searchFields }) {
  const [selectedItem, setSelectedItem] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [options, setOptions] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    async function fetchTopic() {
      const res = await fetch(API_URLS.topic);
      const data = await res.json();
      setOptions(data);
    }
    fetchTopic();
  }, []);

  const handleSelect = (option) => {
    setSelectedItem(option);
    setSearchTerm("");
    onSelectedItemChange(option);
    setIsOpen(false);
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setSearchTerm(event.target.value);
    setIsOpen(!!event.target.value);
  };

  const filteredOptions = options.filter((option) =>
    searchFields.some((field) =>
      option[field].toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  return (
    <>
      <input
        className="form-control"
        type="text"
        value={searchTerm}
        onChange={handleSearch}
      />
      {isOpen && (
        <select
          className="form-control"
          value={selectedItem ? selectedItem.name : ""}
          onChange={(event) =>
            handleSelect(
              options.find((option) => option.name === event.target.value)
            )
          }
        >
          <option value="">Select an option</option>
          {filteredOptions.map((topic, index) => (
            <option key={index} value={topic.name}>
              {topic.name}
            </option>
          ))}
        </select>
      )}
    </>
  );
}

export function SubtopicSearchSelectInput({
  onSelectedItemChange,
  searchFields,
}) {
  const [selectedItem, setSelectedItem] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [options, setOptions] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    async function fetchSubtopic() {
      const res = await fetch(API_URLS.subtopic);
      const data = await res.json();
      setOptions(data);
    }
    fetchSubtopic();
  }, []);

  const handleSelect = (option) => {
    setSelectedItem(option);
    setSearchTerm("");
    onSelectedItemChange(option);
    setIsOpen(false);
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setSearchTerm(event.target.value);
    setIsOpen(!!event.target.value);
  };

  const filteredOptions = options.filter((option) =>
    searchFields.some((field) =>
      option[field].toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  return (
    <>
      <input
        className="form-control"
        type="text"
        value={searchTerm}
        onChange={handleSearch}
      />
      {isOpen && (
        <select
          className="form-control"
          value={selectedItem ? selectedItem.name : ""}
          onChange={(event) =>
            handleSelect(
              options.find((option) => option.name === event.target.value)
            )
          }
        >
          <option value="">Select an option</option>
          {filteredOptions.map((subtopic, index) => (
            <option key={index} value={subtopic.name}>
              {subtopic.name}
            </option>
          ))}
        </select>
      )}
    </>
  );
}

export function ExamtypeSearchSelectInput({
  onSelectedItemChange,
  searchFields,
}) {
  const [selectedItem, setSelectedItem] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [options, setOptions] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    async function fetchExamtype() {
      const res = await fetch(API_URLS.exam_type);
      const data = await res.json();
      setOptions(data);
    }
    fetchExamtype();
  }, []);

  const handleSelect = (option) => {
    setSelectedItem(option);
    setSearchTerm("");
    onSelectedItemChange(option);
    setIsOpen(false);
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setSearchTerm(event.target.value);
    setIsOpen(!!event.target.value);
  };

  const filteredOptions = options.filter((option) =>
    searchFields.some((field) =>
      option[field].toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  return (
    <>
      <input
        className="form-control"
        type="text"
        value={searchTerm}
        onChange={handleSearch}
      />
      {isOpen && (
        <select
          className="form-control"
          value={selectedItem ? selectedItem.name : ""}
          onChange={(event) =>
            handleSelect(
              options.find((option) => option.name === event.target.value)
            )
          }
        >
          <option value="">Select an option</option>
          {filteredOptions.map((examtype, index) => (
            <option key={index} value={examtype.name}>
              {examtype.name}
            </option>
          ))}
        </select>
      )}
    </>
  );
}

export function SetupSearchSelectInput({ onSelectedItemChange, searchFields }) {
  const [selectedItem, setSelectedItem] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [options, setOptions] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    async function fetchExamsetup() {
      const res = await fetch(API_URLS.exam_setup);
      const data = await res.json();
      setOptions(data);
    }
    fetchExamsetup();
  }, []);

  const handleSelect = (option) => {
    setSelectedItem(option);
    setSearchTerm("");
    onSelectedItemChange(option);
    setIsOpen(false);
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setSearchTerm(event.target.value);
    setIsOpen(!!event.target.value);
  };

  const filteredOptions = options.filter((option) =>
    searchFields.some((field) =>
      option[field].toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  return (
    <>
      <input
        className="form-control"
        type="text"
        value={searchTerm}
        onChange={handleSearch}
        placeholder={
          selectedItem ? `${selectedItem.grade}` : "Search Setup ..."
        }
      />
      {isOpen && (
        <select
          className="form-control"
          value={selectedItem ? selectedItem.grade : ""}
          onChange={(event) =>
            handleSelect(
              options.find((option) => option.grade === event.target.value)
            )
          }
        >
          <option value="">Select an option</option>
          {filteredOptions.map((examsetup, index) => (
            <option key={index} value={examsetup.grade}>
              {examsetup.grade}
            </option>
          ))}
        </select>
      )}
    </>
  );
}

export function DormitorySearchSelectInput({
  onSelectedItemChange,
  searchFields,
}) {
  const [selectedItem, setSelectedItem] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [options, setOptions] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    async function fetchDormitory() {
      const res = await fetch(API_URLS.dormitory);
      const data = await res.json();
      setOptions(data);
    }
    fetchDormitory();
  }, []);

  const handleSelect = (option) => {
    setSelectedItem(option);
    setSearchTerm("");
    onSelectedItemChange(option);
    setIsOpen(false);
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setSearchTerm(event.target.value);
    setIsOpen(!!event.target.value);
  };

  const filteredOptions = options.filter((option) =>
    searchFields.some((field) =>
      option[field].toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  return (
    <>
      <input
        className="form-control"
        type="text"
        value={searchTerm}
        onChange={handleSearch}
      />
      {isOpen && (
        <select
          className="form-control"
          value={selectedItem ? selectedItem.name : ""}
          onChange={(event) =>
            handleSelect(
              options.find((option) => option.name === event.target.value)
            )
          }
        >
          <option value="">Select an option</option>
          {filteredOptions.map((dormitory, index) => (
            <option key={index} value={dormitory.name}>
              {dormitory.name}
            </option>
          ))}
        </select>
      )}
    </>
  );
}

export function TimeplanSearchSelectInput({
  onSelectedItemChange,
  searchFields,
}) {
  const [selectedItem, setSelectedItem] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [options, setOptions] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    async function fetchTimeplan() {
      const res = await fetch(API_URLS.timeplan);
      const data = await res.json();
      setOptions(data);
    }
    fetchTimeplan();
  }, []);

  const handleSelect = (option) => {
    setSelectedItem(option);
    setSearchTerm("");
    onSelectedItemChange(option);
    setIsOpen(false);
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setSearchTerm(event.target.value);
    setIsOpen(!!event.target.value);
  };

  const filteredOptions = options.filter((option) =>
    searchFields.some((field) =>
      option[field].toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  return (
    <>
      <input
        className="form-control"
        type="text"
        value={searchTerm}
        onChange={handleSearch}
      />
      {isOpen && (
        <select
          className="form-control"
          value={selectedItem ? selectedItem.name : ""}
          onChange={(event) =>
            handleSelect(
              options.find((option) => option.name === event.target.value)
            )
          }
        >
          <option value="">Select an option</option>
          {filteredOptions.map((timeplan, index) => (
            <option key={index} value={timeplan.name}>
              {timeplan.name}
            </option>
          ))}
        </select>
      )}
    </>
  );
}

export function AssessmentSearchSelectInput({
  onSelectedItemChange,
  searchFields,
}) {
  const [selectedItem, setSelectedItem] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [options, setOptions] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    async function fetchAssessment() {
      const res = await fetch(API_URLS.assessmentrubrics);
      const data = await res.json();
      setOptions(data);
    }
    fetchAssessment();
  }, []);

  const handleSelect = (option) => {
    setSelectedItem(option);
    setSearchTerm("");
    onSelectedItemChange(option);
    setIsOpen(false);
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setSearchTerm(event.target.value);
    setIsOpen(!!event.target.value);
  };

  const filteredOptions = options.filter((option) =>
    searchFields.some((field) =>
      option[field].toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  return (
    <>
      <input
        className="form-control"
        type="text"
        value={searchTerm}
        onChange={handleSearch}
      />
      {isOpen && (
        <select
          className="form-control"
          value={selectedItem ? selectedItem.student : ""}
          onChange={(event) =>
            handleSelect(
              options.find((option) => option.student === event.target.value)
            )
          }
        >
          <option value="">Select an option</option>
          {filteredOptions.map((assessment, index) => (
            <option key={index} value={assessment.student}>
              {assessment.student}
            </option>
          ))}
        </select>
      )}
    </>
  );
}

export function DiarySearchSelectInput({ onSelectedItemChange, searchFields }) {
  const [selectedItem, setSelectedItem] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [options, setOptions] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    async function fetchDiary() {
      const res = await fetch(API_URLS.diary);
      const data = await res.json();
      setOptions(data);
    }
    fetchDiary();
  }, []);

  const handleSelect = (option) => {
    setSelectedItem(option);
    setSearchTerm("");
    onSelectedItemChange(option);
    setIsOpen(false);
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setSearchTerm(event.target.value);
    setIsOpen(!!event.target.value);
  };

  const filteredOptions = options.filter((option) =>
    searchFields.some((field) =>
      option[field].toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  return (
    <>
      <input
        className="form-control"
        type="text"
        value={searchTerm}
        onChange={handleSearch}
        placeholder={
          selectedItem
            ? `${selectedItem.student} ${selectedItem.date}`
            : "Search Diary ..."
        }
      />
      {isOpen && (
        <select
          className="form-control"
          value={selectedItem ? selectedItem.student : ""}
          onChange={(event) =>
            handleSelect(
              options.find((option) => option.student === event.target.value)
            )
          }
        >
          <option value="">Select an option</option>
          {filteredOptions.map((diary, index) => (
            <option key={index} value={diary.student}>
              {diary.student} - {diary.date}
            </option>
          ))}
        </select>
      )}
    </>
  );
}

export function RoomSearchSelectInput({ onSelectedItemChange, searchFields }) {
  const [selectedItem, setSelectedItem] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [options, setOptions] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    async function fetchRoom() {
      const res = await fetch(API_URLS.dorm_room);
      const data = await res.json();
      setOptions(data);
    }
    fetchRoom();
  }, []);

  const handleSelect = (option) => {
    setSelectedItem(option);
    setSearchTerm("");
    onSelectedItemChange(option);
    setIsOpen(false);
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setSearchTerm(event.target.value);
    setIsOpen(!!event.target.value);
  };

  const filteredOptions = options.filter((option) =>
    searchFields.some((field) =>
      option[field].toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  return (
    <>
      <input
        className="form-control"
        type="text"
        value={searchTerm}
        onChange={handleSearch}
      />
      {isOpen && (
        <select
          className="form-control"
          value={selectedItem ? selectedItem.name : ""}
          onChange={(event) =>
            handleSelect(
              options.find((option) => option.name === event.target.value)
            )
          }
        >
          <option value="">Select an option</option>
          {filteredOptions.map((room, index) => (
            <option key={index} value={room.name}>
              {room.name}
            </option>
          ))}
        </select>
      )}
    </>
  );
}
