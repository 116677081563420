import React, { useState } from "react";
import { Toaster } from "react-hot-toast";
import { Link, useLocation } from "react-router-dom";

export default function EditStaffDetails() {
  const { state } = useLocation();
  console.log(state);
  return (
    <div className="main-content">
      <div className="row">
        <div className="col-12 col-md-12 col-lg-12">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/admin">Home</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/admin/hr/staff-list">Staff List</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Edit Staff state
              </li>
            </ol>
          </nav>
        </div>
        <div className="col-md-6 col-lg-12 col-xl-4">
          <div className="shadow-sm p-1 mb-3 bg-white rounded">
            <div className="card-body">
              <div className="d-flex flex-column align-items-center ">
                {" "}
                <img
                  src="https://bootdey.com/img/Content/avatar/avatar7.png"
                  alt="Student Image"
                  className="rounded-circle"
                  style={{ width: "150px" }}
                />
                <div className="mt-4">
                  <h4 className="text-info">
                    {state.first_name} {state.last_name}
                  </h4>
                  <h5 className="text-muted font-size-sm">
                    {state.service_no}
                  </h5>
                  <h6 className="text-muted font-size-sm">{state.gender}</h6>
                </div>
              </div>
            </div>
          </div>
          <div className="shadow-sm p-3 mb-3 bg-white rounded">
            <ul className="list-group list-group-flush">
              <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                <h6 className="mb-0 text-dark">Serial No</h6>
                <span className="text-info font-weight-bold">{state.id}</span>
              </li>
              <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                <h6 className="mb-0  text-dark">National ID</h6>
                <span className="text-info font-weight-bold">
                  {state.national_id}
                </span>
              </li>
              <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                <h6 className="mb-0 text-dark">Driving License</h6>
                <span className="text-info font-weight-bold">
                  {state.driving_license}
                </span>
              </li>
              <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                <h6 className="mb-0 text-dark">Service Number</h6>
                <span className="text-info font-weight-bold">
                  {state.service_no}
                </span>
              </li>
              <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                <h6 className="mb-0 text-dark">Registration Date</h6>
                <span className="text-info font-weight-bold">
                  {state.date_of_joining}
                </span>
              </li>
              <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                <h6 className="mb-0 text-dark">Marital Status</h6>
                <span className="font-weight-bold text-info">
                  {state.marital_status}
                </span>
              </li>
              <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                <h6 className="mb-0 text-dark">Person With Disability</h6>
                <span className="font-weight-bold text-info">
                  {state.with_disability === false ? "No" : "Yes"}
                </span>
              </li>
              <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                <h6 className="mb-0 text-dark">Designation</h6>
                <span className="font-weight-bold text-info">
                  {state.designation}
                </span>
              </li>
            </ul>
          </div>
        </div>
        {/* Info */}
        <div className="col-md-6 col-lg-12 col-xl-8">
          <div className="mb-3 shadow-sm p-1 mb-1 bg-white rounded">
            <div className="card-body">
              <div className="row">
                <div className="col-sm-4">
                  <h6 className="mb-0 text-dark">Staff Number</h6>
                </div>
                <span className="col-sm-8 text-muted font-weight-bold">
                  {state.staff_number}
                </span>
              </div>
              <hr />
              <div className="row">
                <div className="col-sm-4">
                  <h6 className="mb-0 text-dark">Email</h6>
                </div>
                <span className="col-sm-8 text-muted font-weight-bold">
                  {state.email}
                </span>
              </div>
              <hr />
              <div className="row">
                <div className="col-6 col-lg-2">
                  <h6 className="mb-0 text-dark">Phone</h6>
                </div>
                <span className="col-6 col-lg-4 text-muted font-weight-bold">
                  {state.tel_no}
                </span>
                <div className="col-6 col-lg-2">
                  <h6 className="mb-0 text-dark">Mobile</h6>
                </div>
                <span className="col-6 col-lg-4 text-muted font-weight-bold">
                  {state.mobile}
                </span>
              </div>
              <hr />
              <div className="row">
                <div className="col-6 col-lg-2">
                  <h6 className="mb-0 text-dark">County</h6>
                </div>
                <span className="col-6 col-lg-4 text-muted font-weight-bold">
                  {state.home_county}
                </span>
                <div className="col-6 col-lg-2">
                  <h6 className="mb-0 text-dark">Sub County</h6>
                </div>
                <span className="col-6 col-lg-4 text-muted font-weight-bold">
                  {state.home_sub_county}
                </span>
              </div>
              <hr />
              <div className="row">
                <div className="col-6 col-lg-2">
                  <h6 className="mb-0 text-dark">Ward</h6>
                </div>
                <span className="col-6 col-lg-4 text-muted font-weight-bold">
                  {state.home_ward}
                </span>
                <div className="col-6 col-lg-2">
                  <h6 className="mb-0 text-dark">Location</h6>
                </div>
                <span className="col-6 col-lg-4 text-muted font-weight-bold">
                  {state.home_location}
                </span>
              </div>

              <hr />
              <div className="row">
                <div className="col-sm-4">
                  <h6 className="mb-0 text-dark">Permanent Address</h6>
                </div>
                <span className="col-sm-8 text-muted font-weight-bold">
                  {state.permanent_address}
                </span>
              </div>
              <hr />
              <div className="row">
                <div className="col-sm-4 text-dark">
                  <h6 className="mb-0">Current Address</h6>
                </div>
                <span className="col-sm-8 text-muted font-weight-bold">
                  {state.current_address}
                </span>
              </div>
              <hr />
              <div className="row">
                <div className="col-sm-4 text-dark">
                  <h6 className="mb-0">Postal</h6>
                </div>
                <span className="col-sm-8 text-muted font-weight-bold">
                  {`${state.postal_code}-${state.zip_code}   ${state.postal_location}`}
                </span>
              </div>
            </div>
          </div>
          <div className="row gutters-sm">
            <div className="col-sm-6 mb-3">
              <div className="shadow-sm p-1 rounded bg-white ">
                <div className="card-body">
                  <div className="row">
                    <div className="col-sm-6">
                      <h6 className="mb-0 text-dark">Date of Birth</h6>
                    </div>
                    <span className="col-sm-6 text-muted font-weight-bold">
                      {state.dob}
                    </span>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-sm-6">
                      <h6 className="mb-0 text-dark">Birth Certh No</h6>
                    </div>
                    <span className="col-sm-6 text-muted font-weight-bold">
                      {state.birth_cert_no}
                    </span>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-sm-6">
                      <h6 className="mb-0 text-dark">Age</h6>
                    </div>
                    <span className="col-sm-6 text-muted font-weight-bold">
                      {state.age}
                    </span>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-sm-6">
                      <h6 className="mb-0 text-dark">Weight</h6>
                    </div>
                    <span className="col-sm-6 text-muted font-weight-bold">
                      {state.weight} Kg
                    </span>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-sm-6">
                      <h6 className="mb-0 text-dark">Height</h6>
                    </div>
                    <span className="col-sm-6 text-muted font-weight-bold">
                      {state.height} cms
                    </span>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-sm-6">
                      <h6 className="mb-0 text-dark">Blood group</h6>
                    </div>
                    <span className="col-sm-6 text-muted font-weight-bold">
                      {state.blood_group}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-6 mb-3">
              <div className="shadow-sm p-1 rounded bg-white ">
                <h5 className="text-dark text-center mt-2">Contract</h5>
                <div className="card-body">
                  <div className="row">
                    <div className="col-sm-6">
                      <h6 className="mb-0 text-dark">Type</h6>
                    </div>
                    <span className="col-sm-6 text-muted font-weight-bold">
                      {state.contract_type}
                    </span>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-sm-6">
                      <h6 className="mb-0 text-dark">Length</h6>
                    </div>
                    <span className="col-sm-6 text-muted font-weight-bold">
                      {state.contract_length}
                    </span>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-sm-6">
                      <h6 className="mb-0 text-dark">Salary</h6>
                    </div>
                    <span className="col-sm-6 text-muted font-weight-bold">
                      {state.basic_salary}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-lg-12 col-xl-12">
          <div className="d-flex flex-wrap mb-3 shadow-sm bg-white rounded">
            <h5 className="col-12 mt-2 text-center text-dark font-weight-bold">
              Emergency Contact
            </h5>
            <div className="col-12 col-md-6 col-lg-6">
              <div className="card-body">
                <div className="row">
                  <div className="col-sm-4">
                    <h6 className="mb-0 text-dark">Full Name</h6>
                  </div>
                  <span className="col-sm-8 text-muted font-weight-bold">
                    {`${state.emerg_first_name1} ${state.emerg_last_name1}`}
                  </span>
                </div>
                <hr />
                <div className="row">
                  <div className="col-sm-3">
                    <h6 className="mb-0 text-dark">Phone</h6>
                  </div>
                  <span className="col-sm-9 text-muted font-weight-bold">
                    {`${state.emerg_tel_no1}  /  ${state.emerg_mobile_no1}`}
                  </span>
                </div>
                <hr />
                <div className="row">
                  <div className="col-sm-6">
                    <h6 className="mb-0 text-dark">Gender</h6>
                  </div>
                  <span className="col-sm-6 text-muted font-weight-bold">
                    {state.emerg_gender1}
                  </span>
                </div>
                <hr />
                <div className="row">
                  <div className="col-sm-6 text-dark">
                    <h6 className="mb-0 text-dark">Relation</h6>
                  </div>
                  <span className="col-sm-6 text-muted font-weight-bold">
                    {state.emerg_relation1}
                  </span>
                </div>
                <hr />
              </div>
            </div>
            <div className="col-12 col-md-4 col-lg-6">
              <div className="card-body">
                <div className="row">
                  <div className="col-sm-4">
                    <h6 className="mb-0 text-dark">Full Name</h6>
                  </div>
                  <span className="col-sm-8 text-muted font-weight-bold">
                    {`${state.emerg_first_name1} ${state.emerg_last_name2}`}
                  </span>
                </div>
                <hr />
                <div className="row">
                  <div className="col-sm-3">
                    <h6 className="mb-0 text-dark">Phone</h6>
                  </div>
                  <span className="col-sm-9 text-muted font-weight-bold">
                    {`${state.emerg_tel_no2}  /  ${state.emerg_mobile_no2}`}
                  </span>
                </div>
                <hr />
                <div className="row">
                  <div className="col-sm-6">
                    <h6 className="mb-0 text-dark">Gender</h6>
                  </div>
                  <span className="col-sm-6 text-muted font-weight-bold">
                    {state.emerg_gender2}
                  </span>
                </div>
                <hr />
                <div className="row">
                  <div className="col-sm-6 text-dark">
                    <h6 className="mb-0 text-dark">Relation</h6>
                  </div>
                  <span className="col-sm-6 text-muted font-weight-bold">
                    {state.emerg_relation2}
                  </span>
                </div>
                <hr />
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-lg-12 col-xl-12">
          <div className="rounded bg-white mb-5">
            <div className="card-body">
              <div className="row">
                <div className="col-sm-4">
                  <h6 className="mb-0 text-dark">Additional Notes</h6>
                </div>
                <span className="col-sm-8 text-muted font-weight-bold">
                  {state.additional_notes}
                </span>
              </div>
              <hr />
              <div className="row">
                <div>
                  {/* <button
                    onClick={() => {
                      editDetails();
                    }}
                    className="btn btn-sm btn-info float-left"
                  >
                    Edit
                  </button>
                  <button
                    onClick={() => {
                      deleteStaff();
                    }}
                    className="btn btn-sm btn-danger float-right"
                  >
                    Delete
                  </button> */}
                </div>
              </div>
              <hr />
              <Toaster position="top-right" reverseOrder={false} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
