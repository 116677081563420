import React from "react";
import { toast, Toaster } from "react-hot-toast";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { API_URLS } from "../../../utils/api.routes";

function StudentCategoryDetails() {
  const { state } = useLocation();
  const navigate = useNavigate();

  const editCategory = () => {
    navigate("/admin/student-category/edit", {
      state: state,
    });
  };
  const deleteCategory = async () => {
    const headersList = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    await fetch(API_URLS.student_category.concat(state.id, "/"), {
      method: "DELETE",
      headers: headersList,
    })
      .then((res) => {
        if (res.status === 204) {
          toast.success(`Successfully Deleted Category`, {
            style: {
              color: "green",
              fontSize: "1.2em",
            },
            duration: 1000,
          });
          setTimeout(() => {
            navigate("/student-category");
          }, 1000);
        } else {
          toast.error(`${res.status} Error !!! ${res.statusText}`, {
            style: {
              color: "crimson",
              fontSize: "1.2em",
            },
            duration: 3000,
          });
        }
      })
      .catch((err) => {
        toast.error(`Error !!! Not Found`, {
          style: {
            color: "crimson",
            fontSize: "1.2em",
          },
          duration: 3000,
        });
      });
  };
  return (
    <div className="main-content">
      <div className="row justify-content-center">
        <div className="col-12 col-md-12 col-lg-12">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link className="font-weight-bold" to="/admin">
                  Home
                </Link>
              </li>
              <li className="breadcrumb-item">
                <Link
                  className="font-weight-bold"
                  to="/admin/student-category/list"
                >
                  Student Category
                </Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                <span className="font-weight-bold">
                  Student Category Details
                </span>
              </li>
            </ol>
          </nav>
        </div>
        <div className="col-12 col-md-6 col-sm-12">
          <div className="card shadow-sm p-3 mb-5 bg-body rounded">
            <div className="card-header">
              <h4 className="font-weight-bold">Category Details</h4>
            </div>
            <div className="card-body">
              <div className="py-4">
                <p className="clearfix">
                  <span className="float-left font-weight-bold">
                    Category ID
                  </span>
                  <span className="float-right text-muted font-weight-bold">
                    {state.id}
                  </span>
                </p>
                <hr />
                <p className="clearfix">
                  <span className="float-left font-weight-bold">
                    Category Code
                  </span>
                  <span className="float-right text-muted font-weight-bold">
                    {state.code}
                  </span>
                </p>
                <hr />
                <p className="clearfix">
                  <span className="float-left font-weight-bold">
                    Category Name
                  </span>
                  <span className="float-right text-muted font-weight-bold">
                    {state.name}
                  </span>
                </p>
                <hr />
                <div>
                  <button
                    onClick={() => {
                      editCategory();
                    }}
                    className="btn btn-primary float-left"
                  >
                    Edit
                  </button>
                  <button
                    onClick={() => {
                      deleteCategory();
                    }}
                    className="btn btn-danger float-right"
                  >
                    Delete
                  </button>
                  <Toaster position="top-right" reverseOrder={false} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StudentCategoryDetails;
