import { Check, Save } from "@mui/icons-material";
import { Box, CircularProgress, Fab } from "@mui/material";
import { green } from "@mui/material/colors";
import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import {
  toastDangerStyle,
  toastSuccessStyle,
} from "../../../components/shared/Custom.Styles";
import { API_URLS } from "../../../utils/api.routes";
import authHeader from "../../../services/auth-header";
import { DeleteButton } from "../../../components/custom/Custom.Delete";
import authService from "../../../services/auth.service";

export default function AttendanceInstanceActions({ params, rowId, setRowId }) {
  const currentUser = authService.getCurrentUser();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleSubmit = async () => {
    setLoading(true);
    const { id, time, status } = params.row;
    await fetch(API_URLS.attendance_instance.concat(id, "/"), {
      method: "PUT",
      body: JSON.stringify({
        time: time,
        status: status,
      }),
      headers: authHeader(),
    })
      .then((res) => {
        if (res.status === 200) {
          setSuccess(true);
          setRowId(null);
          setLoading(false);
          toast.success("Update Successful", toastSuccessStyle);
        } else {
          toast.error("Update Error", toastDangerStyle);
          setLoading(false);
        }
      })
      .catch((err) => {
        toast.error("An Error Occured", toastDangerStyle);
      });
  };

  useEffect(() => {
    if (rowId === params.id && success) setSuccess(false);
  }, [rowId]);

  const deleteAttendanceInstance = async () => {
    const { id } = params.row;
    await fetch(API_URLS.attendance_instance.concat(id, "/"), {
      method: "DELETE",
      headers: authHeader(),
    })
      .then((res) => {
        if (res.status === 204) {
          toast.success("Deleted Successful", toastSuccessStyle);
        } else {
          toast.error("Deleting Error", toastDangerStyle);
        }
      })
      .catch((err) => {
        toast.error("An Error Occured", toastDangerStyle);
      });
  };
  return (
    <Box
      sx={{
        m: 1,
        position: "relative",
      }}
    >
      {success ? (
        <Fab
          color="primary"
          sx={{
            width: 40,
            height: 40,
            bgcolor: green[500],
            "&:hover": { bgcolor: green[700] },
          }}
        >
          <Check />
        </Fab>
      ) : (
        <Fab
          color="primary"
          sx={{
            width: 40,
            height: 40,
          }}
          disabled={params.id !== rowId || loading}
          onClick={handleSubmit}
        >
          <Save />
        </Fab>
      )}
      {loading && (
        <CircularProgress
          size={52}
          sx={{
            color: green[500],
            position: "absolute",
            top: -6,
            left: -6,
            zIndex: 1,
          }}
        />
      )}
      {/* Render the delete Button to supper Admin and one assigned the role of Delete Grade */}
      {currentUser.roles.includes("SUPER_ADMIN") ||
      currentUser.roles.includes("DELETE_GRADE") ? (
        <DeleteButton onDelete={deleteAttendanceInstance} />
      ) : (
        <></>
      )}
    </Box>
  );
}
