import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { roles } from "./roles.permissions";
import { API_URLS } from "../../utils/api.routes";
import UsersTable from "./UsersTable";
import authHeader from "../../services/auth-header";
import { Toaster, toast } from "react-hot-toast";
import {
  toastDangerStyle,
  toastSuccessStyle,
  toastWarnigStyle,
} from "../../components/shared/Custom.Styles";
import CustomAccordion from "./CustomAccordion";
import { Button, Checkbox, FormControlLabel } from "@mui/material";
import { EnhancedEncryption } from "@mui/icons-material";
import authService from "../../services/auth.service";
import AccessDenied from "../../components/custom/Custom.AccessDenied";

export default function AssignPermissions() {
  const currentUser = authService.getCurrentUser();

  const [options, setOptions] = useState(roles);
  const [users, setUsers] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);

  useEffect(() => {
    const fetchUsers = async () => {
      const res = await fetch(API_URLS.users);
      const data = await res.json();
      setUsers(data);
    };
    fetchUsers();
  }, []);
  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      setSelectedRoles([...selectedRoles, value]);
    } else {
      setSelectedRoles(selectedRoles.filter((v) => v !== value));
    }
  };
  const handleSelectionChange = (selectedItems) => {
    setSelectedUsers(selectedItems);
  };

  const handleSelectAllChange = (event, group) => {
    const { checked } = event.target;
    const values = checked ? group.items.map((item) => item.value) : [];
    setSelectedRoles([
      ...selectedRoles.filter(
        (v) => !group.items.some((item) => item.value === v)
      ),
      ...values,
    ]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    for (let i = 0; i < selectedUsers.length; i++) {
      for (let j = 0; j < selectedRoles.length; j++) {
        await fetch(
          API_URLS.user_roles.concat(
            `/users/${selectedUsers[i]}/roles/${selectedRoles[j]}`
          ),
          {
            method: "POST",
            headers: authHeader(),
          }
        )
          .then((res) => {
            if (res.status === 200) {
              console.log("Successfully Assigned");
              if (
                i === selectedUsers.length - 1 &&
                j === selectedRoles.length - 1
              ) {
                toast.success(`Successfully Assigned Roles`, toastSuccessStyle);
              }
            } else {
              toast.error(
                `${res.status} Error !!! ${res.statusText}`,
                toastDangerStyle
              );
            }
          })
          .catch((err) => {
            toast.error(`Error, Not Found !!!`, toastDangerStyle);
          });
      }
    }
  };
  /* Role based access validation */
  if (
    !currentUser.roles.includes("ASSIGN_ROLE") &&
    !currentUser.roles.includes("UNASSIGN_ROLE") &&
    !currentUser.roles.includes("SUPER_ADMIN")
  ) {
    return <AccessDenied />;
  }
  return (
    <div className="main-content">
      <div className="row">
        <div className="col-12 col-md-12 col-lg-12">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link className="font-weight-bold" to="/admin">
                  Home
                </Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                <span className="font-weight-bold">Permissions & Roles</span>
              </li>
            </ol>
          </nav>
        </div>
        <div className="col-12 col-md-12 col-lg-12">
          {currentUser.roles.includes("ASSIGN_ROLE") ||
          currentUser.roles.includes("SUPER_ADMIN") ? (
            <UsersTable
              data={users}
              onSelectionChange={handleSelectionChange}
            />
          ) : (
            <></>
          )}
          {selectedUsers && <p>Selected Users: {selectedUsers.join(", ")}</p>}
        </div>
        {currentUser.roles.includes("ASSIGN_ROLE") ||
        currentUser.roles.includes("SUPER_ADMIN") ? (
          <div className="col-12 col-md-12 col-lg-12">
            <div className="">
              <form onSubmit={handleSubmit}>
                <div className="col-12 d-flex justify-content-end">
                  <Button
                    variant="contained"
                    endIcon={<EnhancedEncryption />}
                    type="submit"
                  >
                    Assign Role
                  </Button>
                </div>
                <div className="d-flex flex-wrap">
                  {options.map((group) => (
                    <div key={group.title} className="col-4 p-1 mt-1">
                      <CustomAccordion headerText={group.title}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              name={`${group.title} Select All`}
                              size="small"
                              color="success"
                              checked={
                                selectedRoles.filter((v) =>
                                  group.items.some((item) => item.value === v)
                                ).length === group.items.length
                              }
                              onChange={(event) =>
                                handleSelectAllChange(event, group)
                              }
                            />
                          }
                          label="Select All"
                          labelPlacement="end"
                        />
                        {group.items.map((option) => (
                          <div key={option.id} className="">
                            <FormControlLabel
                              value="top"
                              className="col-4"
                              control={
                                <Checkbox
                                  name={option.label}
                                  value={option.value}
                                  checked={selectedRoles.includes(option.value)}
                                  onChange={handleCheckboxChange}
                                  size="small"
                                  color="success"
                                />
                              }
                              label={option.label}
                              labelPlacement="end"
                            />
                          </div>
                        ))}
                      </CustomAccordion>
                    </div>
                  ))}
                </div>

                <Toaster position="top-right" reverseOrder={false} />
              </form>
            </div>
          </div>
        ) : (
          <></>
        )}
        <div className="col-12"></div>
      </div>
    </div>
  );
}
