import React from "react";
import "./assets/css/bootstrap.min.css";
import "./assets/css/style.css";

import "./assets/lib/owlcarousel/assets/owl.carousel.min.css";
import "./assets/lib/animate/animate.min.css"; // Import the CSS file
import { Link } from "react-router-dom";

export default function ContactUs() {
  return (
    <div className="col-md-12" style={{ top: 80 }}>
      <main className="container">
        <section style={{ marginBottom: 20 }}>
          <h2 style={{ color: "#343a40", fontWeight: "bold" }}>About Us</h2>
          <p>
            Our school system is dedicated to providing the highest quality
            education to students of all ages. With a focus on personalized
            learning, we strive to create an environment where every student can
            thrive and reach their full potential.
          </p>
          <p>
            Our team of experienced teachers are passionate about their subjects
            and committed to helping students achieve their academic goals. We
            offer a wide range of courses to suit all interests and abilities,
            and our innovative teaching methods ensure that every student is
            engaged and challenged.
          </p>
          <p>
            At our school system, we believe that education should be accessible
            to everyone. We offer a range of financial aid and scholarship
            options to help ensure that all students can receive the education
            they deserve.
          </p>
        </section>

        <section style={{ marginBottom: 20 }}>
          <h2 style={{ color: "#343a40", fontWeight: "bold" }}>Our Mission</h2>
          <p>
            Our mission is to provide an exceptional education to every student,
            regardless of their background or circumstances. We are committed to
            creating a safe and inclusive learning environment, where students
            can develop their knowledge and skills, and where creativity and
            critical thinking are encouraged.
          </p>
        </section>

        <section style={{ marginBottom: 20 }}>
          <h2 style={{ color: "#343a40", fontWeight: "bold" }}>Our Vision</h2>
          <p>
            Our vision is to be a leader in innovative and effective education,
            and to empower our students to become successful and contributing
            members of society. We aim to provide a comprehensive and
            well-rounded education, with a focus on academic excellence,
            personal growth, and social responsibility.
          </p>
        </section>
      </main>

      <footer
        style={{
          backgroundColor: "#343a40",
          color: "#fff",
          padding: 10,
          marginTop: 20,
        }}
      >
        <p>&copy; 2023 School System. All rights reserved.</p>
      </footer>
    </div>
  );
}
